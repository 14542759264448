<template>

  <div class="flex flex-col">
    <div
        class="flex relative w-full aspect-auto h-64 bg-brand-dark-gray p-4">
      <photo-provider class="relative z-10" v-if="!loading">
        <photo-consumer :src="user.cover_photo">

          <ion-img
              :class="{
                'opacity-0' : !user.cover_photo
              }"
              class="absolute top-0 left-0 w-full h-full object-cover bg-cover z-20" :src="user.cover_photo" />
      <div class="absolute bottom-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-brand-black z-30"></div>

        </photo-consumer>
      </photo-provider>
    </div>


    <div class="flex w-full  px-4 space-x-3 -mt-10 z-50">
      <div class="flex relative">
        <photo-provider class="relative z-40" v-if="!loading">
          <photo-consumer :src="user.avatar">
            <user-avatar
                class="border-[4px] border-brand-black rounded-full"
                :account-type-badge="false"
                :user="user as UserBasicInformationInterface" :size="24"/>
          </photo-consumer>
        </photo-provider>
        <ion-skeleton-text v-if="loading" animated
                           class="w-32 bg-brand-dark-gray h-32 m-0 rounded-full border-4 border-transparent"/>
        <div class="absolute -bottom-3 right-0" @click="directMessage"
             v-if="!loading && !isAuthUser && allowDirectMessage">
          <div class="flex h-14 w-14 z-50">
            <div
                class="flex rounded-full w-full h-full bg-brand-dark-gray text-brand-light-gray border-4 border-brand-black">
              <svg class="m-auto h-4 fill-current" viewBox="0 0 20 20"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0.375458 3.66957C0.665645 1.98488 2.00472 0.746476 3.69477 0.489325C5.31411 0.242933 7.53559 0 10 0C12.4644 0 14.6859 0.242933 16.3052 0.489325C17.9953 0.746476 19.3344 1.98488 19.6245 3.66957C19.8268 4.84372 20 6.33525 20 8C20 9.66475 19.8268 11.1563 19.6245 12.3304C19.3344 14.0151 17.9953 15.2535 16.3052 15.5107C14.8238 15.7361 12.8384 15.9586 10.6241 15.9949L4.50873 19.6085C3.84211 20.0024 3 19.5219 3 18.7476V15.344C1.64656 14.8939 0.624559 13.7766 0.375458 12.3304C0.173212 11.1563 0 9.66475 0 8C0 6.33525 0.173212 4.84372 0.375458 3.66957ZM4 6C4 5.44772 4.44772 5 5 5H15C15.5523 5 16 5.44772 16 6C16 6.55228 15.5523 7 15 7H5C4.44771 7 4 6.55228 4 6ZM4 10C4 9.44772 4.44772 9 5 9H9C9.55228 9 10 9.44772 10 10C10 10.5523 9.55228 11 9 11H5C4.44772 11 4 10.5523 4 10Z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col max-w-fit whitespace-nowrap overflow-hidden mt-auto mb-3">
        <div v-if="user"
             :class="{
              'opacity-0' : loading
             }"
             class="flex space-x-1 mt-1.5">
          <user-badges class="flex" :user="user"/>
          <user-account-type-badge class="my-auto flex" v-if="user" :user="user"/>
        </div>

        <div v-if="!loading" class="">
          <div class="font-medium text-white text-2xl truncate">{{ user.name }}</div>
        </div>
        <ion-skeleton-text v-if="loading" animated class="h-7 m-0 mt-1 w-7/12 rounded-lg"/>

        <div class="flex space-x-1 text-brand-light-gray">
          <div v-if="!loading && showLocation && user.location" class="flex space-x-1">
            <svg class="my-auto w-3 h-3 fill-current" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 384 512">
              <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                  d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
            </svg>
            <div class="my-auto text-sm">{{ user.location.name }}</div>
          </div>
          <ion-skeleton-text v-if="loading" animated class="h-4 m-0 mt-1 w-32 rounded-lg"/>
        </div>

      </div>
    </div>


    <div class="flex w-full grid grid-cols-4 mt-6">

      <template v-if="loading">
        <div class="flex flex-col items-center w-full">
          <div class="flex flex-col items-center">
            <ion-skeleton-text animated class="h-6 m-0 w-6 rounded-lg"/>
            <div class="text-brand-light-gray">Followers</div>
          </div>
        </div>
        <div class="flex flex-col items-center w-full">
          <div class="flex flex-col items-center">
            <ion-skeleton-text animated class="h-6 m-0 w-6 rounded-lg"/>
            <div class="text-brand-light-gray">Following</div>
          </div>
        </div>
        <div class="flex flex-col items-center w-full">
          <div class="flex flex-col items-center">
            <ion-skeleton-text animated class="h-6 m-0 w-6 rounded-lg"/>
            <div class="text-brand-light-gray">Friends</div>
          </div>
        </div>
        <div class="flex flex-col items-center w-full">
          <div class="flex flex-col items-center">
            <ion-skeleton-text animated class="h-6 m-0 w-6 rounded-lg"/>
            <div class="text-brand-light-gray">Winks</div>
          </div>
        </div>
      </template>
      <template v-if="!loading">
        <div @click="viewFollowers" class="flex flex-col items-center -space-y-1 w-full">
          <div class="text-white text-xl font-medium">{{ user.followers }}</div>
          <div class="text-brand-light-gray">{{ pluralize('Follower', user.followers) }}</div>
        </div>
        <div @click="viewFollowing" class="flex flex-col items-center -space-y-1 w-full">
          <div class="text-white text-xl font-medium">{{ user.following }}</div>
          <div class="text-brand-light-gray">Following</div>
        </div>
        <div @click="viewFriends" class="flex flex-col items-center -space-y-1 w-full relative">
          <div class="text-white text-xl font-medium relative">
            <div>{{ user.friends }}</div>
            <div v-if="user.friend_requests && isAuthUser" class="absolute -top-1 -right-2.5">
              <div class="w-4 h-4 bg-brand-lime flex rounded-full">
                <div class="m-auto text-xxs text-brand-black leading-normal font-medium">{{ user.friend_requests }}
                </div>
              </div>
            </div>
          </div>
          <div class="text-brand-light-gray">{{ pluralize('Friend', user.friends) }}</div>
        </div>
        <div @click="viewWinks" class="flex flex-col items-center -space-y-1 w-full">
          <div class="text-white text-xl font-medium">{{ user.winks }}</div>
          <div class="text-brand-light-gray">{{ pluralize('Wink', user.winks) }}</div>
        </div>
        <div v-if="false" class="flex flex-col items-center -space-y-1 w-full">
          <div class="text-white text-xl font-medium">{{ user.likes }}</div>
          <div class="text-brand-light-gray">{{ pluralize('Like', user.likes) }}</div>
        </div>
      </template>

    </div>

    <div v-if="!isAuthUser" class="flex px-4 mt-7 justify-center">
      <ion-skeleton-text v-if="loading" animated class="w-full h-14 m-0 rounded-full"/>

      <div class="flex w-full space-x-2" v-if="!isAuthUser && !loading">
        <div
            @click="follow"
            :class="{
              'bg-brand-lime text-brand-black': !isFollowing,
              'border-brand-dark-gray border-2 text-white': isFollowing
            }"
            class="flex flex-grow rounded-lg p-2 px-2">
          <span v-if="!followBusy" class="m-auto font-medium">{{ isFollowing ? 'Unfollow' : 'Follow' }}</span>
          <svg v-else
               :class="{
                'text-white' : isFollowing,
                'text-brand-dark-gray' : !isFollowing,
               }"
               class="animate-spin h-5 w-5 m-auto z-50"
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                    stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>

        <template v-if="userFriendRequests.includes(user.id)">
          <div
              @click="friendRequest"
              v-if="!isFriends && !isFriendRequested && user.preferences[PreferenceKey.AllowFriendRequests] === '1'"
              class="flex flex-grow rounded-lg bg-brand-dark-gray text-white p-2 px-2">
            <span v-if="!friendsBusy" class="m-auto font-medium">Accept friend request</span>
            <svg v-else class="animate-spin h-5 w-5 text-white m-auto z-50"
                 xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                      stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </template>

        <template v-if="!userFriendRequests.includes(user.id)">
          <div
              @click="friendRequest"
              v-if="!isFriends && !isFriendRequested && user.preferences[PreferenceKey.AllowFriendRequests] === '1'"
              class="flex flex-grow rounded-lg bg-brand-dark-gray text-white p-2 px-2">
            <span v-if="!friendsBusy" class="m-auto font-medium">Send friend request</span>
            <svg v-else class="animate-spin h-5 w-5 text-white m-auto z-50"
                 xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                      stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>

          <div
              @click="cancelFriendRequest"
              v-if="isFriendRequested"
              class="flex flex-grow rounded-lg border-brand-dark-gray border-2 text-white p-2 px-2">
            <span v-if="!friendsBusy" class="mx-auto font-medium">Cancel friend request</span>
            <svg v-else class="animate-spin h-5 w-5 text-white m-auto z-50"
                 xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                      stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>

        </template>


        <div
            @click="removeFriend"
            v-if="isFriends" class="flex flex-grow rounded-lg border-brand-dark-gray border-2 text-white p-2 px-2">
          <span v-if="!friendsBusy" class="mx-auto font-medium">Unfriend</span>
          <svg v-else class="animate-spin h-5 w-5 text-white m-auto z-50"
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                    stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>


        <div @click="wink"
             class="flex rounded-lg border-2 border-brand-dark-gray bg-brand-dark-gray text-white p-2 px-4">
          <span v-if="!winkBusy" class="m-auto font-medium">Wink 😉</span>
          <svg v-else class="animate-spin h-5 w-5 text-white m-auto z-50"
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                    stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>
    </div>

    <div class=" bg-brand-black flex flex-col mt-3 border-b border-brand-dark-gray z-50">
      <ion-segment class="relative z-50" @ionChange="profileTabChanged" :swipe-gesture="false" mode="ios"
                   :value="tab">
        <ion-segment-button v-if="isAuthUser && !loading" value="views">
          <ion-label>
            <svg class="fill-current w-6 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                  d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
            </svg>
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="personal">
          <ion-label>
            <svg class="fill-current" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M81.1 416H366.9c-7.8-54.3-54.4-96-110.9-96H192c-56.4 0-103.1 41.7-110.9 96zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM224 272a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"/></svg>
          </ion-label>
        </ion-segment-button>

        <ion-segment-button v-if="!loading && user.subscribed" value="reviews">
          <ion-label>
            <svg height="19" class="fill-current my-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM625 177L497 305c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L591 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
          </ion-label>
        </ion-segment-button>

        <ion-segment-button value="gallery">
          <ion-label>
            <svg class="fill-current" width="24" height="24" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M4 11H10C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10V4C11 3.73478 10.8946 3.48043 10.7071 3.29289C10.5196 3.10536 10.2652 3 10 3H4C3.73478 3 3.48043 3.10536 3.29289 3.29289C3.10536 3.48043 3 3.73478 3 4V10C3 10.2652 3.10536 10.5196 3.29289 10.7071C3.48043 10.8946 3.73478 11 4 11ZM14 11H20C20.2652 11 20.5196 10.8946 20.7071 10.7071C20.8946 10.5196 21 10.2652 21 10V4C21 3.73478 20.8946 3.48043 20.7071 3.29289C20.5196 3.10536 20.2652 3 20 3H14C13.7348 3 13.4804 3.10536 13.2929 3.29289C13.1054 3.48043 13 3.73478 13 4V10C13 10.2652 13.1054 10.5196 13.2929 10.7071C13.4804 10.8946 13.7348 11 14 11ZM4 21H10C10.2652 21 10.5196 20.8946 10.7071 20.7071C10.8946 20.5196 11 20.2652 11 20V14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13H4C3.73478 13 3.48043 13.1054 3.29289 13.2929C3.10536 13.4804 3 13.7348 3 14V20C3 20.2652 3.10536 20.5196 3.29289 20.7071C3.48043 20.8946 3.73478 21 4 21ZM14 21H20C20.2652 21 20.5196 20.8946 20.7071 20.7071C20.8946 20.5196 21 20.2652 21 20V14C21 13.7348 20.8946 13.4804 20.7071 13.2929C20.5196 13.1054 20.2652 13 20 13H14C13.7348 13 13.4804 13.1054 13.2929 13.2929C13.1054 13.4804 13 13.7348 13 14V20C13 20.2652 13.1054 20.5196 13.2929 20.7071C13.4804 20.8946 13.7348 21 14 21Z"/>
            </svg>
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="feed">
          <ion-label>
            <svg class="fill-current" width="24" height="24" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M0 11.3875H8.00156V3.39062H0V11.3875ZM9.99844 4.38906V6.39062H24V4.38906H9.99844ZM9.99844 10.3891H24V8.3875H9.99844V10.3891ZM9.99844 16.3891H24V14.3875H9.99844V16.3891ZM9.99844 20.3875H24V18.3859H9.99844V20.3875ZM0 21.3906H8.00156V13.3891H0V21.3906Z"/>
            </svg>
          </ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>

    <template v-if="loading">
      <div class="flex flex-col p-4">
        <ion-skeleton-text animated class="h-5 m-0 w-4/12 rounded-lg"/>
        <div class="flex flex-col space-y-2 mt-4">
          <ion-skeleton-text animated class="h-3 m-0 w-12/12 rounded-lg"/>
          <ion-skeleton-text animated class="h-3 m-0 w-12/12 rounded-lg"/>
          <ion-skeleton-text animated class="h-3 m-0 w-10/12 rounded-lg"/>
          <ion-skeleton-text animated class="h-3 m-0 w-5/12 rounded-lg"/>
        </div>
      </div>
    </template>
    <template v-if="!loading">

      <user-profile-views v-if="isAuthUser && tab === 'views' && profileViewReceipts" :user="user"/>
      <div class="flex flex-grow py-10 px-5" v-if="isAuthUser && tab === 'views' && !profileViewReceipts">
        <div class="flex flex-col space-y-2 items-center">

          <div class="flex flex-col w-full items-center space-y-1 px-2">
            <div class="flex text-white text-lg font-medium text-center">It's a 2-way street!</div>
            <div class="text-brand-light-gray text-sm text-center">If you want to see who's looking at you then you must
              be prepared for others to know you're looking at them!
            </div>
          </div>

          <div class="text-xxs text-brand-light-gray text-opacity-50">You can opt back into profile view receipts from
            your account settings.
          </div>

        </div>

      </div>


      <user-profile-reviews v-if="tab === 'reviews'" :user="user" :is-auth-user="isAuthUser" />

      <user-profile-personal v-if="tab === 'personal'" :user="user" :is-auth-user="isAuthUser"/>

      <template v-if="tab==='gallery'">
        <user-profile-gallery :user-id="user.id"
                              :is-auth-user="isAuthUser"/>
      </template>
      <user-profile-posts v-if="tab === 'feed'" :user-id="user.id" :auth-user="authUser" :is-auth-user="isAuthUser"/>
    </template>

  </div>
</template>
<script lang="ts">


import {computed, defineComponent, onMounted, ref, Ref} from "vue";
import {
  alertController,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonSkeletonText, modalController,
  IonImg,
  useIonRouter, isPlatform
} from "@ionic/vue";
import ApiService from "@/services/api/apiService";
import pluralize from "pluralize"
import {useFeed} from "@/composables/useFeed";
import UserProfilePosts from "@/components/UserProfilePosts.vue";
import {useUser} from "@/composables/useUser";
import UserProfileGallery from "@/components/UserProfileGallery.vue";
import UserProfilePersonal from "@/components/UserProfilePersonal.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import ProfileFriendsUsersList from "@/views/user/profile/ProfileFriendsUsersList.vue";
import ProfileWinksUsersList from "@/views/user/profile/ProfileWinksUsersList.vue";
import {usePremium} from "@/composables/usePremium";
import {useVerification} from "@/composables/useVerification";
import UserAccountTypeBadge from "@/components/UserAccountTypeBadge.vue";
import UserProfileViews from "@/components/UserProfileViews.vue";
import UserBadges from "@/components/UserBadges.vue";
import ProfileFollowingUsersList from "@/views/user/profile/ProfileFollowingUsersList.vue";
import ProfileFollowersUsersList from "@/views/user/profile/ProfileFollowersUsersList.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import {UserInterface} from "@/interfaces/UserInterface";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import {Haptics, ImpactStyle} from "@capacitor/haptics";
import UserProfileReviews from "@/components/UserProfileReviews.vue";

export default defineComponent({
  name: 'UserProfile',
  components: {
    UserAvatar,
    UserBadges,
    UserProfileViews,
    UserAccountTypeBadge,
    UserProfilePosts,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    UserProfileGallery,
    UserProfilePersonal,
    UserProfileReviews,
    IonSkeletonText,
    IonImg
  },
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  emits: ['onSetUser'],
  setup(props, {emit}) {

    const loading = ref(true)
    const ionRouter = useIonRouter()
    const tab = ref('personal')

    const user: Ref<UserInterface | undefined> = ref()
    const authUser: Ref<UserInterface | undefined> = ref()
    const userFriendRequests = ref([])

    const friendsBusy = ref(false)

    const profileViewReceipts = computed(() => {
      if (!authUser.value) return false
      return Number(authUser.value?.preferences[PreferenceKey.ProfileViewReceipts])
    })

    onMounted(async () => {

      try {
        await getData()
      } catch (e: any) {
        if (e.status === 404) {

          const alert = await alertController.create({
            header: 'Not available',
            subHeader: 'The requested user is no longer available.',
            buttons: [
              {
                text: 'Ok',
                handler: async () => {
                  alertController.dismiss()
                  await goBack()
                }
              }
            ]
          })
          return await alert.present()

        }
      }

      Observable.subscribe(async (response: any) => {
        if ([ObservableEvent.UserChanged, ObservableEvent.UserGalleryChanged].includes(response.event)) {
          await getData()
        }

        if ([ObservableEvent.UserReviewsChanged].includes(response.event)) {
          await getUser()
        }
      })

      if (isAuthUser.value) tab.value = 'views'
      if (!profileViewReceipts.value) tab.value = 'personal'

      loading.value = false

    })

    const getUser = async () => {
      user.value = await ApiService.User().getById(props.userId)
    }

    const getData = async () => {

      authUser.value = await ApiService.User().authUser()
      user.value = authUser.value


      if (authUser.value?.id !== props.userId) await getUser()

      userFriendRequests.value = (await ApiService.User().getFriendRequests()).map((user:UserBasicInformationInterface)=>{
        return user.id
      })

      emit('onSetUser', user.value)

    }

    const showLocation = computed(() => {
      return Boolean(Number(user.value?.preferences[PreferenceKey.ProfileShowLocation]))
    })

    const isAuthUser = computed(() => {
      if (!authUser.value) return false
      if (authUser.value?.id === user.value?.id) return true
      return false
    })

    const isFollowing = computed(() => {
      if (loading.value) return false
      return user.value?.is_following
    })

    const isFriends = computed(() => {
      if (loading.value) return false
      return user.value?.is_friend
    })

    const isFriendRequested = computed(() => {
      if (loading.value) return false
      return user.value?.is_friend_requested
    })

    const goBack = async () => {
      await ionRouter.back()
    }

    const profileTabChanged = (event: any) => {
      tab.value = event.detail.value
    }

    const createPost = async () => {
      const feed = useFeed()
      await feed.post(authUser.value as UserInterface)
    }

    const followBusy = ref(false)
    const follow = async () => {
      if (followBusy.value || !user.value) return

      if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});
      // if (authUser.value && !authUser.value.id_verified) return await useVerification().presentVerificationRequired('You must be verified to follow other users.')

      if (isFollowing.value) {

        const alert = await alertController.create({
          header: 'Unfollow',
          message: `Are you sure you want to unfollow ${user.value.name}?`,
          buttons: [
            {
              text: 'Cancel',
              handler: async () => {
                followBusy.value = false
                await alertController.dismiss(false)
              }
            },
            {
              text: 'Yes',
              role: 'destructive',
              handler: async () => {
                followBusy.value = true
                alertController.dismiss()
                await useUser().unfollowUser(user.value?.id as number)
                await getUser()
                return followBusy.value = false
              }
            },
          ]
        })
        await alert.present()

      }
      if (!isFollowing.value) {
        followBusy.value = true
        await useUser().followUser(user.value.id)
        await getUser()
        return followBusy.value = false
      }
    }

    const friendRequest = async () => {
      if (friendsBusy.value || !user.value) return
      if (!authUser.value?.id_verified) return await useVerification().presentVerificationRequired('You must be verified to send friend requests.')
      friendsBusy.value = true

      await useUser().makeFriendRequest(user.value.id)
      await getUser()
      friendsBusy.value = false
    }

    const cancelFriendRequest = async () => {
      if (friendsBusy.value) return
      friendsBusy.value = true
      try {
        await useUser().cancelFriendRequest(user.value as any, async (u: any) => {
          await getUser()
          friendsBusy.value = false
        })
      } catch (e) {
        friendsBusy.value = false
      }
    }

    const removeFriend = async () => {
      if (friendsBusy.value) return
      friendsBusy.value = true
      try {
        await useUser().removeFriend(user.value as any, async (u: any) => {
          await getUser()
          friendsBusy.value = false
        })
      } catch (e) {
        friendsBusy.value = false
      }
    }

    const winkBusy = ref(false)
    const wink = async () => {
      if (!user.value) return
      if (winkBusy.value) return
      if (!authUser.value?.id_verified) return await useVerification().presentVerificationRequired('You must be verified to wink at members.')
      winkBusy.value = true

      await useUser().winkAtUser(user.value.id, user.value?.name)
      await getUser()
      winkBusy.value = false
    }

    const viewFriends = async () => {

      if (isAuthUser.value) {
        return ionRouter.push({
          name: 'Friends'
        })
      }

      if (!user.value?.friends) return

      const sheet = await modalController.create({
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        component: ProfileFriendsUsersList,
        componentProps: {
          userId: user.value?.id
        },
        mode: 'ios'
      })
      await sheet.present()
      const data = (await sheet.onDidDismiss()).data

      if (Object.prototype.hasOwnProperty.call(data, 'user')) {
        ionRouter.navigate({
          name: 'PineappleUserProfile',
          params: {
            userId: data.user.id
          }
        }, 'forward', 'push')
      }

    }

    const viewFollowing = async () => {

      if (!user.value?.following) return

      const sheet = await modalController.create({
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        component: ProfileFollowingUsersList,
        componentProps: {
          userId: user.value?.id
        },
        mode: 'ios'
      })
      await sheet.present()
      const data = (await sheet.onDidDismiss()).data

      if (Object.prototype.hasOwnProperty.call(data, 'user')) {
        ionRouter.navigate({
          name: 'PineappleUserProfile',
          params: {
            userId: data.user.id
          }
        }, 'forward', 'push')
      }

    }

    const viewFollowers = async () => {

      if (!user.value?.followers) return

      const sheet = await modalController.create({
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        component: ProfileFollowersUsersList,
        componentProps: {
          userId: user.value?.id
        },
        mode: 'ios'
      })
      await sheet.present()
      const data = (await sheet.onDidDismiss()).data

      if (Object.prototype.hasOwnProperty.call(data, 'user')) {
        ionRouter.navigate({
          name: 'PineappleUserProfile',
          params: {
            userId: data.user.id
          }
        }, 'forward', 'push')
      }

    }

    const viewWinks = async () => {

      if (!user.value?.winks) return

      const sheet = await modalController.create({
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        component: ProfileWinksUsersList,
        componentProps: {
          userId: user.value?.id
        },
        mode: 'ios'
      })
      await sheet.present()
      const data = (await sheet.onDidDismiss()).data

      if (Object.prototype.hasOwnProperty.call(data, 'user')) {
        ionRouter.navigate({
          name: 'PineappleUserProfile',
          params: {
            userId: data.user.id
          }
        }, 'forward', 'push')
      }

    }

    const directMessage = async () => {

      if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});
      if (authUser.value && !authUser.value.id_verified) return await useVerification().presentVerificationRequired('You must be verified & a premium member to direct message users.')

      if (!authUser.value?.subscribed) return usePremium().presentPayWall({
        text: 'Upgrade to send direct messages, premium members can respond immediately.'
      })

      const chat = await ApiService.Chat().with(user.value?.id as number)

      ionRouter.navigate({
        name: 'FlirtViewChat',
        params: {
          chatId: chat.id
        }
      }, 'forward', 'push')

    }

    const allowDirectMessage = computed(() => {
      if (user.value?.preferences[PreferenceKey.OnlyFriendsCanDirectMessage] === '0') return true
      return isFriends.value;
    })

    return {
      loading,
      friendsBusy,
      winkBusy,
      wink,
      directMessage,
      friendRequest,
      removeFriend,
      isAuthUser,
      isFriends,
      isFriendRequested,
      user,
      createPost,
      pluralize,
      profileTabChanged,
      tab,
      goBack,
      authUser,
      showLocation,
      viewFriends,
      viewWinks,
      PreferenceKey,
      allowDirectMessage,
      cancelFriendRequest,
      profileViewReceipts,
      isFollowing,
      follow,
      followBusy,
      viewFollowers,
      viewFollowing,
      userFriendRequests
    }
  }
})
</script>
