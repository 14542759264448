<template>
  <div @click="showSubscriptionPage" class="flex">
    <slot name="body">
      <button
          class="flex bg-brand-lime rounded-full w-full h-14 font-medium text-xl text-brand-black">
        <div class="flex m-auto space-x-2.5">
          <div class="my-auto pt-0.5">
            {{ label }}
          </div>
        </div>
      </button>
    </slot>
  </div>
</template>
<script lang="ts">

import {defineComponent, inject, onMounted, Ref} from "vue";
import {isPlatform, modalController} from "@ionic/vue";
import SubscriptionPage from "@/views/common/SubscriptionPage.vue";
import {UserInterface} from "@/interfaces/UserInterface";
import PWASubscriptionPage from "@/views/common/PWASubscriptionPage.vue";

export default defineComponent({
  name: 'InAppSubscription',
  props: {
    label: {
      type: String,
      default: 'Upgrade to Pineapple Premium'
    }
  },
  setup() {

    const user: Ref<UserInterface> = inject('user') as Ref<UserInterface>

    const showSubscriptionPage = async () => {
      if (!isPlatform('capacitor')){

        const modal = await modalController.create({
          component: PWASubscriptionPage
        })
        await modal.present()

      } else {
        const modal = await modalController.create({
          component: SubscriptionPage
        })
        await modal.present()
        const {data} = await modal.onWillDismiss()
        if (data && data.subscribed) user.value.subscribed = true
      }
    }

    return {
      showSubscriptionPage
    }

  }
});

</script>
