<template>
  <div class="flex flex-col w-full space-y-2 relative">
    <div
        :class="{
          'border-brand-pink' : error,
          'border-brand-lime' : hasFocus
        }"
        class="flex w-full flex-col bg-brand-dark-gray rounded-lg h-14 relative border border-brand-dark-gray relative">
      <div v-if="$slots['icon-start']" class="flex w-6 px-4 h-full absolute top-0 left-0">
        <div class="m-auto text-brand-light-gray">
          <slot name="icon-start"></slot>
        </div>
      </div>
      <div
          :class="{
            'pl-12': $slots['icon-start'],
          }"
          class="flex px-4 my-auto relative z-50">
      <ion-input
          class="text-white text-lg font-medium p-0 m-0 my-auto h-full"
          @ionFocus="focus"
          @ionBlur="blur"
          v-model="model"
          :min="min"
          :max="max"
          :clear-input="clearInput"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="sentences"
          :autofocus="autofocus"
          :inputmode="inputmode"
          :placeholder="placeholder"
          @ionInput="$emit('onChange', model)"
          @keydown="keyPressed"
          :enterkeyhint="enterkeyhint"
          :maxlength="maxLength"
          :debounce="debounce"
          :type="type" />
      </div>
    </div>
    <div v-if="error || counter" class="flex w-full">
      <div v-if="!error && counter" class="flex text-brand-light-gray font-medium text-xs ml-auto mr-0">{{ characterCount }}</div>
      <div v-if="error" class="text-brand-pink font-medium text-xs">{{ error }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">

import {IonInput} from "@ionic/vue";
import {computed, ref, watch} from "vue";

const props = defineProps({
  modelValue: {
    type: String,
  },
  placeholder: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  enterkeyhint: {
    type: String,
    default: 'return'
  },
  autofocus: {
    type: Boolean,
    default: false
  },
  inputmode: {
    type: String,
    default: 'text'
  },
  error: {
    type: String,
    required: false
  },
  counter: {
    type: Boolean,
    default: false
  },
  clearInput: {
    type: Boolean,
    default: false
  },
  maxLength: {
    type: Number,
  },
  debounce: {
    type: Number,
    default: 0
  },
  min: {
    type: String,
    default: undefined
  },
  max: {
    type: String,
    default: undefined
  },
})

const emits = defineEmits(['update:modelValue', 'onKeyEnter', 'onChange'])
const model = ref(props.modelValue ?? '')
const hasFocus = ref(false)

watch(() => props.modelValue, newValue =>{
  if (newValue !== undefined) model.value = newValue
})

watch(() => model.value, newValue => {
  emits('update:modelValue', newValue)
})

const focus = () => {
  hasFocus.value = true
}

const blur = () => {
  hasFocus.value = false
}

const keyPressed = (event:any) => {
  if (event.keyCode === 13) {
    emits('onKeyEnter', model)
  }
}

const characterCount = computed(() => {
  if (props.maxLength) return `${model.value.length}/${props.maxLength}`
  return model.value.length
})

</script>
