<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start" class="mr-4">
                    <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
                        <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                    </ion-button>
                </ion-buttons>
                <ion-title class="text-white font-medium text-2xl">Blocked Users</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content class="h-full">

            <div class="flex flex-col w-full bg-brand-black my-auto h-full">

                <template v-if="loading">
                    <div class="m-auto">
                        <svg class="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                </template>


                <div v-if="!loading && !users.length" class="flex flex-col items-center space-y-6 my-auto px-10">

                    <div class="flex w-20 h-20 text-brand-lime">
                    <svg class="fill-current" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_461_33489)">
                            <path d="M12.4996 3.00488C10.5416 3.00488 8.62752 3.58551 6.99947 4.67333C5.37142 5.76116 4.10251 7.30733 3.35321 9.11632C2.6039 10.9253 2.40785 12.9159 2.78984 14.8363C3.17183 16.7567 4.11472 18.5207 5.49926 19.9052C6.8838 21.2898 8.64781 22.2327 10.5682 22.6147C12.4886 22.9967 14.4792 22.8006 16.2882 22.0513C18.0972 21.302 19.6433 20.0331 20.7312 18.405C21.819 16.777 22.3996 14.8629 22.3996 12.9049C22.3996 10.2792 21.3566 7.76114 19.5 5.90453C17.6434 4.04792 15.1253 3.00488 12.4996 3.00488ZM17.9996 14.0049H6.99961V11.8049H17.9996V14.0049Z" />
                        </g>
                        <defs>
                            <clipPath id="clip0_461_33489">
                                <rect width="22" height="22"  transform="translate(1.5 1.90479)"/>
                            </clipPath>
                        </defs>
                    </svg>
                    </div>

                    <div class="flex flex-col w-full items-center space-y-4 px-2">
                        <div class="flex text-white text-2xl font-medium text-center">You haven't blocked any users.
                        </div>
                        <div class="text-brand-light-gray text-lg text-center">To block a user visit their profile and tap the 3 dots in the top right corner, from here you can block and report if necessary.
                        </div>
                    </div>
                </div>

                <div v-if="!loading && users.length" class="flex w-full flex-col px-4 py-5 space-y-6">
                    <div class="flex flex-col w-full space-y-4">
                        <div class="flex flex-col w-full space-y-4">
                            <template v-for="user in users"
                                      :key="user.id">
                                <div @click="showContext(user)" class="flex justify-between">
                                    <user-list-item
                                            :user="user"/>

                                    <svg class="my-auto" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.52035 14.1598C6.71329 14.1598 7.68035 13.1928 7.68035 11.9998C7.68035 10.8069 6.71329 9.83984 5.52035 9.83984C4.32742 9.83984 3.36035 10.8069 3.36035 11.9998C3.36035 13.1928 4.32742 14.1598 5.52035 14.1598Z"
                                            fill="#A5A7AF"/>
                                        <path
                                            d="M12.0008 14.1598C13.1938 14.1598 14.1608 13.1928 14.1608 11.9998C14.1608 10.8069 13.1938 9.83984 12.0008 9.83984C10.8079 9.83984 9.84082 10.8069 9.84082 11.9998C9.84082 13.1928 10.8079 14.1598 12.0008 14.1598Z"
                                            fill="#A5A7AF"/>
                                        <path
                                            d="M18.4803 14.1598C19.6732 14.1598 20.6403 13.1928 20.6403 11.9998C20.6403 10.8069 19.6732 9.83984 18.4803 9.83984C17.2874 9.83984 16.3203 10.8069 16.3203 11.9998C16.3203 13.1928 17.2874 14.1598 18.4803 14.1598Z"
                                            fill="#A5A7AF"/>
                                    </svg>

                                </div>
                            </template>
                        </div>
                    </div>
                </div>

            </div>


        </ion-content>
    </ion-page>

</template>

<script setup lang="ts">

import {
    useIonRouter,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonButtons,
    onIonViewWillEnter, modalController
} from "@ionic/vue";
import {onMounted, onUnmounted, Ref, ref} from "vue";
import ApiService from "@/services/api/apiService";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import UserListItem from "@/components/UserListItem.vue";
import FeedCommentContextMenu from "@/views/feed/FeedCommentContextMenu.vue";
import ProfileSettingsAccountBlockedContext from "@/views/user/profile/ProfileSettingsAccountBlockedContext.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

const ionRouter = useIonRouter()
const loading = ref(true)
const users: Ref<UserBasicInformationInterface[]> = ref([])

const goBack = async () => {
    await ionRouter.back()
}

const getBlocked = async () => {
    users.value = await ApiService.User().blocked()
}

const observer = ref()

onMounted(()=>{
    observer.value = Observable.subscribe(async (response: any) => {
        if (response.event === ObservableEvent.BlockedUsersChanged) {
            await getBlocked()
        }
    })
})

onIonViewWillEnter(async ()=>{
    await getBlocked()
    loading.value = false
})

const showContext = async (user: UserBasicInformationInterface) => {
    const sheet = await modalController.create({
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        component: ProfileSettingsAccountBlockedContext,
        componentProps: {
            user
        },
        mode: 'ios'
    })
    await sheet.present()
}

</script>
