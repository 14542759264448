<template>
  <div class="flex justify-between flex-col">

    <div class="flex space-x-1">
      <user-avatar @click="viewUserProfile" :user="user"/>
      <div class="flex space-x-2 relative text-ellipsis truncate w-full">
        <div class="absolute top-0 right-0 bg-gradient-to-r from-transparent to-brand-black w-14 h-full"></div>
        <div class="overflow-hidden my-auto">
          <div @click="viewUserProfile"
               class="text-white font-medium text-lg my-auto line-clamp-1">
            {{ user.name }}
          </div>
        </div>
        <div class="flex z-50">
          <user-verification-check class="my-auto h-4" :user="user"/>
        </div>
      </div>
      <div class="my-auto pl-4">

        <div class="flex border-brand-dark-gray justify-between space-x-1">
          <div class="flex my-auto space-x-2">
            <slot name="actions">
              <template v-if="!user.befriended_at">
                <svg @click="acceptReject" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M5.52035 14.1598C6.71329 14.1598 7.68035 13.1928 7.68035 11.9998C7.68035 10.8069 6.71329 9.83984 5.52035 9.83984C4.32742 9.83984 3.36035 10.8069 3.36035 11.9998C3.36035 13.1928 4.32742 14.1598 5.52035 14.1598Z"
                      fill="#A5A7AF"/>
                  <path
                      d="M12.0008 14.1598C13.1938 14.1598 14.1608 13.1928 14.1608 11.9998C14.1608 10.8069 13.1938 9.83984 12.0008 9.83984C10.8079 9.83984 9.84082 10.8069 9.84082 11.9998C9.84082 13.1928 10.8079 14.1598 12.0008 14.1598Z"
                      fill="#A5A7AF"/>
                  <path
                      d="M18.4803 14.1598C19.6732 14.1598 20.6403 13.1928 20.6403 11.9998C20.6403 10.8069 19.6732 9.83984 18.4803 9.83984C17.2874 9.83984 16.3203 10.8069 16.3203 11.9998C16.3203 13.1928 17.2874 14.1598 18.4803 14.1598Z"
                      fill="#A5A7AF"/>
                </svg>
              </template>
              <template v-else>
                <svg @click="viewUserSettings" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M5.52035 14.1598C6.71329 14.1598 7.68035 13.1928 7.68035 11.9998C7.68035 10.8069 6.71329 9.83984 5.52035 9.83984C4.32742 9.83984 3.36035 10.8069 3.36035 11.9998C3.36035 13.1928 4.32742 14.1598 5.52035 14.1598Z"
                      fill="#A5A7AF"/>
                  <path
                      d="M12.0008 14.1598C13.1938 14.1598 14.1608 13.1928 14.1608 11.9998C14.1608 10.8069 13.1938 9.83984 12.0008 9.83984C10.8079 9.83984 9.84082 10.8069 9.84082 11.9998C9.84082 13.1928 10.8079 14.1598 12.0008 14.1598Z"
                      fill="#A5A7AF"/>
                  <path
                      d="M18.4803 14.1598C19.6732 14.1598 20.6403 13.1928 20.6403 11.9998C20.6403 10.8069 19.6732 9.83984 18.4803 9.83984C17.2874 9.83984 16.3203 10.8069 16.3203 11.9998C16.3203 13.1928 17.2874 14.1598 18.4803 14.1598Z"
                      fill="#A5A7AF"/>
                </svg>
              </template>

            </slot>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script setup lang="ts">

import {computed, PropType, provide, ref} from "vue";
import {FriendUserInterface} from "@/interfaces/FriendUserInterface";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {actionSheetController, useIonRouter} from "@ionic/vue";
import UserVerificationCheck from "@/components/UserBadges.vue";
import UserAvatar from "@/components/UserAvatar.vue";

const ionRouter = useIonRouter()

const props = defineProps({
  user: {
    type: Object as PropType<FriendUserInterface>,
    required: true
  }
})

const acceptBusy = ref(false)
const rejectBusy = ref(false)

const isBusy = computed(() => {
  return acceptBusy.value || rejectBusy.value
})

const accept = async () => {
  if (isBusy.value || !props.user) return
  acceptBusy.value = true

  await ApiService.User().makeFriendRequest(props.user.id)
  await Observable.emit(ObservableEvent.FriendsChanged)
  acceptBusy.value = false
}
const reject = async () => {
  if (isBusy.value || !props.user) return
  rejectBusy.value = true

  await ApiService.User().rejectFriendRequest(props.user.id)
  await Observable.emit(ObservableEvent.FriendsChanged)
  rejectBusy.value = false
}

const acceptReject = async () => {
  const sheet = await actionSheetController.create({
    buttons: [
      {
        text: 'Accept',
        handler: async () => {
          await actionSheetController.dismiss()
          await accept()
        }
      },
      {
        text: 'Reject',
        handler: async () => {
          await actionSheetController.dismiss()
          await reject()
        }
      },
      {
        text: 'Cancel',
        role: 'cancel',
      }
    ]
  })
  await sheet.present()
}

const viewUserSettings = async () => {
  if (!props.user?.befriended_at) return
  ionRouter.navigate({
    name: 'PineappleFriendsUserSettings',
    params: {userId: props.user?.id},
  }, 'forward', 'push')
}

const viewUserProfile = async () => {
  if (!props.user?.id) return
  ionRouter.navigate({
    name: 'PineappleFriendsUserProfile',
    params: {
      userId: props.user.id
    }
  }, 'forward', 'push')
}

</script>
