<template>
  <ion-page>
    <ion-content :force-overscroll="false" :fullscreen="true">


      <div
          style="background-image: url('/assets/img/onboarding-bg.png')"
          class="flex bg-blend-lighten flex-col w-full bg-brand-black my-auto h-full bg-contain bg-top bg-no-repeat">
        <div class="flex flex-col mt-auto items-center w-full pb-10">

          <template v-if="!over18">
            <div class="flex flex-col px-6 space-y-40">
              <div class="flex flex-col items-center space-y-14">
                <logo-mark/>
                <div class="flex m-auto text-white text-3xl font-medium text-center">To be able to use pineapple you
                  must
                  be 18 or older.
                </div>
              </div>
              <div class="flex flex-col space-y-4 w-full items-center">
                <button
                    @click="over18 = true"
                    class="flex bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black">
                  <div class="flex m-auto space-x-2.5">
                    <div class="my-auto pt-0.5">
                      I'm 18+
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </template>
          <template v-if="over18">
            <div class="flex w-full flex-col">
              <div class="flex w-full">
                <swiper
                    ref="swiper"
                    :modules="swiperModules"
                    :slides-per-view="1"
                    :auto-height="true"
                    :pagination="{
                      el: '.pagination'
                    }"
                    @swiper="onSwiper"
                >
                  <swiper-slide v-for="(slide, slideKey) in slides" :key="slideKey">
                    <div class="flex flex-col w-full px-6 justify-center space-y-8">
                      <div class="flex px-5 w-full justify-center">
                        <img class="max-h-fit" alt="" :src="slide.image"/>
                      </div>
                      <div class="flex flex-col w-full items-center space-y-2">
                        <div class="flex m-auto text-white text-2xl font-medium">{{ slide.title }}</div>
                        <div class="flex m-auto text-brand-light-gray text-lg text-center">{{ slide.text }}</div>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
              <div class="flex flex-col px-6 mt-10 space-y-8">

                <div class="flex w-full justify-center items-center">
                  <div class="pagination flex space-x-3 w-auto"></div>
                </div>

                <button
                    @click="next"
                    class="flex bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black">
                  <div class="flex m-auto space-x-2.5">
                    <div class="my-auto pt-0.5">
                      Next
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </template>


        </div>

      </div>


    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, onIonViewDidEnter} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import {StatusBar, Style} from "@capacitor/status-bar";
import LogoMark from "@/components/LogoMark.vue";

import {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import {useRouter} from "vue-router";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {DateTime} from "luxon";

export default defineComponent({
  name: 'OnboardingPage',
  components: {
    Swiper,
    SwiperSlide,
    LogoMark,
    IonContent,
    IonPage,
  },
  setup() {

    const over18 = ref(false)

    const slides = ref([
      {
        image: '/assets/img/onboarding1.png',
        title: 'Meet couples & singles',
        text: 'Pineapple is the app where it\'s at, a place to meet open-minded people.'
      },
      {
        image: '/assets/img/onboarding2.png',
        title: 'Explore the feed',
        text: 'Find out what people in your area are up to and share your experiences.'
      },
      {
        image: '/assets/img/onboarding3.png',
        title: 'Verified users',
        text: 'We pride ourselves on real, verified users. A community made by swingers for swingers.'
      },
      {
        image: '/assets/img/onboarding4.png',
        title: 'Flirt',
        text: 'Chat with people who take your fancy, one on one or as a group. '
      }
    ])

    const swiperRef:any = ref(null)

    const onSwiper = async (swiper:any) => {
      swiperRef.value = swiper
    }

    const router = useRouter()

    const next = async () => {
      if (swiperRef.value.isEnd) {
        await router.push({name: 'Welcome'})
        await Preferences.set({
          key: PreferenceKey.CompletedOnboarding,
          value: DateTime.now().toISO()
        })
        return
      }
      swiperRef.value.slideNext()
    }

    return {
      over18,
      slides,
      swiperModules: [Pagination],
      onSwiper,
      next
    }

  }
});
</script>
