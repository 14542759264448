<template>
  <ion-page>
    <ion-content>

      <div class="flex flex-col w-full bg-brand-black my-auto h-full px-6 z-50">

        <div class="flex flex-col m-auto space-y-14 items-center">


          <div class="flex flex-col space-y-4 items-center">
            <svg width="111" height="110" viewBox="0 0 111 110" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="2.5" y="2" width="106" height="106" rx="53" fill="#EBFF00"/>
              <path
                  d="M68.833 41.666H42.1663C40.333 41.666 38.8497 43.166 38.8497 44.9993L38.833 64.9993C38.833 66.8327 40.333 68.3327 42.1663 68.3327H68.833C70.6663 68.3327 72.1663 66.8327 72.1663 64.9993V44.9993C72.1663 43.166 70.6663 41.666 68.833 41.666ZM68.1663 48.7493L56.383 56.116C55.8497 56.4493 55.1497 56.4493 54.6163 56.116L42.833 48.7493C42.6659 48.6555 42.5195 48.5288 42.4028 48.3768C42.2861 48.2248 42.2014 48.0506 42.154 47.865C42.1065 47.6793 42.0972 47.4859 42.1266 47.2965C42.156 47.1071 42.2236 46.9257 42.3251 46.7632C42.4267 46.6007 42.5602 46.4604 42.7176 46.351C42.8749 46.2416 43.0528 46.1652 43.2405 46.1265C43.4282 46.0878 43.6218 46.0876 43.8096 46.1259C43.9974 46.1642 44.1754 46.2402 44.333 46.3493L55.4997 53.3327L66.6663 46.3493C66.8239 46.2402 67.002 46.1642 67.1898 46.1259C67.3775 46.0876 67.5711 46.0878 67.7589 46.1265C67.9466 46.1652 68.1245 46.2416 68.2818 46.351C68.4391 46.4604 68.5726 46.6007 68.6742 46.7632C68.7758 46.9257 68.8433 47.1071 68.8727 47.2965C68.9022 47.4859 68.8929 47.6793 68.8454 47.865C68.7979 48.0506 68.7132 48.2248 68.5965 48.3768C68.4798 48.5288 68.3335 48.6555 68.1663 48.7493Z"
                  fill="#1A1B22"/>
              <rect x="2.5" y="2" width="106" height="106" rx="53" stroke="#EBFF00" stroke-width="4"/>
            </svg>

            <div class="flex flex-col w-full items-center space-y-2">
              <div class="flex m-auto text-white text-2xl font-medium">Instructions sent</div>
              <div class="m-auto text-brand-light-gray text-lg text-center">We sent instructions to change your password
                to
                <span class="text-brand-lime">kinkyboots@gmail.com</span>, please check both your inbox and spam folder.
              </div>
            </div>
          </div>

          <div class="flex flex-col space-y-2 w-full">
            <button
                class="flex bg-brand-dark-gray rounded-full w-full h-14 font-medium text-xl text-white">
              <div class="flex m-auto space-x-2.5">
                <svg class="my-auto" width="20" height="16" viewBox="0 0 20 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM17.6 4.25L10.53 8.67C10.21 8.87 9.79 8.87 9.47 8.67L2.4 4.25C2.29973 4.19371 2.21192 4.11766 2.14189 4.02645C2.07186 3.93525 2.02106 3.83078 1.99258 3.71937C1.96409 3.60796 1.9585 3.49194 1.97616 3.37831C1.99381 3.26468 2.03434 3.15581 2.09528 3.0583C2.15623 2.96079 2.23632 2.87666 2.33073 2.811C2.42513 2.74533 2.53187 2.69951 2.6445 2.6763C2.75712 2.65309 2.87328 2.65297 2.98595 2.67595C3.09863 2.69893 3.20546 2.74453 3.3 2.81L10 7L16.7 2.81C16.7945 2.74453 16.9014 2.69893 17.014 2.67595C17.1267 2.65297 17.2429 2.65309 17.3555 2.6763C17.4681 2.69951 17.5749 2.74533 17.6693 2.811C17.7637 2.87666 17.8438 2.96079 17.9047 3.0583C17.9657 3.15581 18.0062 3.26468 18.0238 3.37831C18.0415 3.49194 18.0359 3.60796 18.0074 3.71937C17.9789 3.83078 17.9281 3.93525 17.8581 4.02645C17.7881 4.11766 17.7003 4.19371 17.6 4.25Z"
                      fill="white"/>
                </svg>
                <div class="my-auto pt-0.5">
                  Open email app
                </div>
              </div>
            </button>
            <button
                class="flex rounded-full w-full h-14 font-medium text-xl text-white">
              <div class="flex m-auto space-x-2.5">
                <div class="my-auto pt-0.5">
                  Resend email
                </div>
              </div>
            </button>
          </div>
        </div>

      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonInput
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'ForgotPasswordInstructionsPage',
  components: {
    IonContent,
    IonPage,
  },
  setup() {

    return {}

  }
});
</script>
