<template>
  <div class="font-sans" v-if="!loading">
    <div v-if="!postOwner">
      <div class="flex flex-col p-4 pt-6 divide-y divide-brand-dark-gray">
        <div class="flex text-white p-4 py-5">
          <div class="flex justify-between">
            <div @click="reportPost" class="flex space-x-2">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21.8998 20.25L12.1498 3.375C12.0045 3.12422 11.7537 3 11.5006 3C11.2475 3 10.9943 3.12422 10.8514 3.375L1.10138 20.25C0.813094 20.7516 1.17403 21.375 1.75059 21.375H21.2506C21.8272 21.375 22.1881 20.7516 21.8998 20.25ZM10.7506 9.9375C10.7506 9.83437 10.835 9.75 10.9381 9.75H12.0631C12.1662 9.75 12.2506 9.83437 12.2506 9.9375V14.25C12.2506 14.3531 12.1662 14.4375 12.0631 14.4375H10.9381C10.835 14.4375 10.7506 14.3531 10.7506 14.25V9.9375ZM11.5006 18.1875C11.2062 18.1815 10.9259 18.0603 10.7198 17.85C10.5137 17.6397 10.3983 17.357 10.3983 17.0625C10.3983 16.768 10.5137 16.4853 10.7198 16.275C10.9259 16.0647 11.2062 15.9435 11.5006 15.9375C11.795 15.9435 12.0753 16.0647 12.2814 16.275C12.4874 16.4853 12.6029 16.768 12.6029 17.0625C12.6029 17.357 12.4874 17.6397 12.2814 17.85C12.0753 18.0603 11.795 18.1815 11.5006 18.1875Z"
                    fill="white"/>
              </svg>
              <div v-if="!reportBusy" class="text-lg">Report post</div>
              <svg v-if="reportBusy" class="animate-spin m-auto h-5 w-5 text-white"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>

          </div>
        </div>

        <div class="flex text-white p-4 py-5">
          <div class="flex justify-between">
            <div @click="wink" class="flex space-x-2">

              <svg class="w-6 h-6 text-white fill-current" width="75" height="74" viewBox="0 0 75 74"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M37.5006 0C17.2891 0 0.845703 16.4434 0.845703 36.6549C0.845703 56.8664 17.2891 73.3098 37.5006 73.3098C57.7121 73.3098 74.1555 56.8664 74.1555 36.6549C74.1555 16.4434 57.7121 0 37.5006 0Z"/>
                <path
                    d="M57.344 45.3167C58.4435 46.0038 58.7864 47.4604 57.9969 48.4889C55.9488 51.1572 53.3663 53.3809 50.4055 55.0124C46.7574 57.0225 42.6561 58.0668 38.4909 58.0461C34.3257 58.0254 30.235 56.9404 26.6071 54.8941C23.6626 53.2332 21.1024 50.984 19.0809 48.2954C18.3017 47.2591 18.659 45.806 19.7653 45.1298V45.1298C20.8716 44.4537 22.3061 44.8129 23.1088 45.8312C24.6966 47.8454 26.6667 49.5371 28.9137 50.8045C31.8446 52.4577 35.1494 53.3342 38.5142 53.3509C41.8791 53.3677 45.1924 52.524 48.1396 50.9001C50.3991 49.6551 52.3859 47.9831 53.9937 45.9847C54.8064 44.9745 56.2445 44.6295 57.344 45.3167V45.3167Z"
                    fill="#1A1B22"/>
                <path
                    d="M24.5105 40.1582C25.6758 40.2425 26.5724 41.2661 26.2606 42.392C26.0533 43.1407 25.7645 43.8666 25.3979 44.5563C24.6993 45.8709 23.7338 47.0251 22.5633 47.9449C21.3928 48.8648 20.043 49.53 18.6006 49.8981C17.8437 50.0912 17.0701 50.2003 16.2937 50.2247C15.1259 50.2615 14.3434 49.1483 14.5369 47.9961V47.9961C14.7304 46.8439 15.8401 46.1029 16.9933 45.9156C17.1817 45.8849 17.3689 45.846 17.5545 45.7986C18.4262 45.5762 19.2417 45.1742 19.949 44.6184C20.6563 44.0625 21.2397 43.3651 21.6619 42.5708C21.7518 42.4016 21.834 42.2289 21.9082 42.0531C22.3629 40.9768 23.3453 40.0738 24.5105 40.1582V40.1582Z"
                    fill="#1A1B22"/>
                <path
                    d="M32.9536 30.0211C33.9533 29.3009 34.1629 27.8739 33.2204 27.0804C32.4583 26.4388 31.6057 25.8977 30.6849 25.4747C29.0674 24.7316 27.287 24.3744 25.4949 24.4336C23.7028 24.4927 21.9521 24.9664 20.3917 25.8144C19.5056 26.2959 18.6958 26.89 17.9827 27.5781C17.0949 28.4348 17.4025 29.8458 18.45 30.4975V30.4975C19.4438 31.1157 20.7341 30.7992 21.6606 30.0839C21.9433 29.8657 22.2449 29.669 22.5627 29.4963C23.5056 28.9839 24.5635 28.6977 25.6463 28.6619C26.7292 28.6262 27.8051 28.842 28.7824 29.291C29.1166 29.4445 29.4358 29.6237 29.7373 29.8263C30.7047 30.4766 32.0077 30.7024 32.9536 30.0211V30.0211Z"
                    fill="#1A1B22"/>
                <path
                    d="M51.5546 32.8109C54.0696 32.8109 56.1406 30.849 56.1406 28.39C56.1406 25.9309 54.0696 23.9691 51.5546 23.9691C49.0396 23.9691 46.9686 25.9309 46.9686 28.39C46.9686 30.849 49.0396 32.8109 51.5546 32.8109Z"
                    fill="#1A1B22" stroke="#1A1B22" stroke-width="0.916372"/>
              </svg>

              <div v-if="!winkBusy" class="text-lg">Wink at {{ post.posted_by.name }}</div>
              <svg v-if="winkBusy" class="animate-spin m-auto h-5 w-5 text-white"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="postOwner">
      <div class="flex flex-col p-4 pt-6 divide-y divide-brand-dark-gray">
        <div  class="flex text-white p-4 py-5">
          <div class="flex justify-between">
            <div  @click="editPost" class="flex space-x-2">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_216911)">
                  <path
                      d="M2.81301 15.9669L1.54635 21.4336C1.50265 21.6334 1.50415 21.8405 1.55073 22.0397C1.59731 22.2389 1.6878 22.4251 1.81559 22.5849C1.94337 22.7446 2.10523 22.8738 2.28933 22.9629C2.47343 23.0521 2.67513 23.099 2.87968 23.1003C2.97499 23.1099 3.07103 23.1099 3.16635 23.1003L8.66635 21.8336L19.2263 11.3136L13.333 5.43359L2.81301 15.9669Z"
                      fill="white"/>
                  <path
                      d="M22.5471 6.04697L18.6138 2.11364C18.3552 1.85635 18.0053 1.71191 17.6405 1.71191C17.2757 1.71191 16.9257 1.85635 16.6671 2.11364L14.4805 4.30031L20.3671 10.187L22.5538 8.00031C22.6818 7.87169 22.7831 7.71911 22.852 7.55128C22.921 7.38346 22.9561 7.20369 22.9555 7.02226C22.9549 6.84083 22.9185 6.6613 22.8484 6.49395C22.7784 6.3266 22.676 6.17471 22.5471 6.04697Z"
                      fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_1_216911">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="text-lg">Edit post</div>
            </div>

          </div>
        </div>
        <div class="flex text-white p-4 py-5 w-full">
          <div class="flex justify-between w-full">
            <div class="flex space-x-2 my-auto">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_216920)">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M2.37546 6.16957C2.66565 4.48488 4.00472 3.24648 5.69477 2.98932C7.31411 2.74293 9.53559 2.5 12 2.5C14.4644 2.5 16.6859 2.74293 18.3052 2.98932C19.9953 3.24648 21.3344 4.48488 21.6245 6.16957C21.8268 7.34372 22 8.83525 22 10.5C22 12.1647 21.8268 13.6563 21.6245 14.8304C21.3344 16.5151 19.9953 17.7535 18.3052 18.0107C16.8238 18.2361 14.8384 18.4586 12.6241 18.4949L6.50873 22.1085C5.84211 22.5024 5 22.0219 5 21.2476V17.844C3.64656 17.3939 2.62456 16.2766 2.37546 14.8304C2.17321 13.6563 2 12.1647 2 10.5C2 8.83525 2.17321 7.34372 2.37546 6.16957ZM6 8.5C6 7.94772 6.44772 7.5 7 7.5H17C17.5523 7.5 18 7.94772 18 8.5C18 9.05228 17.5523 9.5 17 9.5H7C6.44771 9.5 6 9.05228 6 8.5ZM6 12.5C6 11.9477 6.44772 11.5 7 11.5H11C11.5523 11.5 12 11.9477 12 12.5C12 13.0523 11.5523 13.5 11 13.5H7C6.44772 13.5 6 13.0523 6 12.5Z"
                        fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_1_216920">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                  </clipPath>
                </defs>
              </svg>

              <div class="text-lg">Allow comments</div>
            </div>
            <ion-toggle mode="ios" v-model="postRef.allow_comments"/>
          </div>
        </div>
        <div @click="deletePost" class="flex text-white p-4 py-5">
          <div class="flex justify-between">
            <div class="flex space-x-2">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21.1667 6.1H17.8333V4.1C17.8333 3.2175 17.0859 2.5 16.1667 2.5H7.83333C6.91406 2.5 6.16667 3.2175 6.16667 4.1V6.1H2.83333C2.3724 6.1 2 6.4575 2 6.9V7.7C2 7.81 2.09375 7.9 2.20833 7.9H3.78125L4.42448 20.975C4.46615 21.8275 5.20052 22.5 6.08854 22.5H17.9115C18.8021 22.5 19.5339 21.83 19.5755 20.975L20.2187 7.9H21.7917C21.9062 7.9 22 7.81 22 7.7V6.9C22 6.4575 21.6276 6.1 21.1667 6.1ZM15.9583 6.1H8.04167V4.3H15.9583V6.1Z"
                    fill="white"/>
              </svg>
              <div v-if="!deleteBusy" class="text-lg">Delete post</div>
              <svg v-if="deleteBusy" class="animate-spin m-auto h-5 w-5 text-white"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="safe-area-bottom"></div>
  </div>
</template>

<script setup lang="ts">

import {computed, defineProps, inject, onDeactivated, onMounted, onUnmounted, PropType, Ref, ref, watch} from 'vue';
import {useRoute} from "vue-router";
import {alertController, IonContent, IonToggle, modalController, toastController} from "@ionic/vue";
import ApiService from "@/services/api/apiService";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import FeedNewPostPage from "@/views/feed/FeedNewPostPage.vue";
import {useUser} from "@/composables/useUser";
import {EventTypeModel} from "@/definitions/EventTypeModel";
import FeedNewVideoPage from "@/views/feed/FeedNewVideoPage.vue";


const loading = ref(true)
const reportBusy = ref(false)
const deleteBusy = ref(false)
const winkBusy = ref(false)
const authUserId = ref()

const props = defineProps({
  post: {
    type: Object as PropType<FeedPostInterface>,
    required: true
  },
})

onMounted(async () => {
  deleteBusy.value = false
  authUserId.value = parseInt((await Preferences.get({key: PreferenceKey.UserId})).value as string)
  loading.value = false
})

const deletePost = async () => {

  if (isBusy.value) return

  const alertCtrl = await alertController.create({
    header: 'Delete Post',
    message: 'Are you sure you want to delete this post?',
    buttons: [
      {
        text: 'Yes',
        role: 'destructive',
        handler: async () => {
          try {
            deleteBusy.value = true
            await alertCtrl.dismiss()
            await ApiService.Feed().deletePost(props.post.id)
            Observable.emit(ObservableEvent.FeedPostDeleted, props.post)
            await modalController.dismiss()
            deleteBusy.value = false
          } catch (e: any) {
            const toast = await toastController.create({
              message: e.data.message,
              duration: 3000,
              position: 'bottom',
              color: 'danger'
            });
            await toast.present();
            deleteBusy.value = false
          }
        }
      },
      {
        text: 'No',
      }
    ]
  })

  await alertCtrl.present()

}


const postRef: Ref<FeedPostInterface> = ref(props.post)

const isBusy = computed(() => {
  return (reportBusy.value || deleteBusy.value || winkBusy.value)
})

const reportPost = async () => {
  if (isBusy.value) return
  try {

    const alert = await alertController.create({
      header: 'Report post?',
      message: 'Are you sure you want to report this post?',
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Yes',
          role: 'destructive',
          handler: async () => {

            alertController.dismiss()
            reportBusy.value = true
            await ApiService.Feed().reportPost(props.post.id)
            reportBusy.value = false

            await modalController.dismiss()
            const toast = await toastController.create({
              header: 'Post Reported',
              message: 'Thank you for reporting this post, is has been flagged for review by our team.',
              duration: 3000,
              position: 'bottom',
              color: 'primary'
            });
            await toast.present();

          }
        },

      ]
    })
    await alert.present()

  } catch (e: any) {
    const toast = await toastController.create({
      message: e.data.message,
      duration: 3000,
      position: 'bottom',
      color: 'danger'
    });
    await toast.present();
    deleteBusy.value = false
  }
}

// const makeFriendRequest = async () => {
//   if (isBusy.value) return
//   try {
//     winkBusy.value = true
//     if (props.post.posted_by.friends) await ApiService.User().removeFromFriends(props.post.posted_by.id)
//     if (!props.post.posted_by.friends) {
//       await ApiService.User().makeFriendRequest(props.post.posted_by.id)
//       const toast = await toastController.create({
//         message: `You winked at ${props.post.posted_by.name} 😉`,
//         duration: 3000,
//         position: 'bottom',
//         color: 'primary'
//       });
//       modalController.dismiss()
//       await toast.present();
//     }
//     winkBusy.value = false
//   } catch (e: any) {
//     const toast = await toastController.create({
//       message: e.data.message,
//       duration: 3000,
//       position: 'bottom',
//       color: 'danger'
//     });
//     await toast.present();
//     winkBusy.value = false
//   }
// }

watch(() => postRef.value.allow_comments, async newVal => {
  try {
    await ApiService.Feed().allowComments(props.post.id, newVal)
    deleteBusy.value = false
  } catch (e: any) {
    const toast = await toastController.create({
      message: e.data.message,
      duration: 3000,
      position: 'bottom',
      color: 'danger'
    });
    await toast.present();
    deleteBusy.value = false
  }
})

const postOwner = computed(() => {
  return props.post.posted_by.id === authUserId.value
})

const wink = async () => {
  winkBusy.value = true
  await useUser().winkAtUser(props.post.posted_by.id, props.post.posted_by.name)
  winkBusy.value = false
}

const editPost = async () => {

  await modalController.dismiss()
  const modal = await modalController.create({
    component: props.post?.media_type === 'VIDEO' ? FeedNewVideoPage : FeedNewPostPage,
    componentProps: {
      post: props.post,
      authUser: props.post.posted_by
    },
    mode: 'ios',
    presentingElement: document.getElementById('tabs') as HTMLElement,
  })
  await modal.present()
}


</script>

