<template>
  <div
      v-observe-visibility="visibilityChanged"
      class="flex w-full flex-col border-b-4 border-brand-dark-gray max-w-full">
    <div class="flex justify-between p-4 pt-8">
      <div @click="viewProfile" class="flex overflow-hidden">
        <user-avatar class="mr-2 mb-auto" :user="post.posted_by"/>
        <div class="flex flex-col my-auto overflow-hidden">
          <div class="flex flex-col space-y-1">
            <user-verification-check class="h-4" :user="post.posted_by"/>
              <div class="text-white font-medium text-xl truncate">{{
                  post.posted_by.name
                }}
              </div>
          </div>
          <div class="flex space-x-2.5 text-brand-light-gray w-full">
            <template v-if="post.posted_by.location">
              <div class="flex space-x-1">
                <svg class="my-auto w-2.5 h-2.5 fill-current" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 384 512">
                  <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <path
                      d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
                </svg>
                <div class="my-auto text-xs line-clamp-1">{{ post.posted_by.location.name }}</div>
              </div>
              <div class="my-auto">•</div>
            </template>
            <div class="text-xs my-auto min-w-max">{{ postedAt }}</div>
          </div>
        </div>
      </div>
      <div @click="contextMenu" class="flex ml-3 mt-0 mb-auto">
        <svg class="my-auto" width="24" height="25" viewBox="0 0 24 25" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M5.52035 14.5583C6.71329 14.5583 7.68035 13.5912 7.68035 12.3983C7.68035 11.2053 6.71329 10.2383 5.52035 10.2383C4.32742 10.2383 3.36035 11.2053 3.36035 12.3983C3.36035 13.5912 4.32742 14.5583 5.52035 14.5583Z"
              fill="#A5A7AF"/>
          <path
              d="M12.0008 14.5583C13.1938 14.5583 14.1608 13.5912 14.1608 12.3983C14.1608 11.2053 13.1938 10.2383 12.0008 10.2383C10.8079 10.2383 9.84082 11.2053 9.84082 12.3983C9.84082 13.5912 10.8079 14.5583 12.0008 14.5583Z"
              fill="#A5A7AF"/>
          <path
              d="M18.4803 14.5583C19.6732 14.5583 20.6403 13.5912 20.6403 12.3983C20.6403 11.2053 19.6732 10.2383 18.4803 10.2383C17.2874 10.2383 16.3203 11.2053 16.3203 12.3983C16.3203 13.5912 17.2874 14.5583 18.4803 14.5583Z"
              fill="#A5A7AF"/>
        </svg>
      </div>
    </div>

    <div class="flex w-full flex-col" :id="'post' + post.id">
      <div
          v-if="post.data.content"
          :class="[
              `post-${post.id}`
            ]"
          class="flex flex-col px-4 text-lg text-white pb-4 space-y-5 post-content "
          v-html="sanitizeHtml(postContent)"></div>


      <div class="mx-4 mt-0 rounded-xl overflow-hidden border border-brand-darkish-gray"
           v-if="post.type === EventTypeModel.Event">
        <event-card
            @click="viewEvent(post.data.event as EventInterface)"
            :is-nested="true"
            :event="post.data.event as EventInterface"/>
      </div>


      <div v-if="isVideoPost"
           class="flex h-72 w-full relative">

        <div @click="viewXRated(post)"
             v-if="postRef.visibility === PostPrivacy.XRated && !showXRated"
             class="absolute flex top-0 left-0 w-full h-full z-40 bg-brand-black/10 backdrop-blur-xl">
          <div class="absolute top-0 left-0 bg-brand-black w-full h-full z-10 opacity-50"></div>
          <div class="rounded-full flex flex-col m-auto px-16 items-center text-white z-50">
            <svg class="w-3.5 fill-current text-white mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
              <path
                  d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"/>
            </svg>
            <div class="font-medium drop-shadow-lg text-xl">NSFW</div>
            <div class="flex flex-col text items-center space-y-7">
              <div class="flex flex-col">
                <div class="drop-shadow-lg text-center">Not Safe For Work 😈</div>
              </div>
              <div class="flex space-x-1.5 text-white bg-brand-black rounded-full px-5 py-2 shadow-lg">
                <div class="flex w-3 h-3 my-auto text-white">
                  <svg class="w-3 h-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                        d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
                  </svg>
                </div>
                <div>Reveal</div>
              </div>
            </div>
          </div>
        </div>

        <div @click="playVideo(postRef.media[0].url)" class="flex z-20 relative w-full h-full">
          <div class="absolute top-0 left-0 w-full h-full flex text-white z-40">
            <div class="flex w-14 h-14 m-auto bg-brand-dark-gray/90 rounded-full ">
              <svg class="w-5 h-5 fill-current m-auto pl-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                <path
                    d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/>
              </svg>
            </div>
          </div>
          <img
              class="w-full flex m-0 p-0 object-cover pointer-events-none"
              @error="mediaError(postRef.media[0])"
              :src="postRef.media[0].thumbnail ?? postRef.media[0].url" alt=""/>
        </div>


      </div>

      <div v-if="post.media && post.media.length && !isVideoPost"
           class="grid h-96 grid-rows-4 grid-cols-4 grid-flow-col gap-2 relative">

        <div @click="viewXRated(post)"
             v-if="postRef.visibility === PostPrivacy.XRated && !showXRated"
             class="absolute flex top-0 left-0 w-full h-full z-40 bg-brand-black/10 backdrop-blur-xl">
          <div class="absolute top-0 left-0 bg-brand-black w-full h-full z-10 opacity-50"></div>
          <div class="rounded-full flex flex-col m-auto px-16 items-center text-white z-50">
            <svg class="w-3.5 fill-current text-white mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
              <path
                  d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/>
            </svg>
            <div class="font-medium drop-shadow-lg text-xl">NSFW</div>
            <div class="flex flex-col text items-center space-y-7">
              <div class="flex flex-col">
                <div class="drop-shadow-lg text-center">Not Safe For Work 😈</div>
              </div>
              <div class="flex space-x-1.5 text-white bg-brand-black rounded-full px-5 py-2 shadow-lg">
                <div class="flex w-3 h-3 my-auto text-white">
                  <svg class="w-3 h-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                        d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
                  </svg>
                </div>
                <div>Reveal</div>
              </div>
            </div>
          </div>
        </div>

        <photo-provider>
          <photo-consumer v-for="(media, mediaKey) in post.media" :key="mediaKey" :src="media.url"
                          :class="{
                  '!col-span-4 !row-span-4' : post.media.length === 1,
                  'col-span-2 row-span-4' : post.media.length === 2,
                  'col-span-1 row-span-2' : post.media.length === 3 && mediaKey !== 0,
                  '!col-span-2 !row-span-2' : post.media.length === 4 ,
                  'col-span-3 row-span-4' : mediaKey === 0,
                  'col-span-3' : mediaKey !== 0,
                  '!hidden' : mediaKey > 4
                }"
                          class="w-full">
            <div
                class="flex min-w-full h-full overflow-hidden">
              <div class="flex w-full relative">

                <div v-if="mediaKey === 4"
                     class="flex absolute top-0 left-0 w-full h-full bg-brand-black/60 backdrop-blur z-20">
                  <div class="flex m-auto font-bold text-brand-lime">+ {{ post.media.length - 4 }} more</div>
                </div>

                <div v-if="media.error" class="w-full flex bg-brand-dark-gray">
                  <div class="flex m-auto text-brand-light-gray px-8 text-center">
                    <svg class="w-14 h-14 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                      <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                      <path
                          d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7l-55.5-43.5c.5-3.1 .7-6.3 .7-9.6V96c0-35.3-28.7-64-64-64H128c-14.4 0-27.8 4.8-38.5 12.9L38.8 5.1zm120 94.1c5.3-2 11.1-3.2 17.2-3.2c26.5 0 48 21.5 48 48c0 2-.1 4-.4 6L158.8 99.2zM328.1 231.9l20-29.4c4.5-6.6 11.9-10.5 19.8-10.5s15.4 3.9 19.8 10.5L499.4 366.1 328.1 231.9zM221.7 272.5c.3-.4 .6-.7 1-1.1L64 146.4V416c0 35.3 28.7 64 64 64H487.4l-81.2-64H320 248 152c-9.3 0-17.8-5.4-21.8-13.9s-2.6-18.5 3.5-25.6l88-104z"/>
                    </svg>
                  </div>
                </div>

                <div v-if="!media.error" class="flex w-full">
                  <img
                      class="w-full flex m-0 p-0 object-cover pointer-events-none"
                      @error="mediaError(media)"
                      :src="media.thumbnail ?? media.url"/>
                </div>
              </div>
            </div>
          </photo-consumer>
        </photo-provider>
      </div>
    </div>

    <div class="flex border-brand-dark-gray justify-between">

      <div class="flex">
        <div class="flex">
          <div @click="like" class="flex py-4 pl-4 pr-2" :class="{
                            'text-red-500' : commentatorReacted,
                            'text-brand-light-gray' : !commentatorReacted,
                         }">
            <svg
                v-if="totalLikes"

                class="fill-current w-5 h-5"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path
                  d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z"/>
            </svg>

            <svg v-if="!totalLikes" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"/>
            </svg>

          </div>
          <div class="flex my-auto h-full" v-if="totalLikes" @click="showLikedBy">
            <div class="text-sm pl-0 pr-4 text-brand-light-gray my-auto">{{ totalLikes }}</div>
          </div>
        </div>

        <div @click="comment" v-if="post.allow_comments" class="flex py-4 text-brand-light-gray">
          <svg
              class="fill-current w-5 h-5"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
                d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z"/>
            <path
                d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z"/>
          </svg>
          <div v-if="totalComments" class="text-brand-light-gray text-sm px-2 my-auto">{{ totalComments }}
          </div>
        </div>
      </div>

      <div v-if="post.visibility === 'INNER_CIRCLE'"
           class="my-auto mr-4 bg-purple-500 text-white p-1 pt-1.5 px-2 rounded-full font-medium text-xxxs uppercase">
        Inner Circle
      </div>

      <div v-if="!post.approved"
           class="my-auto mr-4 bg-brand-lime text-brand-black p-1 pt-1.5 px-2 font-medium rounded-full text-xxxs uppercase">
        Pending Approval
      </div>

    </div>
  </div>
</template>
<script setup lang="ts">

import {computed, nextTick, onBeforeUnmount, onMounted, onUnmounted, onUpdated, PropType, ref, watch} from "vue";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import {DateTime} from "luxon";
import pluralize from "pluralize"
import {isPlatform, modalController, useIonRouter} from "@ionic/vue";
import FeedPostContextMenu from "@/views/feed/FeedPostContextMenu.vue";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {Swiper, SwiperSlide} from 'swiper/vue';
import UserVerificationCheck from "@/components/UserBadges.vue";
import * as sanitizeHtml from 'sanitize-html';
import FeedCommentContextMenu from "@/views/feed/FeedCommentContextMenu.vue";
import UsersList from "@/views/common/PostUsersList.vue";
import {PostPrivacy} from "@/definitions/PostPrivacy";
import {usePremium} from "@/composables/usePremium";
import {UserInterface} from "@/interfaces/UserInterface";
import UserAvatar from "@/components/UserAvatar.vue";
import {Haptics, ImpactStyle} from "@capacitor/haptics";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import FeedPost from "@/components/FeedPost.vue";
import EventCard from "@/components/EventCard.vue";
import {EventTypeModel} from "@/definitions/EventTypeModel";
import {EventInterface} from "@/interfaces/EventInterface";
import Plyr from "plyr";
import {CapacitorVideoPlayer} from "capacitor-video-player";
import AppStoreAppRequired from "@/views/common/AppStoreAppRequired.vue";
import {useVerification} from "@/composables/useVerification";

const props = defineProps({
  post: {
    type: Object as PropType<FeedPostInterface>,
    required: true
  },
  canViewUserProfile: {
    type: Boolean,
    default: true
  },
  authUser: {
    type: Object as PropType<UserInterface>,
    required: false
  },
})

const postRef = ref(props.post)


const postedAt = computed(() => {
  return DateTime.fromISO(props.post.created_at).toRelative()
})

const totalComments = ref('')
const totalLikes = ref('')
const commentatorReacted = ref(false)
const observer = ref()


watch(() => props.post, post => {

  postRef.value = post

  const likes = post.reactions.map(reaction => {
    return reaction.count
  }).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
  );
  totalLikes.value = !likes ? '' : `${likes} ${pluralize('like', likes)}`

  const reacted = post.reactions.filter(reaction => {
    return reaction.commentator_reacted
  })
  commentatorReacted.value = reacted.length > 0

  const comments = props.post?.count.comments
  totalComments.value = !comments ? 'Comment' : `${comments} ${pluralize('comment', comments)}`


}, {deep: true, immediate: true})

const contextMenu = async () => {

  if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});
  if (props.authUser && !props.authUser.id_verified) return await useVerification().presentVerificationRequired('You must be verified to flag posts or wink at users.')

  const sheet = await modalController.create({
    breakpoints: [0, 1],
    initialBreakpoint: 1,
    component: FeedPostContextMenu,
    componentProps: {
      post: props.post
    },
    mode: 'ios'
  })

  await sheet.present()

}

const like = async () => {
  try {

    if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});
    if (props.authUser && !props.authUser.id_verified) return await useVerification().presentVerificationRequired('You must be verified to like posts.')

    if (commentatorReacted.value) {
      postRef.value.reactions = props.post.reactions.map(reaction => {
        if (reaction.commentator_reacted) {
          reaction.commentator_reacted = false
          reaction.count--
        }
        return reaction
      }).filter(reaction => {
        return reaction.count > 0
      })

      return await ApiService.Feed().deletePostReaction(props.post.id)
    }
    if (!commentatorReacted.value) {
      postRef.value.reactions.push({
        commentator_reacted: true,
        count: 1,
        reaction: '❤️'
      })
      return await ApiService.Feed().postReaction(props.post.id)
    }

  } catch (e) {
    console.log('error')
    console.log(e)
  }
}

const swiperRef: any = ref()
const onSwiper = async (swiper: any) => {
  swiperRef.value = swiper
}

const swiperIndex = computed(() => {
  if (swiperRef.value) {
    return swiperRef.value.realIndex
  }
  return false
})


const ionRouter = useIonRouter()
const comment = async () => {
  ionRouter.navigate({
    name: 'FeedViewPost',
    params: {postId: props.post.id}
  }, 'forward', 'push')
}

const viewProfile = async () => {
  if (!props.canViewUserProfile) return

  ionRouter.navigate({
    name: 'PineappleFeedUserProfile',
    params: {
      userId: props.post?.posted_by.id
    }
  }, 'forward', 'push')
}

const postContent = computed(() => {
  return props.post?.data.content
})

const mediaError = (media: any) => {
  media['error'] = true
}

const showLikedBy = async () => {
  const sheet = await modalController.create({
    breakpoints: [0, 1],
    initialBreakpoint: 1,
    component: UsersList,
    componentProps: {
      postId: props.post?.comment_id
    },
    mode: 'ios'
  })
  await sheet.present()
  const data = (await sheet.onDidDismiss()).data

  if (Object.prototype.hasOwnProperty.call(data, 'user')) {
    ionRouter.navigate({
      name: 'PineappleUserProfile',
      params: {
        userId: data.user.id
      }
    }, 'forward', 'push')
  }


}

const showXRated = ref(false)

const viewXRated = async (post: FeedPostInterface) => {

  if (post.media_type === "VIDEO" && !props.authUser?.subscribed) {
    return await usePremium().presentPayWall({
      text: `Upgrade to unlock NSFW video content.`,
    })
  }

  const dateFormat = 'd-MM-yyyy'
  const today = DateTime.now().toFormat(dateFormat)
  const dailyXRated = JSON.parse((await Preferences.get({key: PreferenceKey.DailyXRated})).value ?? '[]')
  const xRatedToday = dailyXRated.filter((record: string) => record === today)

  if (props.authUser?.id === post.posted_by.id || props.authUser?.subscribed || xRatedToday.length < 5) {
    dailyXRated.push(today)
    await Preferences.set({key: PreferenceKey.DailyXRated, value: JSON.stringify(dailyXRated)})
    return showXRated.value = true
  }

  await usePremium().presentPayWall({
    text: `You've reached your daily limit for NSFW photos. Upgrade to go unlimited.`,
  })

}

const handleClick = (event: any, link: any) => {
  event.preventDefault();

  if (link.getAttribute('target') === 'hashtag') {
    ionRouter.navigate({
      name: 'FeedHashtag',
      params: {
        tag: link.getAttribute('href')
      }
    }, 'forward', 'push')
  }

  if (link.getAttribute('target') === 'mention') {
    ionRouter.navigate({
      name: 'PineappleFeedUserProfile',
      params: {
        userId: link.getAttribute('href')
      }
    }, 'forward', 'push')
  }

  if (link.getAttribute('target') === '_system') {
    window.open(link.getAttribute('href'), '_system')
  }

};

const addClickListeners = () => {
  const container = document.getElementById('post' + props.post.id) as HTMLElement
  const links = container.querySelectorAll('a');
  links.forEach((link: any) => {
    link.addEventListener('click', (ev: any) => {
      handleClick(ev, link)
    });
  });
};

const removeClickListeners = () => {
  const container = document.getElementById('post' + props.post.id) as HTMLElement
  const links = container.querySelectorAll('a');
  links.forEach((link: any) => {
    link.removeEventListener('click', handleClick);
  });
};

// Mounted
onMounted(async () => {

  // if (isVideoPost.value) new Plyr(`#player${postRef.value.id}`, {
  //   controls: isPlatform('ios') ? ['play'] : ['play', 'fullscreen'],
  //   displayDuration: true,
  //   clickToPlay: true,
  //   autopause: true,
  //   hideControls: true,
  //   resetOnEnd: true
  // });

  observer.value = Observable.subscribe(async (response: any) => {
    if (props.post?.visibility === PostPrivacy.XRated) {
      if (response.event === ObservableEvent.FeedShowXRated) {
        showXRated.value = true
      }
      if (response.event === ObservableEvent.FeedHideXRated) {
        showXRated.value = false
      }
    }
  })

  addClickListeners();
})

onUnmounted(() => {
  observer.value?.unsubscribe()
})

onUpdated(async () => {
  const container = document.getElementById('post' + props.post.id) as HTMLElement
  const oldATags = container.querySelectorAll('a');
  oldATags.forEach((aTag: any) => {
    aTag.removeEventListener('click', handleClick);
  });

  addClickListeners()
})

onBeforeUnmount(() => {
  removeClickListeners()
})

const visibilityChanged = async (isVisible: any, entry: any) => {

  if (isVisible && props.post?.visibility === PostPrivacy.XRated) {
    const u = await ApiService.User().authUser()
    const shouldShowXRated = Boolean(Number(u.preferences[PreferenceKey.FeedShowXRated]))
    if (shouldShowXRated) {
      showXRated.value = true
    } else {
      showXRated.value = false
    }

  }
}

const viewEvent = async (event: EventInterface) => {
  ionRouter.navigate({
    name: 'FeedEvent',
    params: {
      eventId: event.id
    }
  }, 'forward', 'push')
}

const isVideoPost = computed(() => {
  return postRef.value.media_type === 'VIDEO';
})

const player = ref()
const playVideo = async (url: string) => {

  if (!props.authUser?.subscribed) {
    return await usePremium().presentPayWall({
      text: `Upgrade to unlock NSFW video content.`,
    })
  }

  if (!isPlatform('capacitor')) {
    const sheet = await modalController.create({
      breakpoints: [1],
      initialBreakpoint: 1,
      component: AppStoreAppRequired,
      mode: 'ios',
    })
    return await sheet.present()
  }

  const playerId = `player${postRef.value.id}`
  player.value = await CapacitorVideoPlayer.initPlayer({
    url: url,
    mode: 'fullscreen',
    playerId
  })

  await CapacitorVideoPlayer.play({
    playerId
  })

}

</script>

<style scoped>
.swiper-slide {
  width: 100% !important;
  height: auto !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

</style>