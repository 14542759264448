<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="!p-0 !m-0">
        <ion-buttons class="justify-between flex w-full">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="fill-current text-white w-6 h-6 p-0.5" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 320 512">
              <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                  d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
            </svg>
          </ion-button>
          <div class="flex relative">

            <ion-button
                v-if="!event"
                @click="createEvent"
                class="!text-xl">
              <div :class="{
                  'text-brand-light-gray' : !canPost,
                  'text-brand-lime' : canPost
                }">Create
              </div>
            </ion-button>
            <ion-button
                v-if="event"
                @click="updateEvent"
                class="!text-xl">
              <div :class="{
                  'text-brand-light-gray' : !canPost,
                  'text-brand-lime' : canPost
                }">Update
              </div>
            </ion-button>

            <div v-if="posting" class="absolute flex top-0 left-0 w-full h-full bg-brand-black z-50">
              <svg class="animate-spin m-auto h-5 w-5 text-brand-lime" xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </div>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>

      <ion-segment
          @ionChange="tabChanged"
          :value="tab"
          class="relative z-50 backdrop-blur-2xl bg-brand-black ml-0 w-full sticky top-0"
          :swipe-gesture="false"
          mode="ios">
        <ion-segment-button value="details">
          <ion-label
              class="text-lg font-medium">
            Details
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="guest-list">
          <ion-label
              class="text-lg font-medium">
            Guest List
          </ion-label>
        </ion-segment-button>
        <ion-segment-button v-if="false" value="settings">
          <ion-label
              class="text-lg font-medium">
            Settings
          </ion-label>
        </ion-segment-button>
      </ion-segment>

      <div v-if="!loading && tab === 'guest-list'" class="flex flex-col py-5 px-4 justify-center space-y-4 z-10">

        <div class="flex flex-col space-y-2">
          <div class="font-medium text-xl text-white">Capacity</div>
          <input-field
              :error="errors && errors['capacity'] ? errors['capacity'][0] : undefined"
              v-model="form.capacity"
              type="number"
              inputmode="numeric"
              enterkeyhint="next"
              placeholder="">
          </input-field>
          <div>
            <div class="text-brand-light-gray text-xxs -mt-1">Enter 0 for no capacity limit on this event.</div>
          </div>
        </div>

        <div class="flex flex-col space-y-2">
          <div class="font-medium text-xl text-white">Privacy</div>
          <div v-if="form.public_guest_list[0] && form.visibility[0] === EventPrivacy.InviteOnly && false" class="text-brand-light-gray text-sm pb-2">Invite-only events with a public guest list will only be visible to guests who've been accepted and attending the event.</div>
          <div class="flex flex-col space-y-2">
            <input-checkbox
                v-for="(p,pIndex) in guestListPrivacyOptions"
                :key="pIndex"
                name="privacy"
                :return-single-value="true"
                :option="p"
                v-model="form.public_guest_list"
            />
          </div>
        </div>

      </div>

      <div v-if="!loading && tab === 'details'" class="flex flex-col py-5 px-4 justify-center space-y-4 z-10">


        <div class="flex flex-col space-y-2">
          <div class="font-medium text-xl text-white">Image</div>
          <div class="flex grid gap-2 grid-cols-1">
            <div
                v-for="(photo,pIndex) in eventPhotos"
                :key="pIndex"
                :style="{
                'background-image' : `url('${photo}')`
              }"
                class="w-full h-60 bg-brand-dark-gray bg-cover bg-center rounded-md flex p-2">
              <svg @click="removePhoto(pIndex)" class="mt-auto ml-auto" width="25" height="25"
                   viewBox="0 0 25 25"
                   fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="25" height="25" rx="12.5" fill="white"/>
                <path
                    d="M16.8921 7L12.5 11.3921L8.10786 7L7 8.10786L11.3921 12.5L7 16.8921L8.10786 18L12.5 13.6079L16.8921 18L18 16.8921L13.6079 12.5L18 8.10786L16.8921 7Z"
                    fill="black"/>
              </svg>
            </div>

            <div v-if="!eventPhotos.length" @click="addPhoto" class="w-full h-60 bg-brand-dark-gray rounded-md flex">
              <svg v-if="busy" class="animate-spin h-7 w-7 text-white m-auto"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <svg v-if="!busy" class="m-auto w-8 fill-current text-white/75" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 512 512">
                <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                    d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/>
              </svg>

            </div>
          </div>
        </div>

        <div class="flex flex-col space-y-2">
          <div class="font-medium text-xl text-white">Name</div>
          <input-field
              class="relative z-0"
              :error="errors && errors['name'] ? errors['name'][0] : undefined"
              v-model="form.name"
              type="text"
              enterkeyhint="next"
              placeholder="">
          </input-field>
        </div>

        <div class="flex flex-col space-y-2">
          <div class="font-medium text-xl text-white">Start date & time</div>
          <input-field
              class="relative z-0"
              :error="errors && errors['starts_at'] ? errors['starts_at'][0] : undefined"
              v-model="form.starts_at"
              type="datetime-local"
              enterkeyhint="next"
              placeholder="">
          </input-field>
        </div>

        <div class="flex flex-col space-y-2">
          <div class="font-medium text-xl text-white">End date & time</div>
          <input-field
              class="relative z-0"
              :error="errors && errors['ends_at'] ? errors['ends_at'][0] : undefined"
              v-model="form.ends_at"
              type="datetime-local"
              enterkeyhint="next"
              placeholder="">
          </input-field>
        </div>

        <div class="flex flex-col space-y-2">
          <div class="font-medium text-xl text-white">Description</div>
          <div class="flex flex-col space-y-2">
            <input-textarea
                v-model="form.description"
                placeholder=""
                enterkeyhint="next"
                :counter="false"
                :error="errors && errors.hasOwnProperty('description') ? errors['description'][0] : null"
            ></input-textarea>
          </div>
        </div>

        <div class="flex flex-col space-y-2">
          <div class="font-medium text-xl text-white">Rules</div>
          <div class="flex flex-col space-y-2">
            <input-textarea
                v-model="form.rules"
                placeholder=""
                enterkeyhint="next"
                :counter="false"
                :error="errors && errors.hasOwnProperty('rules') ? errors['rules'][0] : null"
            ></input-textarea>
          </div>
        </div>

        <div @click="chooseLocation" class="flex flex-col space-y-1">
          <div class="text-white font-medium text-lg">Location</div>
          <menu-button class="text-left" :name="form.location ? form.location : 'Add location'"/>
        </div>

        <div class="flex flex-col space-y-2">
          <div class="font-medium text-xl text-white">Type</div>
          <div class="grid grid-cols-1 gap-2">
            <input-checkbox
                v-for="(p,pIndex) in typeOptions"
                :key="pIndex"
                name="type"
                :return-single-value="true"
                v-model="form.type"
                :option="p"
            />
          </div>
        </div>

        <div class="flex flex-col space-y-2">
          <div class="font-medium text-xl text-white">Privacy</div>
          <div class="flex flex-col space-y-2">
            <input-checkbox
                v-for="(p,pIndex) in privacyOptions"
                :key="pIndex"
                name="privacy"
                :return-single-value="true"
                v-model="form.visibility"
                :option="p"
            />
          </div>
        </div>

      </div>

      <div v-if="!loading && tab === 'settings'" class="flex flex-col py-5 px-4 justify-center space-y-4 z-10">

        <div class="flex flex-col space-y-2">
          <div class="font-medium text-xl text-white">Privacy</div>
          <div class="flex flex-col space-y-2">
            <input-checkbox
                v-for="(p,pIndex) in privacyOptions"
                :key="pIndex"
                name="privacy"
                :return-single-value="true"
                v-model="form.visibility"
                :option="p"
            />
          </div>
        </div>


      </div>
      <div class="safe-area-bottom"></div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  modalController,
  toastController,
} from '@ionic/vue';
import {computed, onMounted, PropType, reactive, Ref, ref} from 'vue';
import InputCheckbox from "@/components/InputCheckbox.vue";
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {EventPrivacy} from "@/definitions/EventPrivacy";
import InputField from "@/components/InputField.vue";
import {EventType} from "@/definitions/EventType";
import {DateTime} from "luxon";
import MenuButton from "@/components/MenuButton.vue";
import InputTextarea from "@/components/InputTextarea.vue";
import ChooseLocationPage from "@/views/common/ChooseLocationPage.vue";
import {EventInterface} from "@/interfaces/EventInterface";


const loading = ref(true)
const busy = ref(false)
const posting = ref(false)

const errors = ref({})

const props = defineProps({
  event: {
    type: Object as PropType<EventInterface>,
    required: false
  }
})

const privacyOptions = ref([
  {
    text: 'Public',
    description: 'Visible to everyone',
    value: EventPrivacy.Public
  },
  {
    text: 'Invite-only',
    description: 'Users must request to attend this event',
    value: EventPrivacy.InviteOnly
  },
])

const guestListPrivacyOptions = ref([
  {
    text: 'Public',
    description: 'Guest list visible to everyone',
    value: true
  },
  {
    text: 'Private',
    description: 'Guest list only visible to event organiser',
    value: false
  },
])


const typeOptions = ref([
  {
    text: 'Club',
    // description: 'Visible to everyone',
    value: EventType.Club
  },
  {
    text: 'House Party',
    // description: 'Users must request to attend this event',
    value: EventType.HouseParty
  },
  {
    text: 'Hotel',
    // description: 'Users must request to attend this event',
    value: EventType.Hotel
  },

  {
    text: 'Outdoors',
    // description: 'Users must request to attend this event',
    value: EventType.Outdoors
  },
])

const eventPhotos: Ref<string[]> = ref([])
const form: any = reactive({
  id: '',
  name: '',
  starts_at: DateTime.now().startOf('hour').plus({hour: 1}).toFormat('yyyy-MM-dd HH:mm'),
  ends_at: DateTime.now().startOf('hour').plus({hour: 1}).toFormat('yyyy-MM-dd HH:mm'),
  capacity: 0,
  type: [],
  visibility: [EventPrivacy.Public],
  public_guest_list: [true],
  description: '',
  rules: '',
  location: '',
  lat: '',
  lng: ''
})

onMounted(async () => {

  if (props.event) {
    form.id = props.event.id
    form.name = props.event.name
    form.starts_at = DateTime.fromISO(props.event.starts_at).toFormat(`yyyy-MM-dd'T'HH:mm`)
    form.ends_at = DateTime.fromISO(props.event.ends_at).toFormat(`yyyy-MM-dd'T'HH:mm`)
    form.capacity = props.event.capacity
    form.type = [props.event.type]
    form.visibility = [props.event.visibility]
    form.public_guest_list = [Boolean(props.event.public_guest_list)]
    form.description = props.event.description
    form.rules = props.event.rules
    form.location = props.event.location
    form.lat = props.event.lat
    form.lng = props.event.lng

    if (props.event.photo_url) eventPhotos.value.push(props.event.photo_url)

  }

  loading.value = false
})


const goBack = async () => {

  if (busy.value || posting.value) return

  const modal = await alertController.create({
    header: 'Discard',
    message: `Are you sure you want to discard any changes?`,
    buttons: [
      {
        text: 'Yes',
        role: 'destructive',
        handler: () => {
          modalController.dismiss()
        }
      },
      {
        text: 'No',
      },

    ]
  })

  await modal.present()

}

const canPost = computed(() => {
  return true
})

const createEvent = async () => {

  const event: any = Object.assign({}, form)

  event.visibility = event.visibility[0]
  event.type = event.type[0]
  event.public_guest_list = event.public_guest_list[0]

  Object.keys(event).forEach((key: any) => {
    if (!event[`${key}`] && !['capacity', 'public_guest_list'].includes(key) ) delete event[`${key}`]
  })

  event.starts_at = DateTime.fromFormat(event.starts_at, `yyyy-MM-dd'T'HH:mm`).toUTC().toISO()
  event.ends_at = DateTime.fromFormat(event.ends_at, `yyyy-MM-dd'T'HH:mm`).toUTC().toISO()

  if (posting.value) return
  posting.value = true
  try {
    errors.value = {}
    const newRecord = await ApiService.Events().create(event)
    if (eventPhotos.value.length) await ApiService.Events().imageFor(newRecord.id, eventPhotos.value[0])
    posting.value = false
    await modalController.dismiss()
    await Observable.emit(ObservableEvent.EventsChanged)
    await Observable.emit(ObservableEvent.PullToRefreshMyEvents)
  } catch (e: any) {
    posting.value = false
    if (e.data.errors) errors.value = e.data.errors
    const toast = await toastController.create({
      message: e.data.message,
      duration: 3000,
      position: 'bottom',
      color: 'danger'
    });
    await toast.present();
  }

}

const updateEvent = async () => {

  const event: any = Object.assign({}, form)

  event.visibility = event.visibility[0]
  event.type = event.type[0]
  event.public_guest_list = event.public_guest_list[0]

  Object.keys(event).forEach((key: any) => {
    if (!event[`${key}`] && !['capacity', 'public_guest_list'].includes(key) ) delete event[`${key}`]
  })

  event.starts_at = DateTime.fromFormat(event.starts_at, `yyyy-MM-dd'T'HH:mm`).toUTC().toISO()
  event.ends_at = DateTime.fromFormat(event.ends_at, `yyyy-MM-dd'T'HH:mm`).toUTC().toISO()


  if (posting.value) return
  posting.value = true
  try {
    errors.value = {}
    const newRecord = await ApiService.Events().update(event)
    if (eventPhotos.value.length && !eventPhotos.value[0].includes('https')) await ApiService.Events().imageFor(newRecord.id, eventPhotos.value[0])
    if (!eventPhotos.value.length) await ApiService.Events().deleteImageFor(newRecord.id)
    posting.value = false
    await modalController.dismiss()
    await Observable.emit(ObservableEvent.EventsChanged)
    await Observable.emit(ObservableEvent.PullToRefreshMyEvents)
  } catch (e:any) {
    posting.value = false
    if (e.data.errors) errors.value = e.data.errors
    const toast = await toastController.create({
      message: e.data.message,
      duration: 3000,
      position: 'bottom',
      color: 'danger'
    });
    await toast.present();
  }

}


const tab = ref('details')
const tabChanged = async (event: any) => {
  tab.value = event.detail.value
}


const addPhoto = async () => {

  if (busy.value || posting.value) return

  try {
    const photo = await useCamera(CameraSource.Photos, CameraResultType.Base64)
    busy.value = true
    if (Object.prototype.hasOwnProperty.call(photo, 'base64String')) eventPhotos.value.push(`data:image/${photo.format};base64,${photo.base64String}`)
    busy.value = false
  } catch (e) {
    busy.value = false
  }

}

const removePhoto = async (index: number) => {

  if (busy.value || posting.value) return

  const modal = await alertController.create({
    header: 'Remove Photo',
    message: 'Are you sure you want to remove this photo from the post?',
    buttons: [
      {
        text: 'Yes',
        role: 'destructive',
        handler: async () => {
          eventPhotos.value.splice(index, 1)
        }
      },
      {
        text: 'No',
      },

    ]
  })

  await modal.present()

}

const chooseLocation = async () => {

  const modal = await modalController.create({
    component: ChooseLocationPage,
    componentProps: {},
    mode: 'ios',
    presentingElement: await modalController.getTop(),
  })
  await modal.present()

  const location: any = (await modal.onWillDismiss()).data
  if (location) {
    form.location = location.name
    form.lat = location.lat
    form.lng = location.lng
  }

}


</script>