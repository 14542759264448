<template>
    <div @click="viewRelationship" class="flex rounded-full overflow-hidden">
      <div
          v-if="user.relationship_status === 'couple'"
          class="flex my-auto">
        <div
            :class="{
                    'bg-brand-blue' : user.account_type === 'joint',
                    'bg-brand-secondary-pink' : user.account_type === 'individual'
                 }"
            class="flex w-4 h-4 rounded-full border-brand-black ">
          <div class="flex m-auto text-brand-black text-opacity-80">
            <svg class="h-2.5 w-2.5 fill-current" width="24" height="25" viewBox="0 0 24 25"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M17.5291 19.2339L17.4684 19.0516C15.8278 15.9225 12.5468 13.7959 8.77975 13.7959C5.01266 13.7959 1.73165 15.9225 0.091139 19.0212L0.0303799 19.2035C1.15437e-07 19.2643 0 19.3554 0 19.4465V20.206C0 20.6617 0.394936 21.0567 0.850633 21.0567H8.77975H16.7392C17.1949 21.0567 17.5899 20.6617 17.5899 20.206V19.4465C17.5899 19.3858 17.5595 19.2946 17.5291 19.2339Z"
              />
              <path
                  d="M18.957 18.3531L18.9266 18.2923C17.9848 16.5303 16.6481 15.1024 15.0684 14.0695C15.919 13.6746 16.8911 13.4619 17.8937 13.4619C20.5063 13.4619 22.7544 14.9201 23.9089 17.0771L23.9696 17.1986C24 17.2594 24 17.3201 24 17.3505V17.867C24 18.2012 23.7266 18.4442 23.4228 18.4442H19.0177L18.957 18.3531Z"
              />
              <path
                  d="M17.8937 11.5784C19.4541 11.5784 20.719 10.3134 20.719 8.75305C20.719 7.19267 19.4541 5.92773 17.8937 5.92773C16.3333 5.92773 15.0684 7.19267 15.0684 8.75305C15.0684 10.3134 16.3333 11.5784 17.8937 11.5784Z"
              />
              <path
                  d="M8.74968 12.4896C11.1658 12.4896 13.1244 10.531 13.1244 8.11492C13.1244 5.69885 11.1658 3.74023 8.74968 3.74023C6.33361 3.74023 4.375 5.69885 4.375 8.11492C4.375 10.531 6.33361 12.4896 8.74968 12.4896Z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div v-if="user.account_type === AccountType.Club" class="flex my-auto">
        <div class="flex w-4 h-4 rounded-full bg-brand-mint border-brand-black ">
          <div class="flex m-auto text-brand-black text-opacity-80">
            <svg class="h-2.5 w-2.5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                  d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z"/>
            </svg>
          </div>
        </div>
      </div>
      <div v-if="user.account_type === AccountType.Host" class="flex my-auto">
        <div class="flex w-4 h-4 rounded-full bg-orange-400 border-brand-black ">
          <div class="flex m-auto text-brand-black text-opacity-80">
            <svg class="h-2.5 w-2.5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M159.2 12.6c5.7-10.5 18.1-15.2 29.3-11.1L320 49.3 451.5 1.5c11.2-4.1 23.7 .7 29.3 11.1l81.6 151c32.4 60.1 10.6 133.3-45.6 167L552.6 429l55.2-20.1c12.5-4.5 26.2 1.9 30.8 14.3s-1.9 26.2-14.3 30.8l-77.8 28.3-77.8 28.3c-12.5 4.5-26.2-1.9-30.8-14.3s1.9-26.2 14.3-30.8l55.2-20.1-35.8-98.5c-61.2 9.4-121.7-29.6-136.3-92.5L320 188.6l-15.3 65.8c-14.6 62.9-75.1 101.9-136.3 92.5l-35.8 98.5 55.2 20.1c12.5 4.5 18.9 18.3 14.3 30.8s-18.3 18.9-30.8 14.3L93.6 482.3 15.8 454C3.3 449.4-3.1 435.7 1.5 423.2s18.3-18.9 30.8-14.3L87.4 429l35.8-98.4c-56.2-33.7-78.1-106.9-45.6-167l81.6-151zm32.4 41L156.8 118 277 161.7l16.5-71L191.6 53.6zM119.8 186.4c-22.4 41.5-2.8 93.3 41.6 109.4c41.3 15 86.5-9.4 96.5-52.3l8.1-34.7L133.7 160.7l-13.9 25.7zM448.4 53.6L346.5 90.7l16.5 71L483.2 118 448.4 53.6zm71.7 132.7l-13.9-25.7L374 208.8l8.1 34.7c10 42.9 55.2 67.3 96.5 52.3c44.3-16.1 64-67.9 41.6-109.4z"/></svg>
          </div>
        </div>
      </div>
    </div>
</template>
<script setup lang="ts">

import {PropType} from "vue";
import {UserInterface} from "@/interfaces/UserInterface";
import {FeedPostedByInterface} from "@/interfaces/FeedPostedByInterface";
import {FriendUserInterface} from "@/interfaces/FriendUserInterface";
import {ChatParticipantInterface} from "@/interfaces/ChatParticipantInterface";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import {AccountType} from "@/definitions/AccountType";
import {modalController, useIonRouter} from "@ionic/vue";
import PartnerUsersList from "@/views/common/PartnerUsersList.vue";

const ionRouter = useIonRouter()

const props = defineProps({
    user: {
        type: Object as PropType<UserBasicInformationInterface | UserInterface | FeedPostedByInterface | FriendUserInterface | ChatParticipantInterface>,
        required: true
    }
})

const viewRelationship = async () => {

  if (props.user && [AccountType.Individual].includes(props.user.account_type) && props.user?.relationship_status === 'couple') {
    const sheet = await modalController.create({
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      component: PartnerUsersList,
      componentProps: {
        userId: props.user?.id
      },
      mode: 'ios'
    })
    await sheet.present()
    const {data} = await sheet.onWillDismiss()

    if (data) {
      ionRouter.navigate({
        name: 'PineappleUserProfile',
        params: {
          userId: data.id
        }
      }, 'forward', 'push')
    }
  }

}


</script>
