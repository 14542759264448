<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="mr-4">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title class="text-white font-medium text-2xl">
          {{ user.account_type === AccountType.Club ? 'Business Details' : 'Profile' }}
        </ion-title>
        <ion-buttons v-if="user.account_type === AccountType.Joint" slot="end">
          <ion-button @click="newProfile" class="bg-brand-dark-gray rounded-md">
            <div  class="rounded-md bg-white w-7 h-7 flex text-brand-black">
              <svg class="w-4 h-4 m-auto fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
              </svg>
            </div>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="h-full">
      <div class="flex flex-col py-5 px-4 justify-center space-y-4">

        <div class="flex flex-col space-y-1">
          <div class="flex flex-col space-y-2">
            <menu-button
                @click="viewProfile(profile)"
                v-for="profile in user.profiles"
                :key="profile.id"
                name="Name">
              <template v-slot:bottom>
                <div class="text-left text-sm">{{ profile.name ?? '--' }}</div>
              </template>
            </menu-button>
          </div>
        </div>

        <div class="flex flex-col space-y-1">
          <menu-button @click="edit('Bio')" name="Bio">
            <template v-slot:bottom>
              <div class="text-left text-sm line-clamp-3">{{ user.bio.replace(/<[^>]*>/g, '') }}</div>
            </template>
          </menu-button>
        </div>

        <div @click="edit('Location')" class="flex flex-col space-y-1">
          <menu-button v-if="!user.location" name="Set location..."/>
          <menu-button v-if="user.location" :name="user.location.name"/>
        </div>

        <div v-if="![AccountType.Club].includes(user.account_type)" class="flex flex-col space-y-1">
          <div class="text-white font-medium text-lg">Preferences</div>
          <div class="space-y-2 flex flex-col">
            <menu-button @click="edit('LookingFor')" name="Looking for">
              <template v-slot:bottom>
                <div v-for="interest in user.sexual_interests" :key="interest.id"
                     class="flex bg-brand-lime text-brand-dark-gray justify-between p-4 py-2 rounded-full min-w-max mt-1.5 mr-1.5">
                  <div class="my-auto text-base font-medium">{{ interest.name }}</div>
                </div>
              </template>
            </menu-button>
            <menu-button @click="edit('Kinks')" name="Kinks">
              <template v-slot:bottom>
                <div v-for="kink in user.desires" :key="kink.id"
                     class="flex bg-brand-lime text-brand-dark-gray justify-between p-4 py-2 rounded-full min-w-max mt-1.5 mr-1.5">
                  <div class="my-auto text-base font-medium">{{ kink.name }}</div>
                </div>
              </template>
            </menu-button>
          </div>
        </div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  actionSheetController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar, modalController,
  onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, inject, Ref, ref} from 'vue';
import ApiService from "@/services/api/apiService";
import {DateTime} from "luxon";
import MenuButton from "@/components/MenuButton.vue";
import AuthService from "@/services/api/auth/authService";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import JoinPremiumCard from "@/components/JoinPremiumCard.vue";
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import {useUser} from "@/composables/useUser";
import {AccountType} from "@/definitions/AccountType";
import pluralize from "pluralize";
import {UserProfileInterface} from "@/interfaces/UserProfileInterface";
import FeedNewPostPage from "@/views/feed/FeedNewPostPage.vue";
import ProfileSettingsProfile from "@/views/user/profile/ProfileSettingsProfile.vue";

const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>
const loading = ref(true)
const ionRouter = useIonRouter()
const busy = ref(false)

onIonViewWillEnter(async () => {
  loading.value = false
})

const goBack = async () => {
  await ionRouter.back()
}

const edit = async (slug: string) => {
  await ionRouter.navigate({name: `ProfileSettings${slug}`}, 'forward', 'push')
}

const viewProfile = async (profile: UserProfileInterface) => {
  const modal = await modalController.create({
    component: ProfileSettingsProfile,
    mode: 'ios',
    componentProps: {
      profile
    },
    presentingElement: document.getElementById('tabs') as HTMLElement,
  })
  await modal.present()
}

const newProfile = async () => {

  if (busy.value) return
  busy.value = true

  const profile = await ApiService.User().createProfile()
  await viewProfile(profile)

}




</script>
