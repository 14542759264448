<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="mr-4">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="h-full">
      <div class="flex flex-col py-10 px-4 justify-center space-y-10">

        <div class="flex flex-col w-full space-y-2">
          <div class="flex text-white text-2xl font-medium">ID Card</div>
          <div class="text-brand-light-gray text-lg">Take a clear photo of your ID Card making sure that the photo is not obscured.
          </div>
        </div>

        <div
            @click="choosePhoto"
            class="flex flex-col items-center space-y-4">
          <div
              :style="[
                  !isImageUrl ? `background-image: url('data:image/jpeg;base64,${photo}')` : `background-image: url('${photo}')`
              ]"
              :class="{
              'border-2' : !photo
            }"
              class="flex w-full rounded-xl bg-cover bg-center border-brand-light-gray border-dashed h-56 px-24">
            <div
                v-if="!busy"
                :class="{ 'opacity-0' : photo}"
                class="text-brand-light-gray m-auto text-center text-sm">Take or choose photo.
            </div>
            <svg v-if="busy" class="animate-spin m-auto h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
          <div v-if="photo && !awaitingVerification" class="text-white font-medium">Change image</div>
        </div>


      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar, useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, ref} from 'vue';
import ApiService from "@/services/api/apiService";
import {DateTime} from "luxon";
import MenuButton from "@/components/MenuButton.vue";
import AuthService from "@/services/api/auth/authService";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import JoinPremiumCard from "@/components/JoinPremiumCard.vue";
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";

export default defineComponent({
  name: 'ProfileSettingsIdCardPage',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonButtons,
    IonButton,
  },
  setup() {

    const ionRouter = useIonRouter()
    const busy = ref(true)
    const status = ref()

    const photo = ref()

    onMounted(async () => {
      status.value = await ApiService.User().getVerificationStatus()
      if (status.value.id_card_photo) photo.value = status.value.id_card_photo
      busy.value = false
    })

    const isImageUrl = computed(()=>{
      if (!photo.value) return
      return photo.value.includes('http')
    })

    const awaitingVerification = computed(()=>{
      if (!status.value) return
      return status.value.submitted_at
    })

    const choosePhoto = async () => {

      if (busy.value || status.value.submitted_at) return

      try {
        const image = await useCamera(CameraSource.Prompt, CameraResultType.Base64)
        busy.value = true
        photo.value = undefined
        if (image && image.base64String) await ApiService.User().setIdCardPhoto(image.base64String)
        photo.value = image.base64String
      } catch (e) {
        busy.value = false
      } finally {
        busy.value = false
      }
    }

    const goBack = async () => {
      await ionRouter.back()
    }

    return {goBack, choosePhoto, busy, photo, isImageUrl, status, awaitingVerification}

  }
});
</script>
