<template>

  <ion-content :force-overscroll="false">

    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content pulling-icon="crescent" refreshing-spinner="crescent"></ion-refresher-content>
    </ion-refresher>

    <div v-if="canSearch && !loading" class="px-4 py-4 w-full sticky bg-brand-black top-0 z-50  border-b border-brand-dark-gray"
         id="tagSearch">
      <input-field
          placeholder="e.g. monochromemonday"
          :clear-input="true"
          :debounce="250"
          v-model="search">
        <template v-slot:icon-start>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21 21L16.514 16.506L21 21ZM19 10.5C19 12.7543 18.1045 14.9163 16.5104 16.5104C14.9163 18.1045 12.7543 19 10.5 19C8.24566 19 6.08365 18.1045 4.48959 16.5104C2.89553 14.9163 2 12.7543 2 10.5C2 8.24566 2.89553 6.08365 4.48959 4.48959C6.08365 2.89553 8.24566 2 10.5 2C12.7543 2 14.9163 2.89553 16.5104 4.48959C18.1045 6.08365 19 8.24566 19 10.5V10.5Z"
                stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </template>
      </input-field>
    </div>

    <div v-if="loading" class="flex w-full h-full">
      <svg class="animate-spin h-10 w-10 m-auto text-white" xmlns="http://www.w3.org/2000/svg"
           fill="none"
           viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>


    <DynamicScroller
        ref="scrollContainer"
        :items="posts"
        :min-item-size="600"
        class="scroller flex"
    >
      <template v-slot="{ item, index, active }">

        <div class="flex w-full">

          <DynamicScrollerItem
              :item="item"
              :active="active"
              :data-index="index"
              class="w-full"
          >
            <feed-post
                :auth-user="user"
                :post="item" />
          </DynamicScrollerItem>
        </div>
      </template>
    </DynamicScroller>


  </ion-content>

</template>
<script setup lang="ts">
import InputField from "@/components/InputField.vue";
import {nextTick, onMounted, onUnmounted, onUpdated, reactive, Ref, ref, watch} from "vue";
import ApiService from "@/services/api/apiService";
import _ from "lodash";
import {
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonContent,
  useIonRouter
} from "@ionic/vue";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import {PostPrivacy} from "@/definitions/PostPrivacy";
import {v4 as uuidv4} from "uuid";
import {bus} from "ionicons/icons";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import FeedTagPost from "@/components/FeedTagPost.vue";
import FeedPost from "@/components/FeedPost.vue";

const loading = ref(true)
const search = ref('')
const posts: Ref<any> = ref([])
const meta: Ref<any> = ref()
const showInfiniteScroll = ref(true)

const busy = ref(false)
const containerId = uuidv4()


const ionRouter = useIonRouter()

const props = defineProps({
  hashtag: {
    type: String,
    default: ''
  },
  canSearch: {
    type: Boolean,
    default: true
  }
})

const itemSize = ref(0)
const observer = ref()
const user = ref()

onMounted(async () => {

  user.value = await ApiService.User().authUser()
  itemSize.value = window.outerWidth / 3

  observer.value = Observable.subscribe(async (response: any) => {
    if (response.event === ObservableEvent.SearchScrollTop) {
      scrollContainer.value.$el.scrollTop = 0
      await getPosts(search.value, 1)
    }
  })

  await getPosts(props.hashtag.replace('#', ''))
  setContainerHeight()
  addContainerEventListeners()


})

const setContainerHeight = () => {
  const containerHeight = scrollContainer.value.$el.closest('ion-content')?.clientHeight
  if (containerHeight === 0) return
  scrollContainer.value.$el.style.height = `${containerHeight-89}px`
}

onUpdated(async () => {
  await nextTick()
})

onUnmounted(() => {
  observer.value?.unsubscribe()
})

const addContainerEventListeners = () => {
  scrollContainer.value.$el.addEventListener("scroll", () => {
    handleScroll()
  });
}

watch(() => search.value, async tag => {
  await getPosts(tag)
})

const getPosts = async (tag = '', page = 1) => {
  if (busy.value) return
  busy.value = true
  const feedPosts = await ApiService.Search().tags(tag, page)
  meta.value = feedPosts.meta

  if (page === 1) {
    scrollContainer.value.$el.scrollTop = 0
    posts.value = feedPosts.data
  } else {
    posts.value = [...posts.value, ...feedPosts.data]
  }
  loading.value = false
  busy.value = false
}

const refresh = async (ev: any) => {
  await getPosts(search.value)

  ev.target.complete()
  showInfiniteScroll.value = true
}

const loadMore = async () => {
  let page = meta.value.current_page + 1
  if (page > meta.value.last_page) {
    return
  }
  await getPosts(search.value, page)
}

const viewPost = async (post: FeedPostInterface) => {
  ionRouter.navigate({
    name: 'SearchViewPost',
    params: {
      postId: post.id
    }
  }, 'forward', 'push')

}


const handleScroll = async () => {
  const threshold = 300; // Adjust this threshold as needed
  const containerHeight = scrollContainer.value.$el.closest('ion-content')?.clientHeight
  const isScrolledToBottom = scrollContainer.value.$el.scrollTop >= scrollContainer.value.$el.offsetHeight - threshold;
  if (isScrolledToBottom) await loadMore()
}

const scrollContainer: Ref<any> = ref(null)


</script>