import {Glassfy} from "capacitor-plugin-glassfy";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {version} from "../package.json"
const appVersion = version

import {createApp} from 'vue'
import router from './router';


import {IonicVue, IonSkeletonText, isPlatform, useBackButton, useIonRouter} from '@ionic/vue';


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Theme variables */
import './theme/variables.css';

import './assets/tailwind.css';
import vue3PhotoPreview from 'vue3-photo-preview';
import 'vue3-photo-preview/dist/index.css';
import 'floating-vue/dist/style.css'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import VueVirtualScroller, {DynamicScroller, DynamicScrollerItem, RecycleScroller} from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import * as Sentry from "@sentry/capacitor";

import 'plyr/dist/plyr.css'

Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: `pineapple@${appVersion}`,
    dist: `1`,
    debug: false,
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VirtualList from 'vue-virtual-list-v3';


import './registerServiceWorker';
import OneSignalVuePlugin from "@onesignal/onesignal-vue3";
import VueObserveVisibility from 'vue-observe-visibility'
import {CapacitorUpdater} from '@capgo/capacitor-updater';
import {SplashScreen} from "@capacitor/splash-screen";
import {App as CapacitorApp} from "@capacitor/app";
import App from './App.vue'

CapacitorUpdater.addListener('appReady', () => {
    // Hide splash
    SplashScreen.hide()
})

CapacitorUpdater.setChannel({
    channel: 'production',
    triggerAutoUpdate: true
}).then(() => CapacitorUpdater.notifyAppReady())

const app = createApp(App)
    .use(OneSignalVuePlugin, {
        appId: process.env.VUE_APP_ONE_SIGNAL_APP_ID,
        notifyButton: {
            enable: false,
        },
        serviceWorkerParam: {scope: "/push/onesignal/myCustomScope/"},
        serviceWorkerPath: "push/onesignal/OneSignalSDKWorker.js",
    })
    .use(IonicVue)
    .use(router)
    .use(vue3PhotoPreview)
    .use(VueVirtualScroller)
    .use(VirtualList)
    .use(VueObserveVisibility)

app.component('RecycleScroller', RecycleScroller)
app.component('DynamicScroller', DynamicScroller)
app.component('DynamicScrollerItem', DynamicScrollerItem)

app.provide('appVersion', appVersion)

router.isReady().then(async () => {

    try {
        if (isPlatform('capacitor')) {
            await Glassfy.initialize({
                apiKey: 'f5867fc6b8524cda943bf5aaff39f451',
                watcherMode: false
            })

            useBackButton(-1, () => {

                const w = document.getElementsByClassName('PhotoSlider__PhotoBox')
                if (w.length) {
                    const modal = w.item(0) as any
                    modal?.click()
                }

            });
            
            return app.mount('#app');
        }
        app.mount('#app');
    } catch (e) {
        // initialization error
    }

});