<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title class="text-white"></ion-title>
        <ion-buttons slot="start">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-router-outlet  class="z-10 w-full mx-auto"></ion-router-outlet>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter,
  IonButton,
  IonInput,
  IonRouterOutlet
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {StatusBar, Style} from "@capacitor/status-bar";
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'LoginRouterOutlet',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonRouterOutlet,
    IonContent
  },
  setup() {

    const router = useRouter()

    const goBack = async () => {
      await router.back()
    }

    const forgotPassword = async () => {
      await router.push({name: 'ForgotPassword'})
    }

    return {goBack,forgotPassword}

  }
});
</script>
