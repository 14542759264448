import ApiServiceBase from "@/services/api/apiServiceBase";
import {PostPrivacy} from "@/definitions/PostPrivacy";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import {SocialFeedType} from "@/definitions/SocialFeedType";
import {EventFilterIs} from "@/definitions/EventFilterIs";

export interface FeedServiceOptions {
  visibility?: PostPrivacy[]
}

export class EventsService extends ApiServiceBase {

  public async all(is?: EventFilterIs): Promise<any> {
    const paramsObj: any = {};

    if (is) paramsObj['is'] = is

    const params = new URLSearchParams(paramsObj);
    return this.http?.get(`/legacy/events?${params.toString()}`);
  }

  public async paginate(page = 1, is?: EventFilterIs): Promise<any> {
    const paramsObj: any = {};

    if (is) paramsObj['is'] = is
    paramsObj['page'] = page

    const params = new URLSearchParams(paramsObj);
    return this.http?.get(`/events?${params.toString()}`);
  }

  public async get(eventId?: number): Promise<any> {
    return this.http?.get(`/events/${eventId}`);
  }

  public async comments(eventId?: number): Promise<any> {
    return this.http?.get(`/events/${eventId}/comments`);
  }

  public async comment(comment: string, eventId: number, commentParentId?: number): Promise<any> {

    const params: any = {
      comment
    };

    if (commentParentId) params['parent_id'] = commentParentId
    return this.http?.put(`/events/${eventId}/comments?${(new URLSearchParams(params)).toString()}`, {});
  }

  public async interested(eventId: number, share?: string|null): Promise<any> {
    const data: any = {}
    if (share !== null) data['share'] = share
    return this.http?.post(`/events/${eventId}/interested`, data);
  }

  public async notInterested(eventId: number): Promise<any> {
    return this.http?.delete(`/events/${eventId}/interested`, {});
  }

  public async attending(eventId: number, share?: string|null): Promise<any> {

    const data: any = {}
    if (share !== null) data['share'] = share

    return this.http?.post(`/events/${eventId}/attending`, data);
  }

  public async notAttending(eventId: number): Promise<any> {
    return this.http?.delete(`/events/${eventId}/attending`, {});
  }

  public async requestInvite(eventId: number): Promise<any> {
    return this.http?.post(`/events/${eventId}/request-invite`, {});
  }

  public async cancelRequestInvite(eventId: number): Promise<any> {
    return this.http?.delete(`/events/${eventId}/request-invite`, {});
  }


  public async confirmInvite(eventId: number, userId: number): Promise<any> {
    return this.http?.post(`/events/${eventId}/user/${userId}/confirm`, {});
  }

  public async rejectInvite(eventId: number, userId: number, reason = ''): Promise<any> {
    return this.http?.post(`/events/${eventId}/user/${userId}/reject`, {
      reason
    });
  }

  public async deleteAttendanceRecord(eventId: number, userId: number): Promise<any> {
    return this.http?.delete(`/events/${eventId}/user/${userId}`, {});
  }

  public async delete(eventId: number): Promise<any> {
    return this.http?.delete(`/events/${eventId}`, {});
  }

  public async pendingResponse(): Promise<any> {
    return this.http?.get(`/events/pending-response`);
  }

  public async create(form: any): Promise<any> {
    return this.http?.post(`/events`, form);
  }

  public async update(form: any): Promise<any> {
    return this.http?.put(`/events/${form.id}`, form);
  }

  public async imageFor(eventId: number, image: string): Promise<any> {
    return this.http?.post(`/events/${eventId}/image`, {
      image
    });
  }

  public async deleteImageFor(eventId: number): Promise<any> {
    return this.http?.delete(`/events/${eventId}/image`, {});
  }



}
