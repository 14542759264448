<template>
  <div class="flex flex-col items-center p-4 py-10 space-y-10">

    <div class="flex flex-col space-y-6 items-center">
      <div class="h-20 w-20 bg-brand-lime text-brand-black rounded-full flex">
        <div class="m-auto">
          <svg class="w-7 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
                d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
          </svg>
        </div>
      </div>

      <div class="flex flex-col w-full items-center space-y-1 ">
        <div class="flex text-white text-2xl font-medium text-center">Nearby</div>
        <div class="text-brand-light-gray text-lg text-center space-y-4">
          <p>Within what radius are you looking?</p>
        </div>
      </div>
    </div>

    <div class="flex flex-col space-y-2 w-full">
      <input-checkbox
          @click="setOption"
          v-for="(o,oIndex) in options"
          :key="oIndex"
          name="option"
          v-model="option"
          :return-single-value="true"
          :option="o"
      />
    </div>

  </div>
</template>

<script setup lang="ts">
import InputCheckbox from "@/components/InputCheckbox.vue";
import {Ref, ref} from "vue";
import {modalController} from "@ionic/vue";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {usePremium} from "@/composables/usePremium";

const options = [
  {
    text: 'Within 20 miles',
    value: 20,
  },
  {
    text: 'Within 60 miles',
    value: 60
  },
  {
    text: 'Within 100 miles',
    value: 100
  },
  {
    text: 'Within 200 miles',
    value: 200
  },
  {
    text: 'Within 300 miles',
    value: 300
  },
  {
    text: 'Within 500 miles',
    value: 500,

  }
]

const option: Ref<any[]> = ref([false])

const setOption = async () => {
  const user = await ApiService.User().authUser()
  if (option.value[0] > 20 && !user.subscribed) {
    option.value = [false]
    return await usePremium().presentPayWall({
      text: 'Upgrade to explore further afield.',
      callback: () => {
        //
      }
    })
  }

  return await modalController.dismiss(option.value[0] ?? false)
}

</script>