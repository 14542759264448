<template>
  <ion-page>
    <ion-content class="flex flex-col h-full">


      <div class="h-full flex flex-col">


        <div class="flex flex-col w-full bg-brand-black my-auto h-full">
          <div class="flex flex-col m-auto items-center w-full px-6 space-y-14 pt-20">

            <div class="flex flex-col items-center space-y-14">
              <logo-mark/>
              <div class="flex flex-col w-full space-y-2 text-center">
                <div class="flex mx-auto text-white text-2xl font-medium">Thank you for telling us about your experience</div>
                <div class="text-brand-light-gray text-lg">Sharing your experience isn’t always easy, and we appreciate it when you do. Your safety is important to us, each report allows us to maintain a safe space for all Pineapple users.</div>
              </div>
            </div>

            <button
                @click="done"
                class="flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
              <div class="flex m-auto space-x-2.5">
                <div class="my-auto pt-0.5">
                  <div>Done</div>
                </div>
              </div>
            </button>

          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {IonContent, IonPage, useIonRouter,} from '@ionic/vue';
import {defineComponent, onMounted, ref} from 'vue';
import {useRoute, useRouter} from "vue-router";
import LogoMark from "@/components/LogoMark.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

export default defineComponent({
  name: 'ReportUserConfirmationPage',
  methods: {useRoute},
  components: {
    IonPage,
    IonContent,
    LogoMark
  },
  setup() {

    const loading = ref(true)
    const ionRouter = useIonRouter()
    const route = useRoute()
    const router = useRouter()

    const message = ref('')
    const errors = ref({})

    const goBack = async () => {
      await ionRouter.back()
    }

    onMounted(() => {
      loading.value = false
    })

    const done = async () => {
      await Observable.emit(ObservableEvent.FriendsChanged)
      ionRouter.navigate({
        name: 'PineappleUserProfile',
        params: {
          userId: route.params.userId
        }
      }, 'back', 'pop')
    }

    return {
      goBack,
      done,
      loading,
      message,
      errors
    }

  }
});
</script>