<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="mr-4">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title class="text-white font-medium text-2xl">Find your partner</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="h-full">
      <div class="flex flex-col py-10 px-4 justify-center space-y-10">
        <div v-if="!partnerInvites.length" class="flex flex-col space-y-2">
          <input-field
              placeholder="Search by username"
              :clear-input="true"
              :debounce="300"
              v-model="partnerSearch">
            <template v-slot:icon-start>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M21 21L16.514 16.506L21 21ZM19 10.5C19 12.7543 18.1045 14.9163 16.5104 16.5104C14.9163 18.1045 12.7543 19 10.5 19C8.24566 19 6.08365 18.1045 4.48959 16.5104C2.89553 14.9163 2 12.7543 2 10.5C2 8.24566 2.89553 6.08365 4.48959 4.48959C6.08365 2.89553 8.24566 2 10.5 2C12.7543 2 14.9163 2.89553 16.5104 4.48959C18.1045 6.08365 19 8.24566 19 10.5V10.5Z"
                      stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </template>
          </input-field>
        </div>

        <div v-if="partnerInvites.length" class="flex flex-col space-y-2">
          <div
              @click="partnerInvites.splice(participantIndex,1)"
              v-for="(partner,participantIndex) in partnerInvites"
              :key="partner.id"
              class="rounded-2xl bg-brand-dark-gray text-white flex px-4 py-3 justify-between">

            <div class="flex space-x-4">
              <div class="flex w-10 h-10 relative">
                <div
                    :style="{
                                    'background-image' : `url('${partner.avatar_thumbnail ?? partner.avatar}')`
                                }"
                    class="w-full rounded-full h-full absolute top-0 left-0 bg-brand-black bg-cover bg-center relative">

                  <div v-if="partner.relationship_status === 'couple'" class="flex w-5 h-5 rounded-full bg-brand-blue absolute -bottom-0.5 -right-0.5 border-brand-black border-2">
                    <div class="flex m-auto text-brand-black text-opacity-80">
                      <svg class="h-2.5 w-2.5 fill-current" width="24" height="25" viewBox="0 0 24 25"
                           xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5291 19.2339L17.4684 19.0516C15.8278 15.9225 12.5468 13.7959 8.77975 13.7959C5.01266 13.7959 1.73165 15.9225 0.091139 19.0212L0.0303799 19.2035C1.15437e-07 19.2643 0 19.3554 0 19.4465V20.206C0 20.6617 0.394936 21.0567 0.850633 21.0567H8.77975H16.7392C17.1949 21.0567 17.5899 20.6617 17.5899 20.206V19.4465C17.5899 19.3858 17.5595 19.2946 17.5291 19.2339Z"
                        />
                        <path d="M18.957 18.3531L18.9266 18.2923C17.9848 16.5303 16.6481 15.1024 15.0684 14.0695C15.919 13.6746 16.8911 13.4619 17.8937 13.4619C20.5063 13.4619 22.7544 14.9201 23.9089 17.0771L23.9696 17.1986C24 17.2594 24 17.3201 24 17.3505V17.867C24 18.2012 23.7266 18.4442 23.4228 18.4442H19.0177L18.957 18.3531Z"
                        />
                        <path d="M17.8937 11.5784C19.4541 11.5784 20.719 10.3134 20.719 8.75305C20.719 7.19267 19.4541 5.92773 17.8937 5.92773C16.3333 5.92773 15.0684 7.19267 15.0684 8.75305C15.0684 10.3134 16.3333 11.5784 17.8937 11.5784Z"
                        />
                        <path d="M8.74968 12.4896C11.1658 12.4896 13.1244 10.531 13.1244 8.11492C13.1244 5.69885 11.1658 3.74023 8.74968 3.74023C6.33361 3.74023 4.375 5.69885 4.375 8.11492C4.375 10.531 6.33361 12.4896 8.74968 12.4896Z"
                        />
                      </svg>
                    </div>
                  </div>

                </div>
              </div>

              <div class="flex space-x-2">
                <div class="flex text-white text-lg font-medium my-auto">{{ partner.name }}</div>
                <user-verification-check class="h-4" :user="partner"/>
              </div>
            </div>
            <div class="flex my-auto">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C10.6864 22.0013 9.38546 21.7436 8.17162 21.2415C6.95777 20.7394 5.85485 20.0029 4.926 19.074C3.99716 18.1452 3.26061 17.0423 2.75853 15.8284C2.25645 14.6146 1.99869 13.3136 2.00001 12V11.8C2.04001 9.83944 2.6553 7.93383 3.76939 6.32005C4.88348 4.70626 6.44726 3.45546 8.26636 2.72309C10.0855 1.99071 12.0797 1.80907 14.0012 2.20073C15.9227 2.59239 17.6867 3.54008 19.074 4.92603C20.4737 6.32457 21.4271 8.10693 21.8134 10.0475C22.1997 11.9881 22.0017 13.9997 21.2443 15.8277C20.487 17.6556 19.2043 19.2179 17.5588 20.3166C15.9132 21.4153 13.9787 22.0012 12 22ZM12 13.41L14.59 16L16 14.59L13.41 12L16 9.41003L14.59 8.00003L12 10.59L9.41001 8.00003L8.00001 9.41003L10.59 12L8.00001 14.59L9.41001 16L12 13.411V13.41Z"
                      fill="#A5A7AF"/>
              </svg>
            </div>
          </div>

        </div>


        <div v-if="partnerSearch.length && partnerSearchResults.length" class="flex flex-col space-y-2">
          <div class="font-medium text-white">Search results ({{ partnerSearchResults.length }})</div>
          <div
              @click="addToInvite(partner)"
              v-for="partner in partnerSearchResults"
              :key="partner.id"
              class="rounded-2xl bg-brand-dark-gray text-white flex px-4 py-3 space-x-4">

            <user-avatar :user="partner" />

            <div class="flex space-x-2">
              <div class="flex text-white text-lg font-medium my-auto">{{ partner.name }}</div>
              <user-verification-check class="h-4" :user="partner"/>
            </div>
          </div>

        </div>

        <button
            @click="sendRequest"
            :class="{
              'bg-opacity-40' : !partnerInvites.length
            }"
            class="mx-auto w-full flex bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
          <div class="flex m-auto space-x-2.5">
            <div class="my-auto pt-0.5">
              <div v-if="!busy">Send request</div>
              <div v-if="busy" class="my-auto">
                <svg class="animate-spin h-5 w-5 text-brand-black" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
          </div>
        </button>

      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  actionSheetController, alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {inject, Ref, ref, watch} from 'vue';
import ApiService from "@/services/api/apiService";
import {DateTime} from "luxon";
import MenuButton from "@/components/MenuButton.vue";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {UserInterface} from "@/interfaces/UserInterface";
import InputField from "@/components/InputField.vue";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import _ from "lodash";
import UserVerificationCheck from "@/components/UserBadges.vue";
import pluralize from "pluralize";
import {useUser} from "@/composables/useUser";
import UserAvatar from "@/components/UserAvatar.vue";

const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>

const loading = ref(true)
const ionRouter = useIonRouter()
const busy = ref(false)

const partnerSearch = ref('')
const partnerSearchResults: Ref<UserBasicInformationInterface[]> = ref([])
const partnerSearchResultsCopy: Ref<UserBasicInformationInterface[]> = ref([])

const partnerInvites: Ref<UserBasicInformationInterface[]> = ref([])


watch(() => partnerSearch.value, async query => {
  partnerSearchResults.value = await ApiService.Search().users(query)
  partnerSearchResultsCopy.value = _.cloneDeep(partnerSearchResults.value)
})

onIonViewWillEnter(async () => {
  loading.value = false
})

const goBack = async () => {
  await ionRouter.back()
}

const sendRequest = async () => {
  if (!partnerInvites.value.length) return
  busy.value = true
  try {
    await useUser().makePartnerRequest(partnerInvites.value[0].id)
    await ionRouter.back()
  } catch (e) {
    //
  } finally {
      busy.value = false
  }
}

watch(() => partnerInvites.value, async partners => {
  const partnerIds = partners.map((p) => p.id)
  partnerSearchResults.value = partnerSearchResultsCopy.value.filter((partner) => {
    return !partnerIds.includes(partner.id)
  })
}, {deep: true})

const addToInvite = (partner: UserBasicInformationInterface) => {
  partnerInvites.value.push(partner)
  partnerSearch.value = ''
}

</script>
