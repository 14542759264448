<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons class="flex" slot="start">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title>
          <div class="text-2xl text-white font-medium">{{tag}}</div>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content id="searchContainer" class="flex h-full w-full">

      <search-tags :can-search="false" :hashtag="tag" />

    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/vue';
import {useRoute, useRouter} from "vue-router";
import SearchTags from "@/components/SearchTags.vue";


const router = useRouter()
const route = useRoute()

const tag = route.params.tag as string

const goBack = async () => {
  await router.back()
}

</script>