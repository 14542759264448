<template>
  <div class="flex">

    <div v-if="verifiedStatus === 'unverified'"
         class="flex w-full bg-brand-dark-gray px-4 py-4 rounded-lg space-x-5">
      <div class="flex">
        <div class="flex w-16 h-16 bg-white rounded-full my-auto">
          <svg class="fill-current text-brand-black m-auto" width="18" height="46" viewBox="0 0 18 46"
               xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_314_166252)">
              <path d="M0.5 22.6246L0.501484 22.6261L0.502968 22.6246L0.501484 22.623L0.5 22.6246Z"/>
              <path d="M11.8135 22.623L11.815 22.6246L11.8164 22.623H11.8135Z"/>
              <path d="M11.8105 22.623L11.813 22.6243L11.8143 22.623H11.8105Z"/>
              <path d="M6.15625 22.623L6.15875 22.6243L6.16001 22.623H6.15625Z"/>
              <path d="M6.15527 22.623L6.15676 22.6246L6.15824 22.623H6.15527Z"/>
              <path d="M9.01418 3.26367L6.1875 6.02913H11.84L9.01418 3.26367Z"/>
              <path d="M6.18577 11.5605L3.35742 14.3268H9.01411L6.18577 11.5605Z"/>
              <path d="M17.4999 14.3268V11.5605L14.6699 14.3268H17.4999Z"/>
              <path d="M17.4999 8.79639V6.0293L14.6699 8.79639H17.4999Z"/>
              <path
                  d="M9.01389 8.79574L9.01306 8.79492L6.18555 11.5612H11.8414L9.01472 8.79492L9.01389 8.79574Z"/>
              <path
                  d="M14.6713 8.79574L14.6704 8.79492L11.8438 11.5612H17.4996L14.6721 8.79492L14.6713 8.79574Z"/>
              <path
                  d="M3.35959 8.79574L3.35876 8.79492L0.53125 11.5612H6.18711L3.36043 8.79492L3.35959 8.79574Z"/>
              <path d="M14.6695 14.3268L11.8412 11.5605L9.01367 14.3268H14.6695Z"/>
              <path d="M11.84 17.0936L9.01418 14.3281L6.1875 17.0936H11.84Z"/>
              <path d="M17.4924 17.0936L14.6665 14.3281L11.8398 17.0936H17.4924Z"/>
              <path d="M6.18573 17.0936L3.35988 14.3281L0.533203 17.0936H6.18573Z"/>
              <path
                  d="M6.18703 17.0938L6.1862 17.0946L6.18537 17.0938L3.36035 19.8576H9.01288L6.18703 17.0938Z"/>
              <path
                  d="M11.8406 17.0938L9.01562 19.8576H14.6682L11.8423 17.0938L11.8415 17.0946L11.8406 17.0938Z"/>
              <path d="M0.529297 11.5605V14.3268H3.35764L0.529297 11.5605Z"/>
              <path d="M0.529297 6.02734V8.79443H3.35764L0.529297 6.02734Z"/>
              <path d="M11.8406 6.0293L9.01562 8.79313H14.6682L11.8423 6.0293L11.8415 6.03011L11.8406 6.0293Z"/>
              <path d="M6.18703 6.0293L6.1862 6.03011L6.18537 6.0293L3.36035 8.79313H9.01288L6.18703 6.0293Z"/>
              <path
                  d="M3.35695 3.26562L3.35612 3.26644L3.35529 3.26562L0.530273 6.02946H6.1828L3.35695 3.26562Z"/>
              <path
                  d="M14.6674 3.26562L14.6665 3.26644L14.6649 3.26562L11.8398 6.02946H17.4924L14.6674 3.26562Z"/>
              <path d="M11.8387 0.5L9.01367 3.26384H14.6662L11.8404 0.5L11.8395 0.500814L11.8387 0.5Z"/>
              <path d="M6.18703 0.5L6.1862 0.500814L6.18537 0.5L3.36035 3.26384H9.01288L6.18703 0.5Z"/>
              <path d="M9.01335 19.8574L6.1875 22.6221H11.84L9.01501 19.8574L9.01418 19.8582L9.01335 19.8574Z"/>
              <path d="M9.01501 26.2521L11.84 23.4883H6.1875L9.01335 26.2521L9.01418 26.2513L9.01501 26.2521Z"/>
              <path d="M11.8418 17.0938L11.8443 17.095L11.8456 17.0938H11.8418Z"/>
              <path d="M11.8398 17.0938L11.8413 17.0953L11.8428 17.0938H11.8398Z"/>
              <path d="M6.18555 17.0938L6.18703 17.0953L6.18851 17.0938H6.18555Z"/>
              <path d="M0.529297 17.0946L1.93141 17.0954L2.10855 17.0946V17.0938L0.529297 17.0946Z"/>
              <path d="M14.668 8.79492L14.6695 8.79645L14.6709 8.79492H14.668Z"/>
              <path d="M14.6699 8.79492L14.6714 8.79645L14.6729 8.79492H14.6699Z"/>
              <path d="M9.0127 8.79492L9.01418 8.79645L9.01566 8.79492H9.0127Z"/>
              <path d="M9.01367 8.79492L9.01516 8.79645L9.01664 8.79492H9.01367Z"/>
              <path d="M3.35742 8.79492L3.35993 8.79619L3.36118 8.79492H3.35742Z"/>
              <path d="M3.35547 8.79492L3.35695 8.79645L3.35844 8.79492H3.35547Z"/>
              <path d="M11.8418 6.0293L11.8443 6.03056L11.8456 6.0293H11.8418Z"/>
              <path d="M11.8398 6.0293L11.8413 6.03083L11.8428 6.0293H11.8398Z"/>
              <path d="M6.18555 6.0293L6.18703 6.03083L6.18851 6.0293H6.18555Z"/>
              <path d="M6.18457 6.0293L6.18605 6.03083L6.18754 6.0293H6.18457Z"/>
              <path d="M6.18457 17.0938L6.18605 17.0953L6.18754 17.0938H6.18457Z"/>
              <path d="M14.6699 19.8574L14.6714 19.859L14.6729 19.8574H14.6699Z"/>
              <path d="M14.668 19.8574L14.6695 19.859L14.6709 19.8574H14.668Z"/>
              <path d="M9.01367 19.8574L9.01516 19.859L9.01664 19.8574H9.01367Z"/>
              <path d="M9.0127 19.8574L9.01418 19.859L9.01566 19.8574H9.0127Z"/>
              <path d="M3.35742 19.8574L3.35993 19.8587L3.36118 19.8574H3.35742Z"/>
              <path d="M3.35547 19.8574L3.35695 19.859L3.35844 19.8574H3.35547Z"/>
              <path d="M8.34196 26.9668L5.41049 24.0996L1.25488 33.0599L8.34196 26.9668Z"/>
              <path d="M8.60781 27.8945L4.05551 32.2962L3.69043 41.7186L8.60781 27.8945Z"/>
              <path d="M9.69434 26.9668L12.625 24.0996L16.7814 33.0599L9.69434 26.9668Z"/>
              <path d="M9.42871 27.8945L13.9802 32.2962L14.3461 41.7186L9.42871 27.8945Z"/>
              <path d="M11.1526 37.4337L8.99293 29.6621L6.88477 37.4126L8.99293 45.5007L11.1526 37.4337Z"/>
            </g>
            <defs>
              <clipPath id="clip0_314_166252">
                <rect width="17" height="45" fill="white" transform="translate(0.5 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div @click="getVerified" class="flex flex-col my-auto">
        <div class="text-white font-medium text-xl">Unverified</div>
        <div class="text-brand-light-gray">Identity has not been verified.</div>
        <div v-if="isAuthUser" class="text-brand-light-gray underline">Tap here to get verified.</div>
      </div>
    </div>

    <div v-if="verifiedStatus === 'verified'"
         class="flex flex-col w-full bg-brand-dark-gray px-4 py-4 rounded-lg">
      <div class="flex space-x-5">
        <div class="flex">
          <div class="flex w-16 h-16 bg-brand-black relative rounded-full">
            <svg
                :class="{
                  'text-brand-lime' : subscribed,
                  'text-brand-light-gray' : !subscribed
                }"
                class="absolute fill-current top-0 right-0" width="21" height="22"
                 viewBox="0 0 21 22"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.43171 1.84058C6.82372 2.05511 8.23524 1.59679 9.23476 0.604586C9.63213 0.212093 10.0563 0 10.5 0C10.8584 0 11.2045 0.138957 11.5336 0.397369C11.6165 0.463191 11.697 0.536327 11.7774 0.616776C12.7769 1.59435 14.1787 2.04292 15.5585 1.82839C16.1144 1.75769 16.5873 1.83326 16.9481 2.09655C17.3065 2.3574 17.5259 2.77915 17.6161 3.3301C17.8428 4.71967 18.7155 5.92153 19.9662 6.56512C20.461 6.8211 20.795 7.15996 20.9316 7.58171C21.0681 8.00346 20.9974 8.47396 20.7463 8.97128C20.1124 10.2292 20.1124 11.7139 20.7463 12.9718C20.9974 13.4691 21.0681 13.9396 20.9316 14.3614C20.795 14.7831 20.461 15.122 19.9662 15.3779C18.7131 16.024 17.8428 17.2234 17.6161 18.613C17.5259 19.1639 17.3065 19.5857 16.9481 19.8465C16.5897 20.1074 16.1192 20.1854 15.5683 20.1001C14.1763 19.8855 12.7648 20.3438 11.7652 21.336C11.3679 21.7285 10.9437 21.9406 10.5 21.9406C10.0563 21.9406 9.63213 21.7285 9.23476 21.336C8.23524 20.3438 6.82372 19.8855 5.43171 20.1001C4.88076 20.1854 4.41025 20.1074 4.05189 19.8465C3.69353 19.5857 3.47412 19.1639 3.38392 18.613C3.1572 17.2234 2.28445 16.0215 1.03384 15.3779C0.538952 15.122 0.204967 14.7831 0.0684473 14.3614C-0.0680722 13.9396 0.00262532 13.4691 0.253724 12.9718C0.887564 11.7139 0.887564 10.2292 0.253724 8.97128C0.00262532 8.47396 -0.0680722 8.00346 0.0684473 7.58171C0.204967 7.15996 0.538952 6.8211 1.03384 6.56512C2.28689 5.9191 3.1572 4.71967 3.38392 3.3301C3.47412 2.77915 3.69353 2.3574 4.05189 2.09655C4.41025 1.8357 4.88076 1.75769 5.43171 1.84301V1.84058Z"/>
              <path d="M6 11L9 14L14 8" stroke="#1A1B22" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
            <svg class="fill-current text-white m-auto" width="18" height="46" viewBox="0 0 18 46"
                 xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_314_166252)">
                <path d="M0.5 22.6246L0.501484 22.6261L0.502968 22.6246L0.501484 22.623L0.5 22.6246Z"/>
                <path d="M11.8135 22.623L11.815 22.6246L11.8164 22.623H11.8135Z"/>
                <path d="M11.8105 22.623L11.813 22.6243L11.8143 22.623H11.8105Z"/>
                <path d="M6.15625 22.623L6.15875 22.6243L6.16001 22.623H6.15625Z"/>
                <path d="M6.15527 22.623L6.15676 22.6246L6.15824 22.623H6.15527Z"/>
                <path d="M9.01418 3.26367L6.1875 6.02913H11.84L9.01418 3.26367Z"/>
                <path d="M6.18577 11.5605L3.35742 14.3268H9.01411L6.18577 11.5605Z"/>
                <path d="M17.4999 14.3268V11.5605L14.6699 14.3268H17.4999Z"/>
                <path d="M17.4999 8.79639V6.0293L14.6699 8.79639H17.4999Z"/>
                <path
                    d="M9.01389 8.79574L9.01306 8.79492L6.18555 11.5612H11.8414L9.01472 8.79492L9.01389 8.79574Z"/>
                <path
                    d="M14.6713 8.79574L14.6704 8.79492L11.8438 11.5612H17.4996L14.6721 8.79492L14.6713 8.79574Z"/>
                <path
                    d="M3.35959 8.79574L3.35876 8.79492L0.53125 11.5612H6.18711L3.36043 8.79492L3.35959 8.79574Z"/>
                <path d="M14.6695 14.3268L11.8412 11.5605L9.01367 14.3268H14.6695Z"/>
                <path d="M11.84 17.0936L9.01418 14.3281L6.1875 17.0936H11.84Z"/>
                <path d="M17.4924 17.0936L14.6665 14.3281L11.8398 17.0936H17.4924Z"/>
                <path d="M6.18573 17.0936L3.35988 14.3281L0.533203 17.0936H6.18573Z"/>
                <path
                    d="M6.18703 17.0938L6.1862 17.0946L6.18537 17.0938L3.36035 19.8576H9.01288L6.18703 17.0938Z"/>
                <path
                    d="M11.8406 17.0938L9.01562 19.8576H14.6682L11.8423 17.0938L11.8415 17.0946L11.8406 17.0938Z"/>
                <path d="M0.529297 11.5605V14.3268H3.35764L0.529297 11.5605Z"/>
                <path d="M0.529297 6.02734V8.79443H3.35764L0.529297 6.02734Z"/>
                <path d="M11.8406 6.0293L9.01562 8.79313H14.6682L11.8423 6.0293L11.8415 6.03011L11.8406 6.0293Z"/>
                <path d="M6.18703 6.0293L6.1862 6.03011L6.18537 6.0293L3.36035 8.79313H9.01288L6.18703 6.0293Z"/>
                <path
                    d="M3.35695 3.26562L3.35612 3.26644L3.35529 3.26562L0.530273 6.02946H6.1828L3.35695 3.26562Z"/>
                <path
                    d="M14.6674 3.26562L14.6665 3.26644L14.6649 3.26562L11.8398 6.02946H17.4924L14.6674 3.26562Z"/>
                <path d="M11.8387 0.5L9.01367 3.26384H14.6662L11.8404 0.5L11.8395 0.500814L11.8387 0.5Z"/>
                <path d="M6.18703 0.5L6.1862 0.500814L6.18537 0.5L3.36035 3.26384H9.01288L6.18703 0.5Z"/>
                <path d="M9.01335 19.8574L6.1875 22.6221H11.84L9.01501 19.8574L9.01418 19.8582L9.01335 19.8574Z"/>
                <path d="M9.01501 26.2521L11.84 23.4883H6.1875L9.01335 26.2521L9.01418 26.2513L9.01501 26.2521Z"/>
                <path d="M11.8418 17.0938L11.8443 17.095L11.8456 17.0938H11.8418Z"/>
                <path d="M11.8398 17.0938L11.8413 17.0953L11.8428 17.0938H11.8398Z"/>
                <path d="M6.18555 17.0938L6.18703 17.0953L6.18851 17.0938H6.18555Z"/>
                <path d="M0.529297 17.0946L1.93141 17.0954L2.10855 17.0946V17.0938L0.529297 17.0946Z"/>
                <path d="M14.668 8.79492L14.6695 8.79645L14.6709 8.79492H14.668Z"/>
                <path d="M14.6699 8.79492L14.6714 8.79645L14.6729 8.79492H14.6699Z"/>
                <path d="M9.0127 8.79492L9.01418 8.79645L9.01566 8.79492H9.0127Z"/>
                <path d="M9.01367 8.79492L9.01516 8.79645L9.01664 8.79492H9.01367Z"/>
                <path d="M3.35742 8.79492L3.35993 8.79619L3.36118 8.79492H3.35742Z"/>
                <path d="M3.35547 8.79492L3.35695 8.79645L3.35844 8.79492H3.35547Z"/>
                <path d="M11.8418 6.0293L11.8443 6.03056L11.8456 6.0293H11.8418Z"/>
                <path d="M11.8398 6.0293L11.8413 6.03083L11.8428 6.0293H11.8398Z"/>
                <path d="M6.18555 6.0293L6.18703 6.03083L6.18851 6.0293H6.18555Z"/>
                <path d="M6.18457 6.0293L6.18605 6.03083L6.18754 6.0293H6.18457Z"/>
                <path d="M6.18457 17.0938L6.18605 17.0953L6.18754 17.0938H6.18457Z"/>
                <path d="M14.6699 19.8574L14.6714 19.859L14.6729 19.8574H14.6699Z"/>
                <path d="M14.668 19.8574L14.6695 19.859L14.6709 19.8574H14.668Z"/>
                <path d="M9.01367 19.8574L9.01516 19.859L9.01664 19.8574H9.01367Z"/>
                <path d="M9.0127 19.8574L9.01418 19.859L9.01566 19.8574H9.0127Z"/>
                <path d="M3.35742 19.8574L3.35993 19.8587L3.36118 19.8574H3.35742Z"/>
                <path d="M3.35547 19.8574L3.35695 19.859L3.35844 19.8574H3.35547Z"/>
                <path d="M8.34196 26.9668L5.41049 24.0996L1.25488 33.0599L8.34196 26.9668Z"/>
                <path d="M8.60781 27.8945L4.05551 32.2962L3.69043 41.7186L8.60781 27.8945Z"/>
                <path d="M9.69434 26.9668L12.625 24.0996L16.7814 33.0599L9.69434 26.9668Z"/>
                <path d="M9.42871 27.8945L13.9802 32.2962L14.3461 41.7186L9.42871 27.8945Z"/>
                <path d="M11.1526 37.4337L8.99293 29.6621L6.88477 37.4126L8.99293 45.5007L11.1526 37.4337Z"/>
              </g>
              <defs>
                <clipPath id="clip0_314_166252">
                  <rect width="17" height="45" fill="white" transform="translate(0.5 0.5)"/>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div class="flex flex-col my-auto">
          <div class="text-white font-medium text-xl">Verified</div>
          <div class="text-brand-light-gray">Identity verified by Pineapple.</div>
        </div>
      </div>

    </div>

    <div v-if="verifiedStatus === 'community'"
         class="flex w-full bg-brand-dark-gray px-4 py-4 rounded-lg space-x-5">
      <div class="flex">
        <div class="flex w-16 h-16 bg-brand-black rounded-full relative">
          <svg class="absolute text-brand-lime fill-current top-0 right-0" width="21" height="22" viewBox="0 0 21 22"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.43171 1.84058C6.82372 2.05511 8.23524 1.59679 9.23476 0.604586C9.63213 0.212093 10.0563 0 10.5 0C10.8584 0 11.2045 0.138957 11.5336 0.397369C11.6165 0.463191 11.697 0.536327 11.7774 0.616776C12.7769 1.59435 14.1787 2.04292 15.5585 1.82839C16.1144 1.75769 16.5873 1.83326 16.9481 2.09655C17.3065 2.3574 17.5259 2.77915 17.6161 3.3301C17.8428 4.71967 18.7155 5.92153 19.9662 6.56512C20.461 6.8211 20.795 7.15996 20.9316 7.58171C21.0681 8.00346 20.9974 8.47396 20.7463 8.97128C20.1124 10.2292 20.1124 11.7139 20.7463 12.9718C20.9974 13.4691 21.0681 13.9396 20.9316 14.3614C20.795 14.7831 20.461 15.122 19.9662 15.3779C18.7131 16.024 17.8428 17.2234 17.6161 18.613C17.5259 19.1639 17.3065 19.5857 16.9481 19.8465C16.5897 20.1074 16.1192 20.1854 15.5683 20.1001C14.1763 19.8855 12.7648 20.3438 11.7652 21.336C11.3679 21.7285 10.9437 21.9406 10.5 21.9406C10.0563 21.9406 9.63213 21.7285 9.23476 21.336C8.23524 20.3438 6.82372 19.8855 5.43171 20.1001C4.88076 20.1854 4.41025 20.1074 4.05189 19.8465C3.69353 19.5857 3.47412 19.1639 3.38392 18.613C3.1572 17.2234 2.28445 16.0215 1.03384 15.3779C0.538952 15.122 0.204967 14.7831 0.0684473 14.3614C-0.0680722 13.9396 0.00262532 13.4691 0.253724 12.9718C0.887564 11.7139 0.887564 10.2292 0.253724 8.97128C0.00262532 8.47396 -0.0680722 8.00346 0.0684473 7.58171C0.204967 7.15996 0.538952 6.8211 1.03384 6.56512C2.28689 5.9191 3.1572 4.71967 3.38392 3.3301C3.47412 2.77915 3.69353 2.3574 4.05189 2.09655C4.41025 1.8357 4.88076 1.75769 5.43171 1.84301V1.84058Z"/>
            <path d="M6 11L9 14L14 8" stroke="#1A1B22" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
          <div v-if="false" class="absolute top-0 right-0 flex">
            <div class="flex w-full h-full absolute top-0 left-0">
              <div class="m-auto text-xs font-bold">7</div>
            </div>
            <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.43171 1.84058C6.82372 2.05511 8.23524 1.59679 9.23476 0.604586C9.63213 0.212093 10.0563 0 10.5 0C10.8584 0 11.2045 0.138957 11.5336 0.397369C11.6165 0.463191 11.697 0.536327 11.7774 0.616776C12.7769 1.59435 14.1787 2.04292 15.5585 1.82839C16.1144 1.75769 16.5873 1.83326 16.9481 2.09655C17.3065 2.3574 17.5259 2.77915 17.6161 3.3301C17.8428 4.71967 18.7155 5.92153 19.9662 6.56512C20.461 6.8211 20.795 7.15996 20.9316 7.58171C21.0681 8.00346 20.9974 8.47396 20.7463 8.97128C20.1124 10.2292 20.1124 11.7139 20.7463 12.9718C20.9974 13.4691 21.0681 13.9396 20.9316 14.3614C20.795 14.7831 20.461 15.122 19.9662 15.3779C18.7131 16.024 17.8428 17.2234 17.6161 18.613C17.5259 19.1639 17.3065 19.5857 16.9481 19.8465C16.5897 20.1074 16.1192 20.1854 15.5683 20.1001C14.1763 19.8855 12.7648 20.3438 11.7652 21.336C11.3679 21.7285 10.9437 21.9406 10.5 21.9406C10.0563 21.9406 9.63213 21.7285 9.23476 21.336C8.23524 20.3438 6.82372 19.8855 5.43171 20.1001C4.88076 20.1854 4.41025 20.1074 4.05189 19.8465C3.69353 19.5857 3.47412 19.1639 3.38392 18.613C3.1572 17.2234 2.28445 16.0215 1.03384 15.3779C0.538952 15.122 0.204967 14.7831 0.0684473 14.3614C-0.0680722 13.9396 0.00262532 13.4691 0.253724 12.9718C0.887564 11.7139 0.887564 10.2292 0.253724 8.97128C0.00262532 8.47396 -0.0680722 8.00346 0.0684473 7.58171C0.204967 7.15996 0.538952 6.8211 1.03384 6.56512C2.28689 5.9191 3.1572 4.71967 3.38392 3.3301C3.47412 2.77915 3.69353 2.3574 4.05189 2.09655C4.41025 1.8357 4.88076 1.75769 5.43171 1.84301V1.84058Z"
                    fill="#8AE666"/>
            </svg>
          </div>
          <svg class="fill-current text-brand-lime m-auto" width="18" height="46" viewBox="0 0 18 46"
               xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_314_166252)">
              <path d="M0.5 22.6246L0.501484 22.6261L0.502968 22.6246L0.501484 22.623L0.5 22.6246Z"/>
              <path d="M11.8135 22.623L11.815 22.6246L11.8164 22.623H11.8135Z"/>
              <path d="M11.8105 22.623L11.813 22.6243L11.8143 22.623H11.8105Z"/>
              <path d="M6.15625 22.623L6.15875 22.6243L6.16001 22.623H6.15625Z"/>
              <path d="M6.15527 22.623L6.15676 22.6246L6.15824 22.623H6.15527Z"/>
              <path d="M9.01418 3.26367L6.1875 6.02913H11.84L9.01418 3.26367Z"/>
              <path d="M6.18577 11.5605L3.35742 14.3268H9.01411L6.18577 11.5605Z"/>
              <path d="M17.4999 14.3268V11.5605L14.6699 14.3268H17.4999Z"/>
              <path d="M17.4999 8.79639V6.0293L14.6699 8.79639H17.4999Z"/>
              <path
                  d="M9.01389 8.79574L9.01306 8.79492L6.18555 11.5612H11.8414L9.01472 8.79492L9.01389 8.79574Z"/>
              <path
                  d="M14.6713 8.79574L14.6704 8.79492L11.8438 11.5612H17.4996L14.6721 8.79492L14.6713 8.79574Z"/>
              <path
                  d="M3.35959 8.79574L3.35876 8.79492L0.53125 11.5612H6.18711L3.36043 8.79492L3.35959 8.79574Z"/>
              <path d="M14.6695 14.3268L11.8412 11.5605L9.01367 14.3268H14.6695Z"/>
              <path d="M11.84 17.0936L9.01418 14.3281L6.1875 17.0936H11.84Z"/>
              <path d="M17.4924 17.0936L14.6665 14.3281L11.8398 17.0936H17.4924Z"/>
              <path d="M6.18573 17.0936L3.35988 14.3281L0.533203 17.0936H6.18573Z"/>
              <path
                  d="M6.18703 17.0938L6.1862 17.0946L6.18537 17.0938L3.36035 19.8576H9.01288L6.18703 17.0938Z"/>
              <path
                  d="M11.8406 17.0938L9.01562 19.8576H14.6682L11.8423 17.0938L11.8415 17.0946L11.8406 17.0938Z"/>
              <path d="M0.529297 11.5605V14.3268H3.35764L0.529297 11.5605Z"/>
              <path d="M0.529297 6.02734V8.79443H3.35764L0.529297 6.02734Z"/>
              <path d="M11.8406 6.0293L9.01562 8.79313H14.6682L11.8423 6.0293L11.8415 6.03011L11.8406 6.0293Z"/>
              <path d="M6.18703 6.0293L6.1862 6.03011L6.18537 6.0293L3.36035 8.79313H9.01288L6.18703 6.0293Z"/>
              <path
                  d="M3.35695 3.26562L3.35612 3.26644L3.35529 3.26562L0.530273 6.02946H6.1828L3.35695 3.26562Z"/>
              <path
                  d="M14.6674 3.26562L14.6665 3.26644L14.6649 3.26562L11.8398 6.02946H17.4924L14.6674 3.26562Z"/>
              <path d="M11.8387 0.5L9.01367 3.26384H14.6662L11.8404 0.5L11.8395 0.500814L11.8387 0.5Z"/>
              <path d="M6.18703 0.5L6.1862 0.500814L6.18537 0.5L3.36035 3.26384H9.01288L6.18703 0.5Z"/>
              <path d="M9.01335 19.8574L6.1875 22.6221H11.84L9.01501 19.8574L9.01418 19.8582L9.01335 19.8574Z"/>
              <path d="M9.01501 26.2521L11.84 23.4883H6.1875L9.01335 26.2521L9.01418 26.2513L9.01501 26.2521Z"/>
              <path d="M11.8418 17.0938L11.8443 17.095L11.8456 17.0938H11.8418Z"/>
              <path d="M11.8398 17.0938L11.8413 17.0953L11.8428 17.0938H11.8398Z"/>
              <path d="M6.18555 17.0938L6.18703 17.0953L6.18851 17.0938H6.18555Z"/>
              <path d="M0.529297 17.0946L1.93141 17.0954L2.10855 17.0946V17.0938L0.529297 17.0946Z"/>
              <path d="M14.668 8.79492L14.6695 8.79645L14.6709 8.79492H14.668Z"/>
              <path d="M14.6699 8.79492L14.6714 8.79645L14.6729 8.79492H14.6699Z"/>
              <path d="M9.0127 8.79492L9.01418 8.79645L9.01566 8.79492H9.0127Z"/>
              <path d="M9.01367 8.79492L9.01516 8.79645L9.01664 8.79492H9.01367Z"/>
              <path d="M3.35742 8.79492L3.35993 8.79619L3.36118 8.79492H3.35742Z"/>
              <path d="M3.35547 8.79492L3.35695 8.79645L3.35844 8.79492H3.35547Z"/>
              <path d="M11.8418 6.0293L11.8443 6.03056L11.8456 6.0293H11.8418Z"/>
              <path d="M11.8398 6.0293L11.8413 6.03083L11.8428 6.0293H11.8398Z"/>
              <path d="M6.18555 6.0293L6.18703 6.03083L6.18851 6.0293H6.18555Z"/>
              <path d="M6.18457 6.0293L6.18605 6.03083L6.18754 6.0293H6.18457Z"/>
              <path d="M6.18457 17.0938L6.18605 17.0953L6.18754 17.0938H6.18457Z"/>
              <path d="M14.6699 19.8574L14.6714 19.859L14.6729 19.8574H14.6699Z"/>
              <path d="M14.668 19.8574L14.6695 19.859L14.6709 19.8574H14.668Z"/>
              <path d="M9.01367 19.8574L9.01516 19.859L9.01664 19.8574H9.01367Z"/>
              <path d="M9.0127 19.8574L9.01418 19.859L9.01566 19.8574H9.0127Z"/>
              <path d="M3.35742 19.8574L3.35993 19.8587L3.36118 19.8574H3.35742Z"/>
              <path d="M3.35547 19.8574L3.35695 19.859L3.35844 19.8574H3.35547Z"/>
              <path d="M8.34196 26.9668L5.41049 24.0996L1.25488 33.0599L8.34196 26.9668Z"/>
              <path d="M8.60781 27.8945L4.05551 32.2962L3.69043 41.7186L8.60781 27.8945Z"/>
              <path d="M9.69434 26.9668L12.625 24.0996L16.7814 33.0599L9.69434 26.9668Z"/>
              <path d="M9.42871 27.8945L13.9802 32.2962L14.3461 41.7186L9.42871 27.8945Z"/>
              <path d="M11.1526 37.4337L8.99293 29.6621L6.88477 37.4126L8.99293 45.5007L11.1526 37.4337Z"/>
            </g>
            <defs>
              <clipPath id="clip0_314_166252">
                <rect width="17" height="45" fill="white" transform="translate(0.5 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div class="flex flex-col my-auto">
        <div class="text-white font-medium text-xl">Verified</div>
        <div class="text-brand-light-gray">Identity verified by Pineapple and members of our community.
        </div>
      </div>
    </div>

  </div>
</template>
<script setup lang="ts">


import {computed, inject, onMounted, PropType, Ref} from "vue";
import {StatusInterface} from "@/interfaces/StatusInterface";
import {useIonRouter} from "@ionic/vue";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";

const props = defineProps({
  user: {
    type: Object as PropType<UserBasicInformationInterface>,
    required: false
  },
  status: {
    type: String,
    required: false
  },
  isAuthUser: {
    type: Boolean,
    default: false
  }
})

const userStatus: Ref<StatusInterface | undefined> = inject('status') as Ref<StatusInterface | undefined>

const verifiedStatus = computed(() => {
  if (props.status) return props.status
  if (userStatus.value && userStatus.value?.verified_at) return 'verified'
  return 'unverified'
})

const subscribed = computed(()=>{
  if (props.user?.subscribed) return true
  return false
})

const ionRouter = useIonRouter()
const getVerified = async () => {
  if (!props.isAuthUser) return
  ionRouter.navigate({
    name: 'ProfileSettingsVerify',
  }, 'forward', 'push')
}

</script>
