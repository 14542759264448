<template>
  <div class="flex flex-col items-center p-5 py-10 pt-7 space-y-10">


    <div class="flex flex-col space-y-6 items-center">
      <div class="flex text-brand-lime">
        <svg class="w-16 h-16 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
          <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
              d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"/>
        </svg>
      </div>

      <div class="flex flex-col w-full items-center space-y-1 px-2">
        <div class="flex text-white text-2xl font-medium text-center">Update Available</div>
        <div class="text-brand-light-gray text-lg text-center leading-tight">We want you to have the best possible experience so you'll need to close and restart the app to get the latest version.</div>
      </div>

      <div v-if="whatsNew.length" class="flex flex-col justify-center items-center px-2 pt-5">
        <div class="text-brand-light-gray text-xs font-medium mb-2">What's new in this release</div>
        <ul class="text-sm text-white space-y-3 text-center">
          <li
              v-for="(change, changeKey) in whatsNew"
              :key="changeKey"
              class="leading-tight">{{change}}</li>

        </ul>
      </div>

    </div>

  </div>
</template>

<script setup lang="ts">

import {onMounted, Ref, ref} from 'vue';
import InAppSubscription from "@/components/InAppSubscription.vue";
import {modalController} from "@ionic/vue";
import {useAppUpdate} from "@/composables/useAppUpdate";
import ApiService from "@/services/api/apiService";

const loading = ref(true)

const props = defineProps({
  appVersion: {
    type: String,
    default: ''
  }
})

const whatsNew: Ref<string[]> = ref([])


onMounted(async () => {
  whatsNew.value = await ApiService.ChangeLog().get(props.appVersion)
  loading.value = false
})
</script>