<template>
  <div class="flex flex-col items-center p-5 py-10 space-y-10">


    <div class="flex flex-col space-y-6 items-center">
      <div class="flex text-brand-light-gray">
        <div class="w-10 h-10 flex bg-brand-light-gray rounded-full">
          <svg class="m-auto h-5 fill-current text-brand-black my-auto" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 448 512">
            <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
          </svg>
        </div>
      </div>

      <div class="flex flex-col w-full items-center space-y-1 px-2">
        <div class="flex text-white text-2xl font-medium text-center">Verification Required</div>
        <div class="flex flex-col space-y-3">

          <template v-if="authUser && authUser.account_type !== AccountType.Couple">
            <div class="text-brand-light-gray text- text-center">To ensure the safety of all members of the Pineapple
              community you must verify that you are who you say you are, once submitted you will have view only access
              until your profile is verified by the Pineapple team.
            </div>
            <div class="text-white text-center">Please upload a video 'selfie' with you saying the phrase "Pineapple Lifestyle"</div>
          </template>

          <template v-if="authUser && authUser.account_type === AccountType.Couple">
            <div class="text-brand-light-gray text- text-center">To ensure the safety of all members of the Pineapple
              community <span class="text-white">you must verify that you are a couple</span>, once submitted you will have view only access
              until your profile is verified by the Pineapple team.
            </div>
            <div class="text-white text-center">Please upload a video 'selfie' with one of you saying the phrase "Pineapple Lifestyle" <span class="font-semibold">you both MUST be present in the video that is submitted or your verification will be rejected.</span></div>
          </template>

        </div>
      </div>


      <div class="flex space-x-3">
        <button
            @click="addVideo"
            :class="{
              'bg-brand-lime text-brand-black' : !videoSrc,
              'bg-brand-dark-gray text-white' : videoSrc
            }"
            class="flex rounded-full  px-6 h-12 font-medium text-lg ">
          <div class="flex m-auto space-x-2.5">
            <div v-if="!videoBusy" class="my-auto pt-0.5">
              {{ videoSrc ? 'Change' : 'Upload' }}
            </div>
            <svg v-if="videoBusy"
                 :class="{
                    'text-white': videoSrc,
                    'text-brand-black': !videoSrc,
                 }"
                 class="animate-spin m-auto h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </button>

        <button
            v-if="videoSrc"
            @click="submitVideo"
            class="flex rounded-full  px-6 h-12 font-medium text-lg bg-brand-lime text-brand-black">
          <div class="flex m-auto space-x-2.5">
            <div v-if="!posting" class="my-auto pt-0.5">
              Submit
            </div>
            <svg v-if="posting"
                 class="animate-spin text-brand-black m-auto h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </button>
      </div>
    </div>

    <div class="text-brand-light-gray text-center text-sm px-10">Verification videos will be removed from our servers upon approval or rejection.</div>


  </div>
</template>

<script setup lang="ts">

import {onMounted, ref} from 'vue';
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {CameraSource, MediaPicker} from "axiallon-capacitor-media-picker";
import {VideoEditor} from "@whiteguru/capacitor-plugin-video-editor";
import {Filesystem} from "@capacitor/filesystem";
import {Capacitor} from "@capacitor/core";
import {Ref} from "vue/dist/vue";
import ApiService from "@/services/api/apiService";
import {modalController, toastController} from "@ionic/vue";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import {AccountType} from "@/definitions/AccountType";

const loading = ref(true)
const posting = ref(false)
const videoSrc = ref()
const uploadProgress = ref(0)
const postVideo: any = ref()
const videoBusy = ref(false)
const postPhotos: Ref<string[]> = ref([])

const authUser: Ref<UserBasicInformationInterface|undefined> = ref()

const resizeVideoSrc = async () => {

  // Transcode with progress
  const progressListener = await VideoEditor.addListener(
      'transcodeProgress',
      (info) => uploadProgress.value = info.progress * 100
  );

  const mediaFileResult = await VideoEditor.edit({
    path: videoSrc.value,
    transcode: {
      keepAspectRatio: true,
    },
  })

  await progressListener.remove();
  uploadProgress.value = 0

  const file = await fetch(Capacitor.convertFileSrc(mediaFileResult.file.path))
  postVideo.value = await file.blob()

}


const addVideo = async () => {
  if (videoBusy.value) return

  try {

    const video = await MediaPicker.getPhoto({
      mediaType: 'video',
      source: CameraSource.Photos,
    })

    if (video.media.length) {
      videoSrc.value = video.media[0].path

      if (!postPhotos.value.length) {
        const thumbnail = await VideoEditor.thumbnail({
          path: videoSrc.value,
          at: 1000 * 3
        })

        const thumbnailFile = await Filesystem.readFile({
          path: thumbnail.file.path
        })
        postPhotos.value = [`data:${thumbnail.file.type};base64,${thumbnailFile.data}`]
      }

      videoBusy.value = false

      // const file = await fetch(video.media[0].webPath)
      // postVideo.value = await file.blob()
    }

  } catch (e) {
    console.error('error', e);
  } finally {
    videoBusy.value = false
  }

}

const submitVideo = async () => {

  try {
    posting.value = true

    await resizeVideoSrc()

    await ApiService.User().attachVerificationVideo(
        postVideo.value,
        (progress: any) => {
          uploadProgress.value = parseInt(`${progress}`)
        }
    )

    Observable.emit(ObservableEvent.UserChanged)

    const toast = await toastController.create({
      message: 'Thank you for submitting your verification video. Our team aim to review all verification submissions within 48 hours of submission.',
      duration: 3000,
      position: 'top',
      color: 'primary'
    });
    await toast.present();

    await modalController.dismiss()

  } catch (e: any) {

    posting.value = false

    if (!e.error) {
      const toast = await toastController.create({
        message: 'Unable to process and upload video. Please try again or contact @ADMIN if the issue persists.',
        duration: 3000,
        position: 'bottom',
        color: 'danger'
      });
      await toast.present();
    }

    const toast = await toastController.create({
      message: e.data.message,
      duration: 3000,
      position: 'bottom',
      color: 'danger'
    });
    await toast.present();
  }

}


onMounted(async () => {
  authUser.value = await ApiService.User().authUser()
  console.log(authUser.value)
  loading.value = false
})
</script>