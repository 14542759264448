<template>
  <div class="flex flex-col">

    <div v-if="!loading && !feed.length" class="flex flex-col p-4">
      <div class="flex flex-col bg-brand-dark-gray p-5 rounded-lg space-y-4">
        <div class="flex flex-col my-auto px-3">
          <div v-if="!isAuthUser" class="text-brand-light-gray font-medium text-lg text-center">They haven't added any posts yet.</div>
          <div v-if="isAuthUser" class="text-white font-medium text-lg text-center">Any posts you create will show here.</div>
        </div>
        <button
            v-if="isAuthUser"
            @click="createPost"
            class="w-full flex bg-black rounded-full px-8 h-14 font-medium text-xl text-white transition-all">
          <div class="flex m-auto space-x-2.5">
            <div class="my-auto pt-0.5">
              <div>Create your first post</div>
            </div>
          </div>
        </button>
      </div>
    </div>

    <div v-if="loading" class="py-16 m-auto">
      <svg class="animate-spin h-5 w-5 text-brand-light-gray" xmlns="http://www.w3.org/2000/svg" fill="none"
           viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>

    </div>

    <div v-if="!loading && feed.length" class="flex flex-col">

      <div class="flex flex-col">
        <feed-post
            v-for="post in feed"
            :can-view-user-profile="false"
            :auth-user="authUser"
            :key="post.id"
            :post="post"/>
      </div>

      <ion-infinite-scroll v-if="showInfiniteScroll" @ionInfinite="loadMore">
        <ion-infinite-scroll-content class="text-white" loading-spinner="crescent"></ion-infinite-scroll-content>
      </ion-infinite-scroll>

    </div>

  </div>
</template>
<script lang="ts">


import {defineComponent, onMounted, onUnmounted, Prop, PropType, Ref, ref,} from "vue";
import {UserInterface} from "@/interfaces/UserInterface";
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import {useFeed} from "@/composables/useFeed";
import FeedPost from "@/components/FeedPost.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import ApiService from "@/services/api/apiService";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";

export default defineComponent({
  name: 'UserProfilePosts',
  components: {
    IonInfiniteScrollContent,
    IonInfiniteScroll,
    FeedPost
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
    isAuthUser: {
      type: Boolean,
      default: false
    },
    authUser: {
      type: Object as PropType<UserInterface>,
      required: false
    }
  },
  setup(props) {

    const loading = ref(true)
    const observer = ref()

    onMounted(async () => {

      await getFeed(1)

      observer.value = Observable.subscribe(async (response: any) => {
        if (response.event === ObservableEvent.FeedPostChanged) {
            const index = feed.value.findIndex(post => {
                return post.id === response.data.id
            })
            Object.keys(response.data).forEach((key:any) => {
                feed.value[index][key] = response.data[key]
            })
        }
      })

      loading.value = false

    })

    onUnmounted(()=>{
      observer.value?.unsubscribe()
    })

    const feed: Ref<(FeedPostInterface|any)[]> = ref([])
    const pagination = ref()

    const showInfiniteScroll = ref(true)

    const getFeed = async (page = 1) => {
      const response = (await ApiService.User().posts(props.userId, page))
      pagination.value = response.meta

      if (pagination.value.current_page >= pagination.value.last_page) showInfiniteScroll.value = false

      if (page === 1) {
        feed.value = response.data
        showInfiniteScroll.value = true
      }

      if (page > 1) {
        feed.value = [...feed.value, ...response.data]
      }

    }

    const createPost = async () => {
      const user = await ApiService.User().get()

      if (!user.id_verified) return

      const feed = useFeed()
      await feed.post(user)
    }

    const loadMore = async (ev: any) => {
      await getFeed(pagination.value.current_page + 1)
      ev.target.complete()
    }

    return {createPost, loadMore, showInfiniteScroll, feed, loading}

  }

})

</script>
