<template>
  <ion-page>
    <ion-content>

      <ion-toolbar></ion-toolbar>

      <div class="flex flex-col w-full h-full bg-brand-black my-auto px-4 z-50">
        <div class="flex flex-col space-y-8 my-auto py-10">

          <div class="flex flex-col w-full space-y-1 px-2">
            <div class="flex text-brand-lime text-2xl font-medium">Pineapple Rules</div>
            <div class="text-brand-light-gray text-base">The safety and comfort of our community is at the core of the
              Pineapple experience. We have a zero-tolerance policy towards violation of the following principles.
            </div>
          </div>

          <div class="flex flex-col w-full space-y-1 px-2">
            <div class="flex text-white text-xl font-medium">No discrimination</div>
            <div class="text-brand-light-gray text-base">Pineapple is about inclusivity, acceptance and respect.
              Discriminatory and aggressive behaviour will result in removal from our platform and a ban from attending
              our events.
            </div>
          </div>

          <div class="flex flex-col w-full space-y-1 px-2">
            <div class="flex text-white text-xl font-medium">Consent is key</div>
            <div class="text-brand-light-gray text-base">Consent is a fundamental principle of Pineapple. Take the time
              to talk about what you and your partner(s) expect from each other and always respect boundaries. No one
              owes you anything, so be prepared to hear, as well as say no.
            </div>
          </div>

          <div class="flex flex-col w-full space-y-1 px-2">
            <div class="flex text-white text-xl font-medium">Be open to others</div>
            <div class="text-brand-light-gray text-base">We are all different. If someone is into something you don’t
              like or share, that doesn’t make it any less worthy of respect. Finding like-minded humans can take time
              and patience, so be open.
            </div>
          </div>

          <div class="flex flex-col w-full space-y-1 px-2">
            <div class="flex text-white text-xl font-medium">Only real humans accepted</div>
            <div class="text-brand-light-gray text-base">Fake profiles, catfishing and other forms of falsified personas
              and identities will result in a permanent ban from the platform. Misuse of information will not be
              tolerated either, so only use photos and information you have permission to use.
            </div>
          </div>


          <div
              @click="forward"
              class="flex flex-col space-y-2 w-full items-center">
            <button
                class="flex bg-brand-lime rounded-full w-full h-14 font-medium text-xl text-brand-black">
              <div class="flex m-auto space-x-2.5">
                <div class="my-auto pt-0.5">
                  I agree
                </div>
              </div>
            </button>
          </div>

        </div>
      </div>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage, IonTitle, IonToolbar, useIonRouter,
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {DateTime} from "luxon";

export default defineComponent({
  name: 'PineappleRulesPage',
  components: {
    IonContent,
    IonPage,
    IonToolbar
  },
  setup() {

    const busy = ref(false)
    const ionRouter = useIonRouter()

    const forward = async () => {
      await ionRouter.navigate('/pineapple/badges', 'forward', 'push')
    }

    return {busy, forward}

  }
});
</script>
