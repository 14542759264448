<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="mr-4">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title class="text-white font-medium text-2xl">Privacy</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="flex h-full w-full">

      <div v-if="loading" class="w-full h-full flex">
        <div class="m-auto">
          <svg class="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>

      <div v-if="!loading" class="flex flex-col py-6 px-4 justify-center space-y-10">

        <div class="flex flex-col space-y-2">

          <input-checkbox
              name="display"
              input-type="toggle"
              v-model="preferences.publicProfileEnabled"
              :option="{
              text: 'Public Web Profile',
              value: true
            }"
          />

          <input-checkbox
              name="display"
              input-type="toggle"
              v-model="preferences.allowFriendRequests"
              :option="{
              text: 'Allow friend requests',
              value: true
            }"
          />

          <input-checkbox
              name="display"
              input-type="toggle"
              v-model="preferences.onlyFriendsCanDirectMessage"
              :option="{
              text: 'Only friends can direct message',
              value: true
            }"
          />

          <input-checkbox
              name="display"
              input-type="toggle"
              v-model="preferences.onlyFriendsCanAddYouToGroupChats"
              :option="{
              text: 'Only friends can add you to group chats',
              value: true
            }"
          />

          <input-checkbox
              name="display"
              input-type="toggle"
              v-model="preferences.profileViewReceipts"
              :option="{
              text: 'Profile view receipts',
              value: true
            }"
          />

          <input-checkbox
              name="display"
              input-type="toggle"
              v-model="preferences.profileLastOnline"
              :option="{
              text: 'Last online status',
              value: true
            }"
          />

        </div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar, onIonViewDidEnter,
  useIonRouter,
} from '@ionic/vue';
import {inject, onMounted, reactive, Ref, ref, watch, watchEffect} from 'vue';
import ApiService from "@/services/api/apiService";
import {DateTime} from "luxon";
import MenuButton from "@/components/MenuButton.vue";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import InputCheckbox from "@/components/InputCheckbox.vue";

const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>

const loading = ref(true)
const ionRouter = useIonRouter()
const busy = ref(false)

const preferences: any = reactive({
  publicProfileEnabled: [true],
  profileLastOnline: [true],
  profileViewReceipts: [true],
  allowFriendRequests: [true],
  onlyFriendsCanDirectMessage: [true],
  onlyFriendsCanAddYouToGroupChats: [true],
})

onIonViewDidEnter(async () => {

  const userPreferences = (await ApiService.User().get()).preferences
  preferences.publicProfileEnabled = [Boolean(Number(userPreferences[PreferenceKey.PublicProfileEnabled]))]
  preferences.profileViewReceipts = [Boolean(Number(userPreferences[PreferenceKey.ProfileViewReceipts]))]
  preferences.profileLastOnline = [Boolean(Number(userPreferences[PreferenceKey.ProfileShowLastOnline]))]
  preferences.allowFriendRequests = [Boolean(Number(userPreferences[PreferenceKey.AllowFriendRequests]))]
  preferences.onlyFriendsCanDirectMessage = [Boolean(Number(userPreferences[PreferenceKey.OnlyFriendsCanDirectMessage]))]
  preferences.onlyFriendsCanAddYouToGroupChats = [Boolean(Number(userPreferences[PreferenceKey.OnlyFriendsCanAddYouToGroupChats]))]

})


const goBack = async () => {
  await ionRouter.back()
}

watch(() => ({...preferences}), async (preference, previousPreference) => {

  if (loading.value) return loading.value = false

  if (previousPreference.publicProfileEnabled[0] !== preference.publicProfileEnabled[0]) {
    await ApiService.User().setPreference(PreferenceKey.PublicProfileEnabled, preference.publicProfileEnabled[0])
    Observable.emit(ObservableEvent.UserChanged)
  }

  if (previousPreference.allowFriendRequests[0] !== preference.allowFriendRequests[0]) {
    await ApiService.User().setPreference(PreferenceKey.AllowFriendRequests, preference.allowFriendRequests[0])
    Observable.emit(ObservableEvent.UserChanged)
  }

  if (previousPreference.onlyFriendsCanDirectMessage[0] !== preference.onlyFriendsCanDirectMessage[0]) {
    await ApiService.User().setPreference(PreferenceKey.OnlyFriendsCanDirectMessage, preference.onlyFriendsCanDirectMessage[0])
    Observable.emit(ObservableEvent.UserChanged)
  }

  if (previousPreference.onlyFriendsCanAddYouToGroupChats[0] !== preference.onlyFriendsCanAddYouToGroupChats[0]) {
    await ApiService.User().setPreference(PreferenceKey.OnlyFriendsCanAddYouToGroupChats, preference.onlyFriendsCanAddYouToGroupChats[0])
    Observable.emit(ObservableEvent.UserChanged)
  }

  if (previousPreference.profileViewReceipts[0] !== preference.profileViewReceipts[0]) {
    await ApiService.User().setPreference(PreferenceKey.ProfileViewReceipts, preference.profileViewReceipts[0])
    Observable.emit(ObservableEvent.UserChanged)
  }

  if (previousPreference.profileLastOnline[0] !== preference.profileLastOnline[0]) {
    await ApiService.User().setPreference(PreferenceKey.ProfileShowLastOnline, preference.profileLastOnline[0])
    Observable.emit(ObservableEvent.UserChanged)
  }

}, {deep: true})


</script>
