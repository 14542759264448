<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <div class="flex justify-between">

                    <ion-buttons class="flex" slot="start">
                        <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
                            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                        </ion-button>
                        <div class="flex w-full ml-4">
                            <div class="text-white font-medium text-2xl border-b-2 border-t border-transparent">Archived</div>
                        </div>
                    </ion-buttons>
                </div>

            </ion-toolbar>
        </ion-header>
        <ion-content class="h-full">

            <ion-refresher v-if="isSubscribed" slot="fixed" @ionRefresh="pullToRefresh($event)">
                <ion-refresher-content pulling-icon="crescent" refreshing-spinner="crescent"></ion-refresher-content>
            </ion-refresher>

            <div class="flex" :class="{
                'h-full' : !chats.length || loading
            }">

                <svg v-if="loading" class="animate-spin h-10 w-10 m-auto text-white" xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>

                <div v-if="!isSubscribed  && !loading" class="flex px-4 py-5 m-auto text-white">
                    <div class="flex flex-col items-center space-y-6">
                        <svg width="75" height="74" viewBox="0 0 75 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M37.5006 0C17.2891 0 0.845703 16.4434 0.845703 36.6549C0.845703 56.8664 17.2891 73.3098 37.5006 73.3098C57.7121 73.3098 74.1555 56.8664 74.1555 36.6549C74.1555 16.4434 57.7121 0 37.5006 0Z"
                                    fill="#EBFF00"/>
                            <path
                                    d="M57.344 45.3167C58.4435 46.0038 58.7864 47.4604 57.9969 48.4889C55.9488 51.1572 53.3663 53.3809 50.4055 55.0124C46.7574 57.0225 42.6561 58.0668 38.4909 58.0461C34.3257 58.0254 30.235 56.9404 26.6071 54.8941C23.6626 53.2332 21.1024 50.984 19.0809 48.2954C18.3017 47.2591 18.659 45.806 19.7653 45.1298C20.8716 44.4537 22.3061 44.8129 23.1088 45.8312C24.6966 47.8454 26.6667 49.5371 28.9137 50.8045C31.8446 52.4577 35.1494 53.3342 38.5142 53.3509C41.8791 53.3677 45.1924 52.524 48.1396 50.9001C50.3991 49.6551 52.3859 47.9831 53.9937 45.9847C54.8064 44.9745 56.2445 44.6295 57.344 45.3167Z"
                                    fill="#1A1B22"/>
                            <path
                                    d="M24.5105 40.1582C25.6758 40.2425 26.5724 41.2661 26.2606 42.392C26.0533 43.1407 25.7645 43.8666 25.3979 44.5563C24.6993 45.8709 23.7338 47.0251 22.5633 47.9449C21.3928 48.8648 20.043 49.53 18.6006 49.8981C17.8437 50.0912 17.0701 50.2003 16.2937 50.2247C15.1259 50.2615 14.3434 49.1483 14.5369 47.9961C14.7304 46.8439 15.8401 46.1029 16.9933 45.9156C17.1817 45.8849 17.3689 45.846 17.5545 45.7986C18.4262 45.5762 19.2417 45.1742 19.949 44.6184C20.6563 44.0625 21.2397 43.3651 21.6619 42.5708C21.7518 42.4016 21.834 42.2289 21.9082 42.0531C22.3629 40.9768 23.3453 40.0738 24.5105 40.1582Z"
                                    fill="#1A1B22"/>
                            <path
                                    d="M32.9536 30.0211C33.9533 29.3009 34.1629 27.8739 33.2204 27.0804C32.4583 26.4388 31.6057 25.8977 30.6849 25.4747C29.0674 24.7316 27.287 24.3744 25.4949 24.4336C23.7028 24.4927 21.9521 24.9664 20.3917 25.8144C19.5056 26.2959 18.6958 26.89 17.9827 27.5781C17.0949 28.4348 17.4025 29.8458 18.45 30.4975C19.4438 31.1157 20.7341 30.7992 21.6606 30.0839C21.9433 29.8657 22.2449 29.669 22.5627 29.4963C23.5056 28.9839 24.5635 28.6977 25.6463 28.6619C26.7292 28.6262 27.8051 28.842 28.7824 29.291C29.1166 29.4445 29.4358 29.6237 29.7373 29.8263C30.7047 30.4766 32.0077 30.7024 32.9536 30.0211Z"
                                    fill="#1A1B22"/>
                            <path
                                    d="M51.5546 32.8109C54.0696 32.8109 56.1406 30.849 56.1406 28.39C56.1406 25.9309 54.0696 23.9691 51.5546 23.9691C49.0396 23.9691 46.9686 25.9309 46.9686 28.39C46.9686 30.849 49.0396 32.8109 51.5546 32.8109Z"
                                    fill="#1A1B22" stroke="#1A1B22" stroke-width="0.916372"/>
                        </svg>
                        <div class="flex flex-col w-full items-center space-y-4 px-2">
                            <div class="flex text-white text-2xl font-medium text-center">Hey sweet stuff, this is a
                                premium
                                feature.
                            </div>
                            <div class="text-brand-light-gray text-lg text-center">Subscribe today to flirt with any and
                                all profiles
                                who you feel a connection with
                            </div>
                        </div>

                        <in-app-subscription class="w-full" label="Upgrade to Pineapple Premium"/>

                    </div>
                </div>

                <div v-if="isSubscribed && !loading && !chats.length" class="flex px-4 py-5 m-auto text-white">
                    <div class="flex flex-col items-center space-y-6">
                        <div class="flex m-auto text-brand-lime">
                            <svg class="w-16 h-16 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path d="M32 32H480c17.7 0 32 14.3 32 32V96c0 17.7-14.3 32-32 32H32C14.3 128 0 113.7 0 96V64C0 46.3 14.3 32 32 32zm0 128H480V416c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V160zm128 80c0 8.8 7.2 16 16 16H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16z"/>
                            </svg>
                        </div>
                        <div class="flex flex-col w-full items-center space-y-4 px-2">
                            <div class="flex text-white text-2xl font-medium text-center">Your archive is empty!
                            </div>
                        </div>


                    </div>
                </div>

                <ion-list lines="none" v-if="isSubscribed && !loading && chats.length"
                          class="py-4 flex flex-col w-full text-white space-y-4 bg-brand-black">


                    <ion-item-sliding class=""
                                      v-for="(chat,chatIndex) in chats"
                                      :key="chat.id">
                        <ion-item>
                            <chat-list-item
                                :chat="chat" />
                        </ion-item>

                        <ion-item-options>
                            <ion-item-option
                                class="bg-red-500 text-white font-medium"
                                @click="deleteChat(chat.id, chatIndex)">
                                <div class="px-4 flex flex-col space-y-1">
                                    <div class="flex m-auto">
                                        <svg class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                                    </div>
                                    <div>Delete</div>
                                </div>
                            </ion-item-option>
                            <ion-item-option
                                    class="bg-brand-lime text-brand-black font-medium"
                                    @click="unarchiveChat(chat.id, chatIndex)">
                                <div class="px-4 flex flex-col space-y-1">
                                    <div class="flex m-auto">
                                        <svg class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M288 109.3V288c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-41.4 41.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l96-96c12.5-12.5 32.8-12.5 45.3 0l96 96c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM48 320h76.2c12.1 0 23.2 6.8 28.6 17.7l14.3 28.6c5.4 10.8 16.5 17.7 28.6 17.7H316.2c12.1 0 23.2-6.8 28.6-17.7l14.3-28.6c5.4-10.8 16.5-17.7 28.6-17.7H464c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V368c0-26.5 21.5-48 48-48z"/></svg>
                                    </div>
                                    <div>Unarchive</div>
                                </div>
                            </ion-item-option>
                        </ion-item-options>


                    </ion-item-sliding>


                </ion-list>


            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonList,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonToolbar,
    useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, inject, onMounted, onUnmounted, Ref, ref} from 'vue';
import InAppSubscription from "@/components/InAppSubscription.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import ApiService from "@/services/api/apiService";
import {UserInterface} from "@/interfaces/UserInterface";
import {ChatInterface} from "@/interfaces/ChatInterface";
import {DateTime} from "luxon";
import ChatListItem from "@/components/ChatListItem.vue";

export default defineComponent({
    name: 'FlirtPage',
    components: {
        ChatListItem,
        IonButtons, IonButton,
        InAppSubscription,
        IonPage,
        IonToolbar,
        IonContent,
        IonHeader,
        IonRefresher,
        IonRefresherContent,
        IonItem,
        IonList,
        IonItemSliding,
        IonItemOption,
        IonItemOptions
    },
    setup() {

        const loading = ref(true)
        const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>
        const chats: Ref<ChatInterface[]> = ref([])
        const ionRouter = useIonRouter()

        onMounted(async () => {
            await getChats()
            loading.value = false
        })

        const getChats = async () => {
            chats.value = await ApiService.Chat().getArchived()
        }

        const pullToRefresh = async (el: any) => {
            await getChats()
            setTimeout(() => {
                el.target.complete()
            }, 500)
        }

        const isSubscribed = computed(() => {
            return user.value?.subscribed
        })

        const messageTime = (time: string) => {
            const dateTime = DateTime.fromISO(time)

            // is today
            if (DateTime.now().toISODate() === dateTime.toISODate()) return dateTime.toFormat('HH:mm')

            // is yesterday
            if (DateTime.now().minus({day: 1}).toISODate() === dateTime.toISODate()) return 'Yesterday'
            return dateTime.toFormat('EEE')

        }


        const deleteChat = async (chatId: number, chatIndex: number) => {
            chats.value.splice(chatIndex, 1)
            await ApiService.Chat().delete(chatId)
        }
        const unarchiveChat = async (chatId: number, chatIndex: number) => {
            chats.value.splice(chatIndex, 1)
            await ApiService.Chat().unarchive(chatId)
            await Observable.emit(ObservableEvent.ChatsChanged)
        }

        const goBack = () => {
            ionRouter.back()
        }

        return {isSubscribed, goBack, deleteChat, unarchiveChat, pullToRefresh, loading, chats, messageTime}

    }
});
</script>
<style scoped>
ion-item {
    --background: #1A1B22;
}
</style>