<template>
  <div class="font-sans" v-if="!loading">
      <div class="flex flex-col p-4 pt-6 divide-y divide-brand-dark-gray">

        <div @click="endPartnership" class="flex text-white p-4 py-5">
          <div class="flex justify-between">
            <div class="flex space-x-2">
              <svg class="w-6 h-6 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M119.4 44.1c23.3-3.9 46.8-1.9 68.6 5.3l49.8 77.5-75.4 75.4c-1.5 1.5-2.4 3.6-2.3 5.8s1 4.2 2.6 5.7l112 104c2.9 2.7 7.4 2.9 10.5 .3s3.8-7 1.7-10.4l-60.4-98.1 90.7-75.6c2.6-2.1 3.5-5.7 2.4-8.8L296.8 61.8c28.5-16.7 62.4-23.2 95.7-17.6C461.5 55.6 512 115.2 512 185.1v5.8c0 41.5-17.2 81.2-47.6 109.5L283.7 469.1c-7.5 7-17.4 10.9-27.7 10.9s-20.2-3.9-27.7-10.9L47.6 300.4C17.2 272.1 0 232.4 0 190.9v-5.8c0-69.9 50.5-129.5 119.4-141z"/></svg>
              <div v-if="!endBusy" class="text-lg">End partnership with {{partner.name}}</div>
              <svg v-if="endBusy" class="animate-spin m-auto h-5 w-5 text-white"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>

          </div>
        </div>
      </div>
    <div class="safe-area-bottom"></div>
  </div>
</template>

<script setup lang="ts">

import {onMounted, PropType, ref} from 'vue';
import {alertController, modalController, toastController} from "@ionic/vue";
import ApiService from "@/services/api/apiService";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

const loading = ref(true)
const busy = ref(false)
const endBusy = ref(false)

const props = defineProps({
  partner: {
    type: Object as PropType<UserBasicInformationInterface>,
    required: true
  }
})

onMounted(async () => {
  loading.value = false

  console.log(props.partner)

})

const endPartnership = async () => {

  const alert = await alertController.create({
    header: 'End Partnership',
    subHeader: `Are you sure you want to end the partnership with ${props.partner.name}?`,
    buttons: [
      {
        text: 'Yes',
        role: 'destructive',
        handler: async () => {

          if (busy.value) return
          busy.value = true

          await alert.dismiss()
          await modalController.dismiss()
          const toast = await toastController.create({
            header: 'Partnership Ended',
            message: `You are no longer linked with ${props.partner.name} as a couple.`,
            duration: 3000,
            position: 'bottom',
            color: 'primary'
          });
          await toast.present();
          await ApiService.User().removeFromPartners(props.partner.id)
          await Observable.emit(ObservableEvent.PartnersChanged)

        }
      },
      {
        text: 'Cancel',
      }
    ]
  })

  await alert.present()
  await alert.onDidDismiss()
  busy.value = false

}

</script>