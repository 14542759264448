<template>
    <ion-page>

        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start" class="mr-4">
                    <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
                        <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                    </ion-button>
                </ion-buttons>
                <ion-title class="text-white font-medium text-2xl">Notifications</ion-title>
                <ion-buttons v-if="unseenNotifications.length > 0" slot="end" class="mr-4">
                  <div  class="cursor-pointer" @click="clearNotifications">
                    <svg class="w-6 h-6 fill-current text-brand-light-gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
                  </div>
                </ion-buttons>

              <ion-buttons v-if="unseenNotifications.length === 0 && notifications.length > 0" slot="end" class="mr-4">
                <div  class="cursor-pointer" @click="deleteNotifications">
                  <svg class="w-6 h-6 fill-current text-brand-light-gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                </div>
              </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content class="flex flex-col text-white h-full">

            <ion-refresher slot="fixed" class="text-white z-50 relative"
                           @ionRefresh="pullToRefresh($event)">
                <ion-refresher-content pulling-icon="crescent" refreshing-spinner="crescent"></ion-refresher-content>
            </ion-refresher>

          <div v-if="loading" class="flex w-full h-full">
            <svg class="animate-spin h-10 w-10 m-auto text-white" xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>

            <div v-if="!loading" class="flex flex-col h-full">
                <div v-if="!notifications.length && !loading" class="flex my-auto">
                    <div class="flex flex-col m-auto items-center">
                        <div class="flex flex-col space-y-4 items-center">
                            <svg width="111" height="110" viewBox="0 0 111 110" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="2.5" y="2" width="106" height="106" rx="53" fill="#EBFF00"/>
                                <path
                                        d="M56 72C57.925 72 59.5 70.4308 59.5 68.5128H52.5C52.5 70.4308 54.0575 72 56 72ZM66.5 61.5385V52.8205C66.5 47.4677 63.63 42.9867 58.625 41.801V40.6154C58.625 39.1682 57.4525 38 56 38C54.5475 38 53.375 39.1682 53.375 40.6154V41.801C48.3525 42.9867 45.5 47.4503 45.5 52.8205V61.5385L42 65.0256V66.7692H70V65.0256L66.5 61.5385Z"
                                        fill="#1A1B22"/>
                                <rect x="2.5" y="2" width="106" height="106" rx="53" stroke="#EBFF00" stroke-width="4"/>
                            </svg>

                            <div class="flex flex-col w-full items-center space-y-2">
                                <div class="flex m-auto text-white text-2xl font-medium">You're all caught up!</div>
                                <div class="m-auto px-5 text-brand-light-gray text-lg text-center">New notifications will appear here as users interact with you and your content.</div>
                            </div>
                        </div>
                    </div>
                </div>

                <ion-list v-if="notifications.length && !loading" class="flex flex-col ">
                    <ion-item-sliding
                            v-for="(notification, index) in notifications"
                            :key="index">
                        <ion-item>

                            <div
                                :class="{
                                  'bg-white/5' : !notification.seen_at
                                }"
                                class="flex space-x-3 py-4 px-4 w-full">
                                <div class="flex">
                                    <div @click="viewUserProfile(notification)" v-if="notification.notified_by"
                                         class="flex w-16 h-16 rounded-full overflow-hidden">
                                        <div
                                                :style="{
                          'background-image': `url('${notification.notified_by.avatar_thumbnail ?? notification.notified_by.avatar}')`
                        }"
                                                class="bg-brand-dark-gray bg-cover bg-center w-full h-full"></div>

                                    </div>
                                    <div v-if="!notification.notified_by"
                                         class="flex w-16 h-16 bg-brand-lime rounded-full">
                                        <div class="flex m-auto">
                                            <svg width="17" height="47" viewBox="0 0 17 47" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_368_186075)">
                                                    <path d="M0 23.4038L0.00149677 23.4053L0.00299353 23.4038L0.00149677 23.4023L0 23.4038Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M11.3105 23.4023L11.3128 23.4035L11.3139 23.4023H11.3105Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M5.65625 23.4023L5.6585 23.4035L5.65962 23.4023H5.65625Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M8.51418 3.61328L5.6875 6.4402H11.34L8.51418 3.61328Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M5.68577 12.0977L2.85742 14.9254H8.51411L5.68577 12.0977Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M16.9999 14.9254V12.0977L14.1699 14.9254H16.9999Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M16.9999 9.26999V6.44141L14.1699 9.26999H16.9999Z"
                                                          fill="#1A1B22"/>
                                                    <path
                                                            d="M8.51389 9.27036L8.51306 9.26953L5.68555 12.0973H11.3414L8.51472 9.26953L8.51389 9.27036Z"
                                                            fill="#1A1B22"/>
                                                    <path
                                                            d="M14.1713 9.27036L14.1704 9.26953L11.3438 12.0973H16.9996L14.1721 9.26953L14.1713 9.27036Z"
                                                            fill="#1A1B22"/>
                                                    <path
                                                            d="M2.85959 9.27036L2.85876 9.26953L0.03125 12.0973H5.68711L2.86043 9.26953L2.85959 9.27036Z"
                                                            fill="#1A1B22"/>
                                                    <path d="M14.1695 14.9254L11.3412 12.0977L8.51367 14.9254H14.1695Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M11.34 17.7488L8.51418 14.9219L5.6875 17.7488H11.34Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M16.9924 17.7488L14.1665 14.9219L11.3398 17.7488H16.9924Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M5.68573 17.7488L2.85988 14.9219L0.0332031 17.7488H5.68573Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M5.68703 17.75L5.6862 17.7508L5.68537 17.75L2.86035 20.5753H8.51288L5.68703 17.75Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M11.3406 17.75L8.51562 20.5753H14.1682L11.3423 17.75L11.3415 17.7508L11.3406 17.75Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M0.0292969 12.0977V14.9254H2.85764L0.0292969 12.0977Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M0.0292969 6.4375V9.26608H2.85764L0.0292969 6.4375Z"
                                                          fill="#1A1B22"/>
                                                    <path
                                                            d="M11.3406 6.44141L8.51562 9.26666H14.1682L11.3423 6.44141L11.3415 6.44224L11.3406 6.44141Z"
                                                            fill="#1A1B22"/>
                                                    <path
                                                            d="M5.68703 6.44141L5.6862 6.44224L5.68537 6.44141L2.86035 9.26666H8.51288L5.68703 6.44141Z"
                                                            fill="#1A1B22"/>
                                                    <path
                                                            d="M2.85695 3.61328L2.85612 3.61411L2.85529 3.61328L0.0302734 6.43854H5.6828L2.85695 3.61328Z"
                                                            fill="#1A1B22"/>
                                                    <path
                                                            d="M14.1674 3.61328L14.1665 3.61411L14.1649 3.61328L11.3398 6.43854H16.9924L14.1674 3.61328Z"
                                                            fill="#1A1B22"/>
                                                    <path
                                                            d="M11.3387 0.789062L8.51367 3.61432H14.1662L11.3404 0.789062L11.3395 0.789894L11.3387 0.789062Z"
                                                            fill="#1A1B22"/>
                                                    <path
                                                            d="M5.68703 0.789062L5.6862 0.789894L5.68537 0.789062L2.86035 3.61432H8.51288L5.68703 0.789062Z"
                                                            fill="#1A1B22"/>
                                                    <path
                                                            d="M8.51335 20.5781L5.6875 23.4042H11.34L8.51501 20.5781L8.51418 20.579L8.51335 20.5781Z"
                                                            fill="#1A1B22"/>
                                                    <path
                                                            d="M8.51501 27.1143L11.34 24.2891H5.6875L8.51335 27.1143L8.51418 27.1135L8.51501 27.1143Z"
                                                            fill="#1A1B22"/>
                                                    <path d="M11.3418 17.75L11.344 17.7511L11.3452 17.75H11.3418Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M0.0292969 17.7508L1.43141 17.7517L1.60855 17.7508V17.75L0.0292969 17.7508Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M2.85742 9.26562L2.85967 9.26675L2.86079 9.26562H2.85742Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M11.3418 6.44141L11.344 6.44253L11.3452 6.44141H11.3418Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M2.85742 20.5781L2.85967 20.5792L2.86079 20.5781H2.85742Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M7.84196 27.8449L4.91049 24.9141L0.754883 34.0735L7.84196 27.8449Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M8.10781 28.793L3.55551 33.2924L3.19043 42.9242L8.10781 28.793Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M9.19434 27.8449L12.125 24.9141L16.2814 34.0735L9.19434 27.8449Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M8.92871 28.793L13.4802 33.2924L13.8461 42.9242L8.92871 28.793Z"
                                                          fill="#1A1B22"/>
                                                    <path d="M10.6526 38.542L8.49293 30.5977L6.38477 38.5203L8.49293 46.7882L10.6526 38.542Z"
                                                          fill="#1A1B22"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_368_186075">
                                                        <rect width="17" height="46" fill="white"
                                                              transform="translate(0 0.789062)"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div @click="viewNotification(notification)" class="flex w-full flex-col my-auto overflow-hidden">
                                    <div
                                            class="flex w-full overflow-hidden">
                                        <div class="flex justify-between w-full overflow-hidden">
                                            <div class="flex flex-col w-full overflow-hidden">
                                                <div v-if="notification.context && notification.context.type === `App\\Models\\User`"
                                                     class="text-lg text-white font-medium truncate">
                                                    {{ notification.notified_by.name }}
                                                </div>
                                                <div v-else class="text-lg text-white font-medium line-clamp-1">{{
                                                    notification.title
                                                    }}
                                                </div>
                                                <div class="text-lg text-white break-words whitespace-pre-wrap">{{ notification.body }}</div>
                                            </div>
                                        </div>
                                        <div v-if="notification.context && notification.context.post && notification.context.post.media.length"
                                             class="flex flex-grow my-auto">
                                            <div class="flex overflow-hidden rounded-md relative">

                                                <div v-if="notification.context.post.visibility === PostPrivacy.XRated"
                                                     class="flex absolute top-0 left-0 w-full h-full z-50">
                                                    <div class="flex m-auto w-5 h-5 text-white/70">
                                                        <svg class="fill-current flex"
                                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                            <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                                            <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"/>
                                                        </svg>
                                                    </div>
                                                </div>

                                                <div
                                                        :style="{
                            'background-image': `url('${notification.context.post.media[0].url}')`
                          }"
                                                        :class="{
                                                'blur-xs' : notification.context.post.visibility === PostPrivacy.XRated
                                                    }"
                                                        class="flex bg-brand-dark-gray bg-center bg-cover w-11 h-11 rounded-md pl-3">
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <template v-if="notification.context">
                                        <div v-if="notification.context.comment"
                                             class="text-brand-light-gray mb-2 mt-3 line-clamp-2 break-all"
                                             v-html="sanitizeHtml(notification.context.comment)"></div>

                                        <div v-if="notification.context.content && !notification.context.comment"
                                             class="text-brand-light-gray mb-2 mt-3 line-clamp-2 break-all"
                                             v-html="sanitizeHtml(notification.context.content)"></div>
                                    </template>


                                    <div class="text-brand-light-gray text-xs mt-1">{{
                                        getNotificationWhen(notification)
                                        }}
                                    </div>
                                </div>
                            </div>
                        </ion-item>
                        <ion-item-options v-if="false" side="end">
                            <ion-item-option color="danger">
                                <div class="px-5 text-base font-medium">Delete</div>
                            </ion-item-option>
                        </ion-item-options>
                    </ion-item-sliding>
                </ion-list>


                <ion-infinite-scroll v-if="showInfiniteScroll" @ionInfinite="loadMore">
                    <ion-infinite-scroll-content class="text-white"
                                                 loading-spinner="crescent"></ion-infinite-scroll-content>
                </ion-infinite-scroll>
            </div>

        </ion-content>
    </ion-page>
</template>

<script lang="ts" setup>
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonPage, IonRefresher, IonRefresherContent,
  IonTitle,
  IonToolbar,
  onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {defineComponent, onMounted, onUnmounted, Ref, ref} from 'vue';
import ApiService from "@/services/api/apiService";
import {DateTime} from "luxon";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {NotificationInterface} from "@/interfaces/NotificationInterface";
import sanitize from "sanitize-html";

const loading = ref(true)
const ionRouter = useIonRouter()
const notifications: Ref<NotificationInterface[]> = ref([])
import * as sanitizeHtml from 'sanitize-html';
import {PostPrivacy} from "@/definitions/PostPrivacy";
import {useNotification} from "@/composables/useNotification";

const pagination = ref()

const unseenNotifications: Ref<NotificationInterface[]> = ref([])

onMounted(async () => {
    await getNotifications()
    loading.value = false
})

const showInfiniteScroll = ref(true)

const getNotifications = async (page = 1) => {
    const unseen = await getUnseenNotifications()
    const response = (await ApiService.User().getNotifications(page))
    pagination.value = response.meta

    if (pagination.value.current_page === pagination.value.last_page) showInfiniteScroll.value = false

    if (page === 1) {
        notifications.value = response.data
        showInfiniteScroll.value = true
    }

    if (page > 1) {
        notifications.value = [...notifications.value, ...response.data]
    }

    if (unseenNotifications.value.length) {
      await seenNotifications(response.data.map((n: any) => {
        return n.id
      }).join(','))
    }

}

const getUnseenNotifications = async () => {
  unseenNotifications.value = await ApiService.User().getUnseenNotifications()
}

const seenNotifications = async (ids: string) => {
    await ApiService.User().seenNotifications(ids)
    console.log(ids)
}

const getNotificationWhen = (notification: any) => {
    return DateTime.fromISO(notification.created_at).toRelative()
}

const goBack = async () => {
    await ionRouter.back()
}

const loadMore = async (ev: any) => {
  console.log('load more')
    await getNotifications(pagination.value.current_page + 1)
    ev.target.complete()
}

const pullToRefresh = async (ev: any) => {
    await getNotifications(1)
    ev.target.complete()
}

const clearNotifications = async () => {

  const alert = await alertController.create({
    header: 'Mark as Seen',
    message: 'Are you sure you want to mark all notifications as seen?',
    buttons: [
      {
        text: 'Yes',
        handler: async () => {
          alertController.dismiss()
          const ids = unseenNotifications.value.map((notification) => {
            return notification.id
          })
          if (ids.length) {
            await seenNotifications(ids.join(','))
            await getNotifications(1)
          }
        }
      },
      {
        text: 'Cancel',
      }
    ]
  })
  await alert.present()

}

const deleteNotifications = async () => {

  const alert = await alertController.create({
    header: 'Remove Notifications',
    message: 'Are you sure you want to completely remove all notifications? This can\'t be undone.',
    buttons: [
      {
        text: 'Yes',
        handler: async () => {
          alertController.dismiss()
          await ApiService.User().deleteAllNotifications()
          notifications.value = []
          unseenNotifications.value = []
        }
      },
      {
        text: 'Cancel',
      }
    ]
  })
  await alert.present()

}

const viewUserProfile = async (notification: NotificationInterface) => {
  ionRouter.navigate({
    name: 'PineappleNotificationsUserProfile',
    params: {
      userId: notification.notified_by.id
    }
  }, 'forward', 'push')
}

const viewNotification = async (notification: NotificationInterface) => {
    await useNotification().present(notification, ionRouter)
}

onUnmounted(()=>{
  Observable.emit(ObservableEvent.NotificationsChanged)
})

</script>

<style scoped>
ion-item {
    --inner-border-width: 0;
    --inner-box-shadow: 0;
    --background: #1A1B22;
    --background-activated: #1A1B22;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --inner-padding-end: 0;
}

ion-list {
    background: #1A1B22;
}

ion-item-options {
    background: #1A1B22 !important;
    border-bottom-width: 0px !important;
}


</style>

