import {NotificationInterface} from "@/interfaces/NotificationInterface";
import {modalController, useIonRouter, UseIonRouterResult} from "@ionic/vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import AppUpdateAvailableSheet from "@/views/common/AppUpdateAvailableSheet.vue";
import ApiService from "@/services/api/apiService";
import UserReviewSheet from "@/views/common/UserReviewSheet.vue";
import EventViewChatPage from "@/views/events/EventViewChatPage.vue";

export function useNotification() {

    const present = async (notification: NotificationInterface, ionRouter?: UseIonRouterResult) => {

        if (
            (notification.context.type === 'Spatie\\Comments\\Models\\Comment' && notification.context.post) ||
            (notification.context.type === "App\\Models\\FeedPost" && notification.context.post)
        ) {
            if (ionRouter) ionRouter.navigate({
                name: 'FeedViewPost',
                params: {
                    postId: notification.context.post.id
                }
            }, 'forward', 'push')
        }

        if ((notification.context.type === "App\\Models\\UserReview")){
            const review = await ApiService.User().getReviewById(notification.context.id as number)
            const sheet = await modalController.create({
                breakpoints: [1],
                initialBreakpoint: 1,
                component: UserReviewSheet,
                componentProps: {
                  review
                },
                mode: 'ios',
            })
            await sheet.present()
        }

        if ((notification.context.type === "App\\Models\\User")){
            if (ionRouter) ionRouter.navigate({
                name: 'PineappleUserProfile',
                params: {
                    userId: notification.notified_by.id
                }
            }, 'forward', 'push')
        }

        if ((notification.context.type === "App\\Models\\Event")){
            const modal = await modalController.create({
                component: EventViewChatPage,
                mode: 'ios',
                componentProps: {
                    eventId: notification.context.id,
                    canViewEvent: true,
                    commentId: notification.context.comment_id
                },
                presentingElement: document.getElementById('tabs') as HTMLElement,
            })
            await modal.present()

        }

    }

    return {
        present
    }

}