<template>
  <div class="flex flex-col">
    <div v-if="layout === 'list'" class="flex justify-between">


      <div class="flex space-x-4">

        <div class="flex relative">
          <div v-if="event.photo_url"
               :style="{
              backgroundImage: `url('${event.photo_url}')`
             }"
               class="w-full h-full absolute bg-cover bg-center top-0 left-0 z-50 rounded-lg"></div>
          <div
              class="flex h-24 w-24 relative bg-cover bg-brand-dark-gray rounded-lg">
            <div class="m-auto">
              <svg class="h-6 fill-current text-brand-light-gray/20" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 640 512">
                <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                    d="M159.2 12.6c5.7-10.5 18.1-15.2 29.3-11.1L320 49.3 451.5 1.5c11.2-4.1 23.7 .7 29.3 11.1l81.6 151c32.4 60.1 10.6 133.3-45.6 167L552.6 429l55.2-20.1c12.5-4.5 26.2 1.9 30.8 14.3s-1.9 26.2-14.3 30.8l-77.8 28.3-77.8 28.3c-12.5 4.5-26.2-1.9-30.8-14.3s1.9-26.2 14.3-30.8l55.2-20.1-35.8-98.5c-61.2 9.4-121.7-29.6-136.3-92.5L320 188.6l-15.3 65.8c-14.6 62.9-75.1 101.9-136.3 92.5l-35.8 98.5 55.2 20.1c12.5 4.5 18.9 18.3 14.3 30.8s-18.3 18.9-30.8 14.3L93.6 482.3 15.8 454C3.3 449.4-3.1 435.7 1.5 423.2s18.3-18.9 30.8-14.3L87.4 429l35.8-98.4c-56.2-33.7-78.1-106.9-45.6-167l81.6-151zm32.4 41L156.8 118 277 161.7l16.5-71L191.6 53.6zM119.8 186.4c-22.4 41.5-2.8 93.3 41.6 109.4c41.3 15 86.5-9.4 96.5-52.3l8.1-34.7L133.7 160.7l-13.9 25.7zM448.4 53.6L346.5 90.7l16.5 71L483.2 118 448.4 53.6zm71.7 132.7l-13.9-25.7L374 208.8l8.1 34.7c10 42.9 55.2 67.3 96.5 52.3c44.3-16.1 64-67.9 41.6-109.4z"/>
              </svg>
            </div>
          </div>
        </div>

        <div class="flex flex-col my-auto">
          <div v-if="event.requested.length && withContextMenu" class="flex mb-3">
            <div class="px-2 bg-brand-lime flex rounded-full">
              <div class="m-auto text-xxs text-brand-black pb-0.5 pt-0.5 font-medium">{{ event.requested.length }}
                pending invite
                {{ pluralize('request', event.requested.length) }}
              </div>
            </div>
          </div>

          <div class="flex text-white text-xl font-medium mb-1.5 line-clamp-2 leading-tight">{{ event.name }}</div>
          <div class="flex justify-between">
            <div class="flex flex-col">
              <div class="flex text-brand-light-gray text-xs line-clamp-1">{{ event.location }}</div>
              <div class="flex text-brand-lime text-sm line-clamp-1">{{ eventDate }}</div>
            </div>

          </div>
        </div>
      </div>

      <div @click.stop="contextMenu(event)" v-if="withContextMenu" class="flex my-auto pl-4 py-4">
        <svg class="my-auto" width="24" height="25" viewBox="0 0 24 25" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M5.52035 14.5583C6.71329 14.5583 7.68035 13.5912 7.68035 12.3983C7.68035 11.2053 6.71329 10.2383 5.52035 10.2383C4.32742 10.2383 3.36035 11.2053 3.36035 12.3983C3.36035 13.5912 4.32742 14.5583 5.52035 14.5583Z"
              fill="#A5A7AF"/>
          <path
              d="M12.0008 14.5583C13.1938 14.5583 14.1608 13.5912 14.1608 12.3983C14.1608 11.2053 13.1938 10.2383 12.0008 10.2383C10.8079 10.2383 9.84082 11.2053 9.84082 12.3983C9.84082 13.5912 10.8079 14.5583 12.0008 14.5583Z"
              fill="#A5A7AF"/>
          <path
              d="M18.4803 14.5583C19.6732 14.5583 20.6403 13.5912 20.6403 12.3983C20.6403 11.2053 19.6732 10.2383 18.4803 10.2383C17.2874 10.2383 16.3203 11.2053 16.3203 12.3983C16.3203 13.5912 17.2874 14.5583 18.4803 14.5583Z"
              fill="#A5A7AF"/>
        </svg>
      </div>

    </div>

    <div v-if="layout === 'card'" class="flex flex-col">

      <div
          v-if="withImage"
          :class="{
            'h-80' : !isNested,
            'h-40' : isNested
          }"
          class="flex relative bg-cover bg-brand-dark-gray">

        <div v-if="event.photo_url"
             :style="{
              backgroundImage: `url('${ !authUser?.app_reviewer ? event.photo_url : ''}')`
             }"
             class="w-full h-full absolute bg-cover bg-center top-0 left-0"></div>

        <div class="m-auto">
          <svg class="h-16 fill-current text-brand-light-gray/20" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 640 512">
            <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
                d="M159.2 12.6c5.7-10.5 18.1-15.2 29.3-11.1L320 49.3 451.5 1.5c11.2-4.1 23.7 .7 29.3 11.1l81.6 151c32.4 60.1 10.6 133.3-45.6 167L552.6 429l55.2-20.1c12.5-4.5 26.2 1.9 30.8 14.3s-1.9 26.2-14.3 30.8l-77.8 28.3-77.8 28.3c-12.5 4.5-26.2-1.9-30.8-14.3s1.9-26.2 14.3-30.8l55.2-20.1-35.8-98.5c-61.2 9.4-121.7-29.6-136.3-92.5L320 188.6l-15.3 65.8c-14.6 62.9-75.1 101.9-136.3 92.5l-35.8 98.5 55.2 20.1c12.5 4.5 18.9 18.3 14.3 30.8s-18.3 18.9-30.8 14.3L93.6 482.3 15.8 454C3.3 449.4-3.1 435.7 1.5 423.2s18.3-18.9 30.8-14.3L87.4 429l35.8-98.4c-56.2-33.7-78.1-106.9-45.6-167l81.6-151zm32.4 41L156.8 118 277 161.7l16.5-71L191.6 53.6zM119.8 186.4c-22.4 41.5-2.8 93.3 41.6 109.4c41.3 15 86.5-9.4 96.5-52.3l8.1-34.7L133.7 160.7l-13.9 25.7zM448.4 53.6L346.5 90.7l16.5 71L483.2 118 448.4 53.6zm71.7 132.7l-13.9-25.7L374 208.8l8.1 34.7c10 42.9 55.2 67.3 96.5 52.3c44.3-16.1 64-67.9 41.6-109.4z"/>
          </svg>
        </div>

        <div class="absolute top-0 left-0 w-full h-1/3 bg-gradient-to-t from-transparent to-brand-black/95"></div>

        <div class="absolute flex top-0 left-0 p-4 w-full justify-between">
          <div class="flex space-x-3 my-auto w-full overflow-hidden mr-10">
            <user-avatar
                :account-type-badge="false"
                :size="7" :user="event.organiser"/>

            <div class="flex flex-col w-full my-auto -space-y-1 overflow-hidden">
              <div class="flex space-x-2">
                <div class="text-white font-medium truncate">{{ event.organiser.name }}</div>
                <user-verification-check
                    class="h-4" :user="event.organiser"/>
              </div>
            </div>
          </div>
          <div v-if="eventVisibility"
               class="text-brand-black bg-brand-lime text-sm font-medium rounded-full px-3 py-1 my-auto">
            {{ eventVisibility }}
          </div>
        </div>

        <div v-if="event.capacity > 0 && event.capacity_remaining <= 5"
             class="absolute flex bottom-0 left-0 p-4 w-full">
          <div
              v-if="event.capacity_remaining > 0"
              class="ml-auto text-white bg-brand-black/70 backdrop-blur text-xs font-medium rounded-full px-3.5 py-2.5">
            Only {{ event.capacity_remaining }} {{ pluralize('space', event.capacity_remaining) }} left!
          </div>
          <div
              v-if="event.capacity_remaining <= 0"
              class="ml-auto text-white bg-brand-black/70 backdrop-blur text-xs font-medium rounded-full px-3.5 py-2.5">
            Guest list full!
          </div>
        </div>

      </div>

      <div class="flex flex-col relative p-4">

        <div v-if="withImage" class="flex absolute -top-2.5 left-4 space-x-2">
          <div class="flex text-brand-black font-medium text-xxs px-1.5 py-0.5 rounded-md bg-white">{{
              eventType
            }}
          </div>
          <div v-if="event.conversations" class="flex text-white font-medium text-xxs px-1.5 py-0.5 rounded-md bg-brand-darkish-gray">{{event.conversations}} {{ pluralize('Conversation', event.conversations) }}</div>
          <div v-if="false"
               class="flex text-white  font-medium text-xxs px-1.5 py-0.5 rounded-md bg-brand-darkish-gray">
            {{ event.interested.length }} {{ event.interested.length < 2 ? 'person is' : 'people are' }} interested in
            this event
          </div>
        </div>

        <div class="flex flex-col pt-2">
          <div class="flex text-white text-xl font-medium mb-1.5 line-clamp-2">{{ event.name }}</div>

          <div class="flex w-full bg">
            <div class="flex flex-col mr-8">
              <div class="flex text-brand-light-gray text-xs line-clamp-1">{{ event.location }}
              </div>
              <div class="flex text-brand-lime text-sm line-clamp-1">{{ eventDate }}</div>
            </div>
            <div class="flex min-w-fit ml-auto">
              <div v-if="!event.public_guest_list" class="flex mt-auto">
                <div class="flex space-x-1.5">
                  <div
                      v-if="event.attending.length"
                      class="flex text-white font-medium text-xxs px-1.5 py-0.5 rounded-md bg-brand-darkish-gray min-w-max">
                    {{event.attending.length}} attending
                  </div>
                  <div
                      v-if="event.interested.length"
                      class="flex text-white font-medium text-xxs px-1.5 py-0.5 rounded-md bg-brand-darkish-gray min-w-max">
                    {{event.interested.length}} interested
                  </div>
                </div>
              </div>
              <div v-if="event.public_guest_list" class="flex mt-auto flex-grow">
                <div class="flex h-9 -space-x-2.5 overflow-hidden p-1 -mr-1">
                  <user-avatar
                      :account-type-badge="false"
                      :account-activity="false"
                      custom-class="ring-2 ring-brand-black my-auto"
                      :size="6"
                      v-for="attendee in event.attending.slice(Math.max(event.attending.length - totalAvatars, 0))"
                      :key="attendee.id"
                      :user="attendee"/>
                  <div v-if="(event.attending.length - totalAvatars) > 0"
                       class="flex h-6 w-6 rounded-full ring-2 ring-brand-black bg-brand-lime z-50 my-auto">
                    <div class="text-[8px] font-medium m-auto">+{{ event.attending.length - totalAvatars }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

    </div>
  </div>
</template>
<script setup lang="ts">

import {computed, onMounted, PropType, Ref, ref} from "vue";
import {EventInterface} from "@/interfaces/EventInterface";
import UserAvatar from "@/components/UserAvatar.vue";
import UserVerificationCheck from "@/components/UserBadges.vue";
import {EventType} from "@/definitions/EventType";
import {DateTime} from "luxon";
import {star} from "ionicons/icons";
import {EventPrivacy} from "@/definitions/EventPrivacy";
import pluralize from "pluralize";
import {modalController} from "@ionic/vue";
import EventContextMenu from "@/views/events/EventContextMenu.vue";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import ApiService from "@/services/api/apiService";

const props = defineProps({
  event: {
    type: Object as PropType<EventInterface>,
    required: true
  },
  layout: {
    type: String as PropType<'card' | 'list'>,
    default: 'card'
  },
  isNested: {
    type: Boolean,
    default: false
  },
  withContextMenu: {
    type: Boolean,
    default: true
  },
  withImage: {
    type: Boolean,
    default: true
  }
})

const totalAvatars = 4;
const authUser: Ref<UserBasicInformationInterface|undefined> = ref()

onMounted(async ()=>{
  authUser.value = await ApiService.User().authUser()
})

const eventType = computed(() => {
  switch (props.event?.type) {
    case EventType.Hotel:
      return 'Hotel'
    case EventType.Club:
      return 'Club'
    case EventType.HouseParty:
      return 'House Party'
    case EventType.Outdoors:
      return 'Outdoors'
  }
  return ''
})

const eventVisibility = computed(() => {
  switch (props.event?.visibility) {
    case EventPrivacy.Public:
      return 'Public'
    case EventPrivacy.InnerCircle:
      return 'Inner Circle'
    case EventPrivacy.InviteOnly:
      return 'Invite-only'
  }
  return ''
})

const eventDate = computed(() => {
  const startDate = DateTime.fromISO(props.event.starts_at)
  const endDate = DateTime.fromISO(props.event.ends_at)

  const diffInHours = endDate.diff(startDate).as('hours')

  if (diffInHours === 0) return startDate.toFormat("EEE, d MMM 'at' HH:mm")
  if (diffInHours < 15) return `${startDate.toFormat("EEE, d MMM 'from' HH:mm")} - ${endDate.toFormat("HH:mm")}`
  if (startDate !== endDate) return `${startDate.toFormat("EEE, d MMM 'at' HH:mm")} - ${endDate.toFormat("EEE, d MMM 'at' HH:mm")}`

  return startDate.toFormat("EEE, d MMM 'at' HH:mm")
})

const contextMenu = async (event: EventInterface) => {

  const sheet = await modalController.create({
    breakpoints: [0, 1],
    initialBreakpoint: 1,
    component: EventContextMenu,
    componentProps: {
      event
    },
    mode: 'ios'
  })

  await sheet.present()

}


</script>