<template>
  <div class="flex flex-col items-center p-5 py-10 pt-7 space-y-10">

    <div class="flex flex-col space-y-6 items-center">
      <div class="flex text-brand-lime">
        <svg  class="w-16 h-16 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M160 32c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160zM396 138.7l96 144c4.9 7.4 5.4 16.8 1.2 24.6S480.9 320 472 320H328 280 200c-9.2 0-17.6-5.3-21.6-13.6s-2.9-18.2 2.9-25.4l64-80c4.6-5.7 11.4-9 18.7-9s14.2 3.3 18.7 9l17.3 21.6 56-84C360.5 132 368 128 376 128s15.5 4 20 10.7zM192 128a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V344c0 75.1 60.9 136 136 136H456c13.3 0 24-10.7 24-24s-10.7-24-24-24H136c-48.6 0-88-39.4-88-88V120z"/></svg>
      </div>

      <div class="flex flex-col w-full items-center space-y-1 px-2">
        <div class="flex text-white text-2xl font-medium text-center">Cover Photo</div>
        <div class="text-brand-light-gray text-lg text-center leading-tight">Your cover photo has been submitted for approval. Whilst this is reviewed your current cover photo will remain in place.</div>
      </div>

      <div
          class="flex flex-col space-y-2 w-full items-center">
        <button
            @click="dismiss"
            class="flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
          <div class="flex m-auto space-x-2.5">
            <div class="my-auto pt-0.5">
              <div>Got it!</div>
            </div>
          </div>
        </button>
      </div>


    </div>

  </div>
</template>

<script setup lang="ts">

import {onMounted, ref} from 'vue';
import {modalController} from "@ionic/vue";
const loading = ref(true)

onMounted(async () => {
  loading.value = false
})

const dismiss = async () => {
  await modalController.dismiss()
}

</script>