<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <div class="flex flex-col w-full -mt-0.5">
          <div class="flex w-full justify-between -mt-4">
            <ion-segment
                @ionChange="tabChanged"
                :value="tab"
                class="relative z-50 backdrop-blur-2xl bg-opacity-40 ml-0"
                :swipe-gesture="false"
                mode="ios">
              <ion-segment-button value="inbox">
                <ion-label
                    class="text-lg font-medium">
                  Inbox
                </ion-label>
              </ion-segment-button>
              <ion-segment-button value="archive">
                <ion-label
                    class="text-lg font-medium">
                  Archive
                </ion-label>
              </ion-segment-button>
            </ion-segment>

            <div class="flex space-x-4 my-auto">
              <div @click="newChat" class="flex space-x-4 mt-0">
                <div class="rounded-md bg-white w-7 h-7 flex text-brand-black">
                  <svg class="w-4 h-4 m-auto fill-current" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 448 512">
                    <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                        d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ion-toolbar>

    </ion-header>
    <ion-content class="h-full flex flex-col">

      <ion-refresher slot="fixed" @ionRefresh="pullToRefresh($event)">
        <ion-refresher-content pulling-icon="crescent" refreshing-spinner="crescent"></ion-refresher-content>
      </ion-refresher>

      <div class="flex py-4" :class="{
                'h-full': loading || tab === 'inbox' && !chats.length || tab === 'archive' && !archivedChats.length,
            }">

        <svg v-if="loading" class="animate-spin h-10 w-10 m-auto text-white" xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>

        <template v-if="!loading && tab === 'inbox'">

          <div v-if="!chats.length" class="flex h-full w-full">
            <div class="flex px-4 py-5 m-auto text-white">
              <div class="flex flex-col items-center space-y-6">

                <div class="h-20 w-20 bg-brand-lime text-brand-black rounded-full flex">
                  <div class="m-auto">
                    <svg class="w-7 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M121 32C91.6 32 66 52 58.9 80.5L1.9 308.4C.6 313.5 0 318.7 0 323.9V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V323.9c0-5.2-.6-10.4-1.9-15.5l-57-227.9C446 52 420.4 32 391 32H121zm0 64H391l48 192H387.8c-12.1 0-23.2 6.8-28.6 17.7l-14.3 28.6c-5.4 10.8-16.5 17.7-28.6 17.7H195.8c-12.1 0-23.2-6.8-28.6-17.7l-14.3-28.6c-5.4-10.8-16.5-17.7-28.6-17.7H73L121 96z"/></svg>
                  </div>
                </div>

                <div class="flex flex-col w-full items-center space-y-0 px-2">
                  <div class="flex text-white text-2xl font-medium text-center">Inbox</div>
                  <div class="text-brand-light-gray text-lg text-center">
                    You haven’t started chatting with anyone yet. Go on, make the first move...
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ion-list lines="none" v-if="chats.length"
                    class="py-4 flex flex-col w-full text-white space-y-4 bg-brand-black">
            <chat-item-sliding
                v-for="chat in chats"
                :key="chat.id"
                :chat="chat" />
          </ion-list>

        </template>

        <template v-if="!loading && tab === 'archive'">

          <div v-if="!archivedChats.length" class="flex h-full w-full">
            <div class="flex px-4 py-5 m-auto text-white">
              <div class="flex flex-col items-center space-y-6">

                <div class="h-20 w-20 bg-brand-lime text-brand-black rounded-full flex">
                  <div class="m-auto">
                    <svg class="w-7 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32H480c17.7 0 32 14.3 32 32V96c0 17.7-14.3 32-32 32H32C14.3 128 0 113.7 0 96V64C0 46.3 14.3 32 32 32zm0 128H480V416c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V160zm128 80c0 8.8 7.2 16 16 16H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16z"/></svg>
                  </div>
                </div>

                <div class="flex flex-col w-full items-center space-y-0 px-2">
                  <div class="flex text-white text-2xl font-medium text-center">Archive</div>
                  <div class="text-brand-light-gray text-lg text-center">
                    Once a chat has been archived you will have the option to completely remove a chat.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ion-list
              v-if="archivedChats.length"
              lines="none"
              class="py-4 flex flex-col w-full text-white space-y-4 bg-brand-black">
            <ion-item-sliding class=""
                              v-for="(chat,chatIndex) in archivedChats"
                              :key="chat.id">
              <ion-item>
                <chat-list-item
                    :chat="chat"/>
              </ion-item>

              <ion-item-options>
                <ion-item-option
                    class="bg-red-500 text-white font-medium"
                    @click="destroyChat(chat.id, chatIndex)">
                  <div class="px-4 flex flex-col space-y-1">
                    <div class="flex m-auto">
                      <svg class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                            d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
                      </svg>
                    </div>
                    <div>Delete</div>
                  </div>
                </ion-item-option>
                <ion-item-option
                    class="bg-brand-lime text-brand-black font-medium"
                    @click="unarchiveChat(chat.id, chatIndex)">
                  <div class="px-4 flex flex-col space-y-1">
                    <div class="flex m-auto">
                      <svg class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                            d="M288 109.3V288c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-41.4 41.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l96-96c12.5-12.5 32.8-12.5 45.3 0l96 96c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM48 320h76.2c12.1 0 23.2 6.8 28.6 17.7l14.3 28.6c5.4 10.8 16.5 17.7 28.6 17.7H316.2c12.1 0 23.2-6.8 28.6-17.7l14.3-28.6c5.4-10.8 16.5-17.7 28.6-17.7H464c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V368c0-26.5 21.5-48 48-48z"/>
                      </svg>
                    </div>
                    <div>Unarchive</div>
                  </div>
                </ion-item-option>
              </ion-item-options>


            </ion-item-sliding>


          </ion-list>
        </template>


      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  alertController,
  IonContent,
  IonHeader,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding, IonLabel,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent, IonSegment, IonSegmentButton,
  IonToolbar, isPlatform,
  modalController,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, inject, onMounted, onUnmounted, Ref, ref} from 'vue';
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import ApiService from "@/services/api/apiService";
import {UserInterface} from "@/interfaces/UserInterface";
import {ChatInterface} from "@/interfaces/ChatInterface";
import FlirtNewChat from "@/views/flirt/FlirtNewChat.vue";
import FlirtViewChat from "@/views/flirt/FlirtViewChat.vue";
import ChatListItem from "@/components/ChatListItem.vue";
import {usePremium} from "@/composables/usePremium";
import {SocialFeedType} from "@/definitions/SocialFeedType";
import {Haptics, ImpactStyle} from "@capacitor/haptics";
import {useVerification} from "@/composables/useVerification";
import ChatItemSliding from "@/components/ChatItemSliding.vue";

const loading = ref(true)
const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>
const chats: Ref<ChatInterface[]> = ref([])
const archivedChats: Ref<ChatInterface[]> = ref([])

const ionRouter = useIonRouter()
const observer = ref()

const tab = ref('inbox')
const tabChanged = (event: any) => {
  tab.value = event.detail.value
}

onMounted(async () => {
  await getChats()

  observer.value = Observable.subscribe(async (response: any) => {
    if (response.event === ObservableEvent.ChatsChanged) {
      await getChats()
    }
  })


  loading.value = false
})

onUnmounted(() => {
  observer.value?.unsubscribe()
})

const getChats = async () => {
  chats.value = await ApiService.Chat().get()
  archivedChats.value = await ApiService.Chat().getArchived()
  Observable.emit(ObservableEvent.UnseenMessagesChanged)
}

const pullToRefresh = async (el: any) => {
  await getChats()
  setTimeout(() => {
    el.target.complete()
  }, 500)
}

const isSubscribed = computed(() => {
  return user.value?.subscribed
})


const newChat = async () => {


  if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});
  if (user.value && !user.value.id_verified) return await useVerification().presentVerificationRequired('You must be verified to start group chats.')



  const modal = await modalController.create({
    component: FlirtNewChat,
    componentProps: {},
    mode: 'ios',
    presentingElement: document.getElementById('tabs') as HTMLElement,
  })
  await modal.present()
}

const deleteChat = async (chatId: number, chatIndex: number) => {
  chats.value.splice(chatIndex, 1)
  await ApiService.Chat().delete(chatId)
}

const viewArchive = () => {
  ionRouter.navigate({
    name: 'FlirtArchive',
  }, 'forward', 'push')
}

const destroyChat = async (chatId: number, chatIndex: number) => {
  archivedChats.value.splice(chatIndex, 1)
  await ApiService.Chat().delete(chatId)
}
const unarchiveChat = async (chatId: number, chatIndex: number) => {
  archivedChats.value.splice(chatIndex, 1)
  await ApiService.Chat().unarchive(chatId)
  await Observable.emit(ObservableEvent.ChatsChanged)
}

</script>
<style scoped>
ion-toolbar {
  --padding-start: 0 !important;
//--padding-end: 0 !important; --padding-bottom: 1px !important;
}

ion-item {
  --background: #1A1B22;
}
</style>
