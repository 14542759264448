<template>
  <div
      :class="{
            'items-end' : isAuthUser
        }"
      class="flex flex-col mt-5 px-4 space-y-2">

    <div class="flex space-x-2">
      <div
          :class="{
                    'pr-20' : !isAuthUser,
                    'pl-20' : isAuthUser
                }"
          class="flex flex-col space-y-2">

        <photo-provider v-if="message.media && message.media.length">
          <photo-consumer v-for="(image,imageKey) in message.media" :key="imageKey" :src="image.url">
            <div
                class="flex flex-col w-full h-96 space-y-2">

              <img class="flex w-full h-full rounded-xl object-cover pointer-events-none" :src="image.url"/>

            </div>
          </photo-consumer>
        </photo-provider>

        <div
            @click="messageOptions"
            v-if="message.message">
          <div class="flex">
            <div
                :class="{
                        'bg-brand-dark-gray mr-auto' : !isAuthUser,
                        'bg-brand-darkish-gray ml-auto' : isAuthUser
                    }"
                class="py-3 px-4 rounded-xl text-lg flex flex-col space-y-3">
              <user-list-item @click="viewProfile(message.posted_by)" :user="message.posted_by"/>
              <div class="flex flex-col space-y-5" v-html="sanitizeHtml(message.message)"></div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="flex text-brand-light-gray text-sm px-2">{{ messageTime }}</div>
  </div>
</template>
<script setup lang="ts">

import {computed, PropType} from "vue";
import {ChatMessageInterface} from "@/interfaces/ChatMessageInterface";
import {DateTime} from "luxon";
import {actionSheetController, alertController, useIonRouter} from "@ionic/vue";
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import * as sanitizeHtml from 'sanitize-html';
import UserListItem from "@/components/UserListItem.vue";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import {ChatParticipantInterface} from "@/interfaces/ChatParticipantInterface";

const props = defineProps({
  isAuthUser: {
    type: Boolean,
    default: false
  },
  message: {
    type: Object as PropType<ChatMessageInterface>,
    required: true
  }
})

const emits = defineEmits(['onDelete'])

const messageTime = computed(() => {
  const dateTime = DateTime.fromISO(props.message?.created_at)

  // is today
  if (DateTime.now().toISODate() === dateTime.toISODate()) return `Today @ ${dateTime.toFormat('HH:mm')}`

  // is yesterday
  if (DateTime.now().minus({day: 1}).toISODate() === dateTime.toISODate()) return `Yesterday @ ${dateTime.toFormat('HH:mm')}`

  return `${dateTime.toFormat('EEE d')} ${dateTime.toFormat('MMM')} @ ${dateTime.toFormat('HH:mm')}`

})

const ionRouter = useIonRouter()

const viewProfile = async (user: ChatParticipantInterface) => {
  if (user.is_auth_user) return
  ionRouter.navigate({
    name: 'FlirtUserProfile',
    params: {
      userId: user.id
    }
  }, 'forward', 'push')
}

const messageOptions = async () => {
  if (!props.isAuthUser) return

  const actionSheet = await actionSheetController.create({
    buttons: [
      {
        text: 'Delete',
        role: 'destructive',
        handler: async () => {
          const alert = await alertController.create({
            header: 'Delete Message',
            message: 'Are you sure you want to delete this message?',
            buttons: [
              {
                text: 'Yes',
                role: 'destructive',
                handler: () => {
                  emits('onDelete', props.message)
                }
              },
              {
                text: 'Cancel',
              }
            ]
          })
          await alert.present()
        }
      },
      {
        text: 'Cancel',
        role: 'cancel'
      }
    ]
  })

  await actionSheet.present()


}

</script>