<template>
  <ion-page>
    <ion-content>

      <div class="flex flex-col w-full bg-brand-black my-auto h-full">

        <template v-if="loading">
          <div class="m-auto">
            <svg class="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </template>

        <template v-if="!loading">
          <div class="flex flex-col m-auto items-center w-full px-6 space-y-14 pt-20">

            <div class="flex flex-col items-center space-y-14">
              <logo-mark/>
              <div class="flex m-auto text-white text-4xl font-medium">Hello Sweet Stuff</div>
            </div>

            <div class="flex flex-col space-y-4 w-full items-center">
              <button
                  @click="createAccount"
                  class="flex bg-brand-dark-gray rounded-full max-w-md w-full h-14 font-medium text-xl text-white">
                <div class="flex m-auto space-x-2.5">
                  <svg class="my-auto" width="20" height="16" viewBox="0 0 20 16" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM17.6 4.25L10.53 8.67C10.21 8.87 9.79 8.87 9.47 8.67L2.4 4.25C2.29973 4.19371 2.21192 4.11766 2.14189 4.02645C2.07186 3.93525 2.02106 3.83078 1.99258 3.71937C1.96409 3.60796 1.9585 3.49194 1.97616 3.37831C1.99381 3.26468 2.03434 3.15581 2.09528 3.0583C2.15623 2.96079 2.23632 2.87666 2.33073 2.811C2.42513 2.74533 2.53187 2.69951 2.6445 2.6763C2.75712 2.65309 2.87328 2.65297 2.98595 2.67595C3.09863 2.69893 3.20546 2.74453 3.3 2.81L10 7L16.7 2.81C16.7945 2.74453 16.9014 2.69893 17.014 2.67595C17.1267 2.65297 17.2429 2.65309 17.3555 2.6763C17.4681 2.69951 17.5749 2.74533 17.6693 2.811C17.7637 2.87666 17.8438 2.96079 17.9047 3.0583C17.9657 3.15581 18.0062 3.26468 18.0238 3.37831C18.0415 3.49194 18.0359 3.60796 18.0074 3.71937C17.9789 3.83078 17.9281 3.93525 17.8581 4.02645C17.7881 4.11766 17.7003 4.19371 17.6 4.25Z"
                        fill="white"/>
                  </svg>
                  <div class="my-auto pt-0.5">
                    Continue with email
                  </div>
                </div>
              </button>
              <div @click="gotoLogin"
                   class="text-lg text-white">Already have an account? <span
                  class="font-medium text-brand-lime">Sign in</span></div>
            </div>


          </div>
          <div class="flex py-16 px-12 mx-auto">
            <div class="text-brand-light-gray text-sm text-center">By signing up to Pineapple<br />you agree to our <a
                class="font-bold" target="_system" href="https://pineapple-public.s3.eu-west-2.amazonaws.com/terms-of-use-eula.pdf">Terms of use (EULA)</a><br />and <a class="font-bold" target="_system" href="https://pineapple-public.s3.eu-west-2.amazonaws.com/app-privacy-policy.pdf">Privacy Policy.</a></div>
          </div>
        </template>

      </div>


    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  createAnimation,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter,
  onIonViewWillEnter,
  useIonRouter
} from '@ionic/vue';
import {defineComponent, onMounted, ref} from 'vue';
import LogoMark from "@/components/LogoMark.vue";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import ApiService from "@/services/api/apiService";

export default defineComponent({
  name: 'HomePage',
  components: {
    LogoMark,
    IonContent,
    IonPage,
  },
  setup() {

    const ionRouter = useIonRouter()

    onIonViewDidEnter(async () => {

      const loggedIn = (await Preferences.get({
        key: PreferenceKey.ApiToken
      })).value

      if (!loggedIn) return loading.value = false

      const user = await ApiService.User().get()

      if (user.onboarding.email_verified) {
        if (user.onboarding.password_set) return ionRouter.navigate('/account/onboarding', 'forward', 'pop')
        if (!user.onboarding.password_set) return ionRouter.navigate('/account/password', 'forward', 'pop')
      }

      if (!user.onboarding.email_verified) {
        await ionRouter.navigate('/account/email/not-verified', 'forward', 'pop')
      }

    })


    const loading = ref(true)

    const gotoLogin = async () => {
      await ionRouter.navigate('/login', 'forward', 'push')
    }

    const createAccount = async () => {
      await ionRouter.navigate('/account', 'forward', 'push')
    }

    return {gotoLogin, createAccount, loading}

  }
});
</script>
