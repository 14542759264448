<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="mr-4">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title class="text-white font-medium text-2xl">Account</ion-title>

      </ion-toolbar>
    </ion-header>
    <ion-content class="flex h-full w-full">

      <div v-if="loading" class="w-full h-full flex">
        <div class="m-auto">
          <svg class="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>

      <div v-if="!loading" class="flex flex-col py-5 px-4 justify-center space-y-10">


        <div class="flex flex-col space-y-2">
          <div class="flex flex-col space-y-1">
            <menu-button @click="edit('Username')" name="Username">
              <template v-slot:bottom>
                <div class="text-left text-sm">{{ user.name }}</div>
              </template>
            </menu-button>
          </div>

          <div @click="edit('AccountType')" class="flex flex-col space-y-1">
            <menu-button inline-label="Account Type" v-if="!user.account_type" name="Set..."/>
            <menu-button inline-label="Account Type" class="capitalize" v-if="user.account_type"
                         :name="`${user.account_type}`"/>
          </div>
        </div>

        <button
            @click="deleteAccount"
            class="mx-auto w-full flex bg-red-500 rounded-full px-8 h-14 font-medium text-xl text-white transition-all">
          <div class="flex m-auto space-x-2.5">
            <div class="my-auto pt-0.5">
              <div v-if="!busy">Delete account</div>
              <div v-if="busy">
                <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
          </div>
        </button>

      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar, onIonViewDidEnter,
  useIonRouter,
} from '@ionic/vue';
import {inject, onMounted, reactive, Ref, ref, watch, watchEffect} from 'vue';
import ApiService from "@/services/api/apiService";
import {DateTime} from "luxon";
import MenuButton from "@/components/MenuButton.vue";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import InputCheckbox from "@/components/InputCheckbox.vue";

const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>

const loading = ref(false)
const ionRouter = useIonRouter()
const busy = ref(false)

const goBack = async () => {
  await ionRouter.back()
}

const deleteAccount = async () => {

  const alert = await alertController.create({
    header: 'Delete Account',
    message: 'Are you sure you want to delete your account?\n\n All associated data including images will be removed.',
    buttons: [
      {
        text: 'No',
      },
      {
        text: 'Yes',
        role: 'destructive',
        handler: async () => {
          busy.value = true
          try {
            await ApiService.User().delete()
            await Preferences.clear()
            await Preferences.set({
              key: PreferenceKey.CompletedOnboarding,
              value: DateTime.now().toISO()
            })
            ionRouter.navigate('/', 'back', 'pop')
          } catch (e) {

            busy.value = false
            const alert = await alertController.create({
              header: 'Delete Account',
              message: 'There was a problem whilst attempting to delete your account, please try again. If the issue persists please contact support@thepineapple.app',
              buttons: [
                {
                  text: 'Ok'
                }
              ]
            })
            await alert.present()

          }
        }
      },
    ]
  })

  await alert.present()

}

const edit = async (slug: string) => {
  await ionRouter.navigate({name: `ProfileSettings${slug}`}, 'forward', 'push')
}

</script>
