<template>
  <ion-item-sliding class="">
    <ion-item>
      <chat-list-item
          @click.stop="viewChat(chat)"
          :chat="chat"/>
    </ion-item>

    <ion-item-options>
      <ion-item-option
          class="bg-red-500 text-white font-medium"
          @click="deleteChat(chat.id)" v-if="!chat.latest_message">
        <div class="px-4">Delete</div>
      </ion-item-option>
      <ion-item-option
          v-if="!isDirectMessage"
          class="bg-orange-600 text-white font-medium"
          @click="leaveChat(chat.id)">
        <div class="px-4 flex flex-col space-y-1">
          <div class="flex m-auto">
            <svg  class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M272 0a48 48 0 1 1 0 96 48 48 0 1 1 0-96zM163.2 160c-14.1 0-27.5 5.6-37.5 15.5l-23.1 23.1c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l23.1-23.1C102.4 108.3 132.2 96 163.2 96c11.7 0 23.3 1.7 34.4 5.2l48.7 15c36.4 11.2 65.9 38 80.5 73.1l6.3 15c5 11.9 16.6 19.7 29.5 19.7H384c17.7 0 32 14.3 32 32s-14.3 32-32 32H362.7c-38.8 0-73.7-23.3-88.6-59.1l-5.1-12.3L238 299l56.7 35.4c18.1 11.3 26.6 33.3 20.7 53.9L286.8 488.8c-4.9 17-22.6 26.8-39.6 22s-26.8-22.6-22-39.6l25.4-88.8-86.2-53.9c-25.1-15.7-36.1-46.5-26.8-74.5l31.2-93.7c-1.9-.2-3.7-.3-5.6-.3zM109.5 310.7c7.4 18.1 20.4 33.9 37.9 44.9l9.5 6-11.5 25.9c-7.7 17.3-24.9 28.5-43.9 28.5H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H91.2l18.3-41.3zM384 48c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48V464c0 26.5-21.5 48-48 48H432c-26.5 0-48-21.5-48-48V320h0c35.3 0 64-28.7 64-64s-28.7-64-64-64h0V48z"/></svg>
          </div>
          <div>Leave</div>
        </div>
      </ion-item-option>
      <ion-item-option
          class="bg-purple-600 text-white font-medium"
          @click="archiveChat(chat.id)" v-if="chat.latest_message">
        <div class="px-4 flex flex-col space-y-1">
          <div class="flex m-auto">
            <svg class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 512 512">
              <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                  d="M32 32H480c17.7 0 32 14.3 32 32V96c0 17.7-14.3 32-32 32H32C14.3 128 0 113.7 0 96V64C0 46.3 14.3 32 32 32zm0 128H480V416c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V160zm128 80c0 8.8 7.2 16 16 16H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16z"/>
            </svg>
          </div>
          <div>Archive</div>
        </div>
      </ion-item-option>
    </ion-item-options>


  </ion-item-sliding>
</template>
<script setup lang="ts">

import {PropType,ref,computed} from "vue";
import {ChatInterface} from "@/interfaces/ChatInterface";
import ChatListItem from "@/components/ChatListItem.vue";
import {alertController, IonItem, IonItemOption, IonItemOptions, IonItemSliding, useIonRouter} from "@ionic/vue";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

const props = defineProps({
    chat: {
        type: Object as PropType<ChatInterface>,
        required: true
    }
})

const busy = ref(false)
const ionRouter = useIonRouter()

const viewChat = async (chat: any) => {

  ionRouter.navigate({
    name: 'FlirtViewChat',
    params: {
      chatId: chat.id
    }
  }, 'forward', 'push')

}

const archiveChat = async (chatId: number) => {
  if (busy.value) return
  busy.value = true
  await ApiService.Chat().archive(chatId)
  await Observable.emit(ObservableEvent.ChatsChanged)
  busy.value = false
}

const leaveChat = async (chatId: number) => {

  const alert = await alertController.create({
    header: 'Leave Chat',
    message: 'Are you sure you want to leave this chat?',
    buttons: [
      {
        text: 'Cancel',
        role: "cancel"
      },
      {
        text: 'Yes',
        role: 'destructive',
        handler: async () => {
          if (busy.value) return
          busy.value = true
          await ApiService.Chat().leave(chatId)
          await Observable.emit(ObservableEvent.ChatsChanged)
          busy.value = false
        }
      }
    ]
  })

  await alert.present()

}


const deleteChat = async (chatId: number) => {
  if (busy.value) return
  busy.value = true
  await ApiService.Chat().delete(chatId)
  await Observable.emit(ObservableEvent.ChatsChanged)
  busy.value = false
}

const isDirectMessage = computed(() => {
  return !props.chat?.participants.find(participant => participant.role === 'ADMIN') && props.chat?.participants.length > 1
})

</script>
<style scoped>
ion-toolbar {
  --padding-start: 0 !important;
//--padding-end: 0 !important; --padding-bottom: 1px !important;
}

ion-item {
  --background: #1A1B22;
}
</style>
