<template>
  <ion-page>
    <ion-tabs @ionTabsDidChange="tabChanged($event)" id="tabs">
      <ion-router-outlet></ion-router-outlet>

      <ion-tab-bar slot="bottom" class="transition-all duration-200">

        <div class="flex flex-grow relative h-full">
          <div @click="tabSelected(selectedTab)" v-if="selectedTab === 'feed'"
               class="absolute top-0 left-0 w-full h-full z-50"></div>
          <ion-tab-button tab="feed" href="/pineapple/feed">
            <svg class="fill-current -rotate-180 mb-1 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                  d="M431.5 12.1c-1.1-4.5-4.2-8.4-8.4-10.5s-9.1-2.2-13.4-.4C393.8 8.1 383 20.8 378.1 35.4L363.3 20.7c-6.2-6.2-16.4-6.2-22.6 0C325.3 36.1 317.1 56 316.2 76.2C297.3 68.2 276.9 64 256 64c-41 0-80.3 16.3-109.3 45.3L45.3 210.7C16.3 239.7 0 279 0 320s16.3 80.3 45.3 109.3l37.5 37.5c29 29 68.3 45.3 109.3 45.3s80.3-16.3 109.3-45.3L402.7 365.3c29-29 45.3-68.3 45.3-109.3c0-20.9-4.2-41.3-12.2-60.2c20.2-.9 40.1-9.1 55.5-24.5c6.2-6.2 6.2-16.4 0-22.6l-14.7-14.7c14.6-4.9 27.3-15.8 34.1-31.6c1.8-4.3 1.7-9.2-.4-13.4s-5.9-7.3-10.5-8.4l-14.3-3.6C492.4 65 496 51.4 496 37.5V32c0-8.8-7.2-16-16-16h-5.5c-13.9 0-27.5 3.6-39.4 10.4l-3.6-14.3zM164.7 212.7c6.2-6.2 16.4-6.2 22.6 0L208 233.4l20.7-20.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-32 32c-6.2 6.2-16.4 6.2-22.6 0l-32-32c-6.2-6.2-6.2-16.4 0-22.6zm-80 80c6.2-6.2 16.4-6.2 22.6 0L128 313.4l20.7-20.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-32 32c-6.2 6.2-16.4 6.2-22.6 0l-32-32c-6.2-6.2-6.2-16.4 0-22.6zm182.6 0L288 313.4l20.7-20.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-32 32c-6.2 6.2-16.4 6.2-22.6 0l-32-32c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0zM164.7 395.3c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L208 393.4l20.7-20.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-32 32c-6.2 6.2-16.4 6.2-22.6 0l-32-32z"/>
            </svg>
          </ion-tab-button>
        </div>

        <div v-if="!user?.app_reviewer" class="flex flex-grow relative h-full">
          <div @click="tabSelected(selectedTab)" v-if="selectedTab === 'events'"
               class="absolute top-0 left-0 w-full h-full z-50"></div>
          <ion-tab-button tab="events" href="/pineapple/events">

            <div class="flex relative">
              <svg class="fill-current mb-1.5" height="22" viewBox="0 0 21 20"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M5.5 0C4.94772 0 4.5 0.447715 4.5 1V2H3.5C1.84315 2 0.5 3.34315 0.5 5V17C0.5 18.6569 1.84315 20 3.5 20H17.5C19.1569 20 20.5 18.6569 20.5 17V5C20.5 3.34315 19.1569 2 17.5 2H16.5V1C16.5 0.447715 16.0523 0 15.5 0C14.9477 0 14.5 0.447715 14.5 1V2H6.5V1C6.5 0.447715 6.05228 0 5.5 0ZM7.5 11C7.5 10.4477 7.94772 10 8.5 10H15.5C16.0523 10 16.5 10.4477 16.5 11C16.5 11.5523 16.0523 12 15.5 12H8.5C7.94772 12 7.5 11.5523 7.5 11ZM4.5 15C4.5 14.4477 4.94772 14 5.5 14H11.5C12.0523 14 12.5 14.4477 12.5 15C12.5 15.5523 12.0523 16 11.5 16H5.5C4.94772 16 4.5 15.5523 4.5 15Z"/>
              </svg>
              <div v-if="pendingResponses" class="absolute -top-1 -right-2.5">
                <div class="w-4 h-4 bg-brand-lime flex rounded-full">
                  <div class="m-auto text-xxs text-brand-black font-medium">{{ pendingResponses }}</div>
                </div>
              </div>
            </div>


          </ion-tab-button>
        </div>

        <div class="flex flex-grow relative h-full">
          <div v-if="selectedTab === 'search'" class="absolute top-0 left-0 w-full h-full z-50"></div>
          <ion-tab-button @click="refreshSearch" tab="search" href="/pineapple/search">
            <svg class="fill-current mb-1 w-6 h-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.01256 4.49467C8.80117 4.16802 9.64641 3.99989 10.5 3.99989C12.2239 3.99989 13.8772 4.68471 15.0962 5.90369C16.3152 7.12268 17 8.77598 17 10.4999C17 12.2238 16.3152 13.8771 15.0962 15.0961C13.8772 16.3151 12.2239 16.9999 10.5 16.9999C9.64641 16.9999 8.80117 16.8318 8.01256 16.5051C7.22394 16.1785 6.50739 15.6997 5.90381 15.0961C5.30023 14.4925 4.82144 13.7759 4.49478 12.9873C4.16813 12.1987 4 11.3535 4 10.4999C4 9.6463 4.16813 8.80106 4.49478 8.01245C4.82144 7.22383 5.30023 6.50728 5.90381 5.90369C6.50739 5.30011 7.22394 4.82133 8.01256 4.49467ZM2.959 6.57883C2.32912 7.78987 2.00018 9.1348 2 10.4999C1.99982 12.1153 2.46001 13.6974 3.32665 15.0607C4.19329 16.4241 5.4305 17.5122 6.89333 18.1977C8.35617 18.8831 9.98405 19.1376 11.5863 18.9311C13.1129 18.7344 14.5559 18.1271 15.7624 17.1759L20.2903 21.7038C20.3812 21.8004 20.4905 21.878 20.6117 21.932C20.7344 21.9867 20.8668 22.0161 21.0011 22.0184C21.1353 22.0208 21.2687 21.9961 21.3932 21.9458C21.5177 21.8955 21.6309 21.8207 21.7258 21.7257C21.8208 21.6307 21.8956 21.5176 21.9459 21.3931C21.9962 21.2686 22.0209 21.1352 22.0186 21.001C22.0162 20.8667 21.9868 20.7343 21.9321 20.6116C21.8781 20.4904 21.8005 20.3811 21.7039 20.2902L17.176 15.7623C17.9725 14.7516 18.5298 13.5724 18.8046 12.3132C19.0956 10.9795 19.0612 9.5954 18.7041 8.27786C18.3471 6.96033 17.678 5.74815 16.7535 4.74388C15.8289 3.73961 14.6761 2.97278 13.3925 2.50827C12.1089 2.04375 10.7323 1.8952 9.3792 2.0752C8.02606 2.25519 6.73619 2.75843 5.61867 3.54234C4.50115 4.32626 3.58887 5.36779 2.959 6.57883Z"
                  stroke-width="0.5"/>
            </svg>
          </ion-tab-button>
        </div>

        <div class="flex flex-grow relative h-full">
          <div v-if="selectedTab === 'flirt'" class="absolute top-0 left-0 w-full h-full z-50"></div>
          <ion-tab-button tab="flirt" href="/pineapple/flirt">
            <div class="flex relative">
              <svg class="fill-current mb-1" width="25" height="24" viewBox="0 0 25 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M2.70847 5.66957C2.99865 3.98488 4.33773 2.74648 6.02778 2.48932C7.64711 2.24293 9.86859 2 12.333 2C14.7974 2 17.0189 2.24293 18.6382 2.48932C20.3283 2.74648 21.6674 3.98488 21.9576 5.66957C22.1598 6.84372 22.333 8.33525 22.333 10C22.333 11.6647 22.1598 13.1563 21.9576 14.3304C21.6674 16.0151 20.3283 17.2535 18.6382 17.5107C17.1568 17.7361 15.1714 17.9586 12.9571 17.9949L6.84173 21.6085C6.17512 22.0024 5.33301 21.5219 5.33301 20.7476V17.344C3.97957 16.8939 2.95757 15.7766 2.70847 14.3304C2.50622 13.1563 2.33301 11.6647 2.33301 10C2.33301 8.33525 2.50622 6.84372 2.70847 5.66957ZM6.33301 8C6.33301 7.44772 6.78072 7 7.33301 7H17.333C17.8853 7 18.333 7.44772 18.333 8C18.333 8.55228 17.8853 9 17.333 9H7.33301C6.78072 9 6.33301 8.55228 6.33301 8ZM6.33301 12C6.33301 11.4477 6.78072 11 7.33301 11H11.333C11.8853 11 12.333 11.4477 12.333 12C12.333 12.5523 11.8853 13 11.333 13H7.33301C6.78072 13 6.33301 12.5523 6.33301 12Z"/>
              </svg>
              <div v-if="unseenMessages.messages" class="absolute -top-1 -right-2.5">
                <div class="w-4 h-4 bg-brand-lime flex rounded-full">
                  <div class="m-auto text-xxs text-brand-black font-medium">{{ unseenMessages.messages }}</div>
                </div>
              </div>
            </div>
          </ion-tab-button>
        </div>

        <div class="flex flex-grow relative h-full">
          <div v-if="selectedTab === 'profile'" class="absolute top-0 left-0 w-full h-full z-50"></div>
          <ion-tab-button tab="profile" href="/pineapple/profile">
            <svg class="fill-current mb-1" width="25" height="24" viewBox="0 0 25 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M17 6.5C17 8.98528 14.9853 11 12.5 11C10.0147 11 8 8.98528 8 6.5C8 4.01472 10.0147 2 12.5 2C14.9853 2 17 4.01472 17 6.5ZM4.5 22C3.94772 22 3.5 21.5523 3.5 21V20C3.49999 16.134 6.634 13 10.5 13H14.5C18.366 13 21.5 16.134 21.5 20V21C21.5 21.5523 21.0523 22 20.5 22H4.5Z"/>
            </svg>
          </ion-tab-button>
        </div>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  isPlatform,
  modalController,
  useIonRouter
} from '@ionic/vue';
import {computed, inject, onMounted, onUnmounted, provide, Ref, ref, watch} from 'vue';
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {StatusInterface} from "@/interfaces/StatusInterface";
import {UserInterface} from "@/interfaces/UserInterface";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {useRoute} from "vue-router";
import PusherJS from "pusher-js";
import {useAppUpdate} from "@/composables/useAppUpdate";
import {AccountType} from "@/definitions/AccountType";
import AccountTypeSheet from "@/views/common/AccountTypeSheet.vue";
import {UnseenMessagesInterface} from "@/interfaces/UnseenMessagesInterface";
import AppResumed from "@/views/common/AppResumed.vue";
import {Geolocation} from "@capacitor/geolocation";
import {useOneSignal} from "@onesignal/onesignal-vue3";
import PWARequired from "@/views/common/PWARequired.vue";
import {App} from "@capacitor/app";
import {Glassfy} from "capacitor-plugin-glassfy";
import {Haptics, ImpactStyle} from "@capacitor/haptics";
import OneSignal from "onesignal-cordova-plugin";
import {OpenedEvent} from "onesignal-cordova-plugin/dist/models/NotificationOpened";
import {NotificationInterface} from "@/interfaces/NotificationInterface";
import {useNotification} from "@/composables/useNotification";
import ProfileSetupSheet from "@/views/common/ProfileSetupSheet.vue";
import ProfileJointSetupSheet from "@/views/common/ProfileJointSetupSheet.vue";
import VerificationSheet from "@/views/common/VerificationSheet.vue";

declare const window: any;

const user: Ref<UserInterface | undefined> = ref()
const status: Ref<StatusInterface | undefined> = ref()
const unseenMessages: Ref<UnseenMessagesInterface | undefined> = ref({messages: 0})
const ionRouter = useIonRouter()
const route = useRoute()

const appVersion: string = inject('appVersion') as string


provide('user', user)
provide('status', status)

const observable = ref()
const showVerification = ref(true)

const getData = async () => {
  await getUser()
  await getStatus()
  await getUnseenMessages()

  if (isPlatform('capacitor')) await Glassfy.connectCustomSubscriber({subscriberId: `${user.value?.id}`});

  Observable.emit(ObservableEvent.PendingEventResponsesChanged)

  if (isPlatform('capacitor')) {

    OneSignal.setAppId(`${process.env.VUE_APP_ONE_SIGNAL_APP_ID}`);
    OneSignal.promptForPushNotificationsWithUserResponse(async (accepted) => {
      if (accepted) {
        OneSignal.setExternalUserId(`${user.value?.id}`);
      }
    });


    OneSignal.setNotificationOpenedHandler(async (openedEvent: OpenedEvent) => {
      const notificationContext = openedEvent.notification.additionalData
      const userNotification: NotificationInterface = await ApiService.User().getNotificationById(notificationContext.notification_id)
      await useNotification().present(userNotification, ionRouter)
    })


  } else {
    const oneSignal = useOneSignal()
    if (!oneSignal.Notifications.permission) {
      await oneSignal.Notifications.requestPermission()
    }
    await oneSignal.login(`${user.value?.id}`)
  }

  // After introducing couples accounts we've changed the account types
  // to be clearer to the users, if they're using an old account type
  // we must make them choose which is most appropriate for them
  if (user.value && [AccountType.Single, AccountType.Couple].includes(user.value.account_type)) {

    if (!await modalController.getTop()) {
      const sheet = await modalController.create({
        breakpoints: [1],
        initialBreakpoint: 1,
        component: AccountTypeSheet,
        mode: 'ios',
        backdropDismiss: false,
      })

      await sheet.present()
    }

  }

}

const getUser = async () => {
  user.value = await ApiService.User().get()
  await Preferences.set({key: PreferenceKey.UserId, value: `${user.value.id}`})
}

const getStatus = async () => {
  status.value = await ApiService.User().getVerificationStatus()
}

const getUnseenMessages = async () => {
  unseenMessages.value = await ApiService.Chat().unseenMessages()
}

const pendingResponses = ref(0)
const getPendingEventResponses = async () => {
  pendingResponses.value = (await ApiService.Events().pendingResponse()).pending
}


const appResumeTimeout: Ref<any> = ref(null)
const appResumeState = ref(false)
const presentAppResumed = async () => {
  let modal = await modalController.getTop()
  if (modal || appResumeState.value) return

  appResumeState.value = true

  modal = await modalController.create({
    component: AppResumed,
  })

  await modal.present()
  await modal.onDidDismiss()
  appResumeState.value = false

}


const shouldShowAppResumed = async () => {
  const pin = (await Preferences.get({key: PreferenceKey.PrivacyPin})).value
  if (typeof pin === 'string' && pin.length !== 0) await presentAppResumed()
}

const updateCurrentPosition = async () => {

  user.value = await ApiService.User().authUser()

  try {
    if (!parseInt(user.value?.preferences[PreferenceKey.LocationDiscoverable])) return

    let precision = 5
    switch (user.value?.preferences[PreferenceKey.LocationAccuracy]) {
      case 'DP5':
        precision = 5
        break
      case 'DP3':
        precision = 3
        break
      case 'DP2':
        precision = 2
        break
      case 'DP1':
        precision = 1
        break
    }

    if (isPlatform('capacitor')) {

      if (((await Geolocation.checkPermissions()).location === 'granted' || (await Geolocation.checkPermissions()).coarseLocation === 'granted')) {
        const {coords} = await Geolocation.getCurrentPosition()
        await ApiService.User().updateCurrentPosition(
            parseFloat(coords.latitude.toFixed(precision)),
            parseFloat(coords.longitude.toFixed(precision))
        )
      }

    } else {

      navigator.geolocation.getCurrentPosition(async (location) => {
        await ApiService.User().updateCurrentPosition(
            parseFloat(location.coords.latitude.toFixed(precision)),
            parseFloat(location.coords.longitude.toFixed(precision))
        )

      }, (error) => {
        console.log(error)
      })
    }

  } catch (e) {
    // Fail silently
  }


}


onMounted(async () => {

  if (!isPlatform('pwa') && isPlatform('mobile') && !isPlatform('capacitor')) {

    const modal = await modalController.create({
      component: PWARequired,
      canDismiss: false,
      animated: false,
      cssClass: 'full-screen',
    })

    await modal.present()

  }

  await shouldShowAppResumed()

  // If there's an active chat id the app could have crashed or the user swipe closed it
  // so when they come back we'll set that chat as inactive
  const activeChatId = (await Preferences.get({key: PreferenceKey.ActiveChatId})).value
  if (activeChatId) await ApiService.Chat().isActive(Number(activeChatId), false)

  if (isPlatform('capacitor')) {

    App.addListener('appStateChange', async (state) => {
      if (state.isActive) {
        Observable.emit(ObservableEvent.AppResumed)
        if (appResumeTimeout.value) clearTimeout(appResumeTimeout.value)
        Observable.emit(ObservableEvent.NotificationsChanged)
        await updateCurrentPosition()
        await getUser()
        await getStatus()
        await getUnseenMessages()
      } else {
        Observable.emit(ObservableEvent.AppPaused)
        appResumeTimeout.value = setTimeout(async () => {
          await shouldShowAppResumed()
        }, 1000 * 3)
      }
    })

  } else {
    document.addEventListener('visibilitychange', async () => {
      if (document.visibilityState === 'visible') {
        Observable.emit(ObservableEvent.AppResumed)
        if (appResumeTimeout.value) clearTimeout(appResumeTimeout.value)
        Observable.emit(ObservableEvent.NotificationsChanged)
        await updateCurrentPosition()
        await getUser()
        await getStatus()
        await getUnseenMessages()
      } else {
        Observable.emit(ObservableEvent.AppPaused)
        await shouldShowAppResumed()
      }
    });
  }

  observable.value = Observable.subscribe(async (response: any) => {

    const tabBar = document.querySelector('ion-tab-bar')

    if (response.event === ObservableEvent.PWAUpdateAvailable) {
      useAppUpdate().updateAvailable(appVersion)
    }

    if (response.event === ObservableEvent.ShowPrivacyScreen) {
      await presentAppResumed()
    }

    if (response.event === ObservableEvent.ProfilesChanged) {
      await getUser()
      await setupProfile()
    }

    if (response.event === ObservableEvent.UserChanged) {
      await getData()
    }

    if (response.event === ObservableEvent.UnseenMessagesChanged) {
      await getUnseenMessages()
    }

    if (response.event === ObservableEvent.PendingEventResponsesChanged) {
      await getPendingEventResponses()
    }

    if (response.event === ObservableEvent.UpdateCurrentPosition) {
      await updateCurrentPosition()
    }

    if (response.event === ObservableEvent.HideTabBar) {
      if (tabBar) tabBar.style.marginBottom = `-${tabBar.clientHeight + 1}px`
    }

    if (response.event === ObservableEvent.ShowTabBar) {
      if (tabBar) tabBar.style.marginBottom = '0'
    }

  })

  await getData()

  const $pusher = new PusherJS(process.env.VUE_APP_PUSHER_APP_KEY as string, {
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    authEndpoint: process.env.VUE_APP_PUSHER_APP_AUTH_ENDPOINT,
    auth: {
      headers: {
        Authorization:
            `Bearer ${(await Preferences.get({key: PreferenceKey.ApiToken})).value}`,
      },
    },
  });

  const flirt = $pusher.subscribe(`private-Flirt.${user.value?.id}`)

  flirt.bind('App\\Events\\ChatMessageSent', async (event: any) => {
    Observable.emit(ObservableEvent.ChatsChanged, event)
    await getUnseenMessages()
  })

  await useAppUpdate().checkForUpdate()

  Observable.emit(ObservableEvent.UpdateCurrentPosition)
  Observable.emit(ObservableEvent.UserUpdateComplete)

  await setupProfile()


})

onUnmounted(() => {
  observable.value?.unsubscribe()
})

const currentRoute = computed(() => {
  return route.name
})

const refreshFeed = async () => {

  if (route.name === 'Feed') {
    Observable.emit(ObservableEvent.FeedScrollTop)
    if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});
  }
}

const refreshSearch = async () => {
  if (route.name === 'Search') {
    Observable.emit(ObservableEvent.SearchScrollTop)
    if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});
  }
}

const verify = async () => {
  showVerification.value = false
  ionRouter.navigate({
    name: 'ProfileSettingsVerify',
  }, 'forward', 'push')
}

const tapped = async () => {
  console.log(ionRouter.canGoBack())
}

const selectedTab = ref('feed')
const tabChanged = (ev: any) => {
  selectedTab.value = ev.tab
}

const tabSelected = async (tab: string) => {


  switch (tab) {
    case 'feed': {
      if (route.name !== 'Feed') {
        ionRouter.back()
      }
      if (route.name === 'Feed') Observable.emit(ObservableEvent.FeedScrollTop)
    }
      break
    case 'events': {
      if (route.name !== 'Events') {
        ionRouter.back()
      }
      if (route.name === 'Events') Observable.emit(ObservableEvent.EventsScrollTop)
    }
      break
    case 'search': {
      if (route.name !== 'Search') {
        ionRouter.back()
      }
      if (route.name === 'Search') Observable.emit(ObservableEvent.SearchScrollTop)
    }
      break
    case 'flirt': {
      if (route.name !== 'Flirt') {
        ionRouter.back()
      }
      // if (route.name === 'Flirt') Observable.emit(ObservableEvent.FeedScrollTop)
    }
      break
    case 'profile': {
      if (route.name !== 'Profile') {
        ionRouter.back()
      }
      // if (route.name === 'Flirt') Observable.emit(ObservableEvent.FeedScrollTop)
    }
  }

}

const setupProfile = async () => {

  if (user.value?.account_type === AccountType.Club) return

  if (!user.value?.profiles.length && user.value?.id_verified) {
    if (!await modalController.getTop()) {
      const sheet = await modalController.create({
        component: ProfileSetupSheet,
        componentProps: {
          user: user.value
        },
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        backdropDismiss: false,
        cssClass: 'auto',
        mode: 'ios'
      })
      await sheet.present()
    }
  }

  if (user.value?.profiles.length === 1 && user.value.account_type === AccountType.Joint) {
    if (!await modalController.getTop()) {
      const sheet = await modalController.create({
        component: ProfileJointSetupSheet,
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        backdropDismiss: false,
        cssClass: 'auto',
        mode: 'ios'
      })
      await sheet.present()

    }
  }

}

watch(() => currentRoute.value, route => {
  if (route !== 'ProfileSettingsVerify') showVerification.value = true
})


const presentedVerificationSheet = ref(false)

watch(() => status.value, async value => {

  if (presentedVerificationSheet.value) return

  if (value && !value.verified_at && !value.submitted_at) {
    presentedVerificationSheet.value = true

    const modal = await modalController.getTop()
    if (modal && modal.id === 'verificationSheet') return

    const sheet = await modalController.create({
      component: VerificationSheet,
      breakpoints: [1],
      initialBreakpoint: 1,
      backdropDismiss: false,
      canDismiss: true,
      cssClass: 'auto',
      mode: 'ios',
      id: 'verificationSheet'
    })
    await sheet.present()
    await sheet.onWillDismiss()
    presentedVerificationSheet.value = false
  }
}, {deep: true})


</script>