<template>
  <ion-page>
    <ion-content>

      <div class="flex flex-col w-full bg-brand-black my-auto px-4 pt-14 z-50">

        <div class="flex flex-col space-y-6">
          <div class="flex flex-col w-full space-y-2">
            <div v-if="accountType !== AccountType.Club" class="flex text-white text-2xl font-medium">Username</div>
            <div v-if="accountType !== AccountType.Club" class="text-brand-light-gray text-lg">Pineapple encourages the use of imaginary names. Be open, never
              exposed.
            </div>

            <div v-if="accountType === AccountType.Club" class="flex text-white text-2xl font-medium">Club name</div>
            <div v-if="accountType === AccountType.Club" class="text-brand-light-gray text-lg">Enter your club or business name.</div>
          </div>

          <input-field
              :error="errors && errors['name'] ? errors['name'][0] : undefined"
              v-model="name"
              type="text"
              :counter="true"
              :max-length="20"
              enterkeyhint="next"
              @onKeyEnter="forward"
              placeholder="Start typing...">
          </input-field>

        </div>

        <button
            @click="forward"
            :class="{
              'bg-opacity-40' : !name.length
            }"
            class="mt-10 flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
          <div class="flex m-auto space-x-2.5">
            <div class="my-auto pt-0.5">
              <div v-if="!busy">Continue</div>
              <div v-if="busy">
                <svg class="animate-spin h-5 w-5 text-brand-black" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
          </div>
        </button>


      </div>

    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonContent,
  IonPage, onIonViewWillEnter, useIonRouter,
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import InputField from "@/components/InputField.vue";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {AccountType} from "@/definitions/AccountType";

const busy = ref(false)
const name = ref('')
const errors = ref({})
const ionRouter = useIonRouter()
const accountType = ref()

onIonViewWillEnter(async () => {
  Observable.emit(ObservableEvent.IonRouterOutletChanged, ionRouter.canGoBack())
  accountType.value = (await Preferences.get({key: PreferenceKey.OnboardingAccountType})).value
})

const forward = async () => {
  if (!name.value || busy.value) return
  errors.value = {}
  busy.value = true
  try {
    await ApiService.User().setName(name.value)
    await ionRouter.navigate('/account/onboarding/bio', 'forward', 'push')
  } catch (e: any) {
    if (!e.data) return
    if (e.data.errors) errors.value = e.data.errors
  } finally {
    busy.value = false
  }
}
</script>
