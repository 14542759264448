<template>
  <div class="relative" v-if="!loading">
    <div v-if="disabled" class="absolute top-0 left-0 w-full h-full z-50"></div>
    <div v-if="['pill'].includes(inputType)"
         @click="checkValue"
         :class="[
          isChecked ? 'bg-brand-lime text-brand-dark-gray' : 'bg-brand-dark-gray text-white',
            fieldClasses
          ]"
         class="flex justify-between p-4 py-2 rounded-full">
      <div
          class="my-auto text-lg font-medium">{{ option.text }}
      </div>
    </div>
    <div v-if="['checkbox'].includes(inputType)"
         @click="checkValue"
         :class="[
          isChecked ? 'bg-brand-lime text-brand-dark-gray' : 'bg-brand-dark-gray text-white',
          fieldClasses
       ]"

         class="flex justify-between p-4 rounded-2xl">

      <div class="flex flex-col space-y-1">
        <div class="my-auto text-lg font-medium">{{ option.text }}</div>
        <div
            v-if="option.description"
            :class="{
               'text-brand-light-gray' : !isChecked,
               'text-brand-black text-opacity-70' : isChecked
            }"
            class="my-auto text-base font-normal leading-tight pr-5" v-html="option.description"></div>
      </div>
      <div class="flex my-auto relative">
        <div class="absolute top-0 left-0 w-full h-full z-50"></div>
        <ion-checkbox mode="ios" :checked="isChecked"/>
      </div>
    </div>
    <div
        v-if="['toggle'].includes(inputType)"
        :class="[
            fieldClasses
        ]"
        class="flex justify-between bg-brand-dark-gray text-white p-4 rounded-2xl">
      <div class="flex">
        <slot name="start"></slot>
        <div
            class="my-auto text-lg font-medium">{{ option.text }}
        </div>
      </div>
      <ion-toggle mode="ios" @ionChange="toggleChanged($event)" :checked="isChecked"/>
    </div>
  </div>
</template>
<script setup lang="ts">

import {computed, onMounted, PropType, ref, watch} from "vue";
import {IonCheckbox, IonToggle} from "@ionic/vue";

export interface InputCheckboxOption {
  text: string,
  value: string | boolean | number,
}

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  modelValue: {
    type: Array,
    required: false
  },
  inputType: {
    type: String,
    default: 'checkbox'
  },
  returnSingleValue: {
    type: Boolean,
    default: false
  },
  option: {
    type: Object as PropType<any>,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  fieldClasses: {
    type: String,
    default: ''
  }
})

const loading = ref(true)

const emits = defineEmits(['update:modelValue'])
const model: any = ref([])

onMounted(() => {
  if (props.modelValue) model.value = props.modelValue
  loading.value = false
})

watch(() => props.modelValue, value => {
  if (value) model.value = value
})

const checkValue = () => {

  const index = model.value.indexOf(props.option.value);

  if (props.returnSingleValue) {
    model.value.splice(index, model.value.length)
  }

  if (model.value.includes(props.option.value)) return model.value.splice(index, 1)
  model.value.push(props.option.value)

}


const isChecked = computed(() => {
  return model.value.includes(props.option.value)
})

const toggleChanged = async (ev: any) => {
  emits('update:modelValue', [ev.detail.checked])
}

</script>
<style scoped>
ion-checkbox {
  --checkbox-background-checked: #1A1B22;
}
</style>