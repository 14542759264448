<template>
  <ion-header>
    <ion-toolbar class="!p-0 !m-0">
      <ion-buttons class="justify-between flex w-full">
        <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
          <svg class="fill-current text-white w-6 h-6 p-0.5" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 320 512">
            <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
          </svg>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div class="flex flex-col space-y-1 z-50 px-4 py-5 bg-brand-black border-b border-brand-dark-gray">
      <input-field
          id="locationSearch"
          type="text"
          v-model="locationName"
          :debounce="300"
          :clear-input="true"
          :counter="false"
          @onChange="updateLocationSearch($event)"
          @onKeyEnter="() => console.log('enter')"
          placeholder="e.g. Stoke">
      </input-field>
      <div id="attribution" class="text-white"></div>
    </div>

    <div class="flex flex-col pb-5 px-4 justify-center space-y-4 relative">
      <div class="flex w-full overflow-scroll">
        <div class="flex flex-col space-y-6 divide-y divide-brand-dark-gray w-full">
          <div v-for="(location,locationKey) in locations"
               class="flex text-white text-lg pt-6"
               @click="chooseLocation(location)"
               :key="locationKey">
            {{ location.formatted_address }}
          </div>
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script setup lang="ts">
import {

  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  modalController,
} from '@ionic/vue';
import {onMounted, ref, watch} from 'vue';
import {useGoogleMaps} from "@/composables/useGoogleMaps";
import InputField from "@/components/InputField.vue";

const loading = ref(true)
const location = ref()
const locationName = ref('')
const locationSearch = ref('')
const locations = ref([])

onMounted(async () => {
  loading.value = false
})

const places = ref()

onMounted(async () => {
  await useGoogleMaps().loadSDK()
  const attributionEl: any = document.querySelector('#attribution')
  // eslint-disable-next-line no-undef
  places.value = new google.maps.places.PlacesService(attributionEl)
  loading.value = false
})

const updateLocationSearch = async (value: any) => {
  locationSearch.value = value
}

const chooseLocation = async (value: any) => {
  location.value = value
  locationName.value = value.formatted_address
  locations.value = []

  await modalController.dismiss({
    name: location.value.formatted_address,
    lat: location.value.geometry.location.lat(),
    lng: location.value.geometry.location.lng()
  })
}

watch(() => locationSearch.value, searchValue => {

  if (!searchValue) {
    locations.value = []
    location.value = undefined
    return
  }

  if (searchValue === locationName.value) return

  // eslint-disable-next-line no-undef
  places.value.textSearch({
    query: searchValue,
    types: ['(cities)']
  }, (response: any) => {
    locations.value = response
  })

})

const goBack = async () => {
  await modalController.dismiss()
}

const done = async () => {
//
}

</script>