<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>

        <ion-buttons class="flex" slot="start">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title>
          <div class="text-2xl text-white font-medium"></div>
        </ion-title>
        <ion-buttons slot="end">
          <div @click="eventChat" class="flex bg-white space-x-2 h-9 rounded-full px-4">
            <div class="font-medium text-sm my-auto">Chat</div>
            <div class="flex my-auto">
              <svg class="fill-current mb-1 h-4" viewBox="0 0 25 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M2.70847 5.66957C2.99865 3.98488 4.33773 2.74648 6.02778 2.48932C7.64711 2.24293 9.86859 2 12.333 2C14.7974 2 17.0189 2.24293 18.6382 2.48932C20.3283 2.74648 21.6674 3.98488 21.9576 5.66957C22.1598 6.84372 22.333 8.33525 22.333 10C22.333 11.6647 22.1598 13.1563 21.9576 14.3304C21.6674 16.0151 20.3283 17.2535 18.6382 17.5107C17.1568 17.7361 15.1714 17.9586 12.9571 17.9949L6.84173 21.6085C6.17512 22.0024 5.33301 21.5219 5.33301 20.7476V17.344C3.97957 16.8939 2.95757 15.7766 2.70847 14.3304C2.50622 13.1563 2.33301 11.6647 2.33301 10C2.33301 8.33525 2.50622 6.84372 2.70847 5.66957ZM6.33301 8C6.33301 7.44772 6.78072 7 7.33301 7H17.333C17.8853 7 18.333 7.44772 18.333 8C18.333 8.55228 17.8853 9 17.333 9H7.33301C6.78072 9 6.33301 8.55228 6.33301 8ZM6.33301 12C6.33301 11.4477 6.78072 11 7.33301 11H11.333C11.8853 11 12.333 11.4477 12.333 12C12.333 12.5523 11.8853 13 11.333 13H7.33301C6.78072 13 6.33301 12.5523 6.33301 12Z"/>
              </svg>
            </div>
          </div>
        </ion-buttons>

      </ion-toolbar>
    </ion-header>
    <ion-content id="ionContent">

      <ion-refresher slot="fixed" class="safe-area-top top-4 lef-0 text-white z-50 relative"
                     @ionRefresh="pullToRefresh($event)">
        <ion-refresher-content pulling-icon="crescent" refreshing-spinner="crescent"></ion-refresher-content>
      </ion-refresher>

      <div class="h-full flex" v-if="loading">
        <div class="m-auto flex">
          <svg class="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>

      <div class="h-full" v-if="!loading && event">

        <photo-provider class="relative w-full z-40">
          <photo-consumer class="w-full" :src="event.photo_url">
            <event-card class="w-full" :event="event"/>
          </photo-consumer>
        </photo-provider>


        <div
            class="flex flex-col">
          <div v-if="!isOrganiser"
               class="flex sticky py-4 top-0 left-0 w-full bg-brand-black/50 backdrop-blur z-50 px-4 space-x-2">

            <div class="flex w-full space-x-2"
                 v-if="event.visibility === EventPrivacy.Public || event.visibility === EventPrivacy.InnerCircle">
              <div
                  v-if="(event.capacity === 0 || event.is_attending) || event.capacity_remaining > 0 && event.capacity > 0"
                  @click="attend"
                  :class="{
                      'opacity-30': event.is_attending
                   }"
                  class="w-1/2 border-2 border-brand-lime bg-brand-lime font-medium rounded-full text-brand-black px-5 h-10 flex flex-grow ">
                <div v-if="!attendBusy" class="m-auto">{{ event.is_attending ? 'Attending' : 'Attend' }}</div>
                <svg v-else class="animate-spin h-5 w-5 m-auto text-brand-black"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                          stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
              <div
                  v-if="!event.is_attending"
                  @click="showInterest"
                  :class="{
                      'opacity-30': event.is_interested
                   }"
                  class="w-1/2 border-2 border-brand-dark-gray bg-brand-dark-gray font-medium rounded-full text-white px-5 h-10 flex flex-grow ">
                <div v-if="!interestBusy" class="m-auto">{{
                    event.is_interested ? 'Interested' : 'Show interest'
                  }}
                </div>
                <svg v-else class="animate-spin h-5 w-5 m-auto text-white"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                          stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>

            <div class="flex w-full space-x-2" v-if="event.visibility === EventPrivacy.InviteOnly">
              <div
                  v-if="!event.is_attending || (event.capacity_remaining > 0 && event.capacity > 0) || event.has_requested_invite"
                  @click="requestInvite"
                  :class="{
                      'opacity-30': event.has_requested_invite
                   }"
                  class="border-2 w-1/2 border-brand-lime bg-brand-lime font-medium rounded-full text-brand-black px-5  h-12 flex flex-grow ">
                <div v-if="!requestBusy" class="m-auto">
                  {{ event.has_requested_invite ? 'Invite requested' : 'Request invite' }}
                </div>
                <svg v-else class="animate-spin h-5 w-5 m-auto text-brand-black"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                          stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
              <div
                  v-if="event.is_attending"
                  @click="notAttending(event)"
                  class="border-2 opacity-30 border-brand-lime bg-brand-lime font-medium rounded-full text-brand-black px-5  h-12 flex flex-grow ">
                <div v-if="!requestBusy" class="m-auto">Attending</div>
                <svg v-else class="animate-spin h-5 w-5 m-auto text-brand-black"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                          stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
              <div
                  v-if="!event.is_attending"
                  @click="showInterest"
                  :class="{
                      'opacity-30': event.is_interested
                   }"
                  class="w-1/2 border-2 border-brand-dark-gray bg-brand-dark-gray font-medium rounded-full text-white px-5 h-10 flex flex-grow ">
                <div v-if="!interestBusy" class="m-auto">{{
                    event.is_interested ? 'Interested' : 'Show interest'
                  }}
                </div>
                <svg v-else class="animate-spin h-5 w-5 m-auto text-white"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                          stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>

            <div class="flex" v-if="false">
              <div class="bg-brand-dark-gray font-medium rounded-full text-white h-12 w-12 flex mt-auto">
                <svg class="h-5 fill-current m-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                  <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <path
                      d="M64 368a48 48 0 1 0 0 96 48 48 0 1 0 0-96zm0-160a48 48 0 1 0 0 96 48 48 0 1 0 0-96zM112 96A48 48 0 1 0 16 96a48 48 0 1 0 96 0z"/>
                </svg>
              </div>
            </div>
          </div>

          <div v-if="isOrganiser || event.public_guest_list" class="flex flex-col">

            <ion-segment

                @ionChange="tabChanged"
                :value="tab"
                class="relative z-20 backdrop-blur-2xl bg-opacity-40 w-ful mb-2"
                :swipe-gesture="false"
                mode="ios">
              <ion-segment-button value="details">
                <ion-label
                    class="text-lg font-medium">
                  Details
                </ion-label>
              </ion-segment-button>
              <ion-segment-button value="guest-list">
                <ion-label
                    class="text-lg font-medium">
                  Guest List
                </ion-label>
              </ion-segment-button>
              <ion-segment-button
                  v-if="isOrganiser && event.visibility === EventPrivacy.InviteOnly"
                  value="pending">
                <ion-label
                    class="text-lg font-medium flex space-x-2">
                  <div>
                    Pending
                  </div>
                  <div v-if="event.requested.length" class="flex my-auto">
                    <div class="w-4 h-4 bg-brand-lime flex rounded-full my-auto">
                      <div class="m-auto text-xxs text-brand-black font-medium leading-normal">{{
                          event.requested.length
                        }}
                      </div>
                    </div>
                  </div>
                </ion-label>
              </ion-segment-button>
            </ion-segment>
          </div>


          <div class="flex flex-col" v-show="tab==='chat'">

          </div>


          <div class="flex flex-col" v-show="tab==='details'">

            <div class="flex flex-col space-y-6 p-4 pb-8">
              <div class="flex flex-col w-full space-y-0.5">
                <div class="flex text-white text-lg font-medium">About</div>
                <div class="text-brand-light-gray text-lg post-content whitespace-pre-wrap  leading-tight"
                     v-html="sanitizeHtml(event.description)"></div>
              </div>

              <div class="flex flex-col w-full space-y-0.5">
                <div class="flex text-white text-lg font-medium">Rules</div>
                <div class="text-brand-light-gray text-lg post-content whitespace-pre-wrap leading-tight"
                     v-html="sanitizeHtml(event.rules)"></div>
              </div>

              <div class="flex flex-col w-full space-y-0.5">
                <div class="flex text-white text-lg font-medium">Location</div>
                <div class="text-brand-light-gray text-lg post-content leading-tight">{{ event.location }}</div>
              </div>

            </div>


          </div>


          <div class="flex flex-col" v-show="tab==='guest-list'">
            <template v-if="event.attending.length || event.interested.length">
              <div v-if="event.attending.length" class="flex flex-col px-4 py-3 space-y-5">
                <div class="flex text-brand-light-gray text-lg">{{ event.attending.length }}
                  {{ event.attending.length < 2 ? 'person is' : 'people are' }} attending this event
                </div>
                <div class="flex flex-col space-y-3">

                  <div
                      class="flex justify-between"
                      v-for="(user, userIndex) in event.attending" :key="userIndex">
                    <div @click="viewProfile(user)" class="flex w-full space-x-3">
                      <div class="flex">
                        <user-avatar :size="10" :user="user"/>
                      </div>
                      <div class="flex flex-col w-full my-auto">
                        <div class="flex space-x-2">
                          <div class="text-white font-medium my-auto line-clamp-1">
                            {{ user.name }}
                          </div>
                          <div class="flex">
                            <user-verification-check class="h-4" :user="user"/>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="isOrganiser" @click="contextMenu(user)" class="flex pl-3  py-2.5 my-auto">
                      <svg class="my-auto" width="24" height="25" viewBox="0 0 24 25" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.52035 14.5583C6.71329 14.5583 7.68035 13.5912 7.68035 12.3983C7.68035 11.2053 6.71329 10.2383 5.52035 10.2383C4.32742 10.2383 3.36035 11.2053 3.36035 12.3983C3.36035 13.5912 4.32742 14.5583 5.52035 14.5583Z"
                            fill="#A5A7AF"/>
                        <path
                            d="M12.0008 14.5583C13.1938 14.5583 14.1608 13.5912 14.1608 12.3983C14.1608 11.2053 13.1938 10.2383 12.0008 10.2383C10.8079 10.2383 9.84082 11.2053 9.84082 12.3983C9.84082 13.5912 10.8079 14.5583 12.0008 14.5583Z"
                            fill="#A5A7AF"/>
                        <path
                            d="M18.4803 14.5583C19.6732 14.5583 20.6403 13.5912 20.6403 12.3983C20.6403 11.2053 19.6732 10.2383 18.4803 10.2383C17.2874 10.2383 16.3203 11.2053 16.3203 12.3983C16.3203 13.5912 17.2874 14.5583 18.4803 14.5583Z"
                            fill="#A5A7AF"/>
                      </svg>
                    </div>

                  </div>

                </div>
              </div>

              <div v-if="event.interested.length" class="flex flex-col px-4 py-3 space-y-5">

                <div class="flex text-brand-light-gray text-lg">{{ event.interested.length }}
                  {{ event.interested.length < 2 ? 'person is' : 'people are' }} interested this event
                </div>
                <div class="flex flex-col space-y-3">
                  <div
                      @click="viewProfile(user)"
                      class="flex"
                      v-for="(user, userIndex) in event.interested" :key="userIndex">
                    <div class="flex w-full space-x-3">
                      <div class="flex">
                        <user-avatar :size="10" :user="user"/>
                      </div>
                      <div class="flex flex-col w-full my-auto">
                        <div class="flex space-x-2">
                          <div class="text-white font-medium my-auto line-clamp-1">
                            {{ user.name }}
                          </div>
                          <div class="flex">
                            <user-verification-check class="h-4" :user="user"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <div v-else class="flex flex-col px-4 py-4 space-y-5">
              <div class="p-3 bg-brand-dark-gray text-brand-light-gray rounded-lg w-full text-center">
                Guest list information will be available soon.
              </div>
            </div>
          </div>

          <div class="flex flex-col" v-show="tab==='pending'">
            <template v-if="event.requested.length">
              <div v-if="event.requested.length" class="flex flex-col px-4 py-3 space-y-5">

                <div class="flex text-brand-light-gray text-lg">The following people would like to attend your event.
                </div>
                <div class="flex flex-col space-y-3">
                  <div
                      class="flex justify-between"
                      v-for="(user, userIndex) in event.requested" :key="userIndex">
                    <div @click="viewProfile(user)" class="flex w-full space-x-3">
                      <div class="flex">
                        <user-avatar :size="10" :user="user"/>
                      </div>
                      <div class="flex flex-col w-full my-auto">
                        <div class="flex space-x-2">
                          <div class="text-white font-medium my-auto line-clamp-1">
                            {{ user.name }}
                          </div>
                          <div class="flex">
                            <user-verification-check class="h-4" :user="user"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div @click="contextMenu(user)" class="flex pl-3  py-2.5 my-auto">
                      <svg class="my-auto" width="24" height="25" viewBox="0 0 24 25" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.52035 14.5583C6.71329 14.5583 7.68035 13.5912 7.68035 12.3983C7.68035 11.2053 6.71329 10.2383 5.52035 10.2383C4.32742 10.2383 3.36035 11.2053 3.36035 12.3983C3.36035 13.5912 4.32742 14.5583 5.52035 14.5583Z"
                            fill="#A5A7AF"/>
                        <path
                            d="M12.0008 14.5583C13.1938 14.5583 14.1608 13.5912 14.1608 12.3983C14.1608 11.2053 13.1938 10.2383 12.0008 10.2383C10.8079 10.2383 9.84082 11.2053 9.84082 12.3983C9.84082 13.5912 10.8079 14.5583 12.0008 14.5583Z"
                            fill="#A5A7AF"/>
                        <path
                            d="M18.4803 14.5583C19.6732 14.5583 20.6403 13.5912 20.6403 12.3983C20.6403 11.2053 19.6732 10.2383 18.4803 10.2383C17.2874 10.2383 16.3203 11.2053 16.3203 12.3983C16.3203 13.5912 17.2874 14.5583 18.4803 14.5583Z"
                            fill="#A5A7AF"/>
                      </svg>
                    </div>

                  </div>
                </div>
              </div>
            </template>

            <div v-else class="flex flex-col px-4 py-4 space-y-5">
              <div class="p-3 bg-brand-dark-gray text-brand-light-gray rounded-lg w-full text-center">
                There's no outstanding invite requests right now.
              </div>
            </div>
          </div>

        </div>

      </div>

    </ion-content>

  </ion-page>
</template>

<script setup lang="ts">
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar, isPlatform,
  modalController,
  toastController,
  useIonRouter,
} from '@ionic/vue';
import {computed, onMounted, onUnmounted, Ref, ref} from 'vue';
import {useRoute} from "vue-router";
import ApiService from "@/services/api/apiService";
import * as sanitizeHtml from 'sanitize-html';

import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {EventInterface} from "@/interfaces/EventInterface";
import EventCard from "@/components/EventCard.vue";
import {EventPrivacy} from "@/definitions/EventPrivacy";
import UserAvatar from "@/components/UserAvatar.vue";
import UserVerificationCheck from "@/components/UserBadges.vue";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import {usePremium} from "@/composables/usePremium";
import EventInviteRequestContextMenu from "@/views/events/EventInviteRequestContextMenu.vue";
import * as events from "events";
import ShareSheet from "@/views/common/ShareSheet.vue";
import {Haptics, ImpactStyle} from "@capacitor/haptics";
import {useVerification} from "@/composables/useVerification";
import FeedNewPostPage from "@/views/feed/FeedNewPostPage.vue";
import EventViewChatPage from "@/views/events/EventViewChatPage.vue";

const loading = ref(true)
const ionRouter = useIonRouter()
const route = useRoute()
const observable = ref()

const authUser = ref()

const eventId = ref()
const event: Ref<EventInterface | undefined> = ref()

const getData = async () => {
  event.value = await ApiService.Events().get(eventId.value)
}

const goBack = async () => {
  await ionRouter.back()
}


onMounted(async () => {

  try {
    eventId.value = route.params.eventId
    await getData()
    authUser.value = await ApiService.User().authUser()
  } catch (e: any) {
    if (e.status === 404) {

      const alert = await alertController.create({
        header: 'Not available',
        subHeader: 'The requested event is no longer available.',
        buttons: [
          {
            text: 'Ok',
            handler: async () => {
              alertController.dismiss()
              await goBack()
            }
          }
        ]
      })
      await alert.present()

    }
  }

  observable.value = Observable.subscribe(async (response: any) => {
    if (response.event === ObservableEvent.EventsChanged) await getData()
  })

  loading.value = false

})

onUnmounted(() => {
  observable.value.unsubscribe()
})

const pullToRefresh = async (ev: any) => {
  await getData();
  ev.target.complete()
}

const tab = ref('details')
const tabChanged = async (event: any) => {

  tab.value = event.detail.value
  if (!authUser.value.subscribed) {
    tab.value = 'details'
    await usePremium().presentPayWall({
      text: `Upgrade to see who's on the guest list.`,
    })
  }
}

const isOrganiser = computed(() => {
  return authUser.value.id === event.value?.organiser.id
})

const viewProfile = async (user: UserBasicInformationInterface) => {

  ionRouter.navigate({
    name: 'PineappleEventsUserProfile',
    params: {
      userId: user.id
    }
  }, 'forward', 'push')

}

const attendBusy = ref(false)
const interestBusy = ref(false)
const requestBusy = ref(false)

const attend = async () => {
  if (attendBusy.value) return

  if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});
  if (authUser.value && !authUser.value.id_verified) return await useVerification().presentVerificationRequired('You must be verified to set your profile as attending this event.')

  try {

    attendBusy.value = true
    if (!event.value?.is_attending) {

      // const sheet = await modalController.create({
      //   breakpoints: [0, 1],
      //   initialBreakpoint: 1,
      //   component: ShareSheet,
      //   mode: 'ios',
      //   componentProps: {
      //     title: 'Attending',
      //     description: `Publicly post this event to the feed with or without a comment to tell others you'll be attending this event.`,
      //     placeholder: `e.g. I can't wait for this event, who's going?`,
      //     button: 'Share to Feed'
      //   }
      // })
      // await sheet.present()
      // let share = (await sheet.onWillDismiss()).data
      // if (share === undefined) return attendBusy.value = false
      // if (share === null) share = undefined

      event.value = await ApiService.Events().attending(event.value?.id as number, null)

    } else {
      event.value = await ApiService.Events().notAttending(event.value.id)
    }

    Observable.emit(ObservableEvent.PullToRefreshMyTickets)
    attendBusy.value = false
  } catch (e: any) {
    attendBusy.value = false
    const toast = await toastController.create({
      message: e.data.message,
      duration: 3000,
      position: 'bottom',
      color: 'danger'
    });
    await toast.present();
  }

}

const showInterest = async () => {
  if (interestBusy.value) return

  if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});
  if (authUser.value && !authUser.value.id_verified) return await useVerification().presentVerificationRequired('You must be verified to show interest in this event.')

  interestBusy.value = true
  try {
    if (event.value?.is_interested) {
      event.value = await ApiService.Events().notInterested(event.value.id)
    } else {

      // const sheet = await modalController.create({
      //   breakpoints: [0, 1],
      //   initialBreakpoint: 1,
      //   component: ShareSheet,
      //   mode: 'ios',
      //   componentProps: {
      //     title: 'Interested',
      //     description: `Publicly post this event to the feed with or without a comment to tell others you're interested in this event.`,
      //     placeholder: `e.g. Interested in this event, who's going?`,
      //     button: 'Share to Feed'
      //   }
      // })
      // await sheet.present()
      // let share = (await sheet.onWillDismiss()).data
      // if (share === undefined) return attendBusy.value = false
      // if (share === null) share = undefined

      event.value = await ApiService.Events().interested(event.value?.id as number, null)
    }
    Observable.emit(ObservableEvent.PullToRefreshMyTickets)
    interestBusy.value = false
  } catch (e: any) {
    interestBusy.value = false
    const toast = await toastController.create({
      message: e.data.message,
      duration: 3000,
      position: 'bottom',
      color: 'danger'
    });
    await toast.present();
  }

}

const requestInvite = async () => {
  if (requestBusy.value) return

  if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});
  if (authUser.value && !authUser.value.id_verified) return await useVerification().presentVerificationRequired('You must be verified to request invites to events.')

  requestBusy.value = true
  try {
    if (event.value?.has_requested_invite) {
      event.value = await ApiService.Events().cancelRequestInvite(event.value.id)
    } else {
      event.value = await ApiService.Events().requestInvite(event.value?.id as number)
    }
    Observable.emit(ObservableEvent.PullToRefreshMyTickets)
    requestBusy.value = false
  } catch (e: any) {
    requestBusy.value = false
    const toast = await toastController.create({
      message: e.data.message,
      duration: 3000,
      position: 'bottom',
      color: 'danger'
    });
    await toast.present();
  }
}

const contextMenu = async (user: UserBasicInformationInterface) => {

  const sheet = await modalController.create({
    breakpoints: [0, 1],
    initialBreakpoint: 1,
    component: EventInviteRequestContextMenu,
    componentProps: {
      event: event.value,
      user
    },
    mode: 'ios'
  })

  await sheet.present()

}

const notAttending = async (e: EventInterface) => {

  if (requestBusy.value) return

  const alert = await alertController.create({
    header: 'Guest List',
    message: 'Are you sure you want to remove yourself from the guest list?',
    buttons: [
      {
        text: 'Cancel'
      },
      {
        text: 'Yes',
        role: 'destructive',
        handler: async () => {
          requestBusy.value = true
          try {
            event.value = await ApiService.Events().notAttending(e.id)
            Observable.emit(ObservableEvent.EventsChanged)
            requestBusy.value = false
          } catch (e: any) {
            const toast = await toastController.create({
              message: e.data.message,
              duration: 3000,
              position: 'bottom',
              color: 'danger'
            });
            await toast.present();
            requestBusy.value = false
          }
        }
      },
    ]
  })

  await alert.present()

}

const eventChat = async () => {

  const modal = await modalController.create({
    component: EventViewChatPage,
    mode: 'ios',
    componentProps: {
      eventId: eventId.value,
      canViewEvent: false
    },
    presentingElement: document.getElementById('tabs') as HTMLElement,
  })
  await modal.present()

}

</script>