<template>
    <div  class="flex w-full space-x-4">
        <div class="flex mt-0 h-full">
            <div class="flex w-16 h-16 bg-brand-dark-gray my-auto relative rounded-full overflow-hidden">
                <div class="w-full h-full absolute top-0 left-0 bg-cover bg-center">
                  <img class="absolute top-0 left-0 w-full h-full object-cover aspect-square"
                       onerror="this.parentNode.removeChild(this)"
                       :src="isDirectMessage ? dmUser.avatar : chat.image" />
                </div>
            </div>
        </div>

        <div class="flex flex-col w-full justify-between text-white border-b border-brand-dark-gray space-y-2">

            <div class="flex flex-col" :class="{
                    'blur-xs' : blur
                }">
                <div v-if="!isDirectMessage" class="flex text-xl font-medium my-auto">{{ chat.name }}</div>
                <div v-if="isDirectMessage" class="flex text-xl font-medium my-auto">{{ dmUser.name }}</div>
                <div v-if="!chat.latest_message"
                     class="flex text-base text-brand-light-gray line-clamp-2 mb-4">No messages yet,
                    make the first move...
                </div>
            </div>

            <div v-if="chat.latest_message" class="flex w-full justify-between pb-4 my-auto">
                <div class="flex w-full flex-col relative" :class="{
                    'blur-xs' : blur
                }">
                    <div class="text-sm text-brand-light-gray line-clamp-2">
                        <strong class="text-sm text-white font-medium">{{
                            chat.latest_message.posted_by.is_auth_user ? 'You' : chat.latest_message.posted_by.name
                            }}:</strong>
                        {{ chat.latest_message.original_message }}
                    </div>
                </div>
                <div class="flex flex-col mt-auto space-y-1">
                    <div class="flex mt-auto my-auto text-sm text-brand-light-gray">
                        {{ messageTime }}
                    </div>
                    <div v-if="chat.unseen_count"
                         class="ml-auto flex my-auto text-sm text-brand-black w-5 h-5 bg-brand-lime rounded-full">
                        <div class="m-auto border-transparent text-xs font-semibold">
                            {{ chat.unseen_count }}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script setup lang="ts">

import {computed, PropType} from "vue";
import {DateTime} from "luxon";
import {ChatInterface} from "@/interfaces/ChatInterface";

const props = defineProps({
    chat: {
        type: Object as PropType<ChatInterface>,
        required: true
    },
    blur: {
        type: Boolean,
        default: false
    }
})

const messageTime = computed(() => {
    const dateTime = DateTime.fromISO(props.chat?.latest_message.created_at)

    // is today
    if (DateTime.now().toISODate() === dateTime.toISODate()) return dateTime.toFormat('HH:mm')

    // is yesterday
    if (DateTime.now().minus({day: 1}).toISODate() === dateTime.toISODate()) return 'Yesterday'

    return dateTime.toFormat('EEE')
})


const isDirectMessage = computed(() => {
    return !props.chat?.participants.find(participant => participant.role === 'ADMIN') && props.chat?.participants.length > 1
})

const dmUser = computed(() => {
  if (!props.chat?.participants.find(participant => !participant.is_auth_user)) console.log(props.chat)
    return props.chat?.participants.find(participant => !participant.is_auth_user)
})
</script>