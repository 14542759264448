<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <div class="flex w-full -mt-0.5">
          <div class="flex flex-col w-full -mt-4">
            <ion-segment @ionChange="tabChanged" :value="tab" class="relative z-50 w-full"
                         :swipe-gesture="false" mode="ios">
              <ion-segment-button value="nearby">
                <ion-label class="flex space-x-1 py-0.5">
                  <svg class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                        d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
                  </svg>
                </ion-label>
              </ion-segment-button>
              <ion-segment-button value="clubs">
                <ion-label class="flex space-x-1  py-0.5">
                  <svg class=" h-5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                        d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z"/>
                  </svg>
                </ion-label>
              </ion-segment-button>

              <ion-segment-button value="hosts">
                <ion-label class="flex space-x-1  py-0.5">
                  <svg class=" h-5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M155.6 17.3C163 3 179.9-3.6 195 1.9L320 47.5l125-45.6c15.1-5.5 32 1.1 39.4 15.4l78.8 152.9c28.8 55.8 10.3 122.3-38.5 156.6L556.1 413l41-15c16.6-6 35 2.5 41 19.1s-2.5 35-19.1 41l-71.1 25.9L476.8 510c-16.6 6.1-35-2.5-41-19.1s2.5-35 19.1-41l41-15-31.3-86.2c-59.4 5.2-116.2-34-130-95.2L320 188.8l-14.6 64.7c-13.8 61.3-70.6 100.4-130 95.2l-31.3 86.2 41 15c16.6 6 25.2 24.4 19.1 41s-24.4 25.2-41 19.1L92.2 484.1 21.1 458.2c-16.6-6.1-25.2-24.4-19.1-41s24.4-25.2 41-19.1l41 15 31.3-86.2C66.5 292.5 48.1 226 76.9 170.2L155.6 17.3zm44 54.4l-27.2 52.8L261.6 157l13.1-57.9L199.6 71.7zm240.9 0L365.4 99.1 378.5 157l89.2-32.5L440.5 71.7z"/></svg>
                </ion-label>
              </ion-segment-button>

              <ion-segment-button v-if="!user.app_reviewer" value="tags">
                <ion-label class="flex space-x-1  py-0.5">
                  <svg class="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                        d="M181.3 32.4c17.4 2.9 29.2 19.4 26.3 36.8L197.8 128h95.1l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3s29.2 19.4 26.3 36.8L357.8 128H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H347.1L325.8 320H384c17.7 0 32 14.3 32 32s-14.3 32-32 32H315.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8l9.8-58.7H155.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8L90.2 384H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l21.3-128H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3zM187.1 192L165.8 320h95.1l21.3-128H187.1z"/>
                  </svg>
                </ion-label>
              </ion-segment-button>
              <ion-segment-button value="user">
                <ion-label class="flex space-x-2 py-0.5">
                  <svg class="fill-current" width="18" height="19" viewBox="0 0 18 19"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.5 4.5C4.5 6.981 6.519 9 9 9C11.481 9 13.5 6.981 13.5 4.5C13.5 2.019 11.481 0 9 0C6.519 0 4.5 2.019 4.5 4.5ZM17 19H18V18C18 14.141 14.859 11 11 11H7C3.14 11 0 14.141 0 18V19H17Z"/>
                  </svg>
                </ion-label>
              </ion-segment-button>
            </ion-segment>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <nearby-list v-if="tab === 'nearby'"/>
    <nearby-list v-if="tab === 'clubs'" account-type="club"/>
    <nearby-list v-if="tab === 'hosts'" account-type="host"/>
    <search-tags v-if="tab==='tags'"/>
    <ion-content v-if="tab==='user'" :force-overscroll="false">
      <div v-show="tab === 'user'"
           :class="{
                    'h-full' : peopleLoading || !searchResults.length || !search
                 }"
           class="flex flex-col w-full">

        <div class="px-4 py-4 w-full sticky bg-brand-black top-0 z-50  border-b border-brand-dark-gray">
          <input-field
              placeholder="Search by name"
              :clear-input="true"
              :debounce="300"
              v-model="search">
            <template v-slot:icon-start>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21 21L16.514 16.506L21 21ZM19 10.5C19 12.7543 18.1045 14.9163 16.5104 16.5104C14.9163 18.1045 12.7543 19 10.5 19C8.24566 19 6.08365 18.1045 4.48959 16.5104C2.89553 14.9163 2 12.7543 2 10.5C2 8.24566 2.89553 6.08365 4.48959 4.48959C6.08365 2.89553 8.24566 2 10.5 2C12.7543 2 14.9163 2.89553 16.5104 4.48959C18.1045 6.08365 19 8.24566 19 10.5V10.5Z"
                    stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </template>
          </input-field>
        </div>

        <div v-if="!searchResults.length && search && !peopleLoading" class="flex h-full w-full">
          <div class="flex px-4 py-5 m-auto text-white">
            <div class="flex flex-col items-center space-y-6">

              <div class="h-20 w-20 bg-brand-lime text-brand-black rounded-full flex">
                <div class="m-auto">
                  <svg  class="w-7 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
                </div>
              </div>

              <div class="flex flex-col w-full items-center space-y-0 px-2">
                <div class="flex text-white text-2xl font-medium text-center">Sad times 🙁</div>
                <div class="text-brand-light-gray text-lg text-center">
                  We couldn't find any users that matched your search, if they're not on Pineapple yet. Give them a nudge!
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!search" class="flex h-full w-full">
          <div class="flex px-4 py-5 m-auto text-white">
            <div class="flex flex-col items-center space-y-6">

              <div class="h-20 w-20 bg-brand-lime text-brand-black rounded-full flex">
                <div class="m-auto">
                  <svg  class="w-7 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
                </div>
              </div>

              <div class="flex flex-col w-full items-center space-y-0 px-2">
                <div class="flex text-white text-2xl font-medium text-center">Find people you know</div>
                <div class="text-brand-light-gray text-lg text-center">
                  Find friends and acquaintances you've met at clubs, meets and events.
                </div>
              </div>
            </div>
          </div>
        </div>

        <svg v-if="peopleLoading" class="animate-spin h-6 w-6 m-auto text-white" xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>

        <div v-if="!peopleLoading" class="flex px-4 py-2 pt-4">
          <div v-if="search.length" class="flex flex-col space-y-4 overflow-hidden">
            <user-list-item
                @click="viewUserProfile(user.id)"
                v-for="user in searchResults"
                :key="user.id"
                :user="user"/>
          </div>
        </div>
      </div>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import {
  IonContent, IonHeader, IonLabel,
  IonPage, IonRange, IonRefresher, IonRefresherContent, IonSegment, IonSegmentButton, IonSkeletonText, IonToolbar,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, inject, onMounted, reactive, Ref, ref, watch} from 'vue';
import {UserInterface} from "@/interfaces/UserInterface";
import ApiService from "@/services/api/apiService";
import InputField from "@/components/InputField.vue";
import _ from "lodash";
import {Geolocation, Position} from "@capacitor/geolocation";
import pluralize from "pluralize";
import UserListItem from "@/components/UserListItem.vue";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import SearchTags from "@/components/SearchTags.vue";
import NearbyList from "@/components/NearbyList.vue";

export default defineComponent({
  name: 'ProfilePage',
  components: {
    NearbyList,
    SearchTags,
    UserListItem,
    IonSegment, IonSegmentButton, IonLabel,
    InputField,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
  },
  setup() {

    const nearbyLoading = ref(true)
    const peopleLoading = ref(false)

    const user: Ref<UserInterface|any> = inject('user') as Ref<UserInterface | undefined>
    const ionRouter = useIonRouter()

    const search = ref('')
    const searchResults: Ref<UserBasicInformationInterface[]> = ref([])
    const searchResultsCopy: Ref<UserBasicInformationInterface[]> = ref([])

    watch(() => search.value, async query => {
      peopleLoading.value = true
      searchResults.value = await ApiService.Search().users(query)
      searchResultsCopy.value = _.cloneDeep(searchResults.value)
      peopleLoading.value = false
    })

    const viewUserProfile = async (userId: number) => {
      ionRouter.navigate({
        name: 'PineappleSearchUserProfile',
        params: {
          userId
        }
      }, 'forward', 'push')
    }


    const tab = ref('nearby')
    const tabChanged = (event: any) => {
      tab.value = event.detail.value
    }

    const currentLocation = reactive({
      lat: 0,
      lng: 0
    })

    onMounted(async () => {
      await getCurrentLocation()
      await getNearbyResults()
    })

    const nearbyRadius = ref(40)
    const nearbyResults: Ref<UserBasicInformationInterface[]> = ref([])
    const currentPosition: Ref<Position | undefined> = ref()
    const getCurrentLocation = async () => {
      currentPosition.value = await Geolocation.getCurrentPosition();
    }

    const getNearbyResults = async () => {
      if (!currentPosition.value) return
      nearbyLoading.value = true
      await getCurrentLocation()
      nearbyResults.value = await ApiService.Search().nearby(currentPosition.value.coords.latitude, currentPosition.value.coords.longitude, nearbyRadius.value)
      nearbyLoading.value = false
    }

    const refreshNearby = async (ev: any) => {
      await getCurrentLocation()
      await getNearbyResults()
      ev.target.complete()
    }


    const radiusLabel = computed(() => {
      return `${nearbyRadius.value} ${pluralize('mile', nearbyRadius.value)} away`
    })

    return {
      user,
      nearbyLoading,
      peopleLoading,
      search,
      searchResults,
      nearbyResults,
      refreshNearby,
      viewUserProfile,
      tabChanged,
      tab,
      nearbyRadius,
      radiusLabel
    }

  }
});
</script>
<style scoped>
ion-toolbar {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --padding-bottom: 1px !important;
}
</style>
