<template>
  <ion-page>

    <ion-header>
      <ion-toolbar>
        <ion-buttons class="flex" slot="start">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title>
          <div class="text-2xl text-white font-medium">Friends</div>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="h-full">

      <ion-refresher slot="fixed" @ionRefresh="pullToRefresh($event)">
        <ion-refresher-content pulling-icon="crescent" refreshing-spinner="crescent"></ion-refresher-content>
      </ion-refresher>

      <ion-segment
          @ionChange="tabChanged($event)"
          :value="tab"
          class="relative z-50 backdrop-blur-2xl bg-opacity-40 w-full sticky top-0"
          :swipe-gesture="false"
          mode="ios">
        <ion-segment-button value="friends">
          <ion-label
              class="text-lg font-medium">
            Friends
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="requests">
          <ion-label
              class="text-lg flex space-x-2 font-medium">
            <div>Requests</div>
            <div v-if="friendRequests.length" class=" my-auto">
              <div class="w-4 h-4 bg-brand-lime flex rounded-full">
                <div class="m-auto text-xxs text-brand-black leading-normal font-medium">{{ friendRequests.length }}
                </div>
              </div>
            </div>
          </ion-label>
        </ion-segment-button>
      </ion-segment>

      <div class="flex h-full relative z-40">

        <svg v-if="loading" class="animate-spin h-10 w-10 m-auto text-white" xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>

        <div v-if="getYourWinkOn && !loading" class="flex px-4 py-5 m-auto text-white">
          <div class="flex flex-col items-center space-y-6">
            <svg width="75" height="74" viewBox="0 0 75 74" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M37.5006 0C17.2891 0 0.845703 16.4434 0.845703 36.6549C0.845703 56.8664 17.2891 73.3098 37.5006 73.3098C57.7121 73.3098 74.1555 56.8664 74.1555 36.6549C74.1555 16.4434 57.7121 0 37.5006 0Z"
                  fill="#EBFF00"/>
              <path
                  d="M57.344 45.3167C58.4435 46.0038 58.7864 47.4604 57.9969 48.4889C55.9488 51.1572 53.3663 53.3809 50.4055 55.0124C46.7574 57.0225 42.6561 58.0668 38.4909 58.0461C34.3257 58.0254 30.235 56.9404 26.6071 54.8941C23.6626 53.2332 21.1024 50.984 19.0809 48.2954C18.3017 47.2591 18.659 45.806 19.7653 45.1298C20.8716 44.4537 22.3061 44.8129 23.1088 45.8312C24.6966 47.8454 26.6667 49.5371 28.9137 50.8045C31.8446 52.4577 35.1494 53.3342 38.5142 53.3509C41.8791 53.3677 45.1924 52.524 48.1396 50.9001C50.3991 49.6551 52.3859 47.9831 53.9937 45.9847C54.8064 44.9745 56.2445 44.6295 57.344 45.3167Z"
                  fill="#1A1B22"/>
              <path
                  d="M24.5105 40.1582C25.6758 40.2425 26.5724 41.2661 26.2606 42.392C26.0533 43.1407 25.7645 43.8666 25.3979 44.5563C24.6993 45.8709 23.7338 47.0251 22.5633 47.9449C21.3928 48.8648 20.043 49.53 18.6006 49.8981C17.8437 50.0912 17.0701 50.2003 16.2937 50.2247C15.1259 50.2615 14.3434 49.1483 14.5369 47.9961C14.7304 46.8439 15.8401 46.1029 16.9933 45.9156C17.1817 45.8849 17.3689 45.846 17.5545 45.7986C18.4262 45.5762 19.2417 45.1742 19.949 44.6184C20.6563 44.0625 21.2397 43.3651 21.6619 42.5708C21.7518 42.4016 21.834 42.2289 21.9082 42.0531C22.3629 40.9768 23.3453 40.0738 24.5105 40.1582Z"
                  fill="#1A1B22"/>
              <path
                  d="M32.9536 30.0211C33.9533 29.3009 34.1629 27.8739 33.2204 27.0804C32.4583 26.4388 31.6057 25.8977 30.6849 25.4747C29.0674 24.7316 27.287 24.3744 25.4949 24.4336C23.7028 24.4927 21.9521 24.9664 20.3917 25.8144C19.5056 26.2959 18.6958 26.89 17.9827 27.5781C17.0949 28.4348 17.4025 29.8458 18.45 30.4975C19.4438 31.1157 20.7341 30.7992 21.6606 30.0839C21.9433 29.8657 22.2449 29.669 22.5627 29.4963C23.5056 28.9839 24.5635 28.6977 25.6463 28.6619C26.7292 28.6262 27.8051 28.842 28.7824 29.291C29.1166 29.4445 29.4358 29.6237 29.7373 29.8263C30.7047 30.4766 32.0077 30.7024 32.9536 30.0211Z"
                  fill="#1A1B22"/>
              <path
                  d="M51.5546 32.8109C54.0696 32.8109 56.1406 30.849 56.1406 28.39C56.1406 25.9309 54.0696 23.9691 51.5546 23.9691C49.0396 23.9691 46.9686 25.9309 46.9686 28.39C46.9686 30.849 49.0396 32.8109 51.5546 32.8109Z"
                  fill="#1A1B22" stroke="#1A1B22" stroke-width="0.916372"/>
            </svg>

            <div class="flex flex-col w-full items-center space-y-4 px-2">
              <div class="flex text-white text-2xl font-medium text-center">Hey sweet stuff, looks like
                you need to get
                your wink on!
              </div>
              <div class="text-brand-light-gray text-lg text-center">A connection could be just around the corner.<br/>Keep
                winking!
              </div>
            </div>
          </div>
        </div>

        <div v-if="!getYourWinkOn && !loading" class="flex w-full flex-col px-4 py-5 space-y-6">

          <div v-if="friendRequests && friendRequests.length && tab === 'requests'"
               class="flex flex-col w-full space-y-4">
            <div class="flex flex-col w-full space-y-4">
              <friend-list-item
                  v-for="(user,userIndex) in friendRequests"
                  :key="userIndex"
                  :user="user"/>
            </div>
          </div>

          <div v-if="friends && friends.length && tab === 'friends'" class="flex flex-col w-full space-y-4">
            <div class="flex flex-col w-full space-y-4">
              <friend-list-item
                  v-for="(user,userIndex) in friends"
                  :key="userIndex"
                  :user="user"/>
            </div>
          </div>


        </div>


      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonButton, IonButtons,
  IonContent, IonHeader, IonLabel,
  IonPage, IonRefresher, IonRefresherContent, IonSegment, IonSegmentButton, IonTitle,
  IonToolbar, onIonViewWillEnter, useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, onUnmounted, Ref, ref} from 'vue';
import VerifiedStatusCard from "@/components/VerifiedStatusCard.vue";
import {FriendUserInterface} from "@/interfaces/FriendUserInterface";
import ApiService from "@/services/api/apiService";
import FriendListItem from "@/components/FriendListItem.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import InputField from "@/components/InputField.vue";
import {useRouter} from "vue-router";


const tab = ref('friends')

const loading = ref(true)
const observer = ref()
const friends: Ref<FriendUserInterface[]> = ref([])
const friendRequests: Ref<FriendUserInterface[]> = ref([])

const search = ref('')

const getData = async () => {

  const [i, ii]: any = await Promise.allSettled([await getFriendRequests(), await getFriends()])
  friendRequests.value = i.value
  friends.value = ii.value
  loading.value = false
}

const getFriends = async () => {
  return await ApiService.User().getFriends()
}

const getFriendRequests = async () => {
  return await ApiService.User().getFriendRequests()
}

const pullToRefresh = async (ev: any) => {
  await getData()
  ev.target.complete()
}

const getYourWinkOn = computed(() => {
  return !friends.value.length && !friendRequests.value.length;
})

onIonViewWillEnter(async () => {
  await getData()
})

onMounted(() => {

  observer.value = Observable.subscribe(async (response: any) => {
    if (response.event === ObservableEvent.FriendsChanged) {
      await getData()
    }
  })

})

const tabChanged = (event: any) => {
  tab.value = event.detail.value
}

onUnmounted(() => {
  observer.value?.unsubscribe()
})

const ionRouter = useIonRouter()

const goBack = async () => {
  await ionRouter.back()
}

</script>