<template>


  <div class="flex w-full h-full">

    <div class="flex flex-col mt-auto w-full pt-14 space-y-10">
      <div class="flex flex-col w-full space-y-14">

        <div v-if="!storedPinError" class="flex flex-col space-y-1">
          <div class="text-white text-xl font-semibold w-full text-center">PIN</div>
          <div class="text-brand-light-gray text-sm w-full text-center">Please enter your PIN</div>
        </div>

        <div v-if="storedPinError" class="flex flex-col space-y-1">
          <div class="animate-wiggle text-red-600 text-xl font-semibold w-full text-center">Incorrect PIN!</div>
          <div class="text-brand-light-gray text-sm w-full text-center">Try again.</div>
        </div>

      </div>

      <div class="flex w-full justify-around px-10">
        <div
            :key="index"
            v-for="(i,index) in [1,2,3,4]"
            :class="{
                    'bg-brand-lime !scale-150' : (pin.length-1) >= index,
                  }"
            class="w-3 h-3 scale-100 bg-brand-dark-gray rounded-full transition-all transition-transform"></div>
      </div>

      <div class="safe-area-bottom grid grid-cols-3 px-5 justify-evenly w-full items-center justify-center my-auto">
        <ion-button
            :key="keyIndex"
            @click="keyPressed(keyIndex)"
            v-for="(key,keyIndex) in pad" class="text-center h-16 flex">
          <div class="m-auto font-bold text-white" v-html="key"></div>
        </ion-button>
      </div>


    </div>
  </div>

</template>

<script setup lang="ts">
import {
  IonButton, modalController, useIonRouter,
} from '@ionic/vue';
import {computed, onMounted, onUnmounted, Ref, ref, watch} from "vue";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {Haptics, ImpactStyle} from "@capacitor/haptics";
import {UserInterface} from "@/interfaces/UserInterface";
import ApiService from "@/services/api/apiService";
import {PluginListenerHandle} from "@capacitor/core";

const pad = [
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '', '0', '&larr;'
]

const pin = ref('')

const ionRouter = useIonRouter()

const keyPressed = async (keyIndex: number) => {

  Haptics.impact({style: ImpactStyle.Medium});

  if (keyIndex === 9) return
  if (keyIndex === 11) {
    return pin.value = pin.value.slice(0, pin.value.length - 1)
  }
  if (pin.value.length === 4) return

  pin.value = pin.value + pad[keyIndex]

}

const close = async () => {
  modalController.dismiss()
}

const user: Ref<UserInterface | undefined> = ref()
const listener: Ref<PluginListenerHandle | undefined> = ref()

onMounted(async () => {
  user.value = await ApiService.User().authUser()
  storedPin.value = (await Preferences.get({key: PreferenceKey.PrivacyPin})).value ?? ''
})

const storedPin = ref('')
const storedPinError = ref(false)

const hasPin = computed(() => {
  return storedPin.value.length
})


watch(() => pin.value, async value => {
  if (hasPin.value) {
    if (value.length === 4 && storedPin.value === value) {
      return modalController.dismiss({validated: true})
    }
    if (value.length === 4 && storedPin.value !== value) {
      storedPinError.value = true
      setTimeout(() => {
        pin.value = ''
      }, 50)
      setTimeout(() => {
        storedPinError.value = false
        pin.value = ''
      }, 1000)
      return
    }
  }
})


</script>
<style scoped>
ion-button {
  --background: transparent;
  --background-activated: #262A34;
  --padding-bottom: 0;
  --padding-top: 0;
  --padding-start: 0;
  --padding-end: 0;
  padding: 0;
  margin: 0;
}
</style>