<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="!p-0 !m-0">
        <ion-buttons class="justify-between flex w-full">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="fill-current text-white w-6 h-6 p-0.5" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 320 512">
              <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                  d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
            </svg>
          </ion-button>
          <div class="flex relative">
            <ion-button
                v-if="!review"
                @click="postReview"
                class="!text-xl">
              <div :class="{
                  'text-brand-light-gray' : !canPost,
                  'text-brand-lime' : canPost
                }">Post
              </div>
            </ion-button>
            <ion-button
                v-if="review"
                @click="updateReview"
                class="!text-xl">
              <div :class="{
                  'text-brand-light-gray' : !canPost,
                  'text-brand-lime' : canPost
                }">Update
              </div>
            </ion-button>

            <div v-if="posting" class="absolute flex top-0 left-0 w-full h-full bg-brand-black z-50">
              <svg class="animate-spin m-auto h-5 w-5 text-brand-lime" xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </div>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>

      <div
          class="bg-brand-black flex w-full p-4 py-5 font-medium text-xl text-white border-b-4 border-brand-dark-gray max-h-80 overflow-scroll">

        <Mentionable
            class="w-full"
            :keys="['@']"
            :items="mentionableUsers"
            offset="6"
            @open="loadUsers()"
            @search="loadUsers($event)"
            filtering-disabled
        >
          <ion-textarea
              auto-grow
              autocapitalize="on"
              v-model="postContent"
              :placeholder="`Leave a ${user.account_type === AccountType.Club ? 'review' : 'validation'} for ${user.name}`"/>

          <template #no-result>
            <div class="text-sm text-white p-3">
              {{ loadingUsers ? 'Loading...' : 'No users found.' }}
            </div>
          </template>

          <template #item-@="{ item }">
            <div class="text-sm">
                @{{ item.value }}
            </div>
          </template>

        </Mentionable>
      </div>

    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTextarea,
  IonToolbar,
  modalController,
  toastController,
} from '@ionic/vue';
import {computed, onMounted, PropType, Ref, ref} from 'vue';
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {usePremium} from "@/composables/usePremium";
import {Mentionable} from 'vue-mention'
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import {UserReviewInterface} from "@/interfaces/UserReviewInterface";
import {AccountType} from "@/definitions/AccountType";

const props = defineProps({
  user: {
    type: Object as PropType<UserBasicInformationInterface>,
    required: true
  },
  review: {
    type: Object as PropType<UserReviewInterface>,
    required: false
  },
})

const loading = ref(true)
const busy = ref(false)
const posting = ref(false)

const postContent = ref('')

onMounted(async () => {

  if (props.review) {
    postContent.value = props.review.original_text
  }

  loading.value = false
})


const goBack = async () => {

  if (busy.value || posting.value) return

  const modal = await alertController.create({
    header: props.review ? 'Discard Changes' : 'Discard Review',
    message: props.review ? 'Are you sure you want to discard any changes to this review?' : 'Are you sure you want to discard this review?',
    buttons: [
      {
        text: 'Yes',
        role: 'destructive',
        handler: () => {
          modalController.dismiss()
        }
      },
      {
        text: 'No',
      },

    ]
  })

  await modal.present()

}


const canPost = computed(() => {
  return postContent.value.length > 3
})

const postReview = async () => {

  if (!canPost.value) return

  try {
    posting.value = true
    await ApiService.User().addReview(props.user.id, postContent.value)
    Observable.emit(ObservableEvent.UserReviewsChanged)
    await modalController.dismiss()
  } catch (e: any) {

    posting.value = false
    if (e.status === 429) {
      return usePremium().presentPayWall({
        text: e.data.message,
        callback: () => {
          //
        }
      });
    }

    const toast = await toastController.create({
      message: e.data.message,
      duration: 3000,
      position: 'bottom',
      color: 'danger'
    });
    await toast.present();
  }
}

const updateReview = async () => {

  if (!canPost.value) return
  if (!props.review) return

  try {
    posting.value = true

    const review: UserReviewInterface = props.review
    review.text = postContent.value

    await ApiService.User().updateReview(review)
    Observable.emit(ObservableEvent.UserReviewsChanged)
    await modalController.dismiss()
    posting.value = false
  } catch (e: any) {
    const toast = await toastController.create({
      message: e.data.message,
      duration: 3000,
      position: 'bottom',
      color: 'danger'
    });
    await toast.present();
    posting.value = false
  }
}

const loadingUsers = ref(false)
const mentionableUsers: Ref<any[]> = ref([])

const mentionableUserId = (name: string) => {
  return (mentionableUsers.value.find((user: UserBasicInformationInterface) => {
    return user.name === name
  })).id
}

const loadUsers = async (searchText = '') => {
  loadingUsers.value = true
  mentionableUsers.value = await fetchUsers(searchText)
  loadingUsers.value = false
}

const fetchUsers = async (search: string) => {
  const results = await ApiService.Search().users(search)
  return results.map((result: UserBasicInformationInterface) => {
    return {
      value: result.name,
      label: result.name
    }
  })
}
</script>