<template>
  <div class="flex flex-col items-center p-5 py-10 pt-7 space-y-10">


    <div class="flex flex-col space-y-6 items-center">

      <div class="flex pt-5">
        <svg class="w-10 text-white fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
          <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
              d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
        </svg>
      </div>

      <div class="flex flex-col w-full items-center space-y-1 px-2">
        <div class="flex text-white text-2xl font-medium text-center">NSFW</div>
        <div class="text-brand-light-gray text-lg text-center">Would you like to uncover all Not Safe For Work photos by default?
          You can disable this again at any time.
        </div>
      </div>

      <button
          @click="enable"
          class=" mx-auto w-full flex bg-brand-lime rounded-full px-8 h-12 font-medium text-xl text-brand-black transition-all">
        <div class="flex m-auto space-x-2.5">
          <div v-if="!busy" class="my-auto pt-0.5">
            <div>Enable</div>
          </div>
          <div v-else class="flex">
            <svg class="animate-spin h-5 w-5 text-brand-black" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </div>
      </button>

    </div>

  </div>
</template>

<script setup lang="ts">

import {ref} from 'vue';
import {modalController} from "@ionic/vue";
import ApiService from "@/services/api/apiService";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

const busy = ref(false)


const enable = async () => {
  if (busy.value) return
  busy.value = true
  await ApiService.User().setPreference(PreferenceKey.FeedShowXRated, '1')
  await Observable.emit(ObservableEvent.UserChanged)
  await Observable.emit(ObservableEvent.FeedShowXRated)
  busy.value = false
  await modalController.dismiss()
}

const cancel = async () => {
  await modalController.dismiss()
}

</script>