<template>


  <div class="w-full h-full flex flex-col">
    <div v-if="loading" class="flex w-full h-full">
      <svg class="animate-spin h-10 w-10 m-auto text-white" xmlns="http://www.w3.org/2000/svg"
           fill="none"
           viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>

    <div v-if="!loading && !feed.length" class="flex h-full w-full">
      <div class="flex px-4 py-5 m-auto text-white">
        <div class="flex flex-col items-center space-y-6">

          <div class="h-20 w-20 bg-brand-lime text-brand-black rounded-full flex">
            <div class="m-auto">
              <svg class="w-10 fill-current" height="22" viewBox="0 0 21 20"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M5.5 0C4.94772 0 4.5 0.447715 4.5 1V2H3.5C1.84315 2 0.5 3.34315 0.5 5V17C0.5 18.6569 1.84315 20 3.5 20H17.5C19.1569 20 20.5 18.6569 20.5 17V5C20.5 3.34315 19.1569 2 17.5 2H16.5V1C16.5 0.447715 16.0523 0 15.5 0C14.9477 0 14.5 0.447715 14.5 1V2H6.5V1C6.5 0.447715 6.05228 0 5.5 0ZM7.5 11C7.5 10.4477 7.94772 10 8.5 10H15.5C16.0523 10 16.5 10.4477 16.5 11C16.5 11.5523 16.0523 12 15.5 12H8.5C7.94772 12 7.5 11.5523 7.5 11ZM4.5 15C4.5 14.4477 4.94772 14 5.5 14H11.5C12.0523 14 12.5 14.4477 12.5 15C12.5 15.5523 12.0523 16 11.5 16H5.5C4.94772 16 4.5 15.5523 4.5 15Z"/>
              </svg>
            </div>
          </div>

          <div class="flex flex-col w-full items-center space-y-0 px-2">
            <div class="flex text-white text-2xl font-medium text-center">Events</div>
            <div class="text-brand-light-gray text-lg text-center">
              There's currently no upcoming events.<br />Check back soon!
            </div>
          </div>
        </div>
      </div>
    </div>
    <DynamicScroller
        v-if="isPlatform('android') && feed.length"
        :buffer="200"
        ref="scrollContainer"
        :items="feed"
        :min-item-size="600"
        class="ion-content-scroll-host scroller flex w-full"
    >

      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
            :item="item"
            :active="active"
            :data-index="index"
            class="w-full"
        >
          <event-card
              class="mb-4"
              @click="viewEvent(item)"
              :layout="layout" :event="item"/>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>

    <virtual-list v-if="(isPlatform('ios') || isPlatform('desktop')) && feed.length"
                  class="list-dynamic ion-content-scroll-host"
                  :keeps="8"
                  data-key="id"
                  :data-sources="feed"
                  :estimate-size="600"
                  ref="scrollContainer"
    >
      <template #={source}>
        <event-card
            class="mb-4"
            :layout="layout"
            @click="viewEvent(source)"
            :event="source"/>
      </template>
    </virtual-list>
  </div>

</template>
<script setup lang="ts">

import {isPlatform, useIonRouter} from "@ionic/vue";
import {nextTick, onMounted, onUnmounted, onUpdated, PropType, Ref, ref} from "vue";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import EventCard from "@/components/EventCard.vue";
import {EventInterface} from "@/interfaces/EventInterface";
import {EventFilterIs} from "@/definitions/EventFilterIs";


const props = defineProps({
  is: {
    type: String as PropType<EventFilterIs>,
    required: false
  },
  layout: {
    type: String as PropType<'card' | 'list'>,
    default: 'card'
  }
})

const pagination = ref()

const user: Ref<UserInterface | undefined> = ref()
const loading = ref(true)
const busy = ref(false)

const feed: Ref<(EventInterface | any)[]> = ref([])

const showInfiniteScrollBottom = ref(true)

const pullToRefresh = async (ev: any) => {
  await getData()
  showInfiniteScrollBottom.value = true
  Observable.emit(ObservableEvent.PendingEventResponsesChanged)
  if (ev) ev.target.complete()
}

const getData = async (page = 1) => {

  if (busy.value) return
  busy.value = true

  const response = await ApiService.Events().paginate(page, props.is)
  pagination.value = response.meta

  if (page === 1) {
    feed.value = response.data
  }

  if (page > 1 && response.data.length) {
    feed.value = [...feed.value, ...response.data]
  }

  busy.value = false

}

const observer = ref()

onMounted(async () => {

  user.value = await ApiService.User().authUser()

  observer.value = Observable.subscribe(async (response: any) => {
    if (
        (response.event === ObservableEvent.PullToRefreshExplore && !props.is) ||
        (response.event === ObservableEvent.PullToRefreshInterested && props.is === EventFilterIs.Interested) ||
        (response.event === ObservableEvent.PullToRefreshAttending && props.is === EventFilterIs.Attending) ||
        (response.event === ObservableEvent.PullToRefreshMyEvents && props.is === EventFilterIs.MyEvents)
    ) {
      await pullToRefresh(response.data)
    }
  })

  observer.value = Observable.subscribe(async (response: any) => {

    if (response.event === (ObservableEvent.RefreshFeed || ObservableEvent.EventsChanged) && !feed.value.length && !response.data) {
      await getData()
    }

    if (response.event === ObservableEvent.EventsScrollTop) {
      if (isPlatform('ios')) {
        scrollContainer.value.$el.scrollTo({top: 0, behavior: 'smooth'})
      } else {
        scrollContainer.value.$el.scrollTop = 0
      }
      await getData()
    }
  })

  await getData()
  loading.value = false

  setContainerHeight()
  addContainerEventListeners()

})

const addContainerEventListeners = () => {
  scrollContainer.value.$el.addEventListener("scroll", () => {
    handleScroll()
  });
}

onUnmounted(() => {
  observer.value?.unsubscribe()
})

const setContainerHeight = () => {
  const containerHeight = scrollContainer.value.$el.closest('ion-content')?.clientHeight
  if (containerHeight === 0) return
  scrollContainer.value.$el.style.height = `${containerHeight}px`
}

onUpdated(async () => {
  await nextTick()
})

const handleScroll = async () => {
  const threshold = 1500; // Adjust this threshold as needed
  const containerHeight = scrollContainer.value.$el.closest('ion-content')?.clientHeight
  const isScrolledToBottom = scrollContainer.value.$el.scrollTop + containerHeight >= scrollContainer.value.$el.scrollHeight - threshold;
  if (isScrolledToBottom && pagination.value.current_page < pagination.value.last_page) await getData(pagination.value.current_page + 1)
}

const scrollContainer: Ref<any> = ref(null)
const ionRouter = useIonRouter()

const viewEvent = async (event: EventInterface) => {
  ionRouter.navigate({
    name: 'Event',
    params: {
      eventId: event.id
    }
  }, 'forward', 'push')
}


</script>
