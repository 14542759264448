import Axios, {AxiosInstance} from "axios";


export default class ApiClient {

    axios: AxiosInstance;

    public errorHandler = (response: Record<string, unknown>) => {
        return Promise.reject(`API Client Error: ${response.status}`);
    }

    public tokenProvider = function (): Promise<unknown> {
        return new Promise<void>((reject => {
            console.log('No token provider defined');
            reject();
        }));
    }

    constructor(headers: Record<string, unknown> = {}) {

        headers = {
            ...headers,
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json'
        }

        this.axios = Axios.create({
            headers: headers
        });

    }

    setBaseUrl(baseUrl: string): void {
        this.axios.defaults.baseURL = baseUrl;
    }

    setHeader(headerName: string, headerValue: string): void {
        this.axios.defaults.headers[headerName] = headerValue;
    }

    get(url: string, parameters: Record<string, unknown> = {}, token = true): Promise<any> {
        return this._call('get', url, {params: parameters});
    }

    post(url: string, data: any, token = true): Promise<any> {
        return this._call('post', url, data, token);
    }

    put(url: string, data: any,  token = true): Promise<any> {
        return this._call('put', url, data, token);
    }

    patch(url: string, data: any, token = true): Promise<any> {
        return this._call('patch', url, data, token);
    }

    delete(url: string, data: any,  token = true): Promise<any> {
        return this._call('delete', url, data, token);
    }

    async upload(url: string, data: any, onProgress?: any) {
        const token = await this.tokenProvider()
        this.setHeader('Authorization', `Bearer ${token}`);
        this.setHeader('Content-Type', 'multipart/form-data')
        return this.axios.post( url,
            data,
            {
                onUploadProgress: function( progressEvent: any ) {
                    if (onProgress) onProgress(Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
                }.bind(this)
            }
        )
    }
    

    async _call(method: string, url: string, data: any, requiresToken = true): Promise<any> {

        if (requiresToken) {
            const token = await this.tokenProvider()
            this.setHeader('Authorization', `Bearer ${token}`);
        }

        if (method === 'post') {
            this.setHeader('Content-Type', 'multipart/form-data')
        }

        if (method === 'delete') {

            return this.axios.delete(url, {data: data})
                .then(
                    (response: any) => {
                        return Promise.resolve(response.data);
                    },
                    (error: any) => {
                        return this.errorHandler(error.response);
                    }
                );

        } else {

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return this.axios[method](url, data)
                .then(
                    (response: any) => {
                        return Promise.resolve(response.data);
                    },
                    (error: any) => {
                        return this.errorHandler(error.response);
                    }
                );

        }


    }

    public registerErrorHandler(callback: any): void {
        this.errorHandler = callback;
    }

    public registerTokenProvider(callback: any): void {
        this.tokenProvider = callback;
    }

}
