<template>
  <ion-page>
    <ion-content>
      <div class="flex flex-col w-full bg-brand-black mb-0 mt-auto h-full">
        <div class="flex flex-col mt-auto items-center w-full px-6 space-y-14">

          <div class="flex flex-col items-center space-y-5">
            <logo-mark/>
            <div class="flex flex-col w-full space-y-2 text-center">
              <div class="flex mx-auto text-white text-2xl font-medium">Let's get to know each other</div>
              <div class="text-brand-light-gray text-lg">What type of account would you like to create?</div>
            </div>
          </div>

          <div class="flex w-full flex-col space-y-2">
            <input-checkbox
                v-for="(a,aIndex) in accountTypes"
                :key="aIndex"
                name="accountType"
                v-model="accountType"
                :return-single-value="true"
                :option="a"
            />
          </div>

          <div class="flex w-full px-5 text-brand-light-gray text-center items-center">
            <div class="w-full text-center" v-if="accountType[0] === AccountType.Individual">For singles or couples who would like their own profile. Profiles can be linked together so you are presented as a couple to the community.</div>
            <div class="w-full text-center" v-if="accountType[0] === AccountType.Joint">Perfect for couples who'd like to share a single account and profile, your profile is based on your collective desires and interests.</div>
            <div class="w-full text-center" v-if="accountType[0] === AccountType.Club">For clubs and businesses.</div>
            <div class="w-full text-center" v-if="accountType[0] === AccountType.Host">For event organisers.</div>
          </div>

          <div
              class="flex flex-col space-y-2 w-full items-center">
            <button
                @click="forward"
                :class="{
                  'bg-opacity-40' : !canProgress
                }"
                class="flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
              <div class="flex m-auto space-x-2.5">
                <div class="my-auto pt-0.5">
                  <div>Continue</div>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div class="safe-area-bottom"></div>
      </div>


    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {IonContent, IonPage, useIonRouter} from '@ionic/vue';
import LogoMark from "@/components/LogoMark.vue";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {AccountType} from "@/definitions/AccountType";
import ApiService from "@/services/api/apiService";
import InputCheckbox from "@/components/InputCheckbox.vue";
import {computed, ref} from "vue";
import {Preferences} from "@capacitor/preferences";

const ionRouter = useIonRouter()

const accountType = ref([])

const busy = ref(false)

const forward = async () => {
  if (busy.value) return
  busy.value = true
  const key = PreferenceKey.OnboardingAccountType
  const value = accountType.value[0]
  try {
    await Preferences.set({
      key,
      value
    })
    await ionRouter.navigate('/account/onboarding/name', 'forward', 'push')
    await ApiService.User().setPreference(key, value)
  } catch (e) {
    //
    console.log(e)
  } finally {
    busy.value = false
  }
}

const accountTypes = [
  {
    text: 'Individual',
    value: AccountType.Individual,
  },
  {
    text: 'Joint',
    value: AccountType.Joint,
  },
  {
    text: 'Club',
    value: AccountType.Club,
  },
  {
    text: 'Host',
    value: AccountType.Host,
  }
]

const canProgress = computed(() => {
  return accountType.value.length === 1
})

</script>