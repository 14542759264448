<template>
    <ion-page>
        <ion-header>
            <ion-toolbar class="!p-0 !m-0">
                <ion-buttons class="justify-between flex w-full">
                    <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
                        <svg class="fill-current text-white w-6 h-6 p-0.5" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 320 512">
                            <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path
                                    d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                        </svg>
                    </ion-button>
                    <div class="flex relative">
                        <ion-button
                                @click="updatePost"
                                class="!text-xl">
                            <span :class="{
                  'text-brand-light-gray' : !canPost,
                  'text-brand-lime' : canPost
                }">Update</span>
                        </ion-button>

                        <div v-if="posting" class="absolute flex top-0 left-0 w-full h-full bg-brand-black z-50">
                            <svg class="animate-spin m-auto h-5 w-5 text-brand-lime" xmlns="http://www.w3.org/2000/svg"
                                 fill="none"
                                 viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                    </div>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <div
                    class="bg-brand-black flex w-full p-4 py-5 font-medium text-xl text-white border-b-4 border-brand-dark-gray max-h-96 overflow-scroll">
                <ion-textarea
                        auto-grow
                        autocapitalize="on"
                        v-model="postContent"
                        placeholder="Comment..."/>
            </div>

        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import {
    actionSheetController,
    alertController,
    IonButton,
    IonButtons,
    IonContent, IonHeader,
    IonPage,
    IonTextarea, IonToolbar,
    modalController, toastController,
    useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, PropType, Ref, ref} from 'vue';
import {useRoute} from "vue-router";
import InputCheckbox from "@/components/InputCheckbox.vue";
import {PostPrivacy} from "@/definitions/PostPrivacy";
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";

export default defineComponent({
    name: 'FeedNewPostPage',
    methods: {useRoute},
    props: {
        comment: {
            type: Object as PropType<FeedPostInterface>,
            required: false
        }
    },
    components: {
        IonToolbar,
        IonHeader,
        IonPage,
        IonContent,
        IonButtons,
        IonButton,
        IonTextarea,
    },
    setup(props) {

        const loading = ref(true)
        const busy = ref(false)
        const posting = ref(false)

        const postContent = ref('')

        onMounted(async () => {
            await reset()

            console.log(props.comment)

            if (props.comment) {
                postContent.value = props.comment.data.original_content
            }

            loading.value = false
        })

        const reset = async () => {
            postContent.value = '',
            posting.value = false
            busy.value = false
        }
        const goBack = async () => {

            if (busy.value || posting.value) return

            const modal = await alertController.create({
                header: 'Discard',
                message: 'Are you sure you discard any changes to this comment?',
                buttons: [
                    {
                        text: 'Yes',
                        role: 'destructive',
                        handler: () => {
                            modalController.dismiss()
                            reset()
                        }
                    },
                    {
                        text: 'No',
                    },

                ]
            })

            await modal.present()

        }

        const canPost = computed(() => {
            return Number(postContent.value.length) > 0
        })

        const updatePost = async () => {
            if (!props.comment) return
            try {
                posting.value = true
                const updatedPost = await ApiService.Feed().updateComment(props.comment.id, postContent.value)
                await reset()
                await modalController.dismiss()
                Observable.emit(ObservableEvent.FeedCommentsChanged, updatedPost)
                posting.value = false
            } catch (e: any) {
                const toast = await toastController.create({
                    message: e.data.message,
                    duration: 3000,
                    position: 'bottom',
                    color: 'danger'
                });
                await toast.present();
                posting.value = false
            }
        }

        return {
            goBack,
            canPost,
            postContent,
            busy,
            loading,
            posting,
            updatePost,
            PostPrivacy
        }

    }
});
</script>