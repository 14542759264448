<template>
  <ion-page>
    <ion-content>

      <div class="flex flex-col w-full bg-brand-black my-auto px-4 pb-6 pt-14 z-50">

        <div class="flex flex-col space-y-6">
          <div class="flex flex-col w-full space-y-2">
            <div class="flex text-white text-2xl font-medium">What are you looking for?</div>
            <div class="text-brand-light-gray text-lg">Select your desires to connect with like-minded people, the ones that'll really get you. Don't sweat – you can edit these at any time.</div>
          </div>

          <div class="flex flex-wrap w-full">
            <input-checkbox
                v-for="(s,sIndex) in lookingForOptions"
                class="mr-2.5 mb-2.5"
                :key="sIndex"
                name="lookingFor"
                v-model="lookingFor"
                input-type="pill"
                :option="s"
            />
          </div>

        </div>

      </div>

    </ion-content>
    <ion-footer>
      <ion-toolbar>

        <div class="flex flex-col space-y-4">
          <input-checkbox
              name="display"
              v-model="showOnProfile"
              input-type="toggle"
              :option="{
              text: 'Show on my profile',
              value: true
            }"
          />
          <button
              @click="forward"
              :class="{
              'bg-opacity-40' : canProgress
            }"
              class="flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
            <div class="flex m-auto space-x-2.5">
              <div class="my-auto pt-0.5">
                <div v-if="!busy">Continue</div>
                <div v-if="busy">
                  <svg class="animate-spin h-5 w-5 text-brand-black" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              </div>
            </div>
          </button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonFooter,
  IonPage,
  IonToolbar,
  onIonViewDidEnter,
  onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, ref} from 'vue';
import InputCheckbox from "@/components/InputCheckbox.vue";
import ApiService from "@/services/api/apiService";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

export default defineComponent({
  name: 'AccountOnboardingLookingForPage',
  components: {
    InputCheckbox,
    IonContent,
    IonPage,
    IonFooter,
    IonToolbar,
  },
  setup() {

    const loading = ref(false)
    const busy = ref(false)
    const lookingFor = ref([])

    const errors = ref({})
    const ionRouter = useIonRouter()

    onIonViewWillEnter(()=>{
      Observable.emit(ObservableEvent.IonRouterOutletChanged, ionRouter.canGoBack())
    })

    onMounted(async ()=>{
      await getLookingForOptions()
      loading.value = false
    })

    const getLookingForOptions = async () => {
      lookingForOptions.value = (await ApiService.Settings().getDesires()).map((g:any) => {
        return {
          text: g.name,
          value: g.id
        }
      })
    }

    const forward = async () => {
      if (!lookingFor.value.length || busy.value) return
      busy.value = true
      try {
        await ApiService.User().setDesires(lookingFor.value.join(','))
        await ApiService.User().setPreference(PreferenceKey.ProfileShowDesires, showOnProfile.value[0])
        await ionRouter.navigate('/account/onboarding/profile-picture', 'forward', 'push')
      } catch (e: any) {
        if (!e.data) return
        if (e.data.errors) errors.value = e.data.errors
      } finally {
        busy.value = false
      }
    }

    const canProgress = computed(() => {
      return !lookingFor.value.length
    })

    const lookingForOptions:any = ref([])
    const showOnProfile:any = ref([true])

    return {busy, forward, lookingFor, lookingForOptions, showOnProfile, errors, canProgress}

  }
});
</script>
