<template>
  <ion-page>
    <ion-content>

      <div class="flex flex-col w-full bg-brand-black my-auto px-4 pb-6 pt-14 z-50">

        <div class="flex flex-col space-y-6">
          <div class="flex flex-col w-full space-y-2">
            <div v-if="accountType === AccountType.Individual" class="flex text-white text-2xl font-medium">I'm interested in</div>
            <div v-if="accountType === AccountType.Joint" class="flex text-white text-2xl font-medium">We're interested in</div>
          </div>

          <div class="flex flex-col space-y-2">
            <input-checkbox
                v-for="(s,sIndex) in interestOptions"
                :key="sIndex"
                name="interests"
                v-model="interests"
                :option="s"
            />
          </div>

        </div>

      </div>

    </ion-content>
    <ion-footer>
      <ion-toolbar>

        <div class="flex flex-col space-y-4">
          <input-checkbox
              name="display"
              v-model="showOnProfile"
              input-type="toggle"
              :option="{
              text: 'Show on my profile',
              value: true
            }"
          />
          <button
              @click="forward"
              :class="{
              'bg-opacity-40' : canProgress
            }"
              class="flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
            <div class="flex m-auto space-x-2.5">
              <div class="my-auto pt-0.5">
                <div v-if="!busy">Continue</div>
                <div v-if="busy">
                  <svg class="animate-spin h-5 w-5 text-brand-black" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              </div>
            </div>
          </button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonContent, IonFooter,
  IonPage, IonToolbar, onIonViewDidEnter, onIonViewWillEnter, useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, ref} from 'vue';
import InputCheckbox from "@/components/InputCheckbox.vue";
import ApiService from "@/services/api/apiService";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {Preferences} from "@capacitor/preferences";
import {AccountType} from "@/definitions/AccountType";

const loading = ref(false)
const busy = ref(false)
const interests = ref([])

const errors = ref({})
const ionRouter = useIonRouter()
const accountType = ref()

onIonViewWillEnter(async () => {
  Observable.emit(ObservableEvent.IonRouterOutletChanged, ionRouter.canGoBack())
  accountType.value = (await Preferences.get({key: PreferenceKey.OnboardingAccountType})).value
})

onMounted(async () => {
  await getSexualityOptions()
  loading.value = false
})

const getSexualityOptions = async () => {
  interestOptions.value = (await ApiService.Settings().getSexualities()).map((g: any) => {
    return {
      text: g.name,
      value: g.id
    }
  })
}

const forward = async () => {
  if (!interests.value.length || busy.value) return
  busy.value = true
  try {
    await ApiService.User().setSexualInterests(interests.value.join(','))
    await ApiService.User().setPreference(PreferenceKey.ProfileShowSexualInterests, showOnProfile.value[0])
    await ionRouter.navigate('/account/onboarding/looking-for', 'forward', 'push')
  } catch (e: any) {
    if (!e.data) return
    if (e.data.errors) errors.value = e.data.errors
  } finally {
    busy.value = false
  }
}

const canProgress = computed(() => {
  return !interests.value.length
})

const interestOptions: any = ref([])
const showOnProfile: any = ref([true])


</script>
