<template>
  <ion-content
      :force-overscroll="!!isSubscribed"
      class="flex h-full">

    <ion-refresher slot="fixed" @ionRefresh="pullToRefresh($event)">
      <ion-refresher-content pulling-icon="crescent" refreshing-spinner="crescent"></ion-refresher-content>
    </ion-refresher>

    <div v-if="!isSubscribed && !loading" class="absolute flex top-0 left-0 w-full h-full bg-brand-black/40 z-40 backdrop-blur ">

      <div class="flex px-10  m-auto text-white">
        <div class="rounded-3xl rounded-b-[44px] bg-brand-black/80 backdrop-blur flex p-5 shadow-2xl">
          <div class="flex flex-col items-center space-y-6">
            <div class="flex flex-col w-full items-center space-y-4 px-2">
              <div class="text-white text-lg font-medium text-center"><div class="text-2xl">Hey {{user.name}}!</div>This is a
                premium feature.
              </div>
              <div class="text-brand-light-gray text-lg text-center leading-tight">Subscribe and get unrestricted access.
              </div>
            </div>
            <in-app-subscription class="w-full" label="Get Pineapple Premium"/>
          </div>
        </div>
      </div>


    </div>


    <div
        v-if="hasLocation"
        @click="setNearby"
        class="sticky top-0 flex w-full bg-brand-black p-4 z-50 space-x-3.5 border-b border-brand-dark-gray">
      <div class="flex justify-between shadow-2xl bg-brand-dark-gray text-white p-4 rounded-2xl w-full">
        <div class="flex flex-col">
          <div class="flex">
            <div class="text-lg font-medium">Within a {{ nearbyRadius }} {{ pluralize('mile', nearbyRadius) }}</div>
          </div>
        </div>
        <div class="flex mr-2 my-auto">
          <svg class="w-5 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
                d="M239 401c9.4 9.4 24.6 9.4 33.9 0L465 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L81 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 401z"/>
          </svg>
        </div>
      </div>
    </div>

    <div v-if="loading" class="flex w-full z-50" style="height: calc(100% - 89px)">
      <svg class="animate-spin h-10 w-10 m-auto text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
           viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>

    <div v-if="!loading && !hasLocation" class="flex h-full w-full">
      <div class="flex px-4 py-5 m-auto text-white">
        <div class="flex flex-col items-center space-y-6">

          <div class="h-20 w-20 bg-brand-lime text-brand-black rounded-full flex">
            <div class="m-auto">
              <svg class="w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                    d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
              </svg>
            </div>
          </div>

          <div class="flex flex-col w-full items-center space-y-0 px-2">
            <div class="flex text-white text-2xl font-medium text-center">Profile Location Required</div>
            <div class="text-brand-light-gray text-lg text-center">
              To use this feature you must have a location set and be sharing this on your profile.
            </div>
          </div>


          <div @click="setLocation" class="bg-brand-dark-gray px-4 py-2.5 font-medium rounded-lg">Set Location</div>
        </div>
      </div>
    </div>

    <div v-if="!loading && hasLocation && !nearbyResults.length" class="flex w-full" style="height: calc(100% - 89px)">
      <div class="flex px-4 py-5 m-auto text-white">
        <div class="flex flex-col items-center space-y-6">

          <div class="h-20 w-20 bg-brand-dark-gray text-brand-light-gray rounded-full flex">
            <div class="m-auto">
              <svg class="w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                    d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
              </svg>
            </div>
          </div>

          <div class="flex flex-col w-full items-center space-y-0 px-2">
            <div class="flex text-white text-2xl font-medium text-center">Is there anybody out there?</div>
            <div class="text-brand-light-gray text-lg text-center">
              We couldn't find any profiles within {{ nearbyRadius }} {{ pluralize('mile', nearbyRadius) }} of your
              location. Try searching a little further afield.
            </div>
          </div>

        </div>
      </div>
    </div>

    <div v-if="!loading && hasLocation && nearbyResults.length" class="flex flex-col w-full">
      <div class="flex flex-col w-full">

        <DynamicScroller
            ref="scrollContainer"
            :min-item-size="76"
            :items="nearbyResults"
            class="scroller flex pb-4"
        >
          <template v-slot="{ item, index, active }">

            <div class="flex w-full py-4">

              <DynamicScrollerItem
                  :item="item"
                  :active="active"
                  :data-index="index"
                  class="w-full pb-4 px-4"
              >

                <div
                    @click="viewUserProfile(item.id)"
                    class="flex bg-brand-dark-gray rounded-2xl">
                  <div class="flex w-full p-4 space-x-3">
                    <div class="flex">
                      <user-avatar :user="item"/>
                    </div>
                    <div class="flex flex-col w-full">
                      <div class="flex space-x-2">
                        <div class="text-white font-medium text-lg my-auto line-clamp-1">
                          {{ item.name }}
                        </div>
                        <div class="flex">
                          <user-verification-check class="h-4" :user="item"/>
                        </div>
                      </div>

                      <div class="flex text-brand-light-gray text-xs w-full justify-between space-x-2">
                        <div class="flex line-clamp-1">{{ item.location ? item.location.name : '--' }}</div>
                        <div class="flex min-w-max">{{ item.distance_label }}</div>
                      </div>
                    </div>
                  </div>


                </div>

              </DynamicScrollerItem>
            </div>
          </template>
        </DynamicScroller>


      </div>
    </div>

  </ion-content>
</template>
<script setup lang="ts">
/* eslint-disable no-undef */

import {computed, nextTick, onMounted, onUnmounted, onUpdated, PropType, ref, Ref, watch} from "vue";
import {UserInterface} from "@/interfaces/UserInterface";
import ApiService from "@/services/api/apiService";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import 'swiper/css';
import {debounce} from "lodash";
import {IonRefresher, IonRefresherContent, modalController, useIonRouter} from "@ionic/vue";
import {usePremium} from "@/composables/usePremium";
import UserAvatar from "@/components/UserAvatar.vue";
import UserVerificationCheck from "@/components/UserBadges.vue";
import {AccountType} from "@/definitions/AccountType";
import pluralize from "pluralize";
import MeetSheet from "@/views/common/MeetSheet.vue";
import NearbySheet from "@/views/common/NearbySheet.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import InAppSubscription from "@/components/InAppSubscription.vue";

const loading = ref(true)

const user: Ref<UserInterface | undefined> = ref()

const nearbyResults: Ref<UserBasicInformationInterface[]> = ref([])
const nearbyRadius = ref(60)

const meta: Ref<any> = ref()
const showInfiniteScroll = ref(true)

const busy = ref(false)

const props = defineProps({
  accountType: {
    type: String as PropType<'individual' | 'couple' | 'club' | 'host' | undefined>,
    default: undefined
  }
})

const getNearby = async (page = 1) => {
  if (busy.value) return
  try {
    busy.value = true
    const response: any = await ApiService.Feed().nearby(nearbyRadius.value, props.accountType, page)
    meta.value = response.meta

    if (page === 1) {
      nearbyResults.value = response.data
    } else {
      nearbyResults.value = [...nearbyResults.value, ...response.data]
    }
  } catch (e) {
    //
  } finally {
    loading.value = false
    busy.value = false
  }


}

const locationPermissions: Ref<any> = ref()

const observer = ref()

onMounted(async () => {
  user.value = await ApiService.User().authUser()
  if (user.value?.subscribed) nearbyRadius.value = 60
  if (user.value?.subscribed && props.accountType === AccountType.Club) nearbyRadius.value = 200
  await getNearby()

  loading.value = false

  setContainerHeight()
  addContainerEventListeners()


  observer.value = Observable.subscribe(async (response: any) => {
    if ([ObservableEvent.UserChanged].includes(response.event)) {
      user.value = await ApiService.User().get()
      await getNearby(1)
    }
  })

})

onUnmounted(() => {
  observer.value?.unsubscribe()
})

onUpdated(async () => {
  await nextTick()
})

const setContainerHeight = () => {
  if (!scrollContainer.value?.$el) return
  const containerHeight = scrollContainer.value.$el.closest('ion-content')?.clientHeight
  if (containerHeight === 0) return
  scrollContainer.value.$el.style.height = `${containerHeight - 92}px`
}

const addContainerEventListeners = () => {
  if (!scrollContainer.value?.$el) return
  scrollContainer.value.$el.addEventListener("scroll", () => {
    handleScroll()
  });
}

watch(() => nearbyRadius.value, debounce(async () => {
  if (!loading.value) await getNearby(1)
}, 500))

const ionRouter = useIonRouter()

const viewUserProfile = async (userId: number) => {
  ionRouter.navigate({
    name: 'PineappleSearchUserProfile',
    params: {
      userId
    }
  }, 'forward', 'push')
}


const pullToRefresh = async (ev: any) => {
  await getNearby()
  ev.target.complete()
}

const handleScroll = async () => {
  if (!scrollContainer.value?.$el) return
  const threshold = 600; // Adjust this threshold as needed
  const containerHeight = scrollContainer.value.$el.closest('ion-content')?.clientHeight
  const isScrolledToBottom = scrollContainer.value.$el.scrollTop >= scrollContainer.value.$el.offsetHeight - threshold;

  if (isScrolledToBottom) {
    let page = meta.value.current_page + 1
    if (page > meta.value.last_page) {
      return
    }
    await getNearby(page)
  }
}

const scrollContainer: Ref<any> = ref(null)

const setNearby = async () => {

  const sheet = await modalController.create({
    breakpoints: [0, 1],
    initialBreakpoint: 1,
    component: NearbySheet,
    mode: 'ios'
  })
  await sheet.present()

  const {data} = await sheet.onWillDismiss()
  if (data) {
    nearbyRadius.value = data
  }

}

const hasLocation = computed(() => {
  if (!user.value) return false
  return user.value?.location?.lat
})

const setLocation = async () => {
  await ionRouter.navigate({name: `ProfileSettingsLocation`}, 'forward', 'push')
}

const isSubscribed = computed(() => {
  return user.value?.subscribed
})


</script>