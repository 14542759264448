<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="mr-4">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title class="text-white font-medium text-2xl">Account Type</ion-title>
        <ion-buttons slot="end">
          <ion-button
              @click="save"
              class="!text-xl">
            <span class="text-brand-lime">Save</span>
          </ion-button>
          <div v-if="busy" class="absolute flex top-0 left-0 w-full h-full bg-brand-black z-50">
            <svg class="animate-spin m-auto h-5 w-5 text-brand-lime" xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="h-full">
      <div class="flex flex-col py-5 px-4 justify-center space-y-4">

        <div class="flex flex-col space-y-2">
          <input-checkbox
              v-for="(s,sIndex) in options"
              :key="sIndex"
              name="option-checkbox"
              v-model="chosenOptions"
              :return-single-value="true"
              :option="s"
          />
        </div>

      </div>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {defineComponent, inject, Ref, ref} from 'vue';
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import InputCheckbox from "@/components/InputCheckbox.vue";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {AccountType} from "@/definitions/AccountType";

export default defineComponent({
  name: 'ProfileSettingsPiercings',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonButtons,
    IonButton,
    IonTitle,
    InputCheckbox,
  },
  setup() {

    const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>
    const loading = ref(true)
    const ionRouter = useIonRouter()

    const busy = ref(false)
    const errors = ref({})

    const chosenOptions: Ref<string[]> = ref([])
    const options: any = ref([])
    const showOnProfile: any = ref([true])

    onIonViewWillEnter(async () => {

      if (user.value?.account_type !== null) {
        chosenOptions.value = [user.value?.account_type as string]
      }

      await getOptions()

      loading.value = false
    })

    const getOptions = async () => {
      options.value = [
        {
          text: 'Individual',
          value: AccountType.Individual
        },
        {
          text: 'Joint',
          value: AccountType.Joint
        },
        {
          text: 'Host',
          value: AccountType.Host
        },
        {
          text: 'Club',
          value: AccountType.Club
        }
      ]
    }


    const goBack = async () => {
      if (busy.value) return
      await ionRouter.back()
    }

    const save = async () => {
      if (busy.value || !chosenOptions.value.length ) return


      const alert = await alertController.create({
        header: 'Verification Required',
        message: `Are you sure you want to change your account type? For the safety of the Pineapple community you will be required to re-submit your ID Verification.`,
        buttons: [
          {
            text: 'Yes',
            handler: async () => {

              if (busy.value) return

              errors.value = {}
              busy.value = true
              try {
                await ApiService.User().setPreference(PreferenceKey.OnboardingAccountType, chosenOptions.value[0])
                await ApiService.User().revokeVerification()
                await Observable.emit(ObservableEvent.UserChanged)
                setTimeout(() => {
                  ionRouter.back()
                  busy.value = false
                }, 1000)
              } catch (e: any) {
                if (!e.data) return
                if (e.data.errors) errors.value = e.data.errors
                busy.value = false
              }

            }
          },
          {
            text: 'Cancel'
          }
        ]
      })

      await alert.present()

    }

    return {loading, goBack, user, busy, errors, save, chosenOptions, showOnProfile, options}

  }
});
</script>
