<template>
    <div class="font-sans p-4 pt-6">
        <div class="flex flex-col w-full space-y-4">
            <div class="flex flex-col w-full space-y-4">
                <chat-participant
                    @click="viewProfile(user)"
                    v-for="(user,userIndex) in participants"
                    :key="userIndex"
                    :user="user"/>
            </div>
        </div>
        <div class="safe-area-bottom"></div>
    </div>
</template>

<script lang="ts" setup>
import {PropType} from 'vue';
import {ChatParticipantInterface} from "@/interfaces/ChatParticipantInterface";
import ChatParticipant from "@/components/ChatParticipant.vue";
import {modalController, useIonRouter} from "@ionic/vue";

const props = defineProps({
    participants: {
        type: Object as PropType<ChatParticipantInterface[]>,
        required: true
    }
})

const ionRouter = useIonRouter()

const viewProfile = async (user: ChatParticipantInterface) => {
  if (user.is_auth_user) return
  modalController.dismiss()
  ionRouter.navigate({
    name: 'FlirtUserProfile',
    params: {
      userId: user.id
    }
  }, 'forward', 'push')
}

</script>