<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="mr-4">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title class="text-white font-medium text-2xl">{{ title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button
              @click="save"
              class="!text-xl">
            <span class="text-brand-lime">Save</span>
          </ion-button>
          <div v-if="busy" class="absolute flex top-0 left-0 w-full h-full bg-brand-black z-50">
            <svg class="animate-spin m-auto h-5 w-5 text-brand-lime" xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="h-full">
      <div v-if="!loading" class="flex flex-col py-5 px-4 justify-center space-y-4">


        <template v-if="setting === 'Name'">
          <input-field
              :error="errors && errors['name'] ? errors['name'][0] : undefined"
              v-model="profileRef.name"
              type="text"
              :counter="true"
              :max-length="20"
              enterkeyhint="next"
              placeholder="Imaginary name">
          </input-field>
        </template>

        <template v-if="setting === 'Height'">
        <div class="flex space-y-2 flex-col">
          <div class="text-white text-sm font-medium">Centimeters</div>
          <div class="flex w-full relative justify-between rounded-lg overflow-hidden">
            <input-field
                class="w-2/3"
                :error="errors && errors['value'] ? errors['value'][0] : undefined"
                type="number"
                v-model="height"
                inputmode="decimal"
                enterkeyhint="next"
                placeholder="Height in CMs"/>
            <div class="flex w-1/3 text-right">
              <div class="flex my-auto w-full">
                <div class="text-white font-medium text-sm m-auto">{{ heightInFtIn }}</div>
              </div>
            </div>
          </div>
        </div>
        </template>

        <template v-if="setting === 'Dob'">
          <div @click="setDob" class="flex w-full relative">
            <div class="flex w-full h-full z-50 absolute top-0 left-0"></div>
            <div class="flex w-full flex-col space-y-2 relative z-10">
              <div class="flex w-full space-x-2">
                <input-field
                    v-model="dob.dd"
                    type="text"
                    placeholder="DD">
                </input-field>
                <input-field
                    v-model="dob.mm"
                    type="text"
                    placeholder="MM">
                </input-field>
                <input-field
                    v-model="dob.yyyy"
                    type="text"
                    placeholder="YYYY">
                </input-field>
              </div>
              <div class="flex w-full">
                <div v-if="errors && errors.hasOwnProperty('dob')" class="text-brand-pink font-medium text-xs">
                  {{ errors['dob'][0] }}
                </div>
              </div>
            </div>
          </div>
        </template>

        <div v-if="options.length" class="flex flex-col space-y-2">
          <input-checkbox
              v-for="(s,sIndex) in options"
              :key="sIndex"
              name="option-checkbox"
              v-model="chosenOptions"
              :return-single-value="true"
              :option="s"
          />
        </div>

      </div>
    </ion-content>
    <ion-footer v-if="!['Name', 'Dob'].includes(setting)">
      <ion-toolbar>
        <div class="flex flex-col w-full">
          <input-checkbox
              name="display"
              v-model="showOnProfile"
              input-type="toggle"
              :option="{
              text: 'Show on profile',
              value: true
            }"
          />
          <div class="text-xs mt-2 mx-auto text-gray-500">Preference is applied to all profiles within the account.
          </div>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonButton,
  IonButtons,
  IonContent, IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar, modalController, pickerController,
} from '@ionic/vue';
import {computed, defineComponent, inject, onMounted, Prop, PropType, Ref, ref} from 'vue';
import ApiService from "@/services/api/apiService";
import InputCheckbox from "@/components/InputCheckbox.vue";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {UserProfileInterface} from "@/interfaces/UserProfileInterface";
import ProfileSettingsName from "@/views/user/profile/ProfileSettingsName.vue";
import ProfileSettingsPronouns from "@/views/user/profile/ProfileSettingsPronouns.vue";
import ProfileSettingsBodyType from "@/views/user/profile/ProfileSettingsBodyType.vue";
import ProfileSettingsDrinkingType from "@/views/user/profile/ProfileSettingsDrinkingType.vue";
import ProfileSettingsGender from "@/views/user/profile/ProfileSettingsGender.vue";
import ProfileSettingsHeight from "@/views/user/profile/ProfileSettingsHeight.vue";
import ProfileSettingsPiercings from "@/views/user/profile/ProfileSettingsPiercings.vue";
import ProfileSettingsReligion from "@/views/user/profile/ProfileSettingsReligion.vue";
import ProfileSettingsSexuality from "@/views/user/profile/ProfileSettingsSexuality.vue";
import ProfileSettingsSmokingType from "@/views/user/profile/ProfileSettingsSmokingType.vue";
import ProfileSettingsTattoos from "@/views/user/profile/ProfileSettingsTattoos.vue";
import InputField from "@/components/InputField.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import {DateTime} from "luxon";


const loading = ref(true)

const busy = ref(false)
const errors = ref({})

const title = ref('')

const options: any = ref([])
const chosenOptions: Ref<(number | boolean)[]> = ref([])
const showOnProfile: any = ref([true])

const dob = ref({
  dd: '',
  mm: '',
  yyyy: '',
})

const dateOfBirth: any = ref('')


const props = defineProps({
  profile: {
    type: Object as PropType<UserProfileInterface>,
    required: true
  },
  setting: {
    type: String as PropType<'Name' | 'Pronouns' | 'BodyType' | 'DrinkingType' | 'Gender' | 'Dob' | 'Height' | 'Piercings' | 'Religion' | 'Sexuality' | 'SmokingType' | 'Tattoos'>,
    required: true
  }
})
const profileRef = ref(props.profile)

const height = ref(profileRef.value.height ? `${profileRef.value.height?.cm}` : '172')

const heightInFtIn = computed(() => {
  if (!height.value) return ''

  const convertedTotalInches = Number(height.value) / 2.54
  const convertedFeet = Math.floor(convertedTotalInches / 12)
  const convertedInches = convertedTotalInches - 12 * convertedFeet
  const feet = Math.round(convertedFeet)
  const inches = Math.round(convertedInches)
  return `${feet} ft ${inches} inches`
})

onMounted(async () => {
  await init()
  loading.value = false
})

const init = async () => {

  switch (props.setting) {
    case 'Name': {
      title.value = 'Name'

    }
      break

    case 'Dob': {
      title.value = 'Date of Birth'
      dateOfBirth.value = profileRef.value.dob
      let ddmmyy = []
      if (dateOfBirth.value) ddmmyy = dateOfBirth.value.split('/')

      if (ddmmyy.length) {
        dob.value.dd = ddmmyy[0]
        dob.value.mm = ddmmyy[1]
        dob.value.yyyy = ddmmyy[2]
      }

      const pref = Boolean(Number((await ApiService.User().authUser()).preferences[PreferenceKey.ProfileShowHeight]))
      showOnProfile.value = [pref]
    }
      break

    case 'Height': {
      title.value = 'Height'
      if (profileRef.value.height) height.value = `${profileRef.value.height.cm}`
      const pref = Boolean(Number((await ApiService.User().authUser()).preferences[PreferenceKey.ProfileShowHeight]))
      showOnProfile.value = [pref]
    }
      break

    case 'Tattoos': {

      title.value = 'Tattoos'

      if (profileRef.value?.has_tattoos !== null) {
        chosenOptions.value = [profileRef.value?.has_tattoos ? true : false]
      }

      options.value = [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ]

      const pref = Boolean(Number((await ApiService.User().authUser()).preferences[PreferenceKey.ProfileShowTattoos]))
      showOnProfile.value = [pref]

    }
      break

    case 'Piercings': {

      title.value = 'Piercings'

      if (profileRef.value?.has_piercings !== null) {
        chosenOptions.value = [profileRef.value?.has_piercings ? true : false]
      }

      options.value = [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ]

      const pref = Boolean(Number((await ApiService.User().authUser()).preferences[PreferenceKey.ProfileShowPiercings]))
      showOnProfile.value = [pref]

    }
      break

    case 'Pronouns': {

      title.value = 'Pronouns'

      if (profileRef.value?.pronouns) {
        chosenOptions.value = [profileRef.value?.pronouns.id]
      }

      options.value = (await ApiService.Settings().getPronouns()).map((g: any) => {
        return {
          text: g.name,
          value: g.id
        }
      })

      const pref = Boolean(Number((await ApiService.User().authUser()).preferences[PreferenceKey.ProfileShowPronouns]))
      showOnProfile.value = [pref]

    }
      break

    case 'BodyType': {

      title.value = 'Body Type'

      if (profileRef.value?.body_type) {
        chosenOptions.value = [profileRef.value?.body_type.id]
      }

      options.value = (await ApiService.Settings().getBodyTypes()).map((g: any) => {
        return {
          text: g.name,
          value: g.id
        }
      })

      const pref = Boolean(Number((await ApiService.User().authUser()).preferences[PreferenceKey.ProfileShowBodyType]))
      showOnProfile.value = [pref]

    }
      break

    case 'DrinkingType': {

      title.value = 'Drinking Type'

      if (profileRef.value?.drinking_type) {
        chosenOptions.value = [profileRef.value?.drinking_type.id]
      }

      options.value = (await ApiService.Settings().getDrinkingTypes()).map((g: any) => {
        return {
          text: g.name,
          value: g.id
        }
      })

      const pref = Boolean(Number((await ApiService.User().authUser()).preferences[PreferenceKey.ProfileShowDrinkingType]))
      showOnProfile.value = [pref]

    }
      break

    case 'Gender': {

      title.value = 'Gender'

      if (profileRef.value?.gender) {
        chosenOptions.value = [profileRef.value?.gender.id]
      }

      options.value = (await ApiService.Settings().getGenders()).map((g: any) => {
        return {
          text: g.name,
          value: g.id
        }
      })

      const pref = Boolean(Number((await ApiService.User().authUser()).preferences[PreferenceKey.ProfileShowGender]))
      showOnProfile.value = [pref]

    }
      break

    case 'Sexuality': {

      title.value = 'Sexuality'

      if (profileRef.value?.sexuality) {
        chosenOptions.value = [profileRef.value?.sexuality.id]
      }

      options.value = (await ApiService.Settings().getSexualities()).map((g: any) => {
        return {
          text: g.name,
          value: g.id
        }
      })

      const pref = Boolean(Number((await ApiService.User().authUser()).preferences[PreferenceKey.ProfileShowSexuality]))
      showOnProfile.value = [pref]

    }
      break

    case 'Religion': {

      title.value = 'Religion'

      if (profileRef.value?.religion) {
        chosenOptions.value = [profileRef.value?.religion.id]
      }

      options.value = (await ApiService.Settings().getReligions()).map((g: any) => {
        return {
          text: g.name,
          value: g.id
        }
      })

      const pref = Boolean(Number((await ApiService.User().authUser()).preferences[PreferenceKey.ProfileShowReligion]))
      showOnProfile.value = [pref]

    }
      break

    case 'SmokingType': {

      title.value = 'Smoking Type'

      if (profileRef.value?.smoking_type) {
        chosenOptions.value = [profileRef.value?.smoking_type.id]
      }

      options.value = (await ApiService.Settings().getSmokingTypes()).map((g: any) => {
        return {
          text: g.name,
          value: g.id
        }
      })

      const pref = Boolean(Number((await ApiService.User().authUser()).preferences[PreferenceKey.ProfileShowSmokingType]))
      showOnProfile.value = [pref]

    }
      break

  }


}


const goBack = async () => {
  if (busy.value) return
  await modalController.dismiss()
}

const save = async () => {
  if (busy.value) return
  errors.value = {}
  busy.value = true

  let user: UserInterface | undefined = undefined

  try {

    switch (props.setting) {
      case 'Name': {
        user = await ApiService.User().setProfileName(profileRef.value.id, profileRef.value.name)
      }
        break

      case 'Dob': {
        user = await ApiService.User().setDob(profileRef.value.id, dateOfBirth.value)
      }
        break

      case 'Height': {
        await ApiService.User().setHeight(profileRef.value.id,Number(height.value))
        await ApiService.User().setPreference(PreferenceKey.ProfileShowHeight, showOnProfile.value[0])
      }
        break

      case 'Tattoos': {
        user = await ApiService.User().setTattoos(profileRef.value.id, chosenOptions.value.length ? chosenOptions.value[0] as boolean : null)
        await ApiService.User().setPreference(PreferenceKey.ProfileShowTattoos, showOnProfile.value[0])
      }
        break

      case 'Piercings': {
        user = await ApiService.User().setPiercings(profileRef.value.id, chosenOptions.value.length ? chosenOptions.value[0] as boolean : null)
        await ApiService.User().setPreference(PreferenceKey.ProfileShowPiercings, showOnProfile.value[0])
      }
        break

      case 'Pronouns': {
        user = await ApiService.User().setPronouns(profileRef.value.id, chosenOptions.value.length ? chosenOptions.value[0] as number : null)
        await ApiService.User().setPreference(PreferenceKey.ProfileShowPronouns, showOnProfile.value[0])
      }
        break

      case 'BodyType': {
        user = await ApiService.User().setBodyType(profileRef.value.id, chosenOptions.value.length ? chosenOptions.value[0] as number : null)
        await ApiService.User().setPreference(PreferenceKey.ProfileShowBodyType, showOnProfile.value[0])
      }
        break

      case 'DrinkingType': {
        user = await ApiService.User().setDrinkingType(profileRef.value.id, chosenOptions.value.length ? chosenOptions.value[0] as number : null)
        await ApiService.User().setPreference(PreferenceKey.ProfileShowDrinkingType, showOnProfile.value[0])
      }
        break

      case 'Gender': {
        user = await ApiService.User().setGender(profileRef.value.id, chosenOptions.value[0] as number)
      }
        break

      case 'Sexuality': {
        user = await ApiService.User().setSexuality(profileRef.value.id, chosenOptions.value[0] as number)
        await ApiService.User().setPreference(PreferenceKey.ProfileShowSexuality, showOnProfile.value[0])
      }
        break

      case 'Religion': {
        user = await ApiService.User().setReligion(profileRef.value.id, chosenOptions.value.length ? chosenOptions.value[0] as number : null)
        await ApiService.User().setPreference(PreferenceKey.ProfileShowReligion, showOnProfile.value[0])
      }
        break

      case 'SmokingType': {
        user = await ApiService.User().setSmokingType(profileRef.value.id, chosenOptions.value.length ? chosenOptions.value[0] as number : null)
        await ApiService.User().setPreference(PreferenceKey.ProfileShowSmokingType, showOnProfile.value[0])
      }
        break

    }

    Observable.emit(ObservableEvent.UserChanged)

    setTimeout(async () => {
      busy.value = false
      await modalController.dismiss(user)
    }, 1000)


  } catch (e: any) {
    if (!e.data) return
    if (e.data.errors) errors.value = e.data.errors
    busy.value = false
  }
}

const setDob = async () => {

  const year = DateTime.now().year
  const years = []
  for (let y = (year - 18); y > (year - 99); y--) {
    years.push(y)
  }

  const picker = await pickerController.create({
    columns: [
      {
        name: 'dd',
        options: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'].map(d => {
          return {
            text: `${d}`,
            value: `${d}`
          }
        })
      },
      {
        name: 'mm',
        options: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map(m => {
          return {
            text: `${DateTime.now().set({'month': parseInt(m)}).toFormat('MMMM')}`,
            value: `${m}`
          }
        })
      },
      {
        name: 'yyyy',
        options: years.map(y => {
          return {
            text: `${y}`,
            value: `${y}`
          }
        }),
      },
    ],
    buttons: [
      {
        text: 'Cancel',
        role: 'cancel',
      },
      {
        text: 'Confirm',
        handler: (value) => {

          dob.value.dd = `${value['dd']['value']}`
          dob.value.mm = `${value['mm']['text']}`
          dob.value.yyyy = `${value['yyyy']['value']}`

          dateOfBirth.value = `${value['dd']['value']}/${value['mm']['value']}/${value['yyyy']['value']}`

        },
      },
    ],
  });
  await picker.present();

}

</script>
<style scoped>
ion-footer ion-toolbar {
  --padding-start: 8px;
  --padding-end: 8px;
}
</style>