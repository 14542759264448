<template>
  <div class="font-sans py-8 pb-4 px-4" v-if="!loading">

    <template v-if="!isAttending">
      <div v-if="!isRejection" class="flex flex-col space-y-3">
        <div
            @click="showRejection"
            class="border-2 border-brand-darkish-gray  font-medium rounded-full text-brand-light-gray px-5  h-12 flex flex-grow ">
          <div v-if="true" class="m-auto">Reject</div>
          <svg v-else class="animate-spin h-5 w-5 m-auto text-brand-black"
               xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                    stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>

        <div
            @click="acceptRequest"
            class="border-2 border-white bg-white font-medium rounded-full text-brand-black px-5  h-12 flex flex-grow ">
          <div v-if="true" class="m-auto">Accept</div>
          <svg v-else class="animate-spin h-5 w-5 m-auto text-brand-black"
               xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                    stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>

      <div v-if="isRejection" class="flex flex-col space-y-3">
        <input-textarea
            v-model="rejectionReason"
            placeholder="Please provide a reason for why you're rejecting this invite request..."
            enterkeyhint="next"></input-textarea>

        <div
            @click="confirmRejection"
            class="border-2 border-red-500 bg-red-500 font-medium rounded-full text-white px-5  h-12 flex flex-grow ">
          <div v-if="true" class="m-auto">Confirm rejection</div>
          <svg v-else class="animate-spin h-5 w-5 m-auto text-brand-black"
               xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                    stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>

        <div @click="hideRejection" class="pb-3 pt-2 text-brand-light-gray w-full text-center">
          <span class="border-b pb-1">Cancel</span>
        </div>

      </div>
    </template>

    <div v-if="isAttending" class="flex flex-col space-y-3">
      <div
          @click="removeFromEvent"
          class="border-2 border-red-500 bg-red-500 font-medium rounded-full text-white px-5  h-12 flex flex-grow ">
        <div v-if="true" class="m-auto">Remove from guest list</div>
        <svg v-else class="animate-spin h-5 w-5 m-auto text-brand-black"
             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                  stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>

    </div>


    <div class="safe-area-bottom"></div>
  </div>
</template>

<script setup lang="ts">

import {computed, defineProps, onMounted, PropType, ref} from 'vue';
import ApiService from "@/services/api/apiService";

import {EventInterface} from "@/interfaces/EventInterface";
import {alertController, modalController, toastController} from "@ionic/vue";
import InputTextarea from "@/components/InputTextarea.vue";
import * as events from "events";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";

const loading = ref(true)

const props = defineProps({
  event: {
    type: Object as PropType<EventInterface>,
    required: true
  },
  user: {
    type: Object as PropType<UserBasicInformationInterface>,
    required: true
  }
})

const authUser = ref()
const isRejection = ref(false)
const rejectionReason = ref('')

const showRejection = async () => {
  isRejection.value = true
}

const hideRejection = async () => {
  isRejection.value = false
}

onMounted(async () => {
  authUser.value = await ApiService.User().authUser()
  loading.value = false
})

const confirmRejection = async () => {

  await modalController.dismiss()

  try {
    await ApiService.Events().rejectInvite(props.event?.id, props.user?.id, rejectionReason.value)
    Observable.emit(ObservableEvent.PendingEventResponsesChanged)
    Observable.emit(ObservableEvent.PullToRefreshMyEvents)
    Observable.emit(ObservableEvent.PullToRefreshMyTickets)
    Observable.emit(ObservableEvent.EventsChanged)
  } catch (e: any) {
    const toast = await toastController.create({
      message: e.data.message,
      duration: 3000,
      position: 'bottom',
      color: 'danger'
    });
    await toast.present();
  }

}


const acceptRequest = async () => {

  await modalController.dismiss()

  try {
    await ApiService.Events().confirmInvite(props.event?.id, props.user?.id)
    Observable.emit(ObservableEvent.PendingEventResponsesChanged)
    Observable.emit(ObservableEvent.PullToRefreshMyEvents)
    Observable.emit(ObservableEvent.PullToRefreshMyTickets)
    Observable.emit(ObservableEvent.EventsChanged)
  } catch (e: any) {
    const toast = await toastController.create({
      message: e.data.message,
      duration: 3000,
      position: 'bottom',
      color: 'danger'
    });
    await toast.present();
  }


}

const isAttending = computed(() => {
  return props.event?.attending.find((user) => user.id === props.user?.id)
})

const removeFromEvent = async () => {

  const alert = await alertController.create({
    header: 'Remove',
    message: `Are you sure you want to remove this user from the guest list?`,
    buttons: [
      {
        text: 'Cancel',
        handler: async () => {
          await alertController.dismiss()
          await modalController.dismiss()
        }
      },
      {
        text: 'Yes',
        role: 'destructive',
        handler:  async () => {

          await alertController.dismiss()
          await modalController.dismiss()

          try {
            await ApiService.Events().deleteAttendanceRecord(props.event?.id, props.user?.id)
            Observable.emit(ObservableEvent.PendingEventResponsesChanged)
            Observable.emit(ObservableEvent.PullToRefreshMyEvents)
            Observable.emit(ObservableEvent.PullToRefreshMyTickets)
            Observable.emit(ObservableEvent.EventsChanged)
          } catch (e:any) {
            const toast = await toastController.create({
              message: e.data.message,
              duration: 3000,
              position: 'bottom',
              color: 'danger'
            });
            await toast.present();
          }

        }
      },
    ]
  })
  await alert.present()

}

</script>

