<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>

            <ion-buttons class="flex" slot="start">
              <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
                <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"/>
                  <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
              </ion-button>
            </ion-buttons>

              <div class="text-xl text-white font-medium my-auto ml-3">Post</div>


      </ion-toolbar>
    </ion-header>
    <ion-content id="ionContent">

      <ion-refresher slot="fixed" class="safe-area-top top-4 lef-0 text-white z-50 relative"
                     @ionRefresh="pullToRefresh($event)">
        <ion-refresher-content pulling-icon="crescent" refreshing-spinner="crescent"></ion-refresher-content>
      </ion-refresher>

      <div class="h-full flex" v-if="loading">
        <div class="m-auto flex">
          <svg class="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>

      <div class="h-full" v-if="!loading">

        <feed-post :auth-user="authUser" :post="post"/>

        <div id="postComments" class="flex flex-col">
          <feed-post-comment
              v-for="(comment,commentIndex) in post.comments"
              :can-reply="true"
              :key="commentIndex"
              @onReply="setReplyTo"
              :comment="comment"/>
        </div>
      </div>

    </ion-content>

    <ion-footer id="keyboard">
      <ion-toolbar>

        <div @click="needsVerification" v-if="!loading && !isVerified"
             class="absolute top-0 left-0 w-full h-full z-50">
        </div>

        <div class="overflow-hidden sticky bottom-0 border-t border-brand-dark-gray border-opacity-80">
          <div v-if="replyTo" class="flex bg-brand-dark-gray p-4 text-brand-light-gray justify-between">
            <div>Replying to @{{ replyTo.posted_by.name }}</div>
            <div @click="removeReply">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 21.9999C10.6864 22.0012 9.38546 21.7435 8.17162 21.2414C6.95777 20.7393 5.85485 20.0028 4.926 19.0739C3.99716 18.1451 3.26061 17.0421 2.75853 15.8283C2.25645 14.6144 1.99869 13.3135 2.00001 11.9999V11.7999C2.04001 9.83932 2.6553 7.93371 3.76939 6.31993C4.88348 4.70614 6.44726 3.45534 8.26636 2.72296C10.0855 1.99059 12.0797 1.80895 14.0012 2.20061C15.9227 2.59227 17.6867 3.53996 19.074 4.9259C20.4737 6.32445 21.4271 8.10681 21.8134 10.0474C22.1997 11.988 22.0017 13.9995 21.2443 15.8275C20.487 17.6555 19.2043 19.2177 17.5588 20.3165C15.9132 21.4152 13.9787 22.0011 12 21.9999ZM12 13.4099L14.59 15.9999L16 14.5899L13.41 11.9999L16 9.4099L14.59 7.9999L12 10.5899L9.41001 7.9999L8.00001 9.4099L10.59 11.9999L8.00001 14.5899L9.41001 15.9999L12 13.4109V13.4099Z"
                    fill="#A5A7AF"/>
              </svg>
            </div>
          </div>
          <div
              :class="{
              'pb-4' : isAndroid,
              'pb-0' : !isAndroid
            }"
              class="bg-brand-black safe-area-bottom px-4 pt-4 z-50">
            <div class="flex bg-brand-black w-full">
              <div
                  class="flex relative w-full rounded-xl max-h-32 overflow-hidden text-white space-x-4">
                <div class="flex overflow-y-scroll w-full relative">
                  <Mentionable
                      class="w-full  relative"
                      :keys="['@']"
                      :items="mentionableUsers"
                      offset="0"
                      @open="loadUsers()"
                      @search="loadUsers($event)"
                      filtering-disabled
                  >

                    <div class="flex relative w-full bg-brand-dark-gray py-4 px-5 pr-20 rounded-3xl">
                      <ion-textarea
                          class="input outline-none text-base max-h-24 relative w-full items-center overflow-y-scroll leading-5"
                          auto-grow
                          autocapitalize="on"
                          v-model="comment"
                          placeholder="Add a comment..."
                          ref="commentField"
                      />
                    </div>

                    <template #no-result>
                      <div class="text-sm text-white p-3">
                        {{ loadingUsers ? 'Loading...' : 'No users found.' }}
                      </div>
                    </template>

                    <template #item-@="{ item }">
                      <div class="text-sm">
                        @{{ item.value }}
                      </div>
                    </template>

                  </Mentionable>
                </div>
                <div
                    @click="postComment"
                    class="absolute bottom-1.5 right-1.5 mt-auto h-10 mb-0 bg-brand-lime font-medium text-brand-black flex rounded-full px-0.5">
                  <div
                      :class="{
                      'px-2' : busy,
                      'px-4' : !busy
                    }"
                      class="m-auto  text-base">
                    <div v-if="!busy">Post</div>
                    <svg v-if="busy" class="animate-spin m-auto h-5 w-5 text-brand-black"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                              stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ion-toolbar>
    </ion-footer>

  </ion-page>
</template>

<script setup lang="ts">
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter, IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent, IonTextarea, IonTitle,
  IonToolbar,
  isPlatform, toastController,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, nextTick, onMounted, onUnmounted, Ref, ref} from 'vue';
import {useRoute} from "vue-router";
import {Keyboard} from "@capacitor/keyboard";
import ApiService from "@/services/api/apiService";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import FeedPostComment from "@/components/FeedPostComment.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import FeedPost from "@/components/FeedPost.vue";
import {useVerification} from "@/composables/useVerification";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import pluralize from "pluralize";
import {Mentionable} from "vue-mention";
import {usePremium} from "@/composables/usePremium";
import {Haptics, ImpactStyle} from "@capacitor/haptics";

const loading = ref(true)
const busy = ref(false)
const ionRouter = useIonRouter()
const route = useRoute()

const observable: any = ref()

const postId = ref()
const post: Ref<FeedPostInterface | any> = ref()

const comment = ref('')
const commentField: any = ref(null)


const replyTo: Ref<FeedPostInterface | undefined> = ref()

const postComponent = ref(null)

const getData = async () => {
  post.value = await ApiService.Feed().getPost(postId.value)
  if (!loading.value) Observable.emit(ObservableEvent.FeedPostChanged, post.value)
}

const goBack = async () => {
  await ionRouter.back()
}

const keyboardVisible = ref(false)
const contentOffset = ref()
const keyboardListeners: Ref<any[]> = ref([])

const authUser = ref()

onMounted(async () => {

  try {
    postId.value = route.params.postId
    await getData()
    authUser.value = await ApiService.User().authUser()
  } catch (e:any) {
    if (e.status===404) {

      const alert = await alertController.create({
        header: 'Not available',
        subHeader: 'The requested post is no longer available.',
        buttons: [
          {
            text: 'Ok',
            handler: async () => {
              alertController.dismiss()
              await goBack()
            }
          }
        ]
      })
      await alert.present()

    }
  }

  observable.value = Observable.subscribe(async (response: any) => {
    if (response.event === ObservableEvent.FeedCommentsChanged) await getData()
  })

  if (isPlatform('capacitor') && isPlatform('ios')) {

    const keyboardWillShow = await Keyboard.addListener('keyboardWillShow', (info) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const sab = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sab")) - 34
      const ionApp = document.getElementById('keyboard')!
      ionApp.style.transition = 'all 400ms ease'
      ionApp.style.setProperty(
          'transform',
          `translate3d(0, -${info.keyboardHeight - sab}px, 0)`
      )
      contentOffset.value = `${info.keyboardHeight}px`
      keyboardVisible.value = true
      scrollToBottom(500)
      console.log('keyboard event')
    })

    keyboardListeners.value.push(keyboardWillShow)

    const keyboardWillHide = await Keyboard.addListener('keyboardWillHide', () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const ionApp = document.getElementById('keyboard')!
      ionApp.style.transition = 'all 200ms ease'
      ionApp.style.removeProperty('transform')
      keyboardVisible.value = false
      // contentOffset.value = '24px'
    })

    keyboardListeners.value.push(keyboardWillHide)

    const keyboardDidHide = await Keyboard.addListener('keyboardDidHide', () => {
      scrollToBottom(500)
    })

    keyboardListeners.value.push(keyboardDidHide)

  }

  setTimeout(() => {
    loading.value = false
  }, 500)

  // eslint-disable-next-line no-undef
  // const ionContent: HTMLIonContentElement = document.getElementById('ionContent') as HTMLIonContentElement
  // const offsetTop = document.getElementById('postComments')?.offsetTop ?? 0
  // await ionContent.scrollToPoint(0,  offsetTop - 100)

})

onUnmounted(() => {
  observable.value.unsubscribe()
  keyboardListeners.value.forEach(l => {
    l.remove()
  })
})

const scrollToBottom = (speed = 0) => {
  // if (content.value) {
  //   content.value.$el.scrollToBottom(speed)
  // }
}

const replyingToPostId = computed(() => {
  if (replyTo.value) return replyTo.value?.id
  return post.value?.comment_id
})

const findComment = (data: any, id: any) => {
  let f: any, s = (d: any, id: any) => d.find((x: any) => x.id == id ? f = x : s(x.comments, id))
  s(data, id)
  return f ? f.comments.length > 0 : false
}

const postComment = async () => {

  if (busy.value) return
  if (!post.value) return
  if (!comment.value) return
  busy.value = true

  try {
    await ApiService.Feed().postCommentReply(replyingToPostId.value as number, comment.value)

    busy.value = false
    // commentField.value.innerText = ''
    comment.value = ''
    replyTo.value = undefined
    Observable.emit(ObservableEvent.FeedCommentsChanged)
  } catch (e: any) {

    busy.value = false
    if (e.status === 429) {
      return usePremium().presentPayWall({
        text: e.data.message,
        callback: () => {
          //
        }
      });
    }

    const toast = await toastController.create({
      message: e.data.message,
      duration: 3000,
      position: 'bottom',
      color: 'danger'
    });
    await toast.present();

  }

}

const setComment = async (ev: any) => {
  comment.value = ev.target.innerText
}

const isAndroid = () => {
  return isPlatform('android')
}

const removeReply = async () => {
  replyTo.value = undefined
}

const setReplyTo = async (to: FeedPostInterface) => {

  if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});
  if (authUser.value && !authUser.value.id_verified) return await useVerification().presentVerificationRequired('You must be verified to reply to comments.')

  replyTo.value = to
  // commentField.value.focus()
}


const pullToRefresh = async (ev: any) => {
  await getData();
  ev.target.complete()
}

const isVerified = computed(() => {
  return authUser.value.id_verified
})

const needsVerification = async () => {
  await useVerification().presentVerificationRequired('You must be verified to comment on posts.')
}

const loadingUsers = ref(false)
const mentionableUsers: Ref<any[]> = ref([])

const loadUsers = async (searchText = '') => {
  loadingUsers.value = true
  mentionableUsers.value = await fetchUsers(searchText)
  loadingUsers.value = false
}

const fetchUsers = async (search: string) => {
  const results = await ApiService.Search().users(search)
  return results.map((result: UserBasicInformationInterface) => {
    return {
      value: result.name,
      label: result.name
    }
  })
}


</script>
<style scoped>
.input:empty:before {
  content: attr(data-placeholder);
  color: rgba(255, 255, 255, 0.5);
}

ion-footer ion-toolbar {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}

ion-textarea {
  --padding-top: 0;
  --padding-bottom: 0;
}

ion-textarea textarea {
  --padding-bottom: 0 !important;
}

</style>