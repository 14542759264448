<template>
  <ion-page>
    <ion-content>

      <div class="flex flex-col w-full bg-brand-black my-auto h-full px-6 z-50">
        <template v-if="checkingStatus">
          <div class="m-auto">
            <svg class="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </template>
        <template v-else>
          <div class="flex w-full flex-col m-auto space-y-14 items-center">

            <div class="flex w-full flex-col space-y-4 items-center">
              <svg width="111" height="110" viewBox="0 0 111 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2.5" y="2" width="106" height="106" rx="53" fill="#EBFF00"/>
                <path
                    d="M68.833 41.666H42.1663C40.333 41.666 38.8497 43.166 38.8497 44.9993L38.833 64.9993C38.833 66.8327 40.333 68.3327 42.1663 68.3327H68.833C70.6663 68.3327 72.1663 66.8327 72.1663 64.9993V44.9993C72.1663 43.166 70.6663 41.666 68.833 41.666ZM68.1663 48.7493L56.383 56.116C55.8497 56.4493 55.1497 56.4493 54.6163 56.116L42.833 48.7493C42.6659 48.6555 42.5195 48.5288 42.4028 48.3768C42.2861 48.2248 42.2014 48.0506 42.154 47.865C42.1065 47.6793 42.0972 47.4859 42.1266 47.2965C42.156 47.1071 42.2236 46.9257 42.3251 46.7632C42.4267 46.6007 42.5602 46.4604 42.7176 46.351C42.8749 46.2416 43.0528 46.1652 43.2405 46.1265C43.4282 46.0878 43.6218 46.0876 43.8096 46.1259C43.9974 46.1642 44.1754 46.2402 44.333 46.3493L55.4997 53.3327L66.6663 46.3493C66.8239 46.2402 67.002 46.1642 67.1898 46.1259C67.3775 46.0876 67.5711 46.0878 67.7589 46.1265C67.9466 46.1652 68.1245 46.2416 68.2818 46.351C68.4391 46.4604 68.5726 46.6007 68.6742 46.7632C68.7758 46.9257 68.8433 47.1071 68.8727 47.2965C68.9022 47.4859 68.8929 47.6793 68.8454 47.865C68.7979 48.0506 68.7132 48.2248 68.5965 48.3768C68.4798 48.5288 68.3335 48.6555 68.1663 48.7493Z"
                    fill="#1A1B22"/>
                <rect x="2.5" y="2" width="106" height="106" rx="53" stroke="#EBFF00" stroke-width="4"/>
              </svg>

              <div class="flex flex-col w-full items-center space-y-2">
                <div class="flex m-auto text-white text-2xl font-medium">Check your inbox</div>
                <div v-if="loading" class="flex flex-col w-full items-center">
                  <ion-skeleton-text class="w-8/12 h-5" animated></ion-skeleton-text>
                  <ion-skeleton-text class="w-10/12 h-5" animated></ion-skeleton-text>
                  <ion-skeleton-text class="w-5/12 h-5" animated></ion-skeleton-text>
                </div>
                <div v-else class="m-auto text-brand-light-gray text-lg text-center">
                  A confirmation email has been sent to <span class="text-brand-lime">{{ user.email }}</span>. Check
                  your email and tap the link we sent you.
                </div>
              </div>
            </div>

            <div class="flex flex-col space-y-2 w-full">
              <button
                  @click="resend"
                  class="flex bg-brand-lime rounded-full w-full h-14 font-medium text-xl text-brand-black">
                <div class="flex m-auto space-x-2.5">
                  <div class="my-auto pt-0.5">
                    <div v-if="!busy">I didn’t get it</div>
                    <div v-if="busy">
                      <svg class="animate-spin h-5 w-5 text-brand-black" xmlns="http://www.w3.org/2000/svg" fill="none"
                           viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </button>

              <button
                  @click="logout"
                  class="flex rounded-full w-full h-14 font-medium text-xl text-white">
                <div class="flex m-auto space-x-2.5">
                  <div class="my-auto pt-0.5">
                    Logout
                  </div>
                </div>
              </button>
            </div>
          </div>
        </template>

      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonInput,
  toastController,
  IonSkeletonText, useIonRouter, isPlatform
} from '@ionic/vue';
import {defineComponent, onMounted, onUnmounted, Ref, ref} from 'vue';
import {useRouter} from "vue-router";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {UserService} from "@/services/api/user/userService";
import {App, AppState} from "@capacitor/app";
import {UserInterface} from "@/interfaces/UserInterface";

export default defineComponent({
  name: 'AccountNotVerifiedPage',
  components: {
    IonContent,
    IonPage,
    IonSkeletonText
  },
  setup() {

    const ionRouter = useIonRouter()
    const busy = ref(false)
    const loading = ref(true)
    const checkingStatus = ref(false)

    const userService = new UserService()
    const user: Ref<UserInterface | undefined> = ref()

    onMounted(async () => {
      await getUser()
      loading.value = false

      if (isPlatform('capacitor')) {
        App.addListener('appStateChange', async (app: AppState) => {
          if (app.isActive) await checkStatus()
        });
      } else {
        document.addEventListener('visibilitychange', async () => {
          await checkStatus()
        });
      }

    })

    const checkStatus = async () => {
      checkingStatus.value = true
      await getUser()

      if (user.value?.onboarding.email_verified && user.value.onboarding.password_set) {
        await App.removeAllListeners()
        return ionRouter.navigate('/account/onboarding', 'forward', 'push')
      }
      if (user.value?.onboarding.email_verified && !user.value.onboarding.password_set) {
        await App.removeAllListeners()
        return ionRouter.navigate('/account/password', 'forward', 'push')
      }
      checkingStatus.value = false
    }

    const getUser = async () => {
      user.value = await userService.get()
    }

    const logout = async () => {
      if (loading.value || busy.value) return
      await Preferences.clear()
      await ionRouter.navigate('/', 'back', 'pop')
    }

    const resend = async () => {
      if (busy.value || loading.value) return
      try {
        busy.value = true
        await userService.sendEmailVerification()

        const toast = await toastController.create({
          message: `A new verification link has been sent to ${user.value?.email}`,
          duration: 3000,
          position: 'top',
          color: 'primary'
        });

        await toast.present();

      } catch (e) {
        //
      } finally {
        busy.value = false
      }
    }

    return {logout, busy, resend, loading, user, checkingStatus}

  }
});
</script>
<style scoped>
ion-skeleton-text {
  --border-radius: 9999px;
  --background: rgba(255, 255, 255, 0.065);
  --background-rgb: 255, 255, 255;
}
</style>