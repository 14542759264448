<template>
  <div class="flex flex-col items-center p-5 py-10 pt-7 space-y-10">

    <div class="flex flex-col space-y-6 items-center">
      <div class="flex text-brand-lime">
        <svg class="w-16 h-16 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm64 32V320c0 17.7 14.3 32 32 32h16c0-35.3 28.7-64 64-64h96c35.3 0 64 28.7 64 64h16c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H96c-17.7 0-32 14.3-32 32zm96 64a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z"/></svg>
      </div>

      <div class="flex flex-col w-full items-center space-y-1 px-2">
        <div class="flex text-white text-2xl font-medium text-center">Profile Picture</div>
        <div class="text-brand-light-gray text-lg text-center leading-tight">Your profile picture has been submitted for approval. Whilst this is reviewed your current profile picture will remain in place.</div>
      </div>

      <div
          class="flex flex-col space-y-2 w-full items-center">
        <button
            @click="dismiss"
            class="flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
          <div class="flex m-auto space-x-2.5">
            <div class="my-auto pt-0.5">
              <div>Got it!</div>
            </div>
          </div>
        </button>
      </div>


    </div>

  </div>
</template>

<script setup lang="ts">

import {onMounted, ref} from 'vue';
import {modalController} from "@ionic/vue";
const loading = ref(true)

onMounted(async () => {
  loading.value = false
})

const dismiss = async () => {
  await modalController.dismiss()
}

</script>