<template>
  <div class="flex flex-col" :data-comment-id="comment.id">
    <div class="flex justify-between p-4 pt-8 overflow-hidden">
      <div @click.stop="viewProfile(comment.posted_by)" v-if="comment.posted_by" class="flex space-x-2 overflow-hidden">
        <user-avatar class="mt-0 mb-auto" :user="comment.posted_by"/>
        <div class="flex flex-col my-auto -space-y-0.5 truncate">
          <div class="flex flex-col space-y-1">
            <user-verification-check class="h-4" :user="comment.posted_by"/>
            <div class="text-white font-medium text-xl truncate">{{ comment.posted_by.name }}</div>
          </div>
          <div class="flex space-x-2.5 text-brand-light-gray w-full">
            <template v-if="comment.posted_by.location && false">
              <div class="flex space-x-1">
                <svg class="my-auto w-2.5 h-2.5 fill-current" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 384 512">
                  <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <path
                      d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
                </svg>
                <div class="my-auto text-xs">{{ comment.posted_by.location.name }}</div>
              </div>
              <div class="my-auto">•</div>
            </template>
            <div class="text-xs my-auto">{{ postedAt }}</div>
          </div>
        </div>
      </div>
      <div class="flex">
        <svg @click="showContext" class="my-auto ml-5" width="24" height="25" viewBox="0 0 24 25" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M5.52035 14.5583C6.71329 14.5583 7.68035 13.5912 7.68035 12.3983C7.68035 11.2053 6.71329 10.2383 5.52035 10.2383C4.32742 10.2383 3.36035 11.2053 3.36035 12.3983C3.36035 13.5912 4.32742 14.5583 5.52035 14.5583Z"
              fill="#A5A7AF"/>
          <path
              d="M12.0008 14.5583C13.1938 14.5583 14.1608 13.5912 14.1608 12.3983C14.1608 11.2053 13.1938 10.2383 12.0008 10.2383C10.8079 10.2383 9.84082 11.2053 9.84082 12.3983C9.84082 13.5912 10.8079 14.5583 12.0008 14.5583Z"
              fill="#A5A7AF"/>
          <path
              d="M18.4803 14.5583C19.6732 14.5583 20.6403 13.5912 20.6403 12.3983C20.6403 11.2053 19.6732 10.2383 18.4803 10.2383C17.2874 10.2383 16.3203 11.2053 16.3203 12.3983C16.3203 13.5912 17.2874 14.5583 18.4803 14.5583Z"
              fill="#A5A7AF"/>
        </svg>
      </div>
    </div>

    <div class="flex flex-col pl-14 overflow-hidden max-w-full" :id="'comment' + comment.id">
      <div class="flex flex-col space-y-5 p-4 pt-0 text-lg text-brand-light-gray pb-4 post-content"
           v-html="sanitizeHtml(commentContent)"></div>
      <div v-if="comment.edited" class="px-4 -mt-4 pb-4 text-brand-light-gray text-xxs my-auto">Edited</div>

      <div class="flex flex-col border-b border-brand-dark-gray mx-4 space-x-2">
        <div class="flex justify-between">
          <div class="flex space-x-2">
            <div v-if="canReply" @click="emit('onReply', comment)" class="flex py-3">
              <div class="text-brand-light-gray text-sm  my-auto">Reply</div>
            </div>

            <div class="flex py-3" v-if="totalLikes" @click="showLikedBy">
              <div class="text-sm px-2 my-auto text-brand-light-gray">{{ totalLikes }}</div>
            </div>
          </div>

          <div class="flex flex-grow ml-auto justify-end">
            <div class="flex h-full  text-brand-darkish-gray px-2 -mr-2" @click="like">
              <svg v-if="!commentatorReacted" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke-width="1.5" stroke="currentColor" class="w-5 h-5 my-auto">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"/>
              </svg>
              <svg v-if="commentatorReacted"
                   class="fill-current w-5 h-5 text-red-500 my-auto"
                   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path
                    d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z"/>
              </svg>
            </div>
          </div>

        </div>
      </div>
      <feed-post-comment
          v-for="(comment,commentIndex) in comment.comments"
          @on-reply="emit('onReply', comment)"
          @tapped-profile="emit('tappedProfile', comment.posted_by)"
          :key="commentIndex"
          :comment="comment"/>

    </div>
  </div>
</template>
<script setup lang="ts">

import {computed, onBeforeUnmount, onMounted, onUpdated, PropType, ref, watch} from "vue";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import {DateTime} from "luxon";
import pluralize from "pluralize"
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {isPlatform, modalController, useIonRouter} from "@ionic/vue";
import FeedPostContextMenu from "@/views/feed/FeedPostContextMenu.vue";
import FeedCommentContextMenu from "@/views/feed/FeedCommentContextMenu.vue";
import UserVerificationCheck from "@/components/UserBadges.vue";
import * as sanitizeHtml from 'sanitize-html';
import UsersList from "@/views/common/PostUsersList.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import {Haptics, ImpactStyle} from "@capacitor/haptics";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import {useVerification} from "@/composables/useVerification";

const props = defineProps({
  comment: {
    type: Object as PropType<FeedPostInterface>,
    required: true
  },
  canReply: {
    type: Boolean,
    default: true
  }
})

const authUser = ref()

const emit = defineEmits(['onReply', 'tappedProfile'])

const commentRef = ref(props.comment)

const postedAt = computed(() => {
  return DateTime.fromISO(commentRef.value.created_at).toRelative()
})

const updatedAt = computed(() => {
  return DateTime.fromISO(commentRef.value.updated_at).toRelative()
})

const totalLikes = ref('')
const commentatorReacted = ref(false)

watch(() => props.comment, comment => {

  commentRef.value = comment

  const likes = comment.reactions.map(reaction => {
    return reaction.count
  }).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
  );
  totalLikes.value = !likes ? '' : `${likes} ${pluralize('like', likes)}`

  const reacted = comment.reactions.filter(reaction => {
    return reaction.commentator_reacted
  })
  commentatorReacted.value = reacted.length > 0

}, {deep: true, immediate: true})

const showContext = async () => {

  if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});
  if (authUser.value && !authUser.value.id_verified) return await useVerification().presentVerificationRequired('You must be verified to report comments or wink at users.')

  const sheet = await modalController.create({
    breakpoints: [0, 1],
    initialBreakpoint: 1,
    component: FeedCommentContextMenu,
    componentProps: {
      comment: props.comment
    },
    mode: 'ios'
  })
  await sheet.present()
}

const like = async () => {
  try {

    if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});
    if (authUser.value && !authUser.value.id_verified) return await useVerification().presentVerificationRequired('You must be verified to like comments.')

    if (commentatorReacted.value) {
      commentRef.value.reactions = props.comment.reactions.map(reaction => {
        if (reaction.commentator_reacted) {
          reaction.commentator_reacted = false
          reaction.count--
        }
        return reaction
      }).filter(reaction => {
        return reaction.count > 0
      })

      return await ApiService.Feed().deletePostCommentReaction(commentRef.value.id)
    }
    if (!commentatorReacted.value) {
      commentRef.value.reactions.push({
        commentator_reacted: true,
        count: 1,
        reaction: '❤️'
      })
      return await ApiService.Feed().postCommentReaction(commentRef.value.id)
    }

  } catch (e) {
    console.log('error')
    console.log(e)
  }

}

const loading = ref(true)

const commentContent = computed(() => {
  return props.comment?.data.content
})

const showLikedBy = async () => {

  if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});

  const sheet = await modalController.create({
    breakpoints: [0, 1],
    initialBreakpoint: 1,
    component: UsersList,
    componentProps: {
      postId: props.comment.id,
      dismissOnSelection: false
    },
    mode: 'ios'
  })
  await sheet.present()

}

const ionRouter = useIonRouter()

const handleClick = (event: any, link: any) => {
  event.preventDefault();

  if (link.getAttribute('target') === 'mention') {
    ionRouter.navigate({
      name: 'FeedPostMentionedUser',
      params: {
        userId: link.getAttribute('href')
      }
    }, 'forward', 'push')
  }

  if (link.getAttribute('target') === '_system') {
    window.open(link.getAttribute('href'), '_system')
  }

};

const addClickListeners = () => {
  const container = document.getElementById('comment' + props.comment.id) as HTMLElement
  if (container) {
    const links = container.querySelectorAll('a');
    links.forEach((link: any) => {
      link.addEventListener('click', (ev: any) => {
        handleClick(ev, link)
      });
    });
  }
};

const removeClickListeners = () => {
  const container = document.getElementById('comment' + props.comment.id) as HTMLElement
  if (container) {
    const links = container.querySelectorAll('a');
    links.forEach((link: any) => {
      link.removeEventListener('click', handleClick);
    });
  }
};

// Mounted
onMounted(async () => {
  loading.value = false
  authUser.value = await ApiService.User().authUser()
  addClickListeners();
})

onUpdated(() => {
  const container = document.getElementById('comment' + props.comment.id) as HTMLElement

  if (container) {
    const oldATags = container.querySelectorAll('a');
    oldATags.forEach((aTag: any) => {
      aTag.removeEventListener('click', handleClick);
    });
    addClickListeners()
  }
})

onBeforeUnmount(() => {
  removeClickListeners()
})


const viewProfile = async (user: UserBasicInformationInterface) => {
  ionRouter.navigate({
    name: 'PineappleUserProfile',
    params: {
      userId: user.id
    }
  }, 'forward', 'push')
}

</script>