<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="mr-4">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title class="text-white font-medium text-2xl">Bio</ion-title>
        <ion-buttons slot="end">
          <ion-button
              @click="save"
              class="!text-xl">
            <span :class="{
                  'text-brand-light-gray' : !bio.length,
                  'text-brand-lime' : bio.length
                }">Save</span>
          </ion-button>
          <div v-if="busy" class="absolute flex top-0 left-0 w-full h-full bg-brand-black z-50">
            <svg class="animate-spin m-auto h-5 w-5 text-brand-lime" xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="h-full">
      <div class="flex flex-col py-5 px-4 justify-center space-y-4">

        <input-textarea
            v-model="bio"
            placeholder="Introduce yourself..."
            enterkeyhint="next"
            :counter="true"
            :error="errors && errors.hasOwnProperty('bio') ? errors['bio'][0] : null"
            :maxLength="2000" />

      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {defineComponent, inject, Ref, ref} from 'vue';
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import InputField from "@/components/InputField.vue";
import InputTextarea from "@/components/InputTextarea.vue";

export default defineComponent({
  name: 'ProfileSettingsUsername',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonButtons,
    IonButton,
    IonTitle,
    InputTextarea
  },
  setup() {

    const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>
    const loading = ref(true)
    const ionRouter = useIonRouter()

    const busy = ref(false)
    const bio = ref(user.value?.bio.replace(/<[^>]*>/g, ''))
    const errors = ref({})

    onIonViewWillEnter(async () => {
      loading.value = false
    })

    const goBack = async () => {
      await ionRouter.back()
    }

    const save = async () => {
      if (!bio.value || busy.value) return
      errors.value = {}
      busy.value = true
      try {
        await ApiService.User().setBio(bio.value)
        await Observable.emit(ObservableEvent.UserChanged)
        setTimeout(()=>{
          ionRouter.back()
          busy.value = false
        }, 1000)
      } catch (e: any) {
        if (!e.data) return
        if (e.data.errors) errors.value = e.data.errors
        busy.value = false
      }
    }

    return {loading, goBack, user, busy, bio, errors, save}

  }
});
</script>
