<template>
    <ion-page>
        <ion-header>
            <ion-toolbar class="!p-0 !m-0">
                <ion-buttons class="justify-between flex w-full">
                    <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
                        <svg class="fill-current text-white w-6 h-6 p-0.5" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 320 512">
                            <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path
                                    d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                        </svg>
                    </ion-button>
                    <div class="flex relative">
                        <ion-button
                                @click="createChat"
                                class="!text-xl">
                            <span :class="{
                                'opacity-0' : creating,
                              'text-brand-light-gray' : !canCreate,
                              'text-brand-lime' : canCreate
                            }">Create</span>
                        </ion-button>
                        <div v-if="creating" class="absolute flex top-0 left-0 w-full h-full bg-brand-black z-50">
                            <svg class="animate-spin m-auto h-5 w-5 text-brand-lime"
                                 xmlns="http://www.w3.org/2000/svg"
                                 fill="none"
                                 viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                    </div>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content>


            <div class="flex flex-col p-4">
                <div class="text-white font-medium text-2xl">New Chat</div>
                <p class="text-lg text-brand-light-gray">Please provide your group chat with a name and image. You must invite at least 2 other people.</p>
            </div>

            <div class="flex flex-col p-4">
                <div class="flex space-x-2">
                    <div class="flex">
                        <div @click="addPhoto"
                             class="flex w-14 h-14 bg-brand-lime rounded-full relative overflow-hidden">

                            <img v-if="chatPhoto" :src="chatPhoto"
                                 class="w-full h-full object-cover absolute top-0 left-0 z-10"/>

                            <svg v-if="busy" class="animate-spin h-7 w-7 text-brand-black m-auto relative z-50"
                                 xmlns="http://www.w3.org/2000/svg"
                                 fill="none"
                                 viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <svg v-if="!busy" class="m-auto" width="30" height="24" viewBox="0 0 30 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.6094 12.9219C19.6094 14.1444 19.1237 15.3168 18.2593 16.1812C17.3949 17.0456 16.2225 17.5312 15 17.5312C13.7775 17.5312 12.6051 17.0456 11.7407 16.1812C10.8763 15.3168 10.3906 14.1444 10.3906 12.9219C10.3906 11.6994 10.8763 10.527 11.7407 9.66255C12.6051 8.79813 13.7775 8.3125 15 8.3125C16.2225 8.3125 17.3949 8.79813 18.2593 9.66255C19.1237 10.527 19.6094 11.6994 19.6094 12.9219Z"
                                      fill="#1A1B22"/>
                                <path d="M3.9375 4.625C2.95951 4.625 2.02158 5.0135 1.33004 5.70504C0.638503 6.39658 0.25 7.33451 0.25 8.3125L0.25 19.375C0.25 20.353 0.638503 21.2909 1.33004 21.9825C2.02158 22.674 2.95951 23.0625 3.9375 23.0625H26.0625C27.0405 23.0625 27.9784 22.674 28.67 21.9825C29.3615 21.2909 29.75 20.353 29.75 19.375V8.3125C29.75 7.33451 29.3615 6.39658 28.67 5.70504C27.9784 5.0135 27.0405 4.625 26.0625 4.625H23.9016C22.9237 4.62479 21.9859 4.23615 21.2946 3.54456L19.7679 2.01794C19.0766 1.32635 18.1388 0.937709 17.1609 0.9375H12.8391C11.8612 0.937709 10.9234 1.32635 10.2321 2.01794L8.70544 3.54456C8.01406 4.23615 7.07628 4.62479 6.09837 4.625H3.9375ZM4.85938 8.3125C4.61488 8.3125 4.3804 8.21537 4.20751 8.04249C4.03463 7.8696 3.9375 7.63512 3.9375 7.39062C3.9375 7.14613 4.03463 6.91165 4.20751 6.73876C4.3804 6.56588 4.61488 6.46875 4.85938 6.46875C5.10387 6.46875 5.33835 6.56588 5.51124 6.73876C5.68412 6.91165 5.78125 7.14613 5.78125 7.39062C5.78125 7.63512 5.68412 7.8696 5.51124 8.04249C5.33835 8.21537 5.10387 8.3125 4.85938 8.3125ZM21.4531 12.9219C21.4531 14.6334 20.7732 16.2747 19.563 17.4849C18.3529 18.6951 16.7115 19.375 15 19.375C13.2885 19.375 11.6471 18.6951 10.437 17.4849C9.22676 16.2747 8.54688 14.6334 8.54688 12.9219C8.54688 11.2104 9.22676 9.56902 10.437 8.35883C11.6471 7.14863 13.2885 6.46875 15 6.46875C16.7115 6.46875 18.3529 7.14863 19.563 8.35883C20.7732 9.56902 21.4531 11.2104 21.4531 12.9219Z"
                                      fill="#1A1B22"/>
                            </svg>
                        </div>
                    </div>
                    <input-field
                            v-model="chatName"
                            placeholder="Name your chat"/>
                </div>
            </div>

            <div class="flex flex-col p-4 space-y-4">

                <div class="flex flex-col space-y-2">
                    <div class="font-medium text-xl text-white">Invite people</div>
                    <input-field
                            placeholder="Search by username"
                            :clear-input="true"
                            :debounce="300"
                            v-model="participantSearch">
                        <template v-slot:icon-start>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 21L16.514 16.506L21 21ZM19 10.5C19 12.7543 18.1045 14.9163 16.5104 16.5104C14.9163 18.1045 12.7543 19 10.5 19C8.24566 19 6.08365 18.1045 4.48959 16.5104C2.89553 14.9163 2 12.7543 2 10.5C2 8.24566 2.89553 6.08365 4.48959 4.48959C6.08365 2.89553 8.24566 2 10.5 2C12.7543 2 14.9163 2.89553 16.5104 4.48959C18.1045 6.08365 19 8.24566 19 10.5V10.5Z"
                                      stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </template>
                    </input-field>
                </div>

                <div v-if="chatParticipants.length" class="flex flex-col space-y-2">
                    <div class="font-medium text-white">Invited</div>
                    <div
                            @click="chatParticipants.splice(participantIndex,1)"
                            v-for="(participant,participantIndex) in chatParticipants"
                            :key="participant.id"
                            class="rounded-2xl bg-brand-dark-gray text-white flex px-4 py-3 justify-between">

                        <div class="flex space-x-4">
                            <user-avatar :user="participant" />
                            <div class="flex text-white text-lg font-medium my-auto">{{ participant.name }}</div>
                        </div>
                        <div class="flex my-auto">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22C10.6864 22.0013 9.38546 21.7436 8.17162 21.2415C6.95777 20.7394 5.85485 20.0029 4.926 19.074C3.99716 18.1452 3.26061 17.0423 2.75853 15.8284C2.25645 14.6146 1.99869 13.3136 2.00001 12V11.8C2.04001 9.83944 2.6553 7.93383 3.76939 6.32005C4.88348 4.70626 6.44726 3.45546 8.26636 2.72309C10.0855 1.99071 12.0797 1.80907 14.0012 2.20073C15.9227 2.59239 17.6867 3.54008 19.074 4.92603C20.4737 6.32457 21.4271 8.10693 21.8134 10.0475C22.1997 11.9881 22.0017 13.9997 21.2443 15.8277C20.487 17.6556 19.2043 19.2179 17.5588 20.3166C15.9132 21.4153 13.9787 22.0012 12 22ZM12 13.41L14.59 16L16 14.59L13.41 12L16 9.41003L14.59 8.00003L12 10.59L9.41001 8.00003L8.00001 9.41003L10.59 12L8.00001 14.59L9.41001 16L12 13.411V13.41Z"
                                      fill="#A5A7AF"/>
                            </svg>
                        </div>
                    </div>

                </div>

                <div v-if="participantSearch.length && participantSearchResults.length" class="flex flex-col space-y-2">
                    <div class="font-medium text-white">Search results ({{ participantSearchResults.length }})</div>
                    <div
                            @click="chatParticipants.push(participant)"
                            v-for="participant in participantSearchResults"
                            :key="participant.id"
                            class="rounded-2xl bg-brand-dark-gray text-white flex px-4 py-3 space-x-4">

                      <user-avatar :user="participant" />

                        <div class="flex space-x-2">
                            <div class="flex text-white text-lg font-medium my-auto">{{ participant.name }}</div>
                            <user-verification-check class="h-4" :user="participant"/>
                        </div>
                    </div>

                </div>


            </div>

            <div class="safe-area-bottom"></div>

        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import {
    actionSheetController,
    alertController,
    IonButton,
    IonButtons,
    IonContent, IonHeader,
    IonPage,
    IonTextarea, IonToolbar,
    modalController, toastController,
    useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, PropType, Ref, ref, watch} from 'vue';
import {useRoute} from "vue-router";
import InputCheckbox from "@/components/InputCheckbox.vue";
import {PostPrivacy} from "@/definitions/PostPrivacy";
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import InputField from "@/components/InputField.vue";
import MenuButton from "@/components/MenuButton.vue";
import {SearchUserInterface} from "@/interfaces/SearchUserInterface";
import _ from "lodash";
import UserVerificationCheck from "@/components/UserBadges.vue";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import UserAvatar from "@/components/UserAvatar.vue";

export default defineComponent({
    name: 'FeedNewPostPage',
    methods: {useRoute},
    props: {
        post: {
            type: Object as PropType<FeedPostInterface>,
            required: false
        }
    },
    components: {
      UserAvatar,
        UserVerificationCheck,
        InputField,
        IonPage,
        IonContent,
        IonButtons,
        IonButton,
        IonToolbar,
        IonHeader
    },
    setup() {

        const loading = ref(true)
        const busy = ref(false)
        const creating = ref(false)

        const chatName = ref('')
        const chatPhoto = ref('')
        const chatParticipants: Ref<UserBasicInformationInterface[]> = ref([])

        const participantSearch = ref('')
        const participantSearchResults: Ref<UserBasicInformationInterface[]> = ref([])

        onMounted(async () => {
            loading.value = false
        })

        const goBack = async () => {

            if (busy.value || creating.value) return

            const modal = await alertController.create({
                header: 'Discard Chat',
                message: 'Are you sure you want to discard this group?',
                buttons: [
                    {
                        text: 'Yes',
                        role: 'destructive',
                        handler: () => {
                            modalController.dismiss()
                        }
                    },
                    {
                        text: 'No',
                    },

                ]
            })

            await modal.present()

        }

        const addPhoto = async () => {

            if (busy.value || creating.value) return

            const actionSheet = await actionSheetController.create({
                buttons: [
                    {
                        text: 'Take Photo',
                        handler: async () => {
                            try {
                                const photo = await useCamera(CameraSource.Camera, CameraResultType.Base64)
                                busy.value = true
                                if (Object.prototype.hasOwnProperty.call(photo, 'base64String')) chatPhoto.value = `data:image/${photo.format};base64,${photo.base64String}`
                                busy.value = false
                            } catch (e) {
                                busy.value = false
                            }
                        }
                    },
                    {
                        text: 'Choose Photo',
                        handler: async () => {
                            try {
                                const photo = await useCamera(CameraSource.Photos, CameraResultType.Base64)
                                busy.value = true
                                if (Object.prototype.hasOwnProperty.call(photo, 'base64String')) chatPhoto.value = `data:image/${photo.format};base64,${photo.base64String}`
                                busy.value = false
                            } catch (e) {
                                busy.value = false
                            }
                        }
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel'
                    }
                ]
            })

            await actionSheet.present()

        }

        const canCreate = computed(() => {
            return chatName.value.length && chatParticipants.value.length > 1
        })

        const createChat = async () => {

            if (creating.value) return

            try {
                creating.value = true
                const chat = await ApiService.Chat().create(chatName.value, chatParticipants.value.flatMap(participant => participant.id))
                if (chatPhoto.value) await ApiService.Chat().setImage(chat.id, chatPhoto.value)
                Observable.emit(ObservableEvent.ChatsChanged)
                await modalController.dismiss()
            } catch (e: any) {
                const toast = await toastController.create({
                    message: e.data.message,
                    duration: 3000,
                    position: 'bottom',
                    color: 'danger'
                });
                await toast.present();
                creating.value = false
            }
        }

        const participantSearchResultsCopy: Ref<UserBasicInformationInterface[]> = ref([])

        watch(() => participantSearch.value, async query => {
            participantSearchResults.value = await ApiService.Search().chatUsers(query)
            participantSearchResultsCopy.value = _.cloneDeep(participantSearchResults.value)
        })

        watch(() => chatParticipants.value, async participants => {
            const participantIds = participants.map((p) => p.id)
            participantSearchResults.value = participantSearchResultsCopy.value.filter((participant) => {
                return !participantIds.includes(participant.id)
            })
        }, {deep: true})

        return {
            goBack,
            createChat,
            canCreate,
            addPhoto,
            busy,
            loading,
            creating,
            PostPrivacy,
            chatName,
            chatPhoto,
            participantSearch,
            chatParticipants,
            participantSearchResults
        }

    }
});
</script>

<style scoped>
ion-header ion-toolbar {
    --padding-start: 16px !important;
    --padding-end: 16px !important;
    --padding-top: 16px !important;
    --padding-top: 16px !important;
}
</style>