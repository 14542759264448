<template>
  <ion-page>
    <ion-content>

      <div class="flex flex-col w-full bg-brand-black my-auto px-4 pt-14 z-50">

        <div class="flex flex-col space-y-6">
          <div class="flex flex-col w-full space-y-2">
            <div class="flex text-white text-2xl font-medium">Create your password</div>
          </div>

          <div class="flex flex-col space-y-2">
            <input-field
                v-model="form.password"
                type="password"
                placeholder="Create password">
            </input-field>
            <input-field
                :error="errors && errors['password'] ? errors['password'][0] : undefined"
                v-model="form.password_confirmation"
                type="password"
                @onKeyEnter="setPassword"
                placeholder="Confirm password">
            </input-field>
          </div>


        </div>

        <button
            @click="setPassword"
            :class="{
              'bg-opacity-40' : !canSetPassword
            }"
            class="mt-10 flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
          <div class="flex m-auto space-x-2.5">
            <div class="my-auto pt-0.5">
              <div v-if="!busy">Continue</div>
              <div v-if="busy">
                <svg class="animate-spin h-5 w-5 text-brand-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
          </div>
        </button>


      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  useIonRouter
} from '@ionic/vue';
import {computed, defineComponent, reactive, ref} from 'vue';
import InputField from "@/components/InputField.vue";
import {UserService} from "@/services/api/user/userService";

export default defineComponent({
  name: 'AccountPasswordPage',
  components: {
    InputField,
    IonContent,
    IonPage,
  },
  setup() {

    const ionRouter = useIonRouter()
    const errors = ref({})
    const busy = ref(false)
    const form = reactive({
      password: '',
      password_confirmation: ''
    })

    const userService = new UserService()

    const canSetPassword = computed(()=>{
      return !!(form.password && form.password_confirmation);
    })

    const setPassword = async () => {
      if (!canSetPassword.value || busy.value) return
      busy.value = true
      try {

        await userService.changePassword(form.password, form.password_confirmation)
        form.password = ''
        form.password_confirmation = ''

        await ionRouter.navigate('/account/onboarding', 'forward', 'replace')

      } catch (e:any) {
        if (!e.data) return
        if (e.data.errors) errors.value = e.data.errors
      } finally {
        busy.value = false
      }
    }


    return {canSetPassword, setPassword, busy, errors, form}

  }
});
</script>
