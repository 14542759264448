<template>
  <div class="flex flex-col items-center p-4 py-10 space-y-10">

    <div class="flex flex-col space-y-6 items-center">
      <div class="h-20 w-20 bg-brand-lime text-brand-black rounded-full flex">
        <div class="m-auto">
          <svg class="w-10 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
            <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
                d="M155.6 17.3C163 3 179.9-3.6 195 1.9L320 47.5l125-45.6c15.1-5.5 32 1.1 39.4 15.4l78.8 152.9c28.8 55.8 10.3 122.3-38.5 156.6L556.1 413l41-15c16.6-6 35 2.5 41 19.1s-2.5 35-19.1 41l-71.1 25.9L476.8 510c-16.6 6.1-35-2.5-41-19.1s2.5-35 19.1-41l41-15-31.3-86.2c-59.4 5.2-116.2-34-130-95.2L320 188.8l-14.6 64.7c-13.8 61.3-70.6 100.4-130 95.2l-31.3 86.2 41 15c16.6 6 25.2 24.4 19.1 41s-24.4 25.2-41 19.1L92.2 484.1 21.1 458.2c-16.6-6.1-25.2-24.4-19.1-41s24.4-25.2 41-19.1l41 15 31.3-86.2C66.5 292.5 48.1 226 76.9 170.2L155.6 17.3zm44 54.4l-27.2 52.8L261.6 157l13.1-57.9L199.6 71.7zm240.9 0L365.4 99.1 378.5 157l89.2-32.5L440.5 71.7z"/>
          </svg>
        </div>
      </div>

      <div class="flex flex-col w-full items-center space-y-1 ">
        <div class="flex text-white text-2xl font-medium text-center">Availability</div>
        <div class="text-brand-light-gray text-lg text-center space-y-4">
          <p>Choose how long would you like to be available to meet for?</p>
        </div>
      </div>
    </div>

    <div class="flex flex-col space-y-2 w-full">
      <input-checkbox
          @click="setOption"
          v-for="(o,oIndex) in options"
          :key="oIndex"
          name="option"
          v-model="option"
          :return-single-value="true"
          :option="o"
      />
    </div>

  </div>
</template>

<script setup lang="ts">
import InputCheckbox from "@/components/InputCheckbox.vue";
import {Ref, ref} from "vue";
import {modalController} from "@ionic/vue";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

const options = [
  {
    text: 'Available for 3 hours',
    value: 3
  },
  {
    text: 'Available for 6 hours',
    value: 6
  },
  {
    text: 'Available for 9 hours',
    value: 9
  },
  {
    text: 'Available for 12 hours',
    value: 12
  },
  {
    text: 'Available for 15 hours',
    value: 15
  },
  {
    text: 'Not available to meet',
    value: 0
  }
]

const option: Ref<any[]> = ref([false])

const setOption = async () => {
  const user = await ApiService.User().setAvailability(option.value[0])
  await Observable.emit(ObservableEvent.UserChanged)
  await modalController.dismiss(user)
}

</script>