<template>
  <div class="flex flex-col border-b-4 border-brand-dark-gray pb-2">
    <div class="flex justify-between p-4 pt-6">
      <div @click.stop="viewProfile(review.reviewer)" v-if="review.reviewer" class="flex space-x-2">
        <user-avatar :user="review.reviewer"/>
        <div class="flex flex-col my-auto -space-y-0.5">
          <div class="flex space-x-2">
            <div class="text-white font-medium text-xl">{{ review.reviewer.name }}</div>
            <user-verification-check class="h-4" :user="review.reviewer"/>
          </div>
          <div class="flex space-x-2.5 text-brand-light-gray w-full">
            <template v-if="review.reviewer.location && false">
              <div class="flex space-x-1">
                <svg class="my-auto w-2.5 h-2.5 fill-current" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 384 512">
                  <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <path
                      d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
                </svg>
                <div class="my-auto text-xs">{{ review.reviewer.location.name }}</div>
              </div>
              <div class="my-auto">•</div>
            </template>
            <div class="text-xs my-auto">{{ postedAt }}</div>
          </div>
        </div>
      </div>
      <svg v-if="authUser && (authUser.id === review.user_id || authUser.id === review.reviewer.id)"  @click="showContext" class="my-auto" width="24" height="25" viewBox="0 0 24 25" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.52035 14.5583C6.71329 14.5583 7.68035 13.5912 7.68035 12.3983C7.68035 11.2053 6.71329 10.2383 5.52035 10.2383C4.32742 10.2383 3.36035 11.2053 3.36035 12.3983C3.36035 13.5912 4.32742 14.5583 5.52035 14.5583Z"
            fill="#A5A7AF"/>
        <path
            d="M12.0008 14.5583C13.1938 14.5583 14.1608 13.5912 14.1608 12.3983C14.1608 11.2053 13.1938 10.2383 12.0008 10.2383C10.8079 10.2383 9.84082 11.2053 9.84082 12.3983C9.84082 13.5912 10.8079 14.5583 12.0008 14.5583Z"
            fill="#A5A7AF"/>
        <path
            d="M18.4803 14.5583C19.6732 14.5583 20.6403 13.5912 20.6403 12.3983C20.6403 11.2053 19.6732 10.2383 18.4803 10.2383C17.2874 10.2383 16.3203 11.2053 16.3203 12.3983C16.3203 13.5912 17.2874 14.5583 18.4803 14.5583Z"
            fill="#A5A7AF"/>
      </svg>
    </div>

    <div class="flex flex-col pl-12" :id="'comment' + review.id">
      <div class="flex flex-col space-y-5 p-4 pt-0 text-lg text-white pb-4 post-content"
           v-html="sanitizeHtml(review.text)"></div>
      <div v-if="review.edited_at" class="px-4 -mt-4 pb-4 text-brand-light-gray text-xxs my-auto">Edited</div>
    </div>
  </div>
</template>
<script setup lang="ts">

import {computed, onMounted, PropType, ref} from "vue";
import ApiService from "@/services/api/apiService";
import {isPlatform, modalController, useIonRouter} from "@ionic/vue";
import UserVerificationCheck from "@/components/UserBadges.vue";
import * as sanitizeHtml from 'sanitize-html';
import UserAvatar from "@/components/UserAvatar.vue";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import {ReviewInterface} from "@/interfaces/ReviewInterface";
import {DateTime} from "luxon";
import {Haptics, ImpactStyle} from "@capacitor/haptics";
import {useVerification} from "@/composables/useVerification";
import UserReviewContextMenu from "@/components/UserReviewContextMenu.vue";

const props = defineProps({
  review: {
    type: Object as PropType<ReviewInterface>,
    required: true
  }
})

const authUser = ref()

const emit = defineEmits(['onReply', 'tappedProfile'])

const postedAt = computed(() => {
  return DateTime.fromISO(props.review.created_at).toRelative()
})

const showContext = async () => {

  const sheet = await modalController.create({
    breakpoints: [0, 1],
    initialBreakpoint: 1,
    component: UserReviewContextMenu,
    componentProps: {
      review: props.review
    },
    mode: 'ios'
  })
  await sheet.present()
}

const loading = ref(true)

const ionRouter = useIonRouter()

// Mounted
onMounted(async () => {
  authUser.value = await ApiService.User().authUser()
  loading.value = false
})

const viewProfile = async (user: UserBasicInformationInterface) => {
  if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});
  ionRouter.navigate({
    name: 'PineappleUserProfile',
    params: {
      userId: user.id
    }
  }, 'forward', 'push')
}

</script>