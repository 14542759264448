<template>
  <ion-page>
    <ion-content>
      <div class="flex flex-col w-full bg-brand-black my-auto px-6 mx-auto pt-14 z-50 relative items-center">

        <div class="flex flex-col space-y-6 w-full mx-auto">
          <div class="text-white font-medium text-2xl">Hello Sweet Stuff</div>

          <div class="flex flex-col space-y-2">

            <input-field
                :error="errors && errors['email'] ? errors['email'][0] : undefined"
                v-model="form.email"
                type="email"
                placeholder="Email address">
              <template v-slot:icon-start>
                <svg class="fill-current" width="24" height="24" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M19.2 4.80078H4.8C3.81 4.80078 3.009 5.61078 3.009 6.60078L3 17.4008C3 18.3908 3.81 19.2008 4.8 19.2008H19.2C20.19 19.2008 21 18.3908 21 17.4008V6.60078C21 5.61078 20.19 4.80078 19.2 4.80078ZM18.84 8.62578L12.477 12.6038C12.189 12.7838 11.811 12.7838 11.523 12.6038L5.16 8.62578C5.06975 8.57512 4.99073 8.50668 4.9277 8.42459C4.86467 8.3425 4.81896 8.24848 4.79332 8.14821C4.76768 8.04795 4.76265 7.94352 4.77854 7.84126C4.79443 7.73899 4.8309 7.64101 4.88575 7.55325C4.9406 7.46549 5.01269 7.38977 5.09765 7.33068C5.18262 7.27158 5.27868 7.23034 5.38005 7.20945C5.48141 7.18856 5.58595 7.18845 5.68736 7.20913C5.78876 7.22982 5.88492 7.27086 5.97 7.32978L12 11.1008L18.03 7.32978C18.1151 7.27086 18.2112 7.22982 18.3126 7.20913C18.414 7.18845 18.5186 7.18856 18.62 7.20945C18.7213 7.23034 18.8174 7.27158 18.9023 7.33068C18.9873 7.38977 19.0594 7.46549 19.1142 7.55325C19.1691 7.64101 19.2056 7.73899 19.2215 7.84126C19.2373 7.94352 19.2323 8.04795 19.2067 8.14821C19.181 8.24848 19.1353 8.3425 19.0723 8.42459C19.0093 8.50668 18.9302 8.57512 18.84 8.62578Z"
                      />
                </svg>
              </template>
            </input-field>

            <input-field
                :error="errors && errors['password'] ? errors['password'][0] : undefined"
                v-model="form.password"
                enterkeyhint="Sign in"
                @onKeyEnter="signIn"
                type="password"
                placeholder="Password">
              <template v-slot:icon-start>
                <svg class="fill-current" width="24" height="24" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_212_169963)">
                    <path
                        d="M8.72763 11.1818H15.2731V8.72727C15.2731 7.82386 14.9535 7.05256 14.3143 6.41335C13.6751 5.77415 12.9038 5.45455 12.0004 5.45455C11.0969 5.45455 10.3256 5.77415 9.68643 6.41335C9.04723 7.05256 8.72763 7.82386 8.72763 8.72727V11.1818ZM19.364 12.4091V19.7727C19.364 20.1136 19.2447 20.4034 19.006 20.642C18.7674 20.8807 18.4776 21 18.1367 21H5.86399C5.52308 21 5.23331 20.8807 4.99467 20.642C4.75604 20.4034 4.63672 20.1136 4.63672 19.7727V12.4091C4.63672 12.0682 4.75604 11.7784 4.99467 11.5398C5.23331 11.3011 5.52308 11.1818 5.86399 11.1818H6.27308V8.72727C6.27308 7.15909 6.83558 5.8125 7.96058 4.6875C9.08558 3.5625 10.4322 3 12.0004 3C13.5685 3 14.9151 3.5625 16.0401 4.6875C17.1651 5.8125 17.7276 7.15909 17.7276 8.72727V11.1818H18.1367C18.4776 11.1818 18.7674 11.3011 19.006 11.5398C19.2447 11.7784 19.364 12.0682 19.364 12.4091Z"
                        />
                  </g>
                  <defs>
                    <clipPath id="clip0_212_169963">
                      <rect width="14.7273" height="18" transform="translate(4.63672 3)"/>
                    </clipPath>
                  </defs>
                </svg>
              </template>
            </input-field>

            <div @click="forgotPassword" class="flex w-full">
              <div class="text-white font-medium text-base mt-2">Forgot password?</div>
            </div>
          </div>


        </div>

        <button
            @click="signIn"
            :class="{
              'bg-opacity-40' : !canSignIn
            }"
            class="mt-10 flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
          <div class="flex m-auto space-x-2.5">
            <div class="my-auto pt-0.5">
              <div v-if="!busy">Sign in</div>
              <div v-if="busy">
                <svg class="animate-spin h-5 w-5 text-brand-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
          </div>
        </button>

      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import {useRouter} from "vue-router";
import InputField from "@/components/InputField.vue";
import AuthService from "@/services/api/auth/authService";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {UserService} from "@/services/api/user/userService";

export default defineComponent({
  name: 'LoginPage',
  components: {
    InputField,
    IonContent,
    IonPage,
  },
  setup() {

    const router = useRouter()
    const ionRouter = useIonRouter()

    const form = reactive({
      email: '',
      password: ''
    })

    const errors = ref({})

    const busy = ref(false)

    const goBack = async () => {
      await router.back()
    }

    const forgotPassword =  async () => {
      window.open(`${process.env.VUE_APP_WEB_URL}/forgot-password`, '_system')
    }

    const signIn = async () => {
      if (!canSignIn.value || busy.value) return

      try {
        busy.value = true
        errors.value = {}
        const [authService, userService] = [new AuthService(), new UserService()]

        await authService.login(form.email, form.password)
        await ionRouter.navigate('/', 'forward', 'pop')

      } catch (e:any) {
          if (!e.data) return
        if (e.data.errors) errors.value = e.data.errors
      } finally {
        busy.value = false
      }

    }

    const canSignIn = computed(()=>{
      if (form.email.length && form.password.length) return true
      return false
    })

    return {goBack,forgotPassword, signIn, canSignIn, form, busy, errors}

  }
});
</script>
