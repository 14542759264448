<template>
  <div class="flex flex-col items-center p-5 py-10 pt-10 space-y-10">

    <div class="flex flex-col space-y-6 items-center">
      <div class="flex text-brand-lime">
        <svg  class="w-16 h-16 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
      </div>

      <div class="flex flex-col w-full items-center space-y-1 px-2">
        <div class="flex text-white text-2xl font-medium text-center">Your Profile</div>
        <div class="text-brand-light-gray text-lg text-center leading-tight">It's time to setup your profile to help others get to know you.</div>
        <div v-if="user?.account_type === AccountType.Joint" class="text-gray-500 text-sm text-center leading-tight py-3 px-5">As a joint account you can have more than one profile for each of you in the relationship. You'll be able to add another profile after you've added your first.</div>
      </div>

      <div
          class="flex flex-col space-y-2 w-full items-center">
        <button
            @click="setup"
            class="flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
          <div class="flex w-full  m-auto space-x-2.5">
            <div class="m-auto pt-0.5">
              <div>Create Profile</div>
            </div>
          </div>
        </button>
      </div>


    </div>

  </div>
</template>

<script setup lang="ts">

import {onMounted, PropType, ref} from 'vue';
import {modalController} from "@ionic/vue";
import ApiService from "@/services/api/apiService";
import {UserProfileInterface} from "@/interfaces/UserProfileInterface";
import ProfileSettingsProfile from "@/views/user/profile/ProfileSettingsProfile.vue";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import {AccountType} from "@/definitions/AccountType";

const loading = ref(true)

onMounted(async () => {
  loading.value = false
})

const props = defineProps({
  user: {
    type: Object as PropType<UserBasicInformationInterface>
  }
})

const dismiss = async () => {
  await modalController.dismiss()
}

const busy = ref(false)

const setup = async () => {
  if (busy.value) return
  busy.value = true
  await modalController.dismiss()
  const profile = await ApiService.User().createProfile()
  await viewProfile(profile)

}

const viewProfile = async (profile: UserProfileInterface) => {
  const modal = await modalController.create({
    component: ProfileSettingsProfile,
    mode: 'ios',
    componentProps: {
      profile
    },
    presentingElement: document.getElementById('tabs') as HTMLElement,
  })
  await modal.present()
}

</script>