<template>
  <div class="flex flex-col w-full relative">
    <div @click="seeViewers" v-if="!loading && !user.subscribed" class="w-full h-full bg-brand-black/5 z-40 top-0 absolute backdrop-blur left-0"></div>
    <div v-if="loading" class="py-16 m-auto">
      <svg class="animate-spin h-5 w-5 text-brand-light-gray" xmlns="http://www.w3.org/2000/svg" fill="none"
           viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>

    </div>
    <div class="flex flex-grow items-center justify-center">

      <div
          v-if="!viewers.length && !loading"
          class="px-4 py-6 flex w-full">
        <div
            class="w-full mx-auto flex justify-between bg-brand-dark-gray text-white items-center text-center p-4 h-[72px] rounded-2xl shadow-xl space-x-4">
          <div class="flex flex-col my-auto w-full space-y-1 -mt-1.5">
            <div class="flex space-x-2 mx-auto">
              <div class="my-auto text-lg leading-normal text-center font-medium text-white">
                Check back soon!
              </div>
            </div>
            <div class="flex space-x-1 mx-auto">
              <div class="text-xs">When somebody views your profile they'll appear here.</div>
            </div>
          </div>

        </div>
      </div>

      <swiper
          v-if="viewers.length"
          ref="swiper"
          :space-between="0"
          :slides-offset-before="0"
          :slides-offset-after="0"
          :slides-per-view="viewers.length > 1 ? 1.5 : 1"
          :centered-slides="true"
          :modules="[Virtual]"
          :virtual="{
          enabled: true
        }"
          @swiper="onSwiper"
      >
        <swiper-slide
            class="py-8"
            @click.stop="viewUserProfile(viewer.user.id)"
            v-for="(viewer, viewerKey) in viewers"
            :key="viewerKey">
          <div class="flex px-1.5">
            <div
                class="w-[250px] mx-auto flex justify-between bg-brand-dark-gray text-white p-2 pr-4 rounded-2xl shadow-xl space-x-3">
              <div class="flex relative">
                <div
                    :style="{
                  backgroundImage: `url('${viewer.user.avatar_thumbnail ?? viewer.user.avatar}')`
                }"
                    class="w-14 h-14 rounded-xl bg-brand-dark-gray !bg-cover">

                </div>
                <user-account-type-badge class="absolute -bottom-0.5 -right-0.5 border-2 border-brand-black"
                                         :user="viewer.user"/>
              </div>
              <div class="flex flex-col my-auto w-full pb-1 space-y-0.5">
                <div class="flex space-x-2">
                  <div
                      class="my-auto text-lg leading-normal font-medium text-white break-all text-ellipsis line-clamp-1">
                    {{ viewer.user.name }}
                  </div>
                  <user-badges :user="viewer.user"/>
                </div>
                <div class="flex space-x-1">
                  <div class="flex my-auto">
                    <svg class="fill-current w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                      <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                      <path
                          d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
                    </svg>

                  </div>
                  <div class="text-xs">viewed {{viewedAt(viewer.last_viewed)}}</div>
                </div>
              </div>

            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div v-if="!loading" class="flex flex-col text-xs px-5 w-full text-center items-center pb-4 z-50">
      <div class="text-brand-light-gray text-opacity-80">Viewed your profile in the last 7 days.</div>
      <div class="text-xxs text-brand-light-gray text-opacity-50">You can opt out of view receipts from your account settings.</div>
    </div>

  </div>
</template>
<script setup lang="ts">

import {computed, onMounted, onUnmounted, PropType, Ref, ref} from "vue";
import {DateTime} from "luxon";
import {ChatInterface} from "@/interfaces/ChatInterface";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import ApiService from "@/services/api/apiService";
import UserAccountTypeBadge from "@/components/UserAccountTypeBadge.vue";
import UserBadges from "@/components/UserBadges.vue";
import {UserViewerInterface} from "@/interfaces/UserViewerInterface";
import {Virtual} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import {useIonRouter} from "@ionic/vue";
import pluralize from "pluralize";
import {usePremium} from "@/composables/usePremium";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

const props = defineProps({
  user: {
    type: Object as PropType<UserBasicInformationInterface>,
    required: true
  },
})


const loading = ref(true)
const viewers: Ref<UserViewerInterface[]> = ref([])
const observer = ref()

onMounted(async () => {
  await getData()

  observer.value = Observable.subscribe(async (response: any) => {
    if ([ObservableEvent.AppResumed].includes(response.event)) {
      await getData()
    }
  })

  loading.value = false
})

onUnmounted(() => {
  observer.value?.unsubscribe()
})


const getData = async () => {
  viewers.value = await ApiService.User().views()
}

const swiperRef: any = ref(null)

const onSwiper = async (swiper: any) => {
  swiperRef.value = swiper
}

const ionRouter = useIonRouter()

const viewUserProfile = async (userId: number) => {
  ionRouter.navigate({
    name: 'PineappleProfileUserProfile',
    params: {
      userId
    }
  }, 'forward', 'push')
}

const viewedAt = (date:string) => {
  return DateTime.fromISO(date).toRelative()
}

const seeViewers = async () => {
  return usePremium().presentPayWall({
    text: 'See who has been looking at your profile!',
    callback: () => {
      //
    }
  });
}

</script>