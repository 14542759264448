<template>
  <div class="flex my-auto space-x-1">
    <div
        :class="{
          'w-4' : !user.reviews || !user.subscribed,
          'px-2' : user.reviews && user.subscribed,
          'bg-brand-lime' : user.subscribed,
          'bg-brand-light-gray bg-opacity-50' : !user.subscribed

        }"
        class="h-4 flex rounded-full">
      <svg v-if="!user.reviews || !user.subscribed" class="m-auto h-2 fill-current text-brand-black my-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
      <div v-if="user.reviews && user.subscribed" class="flex space-x-1">
        <svg class="h-2 fill-current text-brand-black my-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM625 177L497 305c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L591 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
        <div class="text-xxs text-brand-black my-auto font-medium">{{ user.reviews }}</div>
      </div>
    </div>

    <svg v-if="user.ambassador && ambassador" class="h-4 w-4" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1302_7)">
        <mask id="mask0_1302_7" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="85" height="85">
          <path d="M85 0H0V85H85V0Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_1302_7)">
          <mask id="mask1_1302_7" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="85" height="85">
            <path d="M85 0H0V85H85V0Z" fill="white"/>
          </mask>
          <g mask="url(#mask1_1302_7)">
            <path d="M81.0654 42.4994C81.0654 63.7981 63.799 81.0642 42.5003 81.0642C21.2016 81.0642 3.93555 63.7981 3.93555 42.4994C3.93555 21.2007 21.2016 3.93457 42.5003 3.93457C63.799 3.93457 81.0654 21.2007 81.0654 42.4994Z" stroke="#EBFF00" stroke-width="6" stroke-miterlimit="10"/>
            <path d="M45.1355 21.2677L49.8082 30.7368C50.2355 31.6031 51.0631 32.2045 52.0203 32.343L62.4689 33.8614C64.879 34.2112 65.8406 37.172 64.0975 38.872L56.5368 46.2422C55.8446 46.9165 55.529 47.8886 55.6912 48.8428L57.4761 59.2497C57.887 61.6494 55.3697 63.4789 53.2142 62.346L43.8687 57.4321C43.0127 56.9826 41.99 56.9826 41.1356 57.4321L31.79 62.346C29.6345 63.4789 27.1158 61.6494 27.5281 59.2497L29.313 48.8428C29.4767 47.8901 29.1611 46.918 28.4674 46.2422L20.9067 38.872C19.1636 37.172 20.1252 34.2112 22.5353 33.8614L32.9839 32.343C33.9411 32.2045 34.7673 31.6031 35.196 30.7368L39.8687 21.2677C40.9465 19.0839 44.0592 19.0839 45.137 21.2677H45.1355Z" fill="#EBFF00"/>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1302_7">
          <rect width="85" height="85" fill="white"/>
        </clipPath>
      </defs>
    </svg>

    <div
        v-if="accountType"
        class="flex font-medium bg-white/10 text-white px-1.5 uppercase rounded-full text-xxs my-auto">
      {{ accountType }}
    </div>

  </div>
</template>
<script setup lang="ts">

import {computed, PropType, ref, watch} from "vue";
import {UserInterface} from "@/interfaces/UserInterface";
import {FeedPostedByInterface} from "@/interfaces/FeedPostedByInterface";
import {FriendUserInterface} from "@/interfaces/FriendUserInterface";
import {ChatParticipantInterface} from "@/interfaces/ChatParticipantInterface";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import {AccountType} from "@/definitions/AccountType";

const props = defineProps({
  user: {
    type: Object as PropType<UserInterface | FeedPostedByInterface | FriendUserInterface | ChatParticipantInterface | UserBasicInformationInterface>,
    required: true
  },
  ambassador: {
    type: Boolean,
    default: true
  }
})

const accountType = computed(() => {

  let type = ''
  switch (props.user?.account_type) {
    case AccountType.Individual:
      type = 'Solo'
      break
    case AccountType.Single:
      type = 'Solo'
      break
    case AccountType.Joint:
      type = 'Couple'
      break
    case AccountType.Club:
      type = 'Club'
      break
    case AccountType.Host:
      type = 'Host'
      break
    case AccountType.Couple:
      type = 'Couple'
      break
  }

  if (props.user.relationship_status === 'couple' && props.user?.account_type !== AccountType.Joint) {
    return 'linked'
  }

  return type

})


</script>
