<template>
  <ion-page>
    <ion-content class="h-full">

      <div class="flex w-full h-full">

        <div v-if="hasPin" @click="logout" class="absolute text-lg text-white font-bold top-0 right-0 safe-area-top pr-6 mt-5">Log out</div>
        <div v-if="!hasPin && !newPin" @click="skip" class="absolute text-lg text-white font-bold top-0 right-0 safe-area-top pr-6 mt-5">Skip</div>
        <div v-if="!hasPin && newPin" @click="startAgain" class="absolute text-lg text-white font-bold top-0 right-0 safe-area-top pr-6 mt-5">Start again</div>

        <div class="flex flex-col mt-auto w-full space-y-14 pb-5">
          <div class="flex flex-col w-full space-y-14">
            <logo-mark class="m-auto w-10"/>
            <div class="flex w-full justify-around px-10">
              <div
                  :key="index"
                  v-for="(i,index) in [1,2,3,4]"
                  :class="{
                    'bg-brand-lime !scale-150' : (pin.length-1) >= index,
                  }"
                  class="w-3 h-3 scale-100 bg-brand-dark-gray rounded-full transition-all transition-transform"></div>
            </div>

            <div v-if="hasPin && !storedPinError" class="flex flex-col space-y-1">
              <div class="text-white text-xl font-semibold w-full text-center">Hey {{ user.name }} 👋</div>
              <div class="text-brand-light-gray text-sm w-full text-center">Please enter your PIN</div>
            </div>
            <div v-if="hasPin && storedPinError" class="flex flex-col space-y-1">
              <div class="animate-wiggle text-red-600 text-xl font-semibold w-full text-center">Incorrect PIN!</div>
              <div class="text-brand-light-gray text-sm w-full text-center">Try again.</div>
            </div>

            <div v-if="!hasPin" class="flex flex-col space-y-1 px-10">
              <div v-if="!newPin" class="text-white text-xl font-semibold w-full text-center">Create PIN 🔒</div>
              <div v-if="newPin && !newPinError" class="text-white text-xl font-semibold w-full text-center">Re-enter to confirm PIN 🔒</div>
              <div v-if="newPin && newPinError" class="animate-wiggle text-red-600 text-xl font-semibold w-full text-center">Incorrect PIN!</div>
              <div class="text-brand-light-gray text-sm w-full text-center">To enhance your privacy, you can choose to enter a PIN every time you open the app.</div>
            </div>


          </div>

          <div class="safe-area-bottom grid grid-cols-3 px-5 justify-evenly w-full items-center justify-center my-auto">
            <ion-button
                :key="keyIndex"
                @click="keyPressed(keyIndex)"
                v-for="(key,keyIndex) in pad" class="text-center h-16 flex">
              <div class="m-auto font-bold text-white" v-html="key"></div>
            </ion-button>
          </div>


        </div>
      </div>

    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonContent,
  IonButton,
  IonPage, modalController, useIonRouter, useBackButton,
} from '@ionic/vue';
import LogoMark from "@/components/LogoMark.vue";
import {computed, onMounted, onUnmounted, Ref, ref, watch} from "vue";
import AuthService from "@/services/api/auth/authService";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {DateTime} from "luxon";
import OneSignal from "onesignal-cordova-plugin";
import {Haptics, ImpactStyle} from "@capacitor/haptics";
import {BiometryType, NativeBiometric} from "capacitor-native-biometric";
import {UserInterface} from "@/interfaces/UserInterface";
import ApiService from "@/services/api/apiService";
import {App} from "@capacitor/app";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {PluginListenerHandle} from "@capacitor/core";

const pad = [
    '1','2','3','4','5','6','7','8','9','','0','&larr;'
]

const pin = ref('')

const ionRouter = useIonRouter()
const logout = async () => {
  const authService = new AuthService()
  await authService.logout(() => {
    ionRouter.replace('/')
  })
}

const keyPressed = async (keyIndex: number) => {

  Haptics.impact({ style: ImpactStyle.Medium });

  if (keyIndex === 9) return
  if (keyIndex === 11) {
    return pin.value = pin.value.slice(0, pin.value.length - 1)
  }
  if (pin.value.length === 4) return

  pin.value = pin.value + pad[keyIndex]

}

const close = async () => {
  modalController.dismiss()
}

const skip = async () => {
  modalController.dismiss()
  Preferences.set({key: PreferenceKey.PrivacyPin, value: ''})
}

const startAgain = async () => {
  newPin.value = ''
  newPinConfirmation.value = ''
  pin.value = ''
}

const biometricsBusy = ref(false)

const performBiometricVerification = async () => {

  if (biometricsBusy.value) return
  biometricsBusy.value = true

  setTimeout(async () => {

    const result = await NativeBiometric.isAvailable();

    if (!result.isAvailable) return;
    const verified = await NativeBiometric.verifyIdentity({
      reason: "For easy pin verification",
      title: "Authenticate",
      maxAttempts: 1
    })
        .then(() => true)
        .catch(() => false);

    if (!verified) {
      setTimeout(()=>{
        biometricsBusy.value = false
      }, 1000)
      return;
    }

    const credentials = await NativeBiometric.getCredentials({
      server: "thepineapple.app",
    });

    pin.value = credentials.password
    biometricsBusy.value = false

  }, 300)
}

const user: Ref<UserInterface|undefined> = ref()
const listener: Ref<PluginListenerHandle|undefined> = ref()
const backButton = useBackButton(999, () => {
  // disables back button
})

onMounted(async ()=>{

  user.value = await ApiService.User().authUser()
  storedPin.value = (await Preferences.get({key: PreferenceKey.PrivacyPin})).value ?? ''

  listener.value = App.addListener('appStateChange', async (state) => {
    if (state.isActive) {
      // await performBiometricVerification()
    }
  })

  if (hasPin.value) {
    // return await performBiometricVerification()
  }

})

onUnmounted(()=>{
  backButton.unregister()
  listener.value?.remove()
})

const storedPin = ref('')
const storedPinError = ref(false)

const hasPin = computed(()=>{
  return storedPin.value.length
})

const newPin = ref('')
const newPinConfirmation = ref('')
const newPinError = ref(false)

watch(()=>pin.value, async value => {

  if (hasPin.value) {
    if (value.length === 4 && storedPin.value === value) return close()
    if (value.length === 4 && storedPin.value !== value) {
      storedPinError.value = true
      setTimeout(()=>{
        pin.value = ''
      },50)
      setTimeout(()=>{
        storedPinError.value = false
        pin.value = ''
      },1000)
      return
    }
  }

  if (!hasPin.value) {

    if (!newPin.value && value.length === 4) {
      newPin.value = value
      pin.value = ''
      return
    }

    if (newPin.value && value.length === 4) {
      newPinConfirmation.value = value

      if (newPin.value === newPinConfirmation.value) {
        // await NativeBiometric.setCredentials({
        //   username: "username",
        //   password: pin.value,
        //   server: "thepineapple.app",
        // })
        await Preferences.set({key: PreferenceKey.PrivacyPin, value: newPin.value})
        await close()
        return
      }
      if (newPin.value !== newPinConfirmation.value) {
        newPinError.value = true
        setTimeout(()=>{
          pin.value = ''
        },50)
        setTimeout(()=>{
          newPinError.value = false
          pin.value = ''
        },1000)
        return
      }

    }

  }
})




</script>
<style scoped>
ion-button {
  --background: transparent;
  --background-activated: #262A34;
  --padding-bottom: 0;
  --padding-top: 0;
  --padding-start: 0;
  --padding-end: 0;
  padding: 0;
  margin: 0;
}
</style>