<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="mr-4">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title class="text-white font-medium text-2xl">Location</ion-title>
        <ion-buttons slot="end">
          <ion-button
              @click="save"
              class="!text-xl">
            <span :class="{
                  'text-brand-light-gray' : !location,
                  'text-brand-lime' : location
                }">Save</span>
          </ion-button>
          <div v-if="busy" class="absolute flex top-0 left-0 w-full h-full bg-brand-black z-50">
            <svg class="animate-spin m-auto h-5 w-5 text-brand-lime" xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="h-full">
      <div class="flex flex-col space-y-1 sticky top-0 z-50 px-4 py-5 bg-brand-black border-b border-brand-dark-gray">
        <input-field
            id="locationSearch"
            type="text"
            v-model="locationName"
            :debounce="300"
            :clear-input="true"
            :counter="false"
            @onChange="updateLocationSearch($event)"
            @onKeyEnter="() => console.log('enter')"
            placeholder="e.g. Stoke">
        </input-field>
        <div id="attribution" class="text-white"></div>
      </div>

      <div class="flex flex-col pb-5 px-4 justify-center space-y-4 relative">
        <div class="flex w-full overflow-scroll">
          <div class="flex flex-col space-y-6 divide-y divide-brand-dark-gray w-full">
            <div v-for="(location,locationKey) in locations"
                 class="flex text-white text-lg pt-6"
                 @click="chooseLocation(location)"
                 :key="locationKey">
              {{location.formatted_address}}
            </div>
          </div>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <input-checkbox
            name="display"
            v-model="showOnProfile"
            input-type="toggle"
            :option="{
              text: 'Show on my profile',
              value: true
            }"
        />
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonButtons,
  IonContent, IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, inject, onMounted, reactive, Ref, ref, watch} from 'vue';
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import InputField from "@/components/InputField.vue";
import InputCheckbox from "@/components/InputCheckbox.vue";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {useGoogleMaps} from "@/composables/useGoogleMaps";

export default defineComponent({
  name: 'ProfileSettingsLocation',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonButtons,
    IonButton,
    IonTitle,
    InputCheckbox,
    IonFooter,
    InputField
  },
  setup() {

    const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>
    const loading = ref(true)
    const ionRouter = useIonRouter()

    const busy = ref(false)
    const errors = ref({})
    const showOnProfile: any = ref([true])

    const location = ref()
    const locationName = ref(user.value?.location?.name ?? '')
    const locationSearch = ref('')
    const locations = ref([])

    onIonViewWillEnter(async () => {
      const pref = await ApiService.User().getPreference(PreferenceKey.ProfileShowLocation)
      showOnProfile.value.push(pref ? !!Number(pref) : true)
      loading.value = false
    })

    const goBack = async () => {
      if (busy.value) return
      await ionRouter.back()
    }

    const save = async () => {
      if (busy.value || !location.value) return
      errors.value = {}
      busy.value = true
      try {
        await ApiService.User().setLocation({
          name: locationName.value,
          lat: location.value.geometry.location.lat(),
          lng: location.value.geometry.location.lng(),
        })
        await ApiService.User().setPreference(PreferenceKey.ProfileShowLocation, showOnProfile.value[0])
        await Observable.emit(ObservableEvent.UserChanged)
        setTimeout(() => {
          ionRouter.back()
          busy.value = false
        }, 1000)
      } catch (e: any) {
        if (!e.data) return
        if (e.data.errors) errors.value = e.data.errors
        busy.value = false
      }
    }

    const places = ref()

    onMounted(async () => {
      await useGoogleMaps().loadSDK()
      const attributionEl: any = document.querySelector('#attribution')
      // eslint-disable-next-line no-undef
      places.value = new google.maps.places.PlacesService(attributionEl)
      loading.value = false
    })

    const updateLocationSearch = async (value:any) => {
      locationSearch.value = value
    }

    const chooseLocation = async (value:any) => {
      location.value = value
      locationName.value = value.formatted_address
      locations.value = []
    }

    watch(() => locationSearch.value, searchValue => {

      if (!searchValue) {
        locations.value = []
        location.value = undefined
        return
      }

      if (searchValue === locationName.value) return

      // eslint-disable-next-line no-undef
      places.value.textSearch({
        query: searchValue,
        types: ['(cities)']
      }, (response: any) => {
        locations.value = response
      })

    })

    return {loading, goBack, user, busy, errors, save, showOnProfile, locationSearch, locations, location, updateLocationSearch, chooseLocation, locationName}

  }
});
</script>
