<template>
  <ion-page>
    <ion-content>

      <div class="safe-area-top bg-brand-black sticky top-0 z-40">
        <div class="flex p-4 border-b border-brand-dark-gray border-opacity-80 justify-between">
          <ion-buttons class="justify-between flex">
            <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
              <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </ion-button>
          </ion-buttons>
          <ion-buttons class="justify-between flex opacity-0">
            <ion-button class="bg-brand-dark-gray rounded-md">
              <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </ion-button>
          </ion-buttons>
        </div>
      </div>


      <div class="flex px-4 flex-col space-y-8 my-auto py-8">

        <div class="flex flex-col w-full space-y-1 px-2">
          <div class="flex text-white text-2xl font-medium">Reporting users</div>
          <div class="text-brand-light-gray text-base">The safety and comfort of our community is at the core of the Pineapple experience. We have a zero-tolerance policy towards violation of any of our core principles. Our reporting process is as follows:
          </div>
        </div>

        <div class="flex flex-col w-full space-y-1 px-2">
          <div class="flex text-white text-xl font-medium">Report user</div>
          <div class="text-brand-light-gray text-base">The more details you provide the quicker we’ll be able to identify and investigate the user in question. None of the details you provide will be shared with the person you reported. l result in removal from our platform and a ban from attending
            our events.
          </div>
        </div>

        <div class="flex flex-col w-full space-y-1 px-2">
          <div class="flex text-white text-xl font-medium">Pineapple will investigate</div>
          <div class="text-brand-light-gray text-base">A member of our Trust & Safety team will look into the matter to determine next steps and take action based on our Community Guidelines. Appropriate measures will be taken, which may include banning the user from our platform. This process usually takes up to 2 weeks and we will update you on the end result.
          </div>
        </div>

        <div class="flex flex-col w-full space-y-1 px-2">
          <div class="flex text-white text-xl font-medium">Be truthful</div>
          <div class="text-brand-light-gray text-base">Every report is taken seriously and handled with care. Any user found creating fake reports will lead to a permanent ban from our platform.
          </div>
        </div>
      </div>

      <div class="px-4 sticky backdrop-blur-md bottom-0 py-5 flex flex-col border-t border-brand-dark-gray border-opacity-80">
        <button
            @click="reportUser"
            class="mb-2 flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
          <div class="flex m-auto space-x-2.5">
            <div class="my-auto pt-0.5">
              <div v-if="!reportBusy">Report user</div>
              <div v-if="reportBusy">
                <svg class="animate-spin h-5 w-5 text-brand-black" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
          </div>
        </button>
        <button
            @click="reportAndBlock"
            class="flex w-full bg-brand-dark-gray rounded-full px-8 h-14 font-medium text-xl text-white transition-all">
          <div class="flex m-auto space-x-2.5">
            <div class="my-auto pt-0.5">
              <div v-if="!reportAndBlockBusy">Report & block user</div>
              <div v-if="reportAndBlockBusy">
                <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
          </div>
        </button>
        <div class="safe-area-bottom"></div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonButtons,
  IonContent,
  IonPage, onIonViewDidLeave, onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, inject, onMounted, onUnmounted, Ref, ref} from 'vue';
import {useRoute} from "vue-router";
import {FriendUserInterface} from "@/interfaces/FriendUserInterface";
import ApiService from "@/services/api/apiService";
import MenuButton from "@/components/MenuButton.vue";
import {DateTime} from "luxon";
import {useUser} from "@/composables/useUser";
import InputTextarea from "@/components/InputTextarea.vue";
import {Preferences} from "@capacitor/preferences";

export default defineComponent({
  name: 'ReportUserConfirmationPage',
  methods: {useRoute},
  components: {
    IonPage,
    IonContent,
    IonButtons,
    IonButton,
  },
  setup() {

    const loading = ref(true)
    const reportBusy = ref(false)
    const reportAndBlockBusy = ref(false)
    const ionRouter = useIonRouter()
    const route = useRoute()
    const reason = ref()

    const message = ref('')
    const errors = ref({})

    const goBack = async () => {
      await ionRouter.back()
    }

    const reportUser = async () => {
      if (isBusy.value) return
      reportBusy.value = true
      try {
        await ApiService.User().reportUser(Number(route.params.userId), reason.value)

        ionRouter.navigate({
          name: 'ReportUserConfirmationSuccessPage',
          params: {
            userId: route.params.userId,
          }
        }, 'forward', 'push')
      } catch (e) {
        //
      } finally {
        reportBusy.value = false
      }
    }

    const reportAndBlock = async () => {
      if (isBusy.value) return
      reportAndBlockBusy.value = true
      try {
        await ApiService.User().reportAndBlock(Number(route.params.userId), reason.value)
        ionRouter.navigate({
          name: 'ReportUserConfirmationSuccessPage',
          params: {
            userId: route.params.userId,
          }
        }, 'forward', 'push')
      } catch (e) {
        //
      } finally {
        reportAndBlockBusy.value = false
      }
    }

    const isBusy = computed(()=>{
      return reportBusy.value || reportAndBlockBusy.value
    })

    onMounted(()=>{
      console.log(route.params)
      loading.value = false
    })

    onIonViewWillEnter(async ()=>{
      reason.value = (await Preferences.get({key: 'REPORT_USER_REASON'})).value
    })

    onIonViewDidLeave(async ()=> {
      await Preferences.remove({key: 'REPORT_USER_REASON'})
    })

    return {
      goBack,
      reportBusy,
      reportAndBlockBusy,
      reportUser,
      reportAndBlock,
      loading,
      message,
      errors
    }

  }
});
</script>