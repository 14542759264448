<template>
  <div class="flex flex-col">

    <div v-if="!loading && !isFriendsWithAuthUser" class="flex flex-col p-4">
      <div class="flex flex-col bg-brand-dark-gray p-5  rounded-lg space-y-4">
        <div class="flex flex-col my-auto px-3">
          <div class="text-brand-light-gray font-medium text-lg text-center">Send a friend request – if they accept, you'll be able to view their photo gallery.</div>
        </div>
      </div>
    </div>

    <div v-if="!loading && !gallery.length && isFriendsWithAuthUser" class="flex flex-col p-4">
      <div class="flex flex-col bg-brand-dark-gray p-5  rounded-lg space-y-4">
        <div class="flex flex-col my-auto px-3">
          <div v-if="!isAuthUser" class="text-brand-light-gray font-medium text-lg text-center">They have not added any photos yet.</div>
          <div v-if="isAuthUser" class="text-white font-medium text-lg text-center">Any images you add to your gallery will show here.</div>
        </div>
        <button
            v-if="isAuthUser"
            @click="manageGallery"
            class="w-full flex bg-black rounded-full px-8 h-14 font-medium text-xl text-white transition-all">
          <div class="flex m-auto space-x-2.5">
            <div class="my-auto pt-0.5">
              <div>Add images</div>
            </div>
          </div>
        </button>
      </div>
    </div>

    <div v-if="loading" class="py-16 m-auto">
      <svg class="animate-spin h-5 w-5 text-brand-light-gray" xmlns="http://www.w3.org/2000/svg" fill="none"
           viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>

    </div>

    <div v-if="!loading && gallery.length && isFriendsWithAuthUser" class="flex flex-col">

      <div
          :class="{
            'grid-cols-2' : authUser.subscribed || authUser.id === userId,
            'grid-cols-7' : !authUser.subscribed || authUser.id !== userId
          }"
          class="flex grid gap-4 p-4">
        <div v-if="false" class="bg-brand-light-gray text-brand-black rounded-md w-full flex">
          <svg class="m-auto fill-current" width="30" height="24" viewBox="0 0 30 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.9375 4.625C2.95951 4.625 2.02158 5.0135 1.33004 5.70504C0.638503 6.39658 0.25 7.33451 0.25 8.3125L0.25 19.375C0.25 20.353 0.638503 21.2909 1.33004 21.9825C2.02158 22.674 2.95951 23.0625 3.9375 23.0625H26.0625C27.0405 23.0625 27.9784 22.674 28.67 21.9825C29.3615 21.2909 29.75 20.353 29.75 19.375V8.3125C29.75 7.33451 29.3615 6.39658 28.67 5.70504C27.9784 5.0135 27.0405 4.625 26.0625 4.625H23.9016C22.9237 4.62479 21.9859 4.23615 21.2946 3.54456L19.7679 2.01794C19.0766 1.32635 18.1388 0.937709 17.1609 0.9375H12.8391C11.8612 0.937709 10.9234 1.32635 10.2321 2.01794L8.70544 3.54456C8.01406 4.23615 7.07628 4.62479 6.09837 4.625H3.9375ZM4.85938 8.3125C4.61488 8.3125 4.3804 8.21537 4.20751 8.04249C4.03463 7.8696 3.9375 7.63512 3.9375 7.39062C3.9375 7.14613 4.03463 6.91165 4.20751 6.73876C4.3804 6.56588 4.61488 6.46875 4.85938 6.46875C5.10387 6.46875 5.33835 6.56588 5.51124 6.73876C5.68412 6.91165 5.78125 7.14613 5.78125 7.39062C5.78125 7.63512 5.68412 7.8696 5.51124 8.04249C5.33835 8.21537 5.10387 8.3125 4.85938 8.3125ZM21.4531 12.9219C21.4531 14.6334 20.7732 16.2747 19.563 17.4849C18.3529 18.6951 16.7115 19.375 15 19.375C13.2885 19.375 11.6471 18.6951 10.437 17.4849C9.22676 16.2747 8.54688 14.6334 8.54688 12.9219C8.54688 11.2104 9.22676 9.56902 10.437 8.35883C11.6471 7.14863 13.2885 6.46875 15 6.46875C16.7115 6.46875 18.3529 7.14863 19.563 8.35883C20.7732 9.56902 21.4531 11.2104 21.4531 12.9219Z" />
          </svg>
        </div>
        <template v-if="authUser.subscribed || authUser.id === userId">
          <photo-provider>
            <photo-consumer v-for="media in gallery" :key="media.id" :src="media.url">
              <div class="rounded-md overflow-hidden">
              <ion-img :src="media.thumbnail ?? media.url" class="rounded-md object-cover aspect-square view-box  w-full pointer-events-none" />
              </div>
            </photo-consumer>
          </photo-provider>
        </template>
        <template v-if="!authUser.subscribed && authUser.id !== userId">
          <div @click="notSubscribed" class="rounded-md overflow-hidden" v-for="media in gallery" :key="media.id" :src="media.url">
            <ion-img :src="media.thumbnail ?? media.url" class=" object-cover aspect-square view-box  w-full pointer-events-none" />
          </div>
        </template>
      </div>

      <ion-infinite-scroll v-if="showInfiniteScroll" @ionInfinite="loadMore">
        <ion-infinite-scroll-content class="text-white" loading-spinner="crescent"></ion-infinite-scroll-content>
      </ion-infinite-scroll>

    </div>

  </div>
</template>
<script lang="ts">


import {computed, defineComponent, onMounted, onUnmounted, PropType, Ref, ref,} from "vue";
import {UserInterface} from "@/interfaces/UserInterface";
import {
  IonContent,
    IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import {useFeed} from "@/composables/useFeed";
import FeedPost from "@/components/FeedPost.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import ApiService from "@/services/api/apiService";
import {UserGalleryInterface} from "@/interfaces/UserGalleryInterface";
import {useUser} from "@/composables/useUser";
import {usePremium} from "@/composables/usePremium";

export default defineComponent({
  name: 'UserProfileGallery',
  components: {
    IonInfiniteScrollContent,
    IonInfiniteScroll
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
    isAuthUser: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {

    const loading = ref(true)
    const observer = ref()
    const authUser = ref()

    const authUserFriends = ref([])

    onMounted(async () => {

      await getGallery(1)
      await getAuthUserFriends()

      authUser.value = await ApiService.User().authUser()
      observer.value = Observable.subscribe(async (response: any) => {
        if (response.event === ObservableEvent.UserGalleryChanged) {
          await getGallery(1)
        }
      })

      loading.value = false

    })

    onUnmounted(()=>{
      observer.value?.unsubscribe()
    })

    const gallery: Ref<UserGalleryInterface[]> = ref([])
    const pagination = ref()

    const showInfiniteScroll = ref(true)

    const getAuthUserFriends = async () => {
      authUserFriends.value = await ApiService.User().getFriends()
    }

    const getGallery = async (page = 1) => {
      const response = (await ApiService.User().galleryByUserId(props.userId, page))
      pagination.value = response.meta

      if (pagination.value.current_page >= pagination.value.last_page) showInfiniteScroll.value = false

      if (page === 1) {
        gallery.value = response.data
        showInfiniteScroll.value = true
      }

      if (page > 1) {
        gallery.value = [...gallery.value, ...response.data]
      }

    }

    const manageGallery = async () => {
      const user = useUser()
      await user.manageGallery()
    }

    const loadMore = async (ev: any) => {
      await getGallery(pagination.value.current_page + 1)
      ev.target.complete()
    }

    const isFriendsWithAuthUser = computed(()=>{
      if (props.isAuthUser) return true
      if (!authUserFriends.value) return false
      return authUserFriends.value.find((user: any) => user.id === props.userId)
    })

    const notSubscribed = async () => {
      return usePremium().presentPayWall({
        text: 'Upgrade and become a premium member to view friends only gallery photos full screen with pinch and zoom 😉',
        callback: () => {
          //
        }
      });
    }

    return {notSubscribed, authUser, manageGallery, loadMore, showInfiniteScroll, gallery, loading, isFriendsWithAuthUser}

  }

})

</script>
