<template>
  <div class="flex flex-col">
    <div class="flex w-full px-4 py-6">

      <div v-if="loading" class="h-10 flex justify-center w-full">
        <svg class="animate-spin h-5 w-5 m-auto text-white"
             xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                  stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>

      <div v-if="!loading" class="flex">
        <div class="flex flex-col divide-brand-dark-gray space-y-4  overflow-y-scroll">

          <user-list-item
              @click="viewUserProfile(user)"
              v-for="user in users"
              :key="user.id"
              :user="user"/>

        </div>
      </div>

    </div>

    <div class="safe-area-bottom"></div>

  </div>
</template>

<script setup lang="ts">

import {onMounted, Ref, ref} from 'vue';
import ApiService from "@/services/api/apiService";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import UserListItem from "@/components/UserListItem.vue";
import {modalController} from "@ionic/vue";

const props = defineProps({
  userId: {
    type: Number,
    required: true
  }
})

const loading = ref(true)

onMounted(async () => {
  await getPartners()
  loading.value = false
})

const users: Ref<UserBasicInformationInterface[]> = ref([])

const getPartners = async () => {
  users.value = await ApiService.User().getPartnersForUser(props.userId)
}

const viewUserProfile = async (user: UserBasicInformationInterface) => {
  await modalController.dismiss(user)
}

</script>