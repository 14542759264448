<template>

  <div class="flex flex-col w-full h-full">

    <div v-if="user && hasLocation" @click="meetOptions"
         class="sticky top-0 flex w-full bg-brand-black p-4 z-20 space-x-3.5 border-b border-brand-dark-gray">
      <div class="flex justify-between shadow-2xl bg-brand-dark-gray text-white p-4 rounded-2xl w-full">
        <div class="flex flex-col">
          <div class="flex">
            <div class="text-lg font-medium">{{ availableToMeetLabel }}</div>
            <div
                :class="{
                'bg-brand-lime' : user.available_to_meet_until,
                'bg-red-400' : !user.available_to_meet_until
              }"
                class="my-auto ml-2 w-3 h-3 rounded-full"></div>
          </div>
          <div v-if="false" class="text-xs">--</div>
        </div>
        <div class="flex mr-2 my-auto">
          <svg class="w-5 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
                d="M239 401c9.4 9.4 24.6 9.4 33.9 0L465 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L81 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 401z"/>
          </svg>
        </div>
      </div>
    </div>

    <div v-if="loading" class="flex w-full" style="height: calc(100% - 92px);">
      <svg class="animate-spin h-10 w-10 m-auto text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
           viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>

    <div v-if="!loading && !feed.length && hasLocation" class="flex w-full" style="height: calc(100% - 92px);">
      <div class="flex px-4 pb-5  text-white m-auto ">
        <div class="flex flex-col items-center space-y-6">
          <div class="flex">
            <div class="h-20 w-20 bg-brand-lime text-brand-black rounded-full flex">
              <div class="m-auto">
                <svg class="w-7 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                  <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <path
                      d="M155.6 17.3C163 3 179.9-3.6 195 1.9L320 47.5l125-45.6c15.1-5.5 32 1.1 39.4 15.4l78.8 152.9c28.8 55.8 10.3 122.3-38.5 156.6L556.1 413l41-15c16.6-6 35 2.5 41 19.1s-2.5 35-19.1 41l-71.1 25.9L476.8 510c-16.6 6.1-35-2.5-41-19.1s2.5-35 19.1-41l41-15-31.3-86.2c-59.4 5.2-116.2-34-130-95.2L320 188.8l-14.6 64.7c-13.8 61.3-70.6 100.4-130 95.2l-31.3 86.2 41 15c16.6 6 25.2 24.4 19.1 41s-24.4 25.2-41 19.1L92.2 484.1 21.1 458.2c-16.6-6.1-25.2-24.4-19.1-41s24.4-25.2 41-19.1l41 15 31.3-86.2C66.5 292.5 48.1 226 76.9 170.2L155.6 17.3zm44 54.4l-27.2 52.8L261.6 157l13.1-57.9L199.6 71.7zm240.9 0L365.4 99.1 378.5 157l89.2-32.5L440.5 71.7z"/>
                </svg>
              </div>
            </div>
          </div>

          <div class="flex flex-col h-full w-full items-center space-y-0 px-2">
            <div class="flex text-white text-2xl font-medium text-center">Sad face 🙁</div>
            <div class="text-brand-light-gray text-lg text-center">
              It doesn't look like anybody is looking to meet up right now, check back soon.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loading && !hasLocation" class="flex h-full w-full">
      <div class="flex px-4 py-5 m-auto text-white">
        <div class="flex flex-col items-center space-y-6">

          <div class="h-20 w-20 bg-brand-lime text-brand-black rounded-full flex">
            <div class="m-auto">
              <svg class="w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                    d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
              </svg>
            </div>
          </div>

          <div class="flex flex-col w-full items-center space-y-0 px-2">
            <div class="flex text-white text-2xl font-medium text-center">Profile Location Required</div>
            <div class="text-brand-light-gray text-lg text-center">
              To use this feature you must have a location set and be sharing this on your profile.
            </div>
          </div>


          <div @click="setLocation" class="bg-brand-dark-gray px-4 py-2.5 font-medium rounded-lg">Set Location</div>
        </div>
      </div>
    </div>

    <div v-if="!loading && feed.length && hasLocation" class="flex flex-col p-5 px-4 space-y-3">
      <div
          @click="viewProfile(user)"
          class="flex bg-brand-dark-gray rounded-2xl"
          v-for="(user, userIndex) in feed" :key="userIndex">

        <div class="flex w-full p-4 space-x-3">
          <div class="flex">
            <user-avatar :user="user"/>
          </div>
          <div class="flex flex-col w-full">
            <div class="flex space-x-2">
              <div class="text-white font-medium text-lg my-auto line-clamp-1">
                {{ user.name }}
              </div>
              <div class="flex">
                <user-verification-check class="h-4" :user="user"/>
              </div>
            </div>

            <div class="flex text-brand-light-gray text-xs w-full justify-between">
              <div class="flex">{{ user.location ? user.location.name : '--' }}</div>
              <div class="flex">{{ user.distance_label }}</div>
            </div>
          </div>
        </div>


      </div>
    </div>

  </div>


</template>
<script setup lang="ts">

import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  modalController, onIonViewWillEnter, useIonRouter,
} from "@ionic/vue";
import {computed, nextTick, onMounted, onUnmounted, onUpdated, PropType, Ref, ref} from "vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import MeetSheet from "@/views/common/MeetSheet.vue";
import ApiService from "@/services/api/apiService";
import {UserInterface} from "@/interfaces/UserInterface";
import {DateTime} from "luxon";
import UserAvatar from "@/components/UserAvatar.vue";
import UserVerificationCheck from "@/components/UserBadges.vue";
import {SocialFeedType} from "@/definitions/SocialFeedType";


const loading = ref(true)
const busy = ref(false)

const user: Ref<UserBasicInformationInterface | UserInterface | undefined> = ref()
const feed: Ref<(UserBasicInformationInterface | any)[]> = ref([])


const pullToRefresh = async (ev: any) => {
  await getData()
  await getUser()
  ev.target.complete()
}


const getData = async () => {
  if (busy.value) return
  busy.value = true
  try {
    feed.value = await ApiService.User().availableToMeet()
  } catch (e) {
    //
  }
  busy.value = false
}

const observer = ref()

const getUser = async () => {
  user.value = await ApiService.User().authUser()
}

onMounted(async () => {

  observer.value = Observable.subscribe(async (response: any) => {

    if (
        (response.event === ObservableEvent.PullToRefreshMeet)
    ) {
      await pullToRefresh(response.data)
    }

    if (response.event === ObservableEvent.RefreshFeed && !feed.value.length) {
      await getData()
    }

    if ([ObservableEvent.UserUpdateComplete, ObservableEvent.UserChanged].includes(response.event)) {
      await getUser()
    }

  })

  observer.value = Observable.subscribe(async (response: any) => {
    if (response.event === ObservableEvent.FeedScrollTop) {
      //
    }
  })

  await getUser()
  await getData()
  loading.value = false

})

onUnmounted(() => {
  observer.value?.unsubscribe()
})

onUpdated(async () => {
  await nextTick()
})


const meetOptions = async () => {

  const sheet = await modalController.create({
    breakpoints: [0, 1],
    initialBreakpoint: 1,
    component: MeetSheet,
    mode: 'ios'
  })
  await sheet.present()

  const {data} = await sheet.onWillDismiss()
  if (data) {
    user.value = data
  }

}

const availableToMeetLabel = computed(() => {
  if (!user.value) return

  return user.value?.available_to_meet_until ? `Available to meet until ${DateTime.fromISO(user.value?.available_to_meet_until).toFormat('HH:mm')}` : 'Not available to meet'
})

const hasLocation = computed(() => {
  if (!user.value) return false
  return user.value?.location?.lat
})
const ionRouter = useIonRouter()

const setLocation = async () => {
  await ionRouter.navigate({name: `ProfileSettingsLocation`}, 'forward', 'push')
}

const viewProfile = async (user: UserBasicInformationInterface) => {

  ionRouter.navigate({
    name: 'PineappleFeedUserProfile',
    params: {
      userId: user.id
    }
  }, 'forward', 'push')

}

</script>