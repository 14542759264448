<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="mr-4">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title class="text-white font-medium text-2xl capitalize">{{ title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button
              @click="save"
              class="!text-xl">
            <span class="text-brand-lime">Save</span>
          </ion-button>
          <div v-if="busy" class="absolute flex top-0 left-0 w-full h-full bg-brand-black z-50">
            <svg class="animate-spin m-auto h-5 w-5 text-brand-lime" xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="h-full">
      <div class="flex flex-col py-5 px-4 justify-center space-y-4">

        <div class="flex flex-wrap">
          <input-checkbox
              v-for="(s,sIndex) in options"
              class="mr-2.5 mb-2.5"
              :key="sIndex"
              name="lookingFor"
              v-model="chosenOptions"
              input-type="pill"
              :option="s"
          />
        </div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {computed, Ref, ref} from 'vue';
import ApiService from "@/services/api/apiService";
import InputCheckbox from "@/components/InputCheckbox.vue";
import {FeedPreference} from "@/services/api/feed/feedPreferencesService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {useRoute} from "vue-router";


const loading = ref(true)
const ionRouter = useIonRouter()
const route = useRoute()
const busy = ref(false)

const chosenOptions: Ref<number[]> = ref([])
const options: any = ref([])

const preferenceKey: FeedPreference = route.params.key as FeedPreference


onIonViewWillEnter(async () => {

  const userPreference = (await ApiService.FeedPreferences().get())[preferenceKey.toLowerCase()]

  chosenOptions.value = userPreference ? userPreference.map((p:any) => p.id) : []


  await getOptions()

  loading.value = false
})

const getOptions = async () => {

  switch (preferenceKey) {
    case FeedPreference.Kinks: {
      options.value = (await ApiService.Settings().getDesires()).map((g: any) => {
        return {
          text: g.name,
          value: g.id
        }
      })
    }
      break
    case FeedPreference.Interests: {
      options.value = (await ApiService.Settings().getSexualities()).map((g: any) => {
        return {
          text: g.name,
          value: g.id
        }
      })
    }
      break
    case FeedPreference.Gender: {
      options.value = (await ApiService.Settings().getGenders()).map((g: any) => {
        return {
          text: g.name,
          value: g.id
        }
      })
    }
      break
    case FeedPreference.Body: {
      options.value = (await ApiService.Settings().getBodyTypes()).map((g: any) => {
        return {
          text: g.name,
          value: g.id
        }
      })
    }
      break
    case FeedPreference.Drinking: {
      options.value = (await ApiService.Settings().getDrinkingTypes()).map((g: any) => {
        return {
          text: g.name,
          value: g.id
        }
      })
    }
      break
    case FeedPreference.Religion: {
      options.value = (await ApiService.Settings().getReligions()).map((g: any) => {
        return {
          text: g.name,
          value: g.id
        }
      })
    }
      break
    case FeedPreference.Sexuality: {
      options.value = (await ApiService.Settings().getSexualities()).filter((g:any)=>{
        return !g.combination
      }).map((g: any) => {
        return {
          text: g.name,
          value: g.id
        }
      })
    }
      break
    case FeedPreference.Smoking: {
      options.value = (await ApiService.Settings().getSmokingTypes()).map((g: any) => {
        return {
          text: g.name,
          value: g.id
        }
      })
    }
      break
    case FeedPreference.Relationship: {
      options.value = [
        {
          text: 'Single',
          value: 'individual'
        },
        {
          text: 'Couple',
          value: 'joint'
        }
      ]
    }
      break
    case FeedPreference.Piercings: {
      options.value = [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ]
    }
      break
    case FeedPreference.Tattoos: {
      options.value = [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ]
    }
  }
}


const goBack = async () => {
  if (busy.value) return
  await ionRouter.back()
}

const save = async () => {
  if (busy.value) return
  busy.value = true
  try {
    await ApiService.FeedPreferences().set(preferenceKey, chosenOptions.value)
    await Observable.emit(ObservableEvent.FeedPreferencesChanged)
    ionRouter.back()
    busy.value = false
  } catch (e: any) {
    //   if (!e.data) return
    //   if (e.data.errors) errors.value = e.data.errors
    busy.value = false
  }
}

const title = computed(()=>{
  switch (preferenceKey) {
    default: {
      return preferenceKey.toLowerCase()
    }
  }
})

</script>
