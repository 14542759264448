<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons class="flex" slot="start">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title>
          <div
              v-if="!loading"
              @click="showParticipants" class="flex items-center justify-center space-x-3.5">
            <div class="flex">
              <div
                  :style="{
                                    'background-image': `url('${isDirectMessage ? dmUser.avatar :  chat.image}')`
                                }"
                  class="flex w-10 h-10 bg-cover object-cover bg-center bg-brand-dark-gray rounded-full"></div>
            </div>
            <div class="flex text-left">
              <div class="flex flex-col -mt-1.5">
                <div class="text-white font-medium text-xl">{{
                    isDirectMessage
                        ? dmUser.name.length > 18 ? `${dmUser.name.substring(0, 15)}...` : dmUser.name
                        : chat.name.length > 18 ? `${chat.name.substring(0, 15)}...` : chat.name }}</div>
                <div v-if="!isDirectMessage" class="text-brand-light-gray font-normal text-xs">You and
                  {{ chat.participants.length - 1 }} {{ pluralize('other', chat.participants.length - 1) }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex mx-auto space-x-3.5  items-center justify-center" v-if="loading">
            <ion-skeleton-text animated class="h-10 w-10 rounded-full"/>
            <div class="flex flex-col my-auto">
              <ion-skeleton-text animated class="h-3 w-32 rounded-lg"/>
              <ion-skeleton-text animated class="h-3 w-20 rounded-lg"/>
            </div>
          </div>
        </ion-title>
        <ion-buttons v-if="false" slot="end">
          <ion-button>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M5.52035 14.1598C6.71329 14.1598 7.68035 13.1928 7.68035 11.9998C7.68035 10.8069 6.71329 9.83984 5.52035 9.83984C4.32742 9.83984 3.36035 10.8069 3.36035 11.9998C3.36035 13.1928 4.32742 14.1598 5.52035 14.1598Z"
                  fill="#A5A7AF"/>
              <path
                  d="M12.0008 14.1598C13.1938 14.1598 14.1608 13.1928 14.1608 11.9998C14.1608 10.8069 13.1938 9.83984 12.0008 9.83984C10.8079 9.83984 9.84082 10.8069 9.84082 11.9998C9.84082 13.1928 10.8079 14.1598 12.0008 14.1598Z"
                  fill="#A5A7AF"/>
              <path
                  d="M18.4803 14.1598C19.6732 14.1598 20.6403 13.1928 20.6403 11.9998C20.6403 10.8069 19.6732 9.83984 18.4803 9.83984C17.2874 9.83984 16.3203 10.8069 16.3203 11.9998C16.3203 13.1928 17.2874 14.1598 18.4803 14.1598Z"
                  fill="#A5A7AF"/>
            </svg>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content
        id="ionContent"
        @ionScroll="infiniteScrollTop"
        :scroll-events="true" ref="chatContainer" class="h-full">

      <ion-infinite-scroll threshold="0%" position="top" v-if="showInfiniteScroll" @ionInfinite="loadMore">
        <ion-infinite-scroll-content class="text-white"
                                     loading-spinner="crescent"></ion-infinite-scroll-content>
      </ion-infinite-scroll>

      <div v-if="loading" class="h-full flex">
        <svg class="animate-spin h-10 w-10 m-auto text-white" xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>

      <div class="flex">

        <div v-if="!loading" class="pb-8 pt-0 flex flex-col w-full text-white ">

          <div v-if="false" class="sticky top-8 bg-brand-dark-gray p-2.5 px-3 rounded-xl font-medium mx-auto">
            Today
          </div>

          <chat-message
              @on-delete="deleteMessage"
              :is-auth-user="isAuthUser(message.posted_by.id)"
              :message="message"
              :key="messageKey"
              v-for="(message, messageKey) in messages"/>

        </div>

      </div>


      <div @click="removeMedia" class="sticky bottom-4 left-4 w-40 my-auto" v-if="messageMedia">
        <img :src="messageMedia" class="flex rounded-lg w-full h-full object-center object-cover">
      </div>

    </ion-content>
    <ion-footer id="keyboard" class="relative">
      <div v-if="loading" class="w-full h-full  absolute top-0 left-0 z-50"></div>
      <ion-toolbar>
        <div class="safe-area-bottom overflow-hidden sticky bottom-0 border-t border-brand-dark-gray border-opacity-80">
          <div
              :class="{
              'pb-4' : isAndroid,
              'pb-0' : !isAndroid
            }"
              class="bg-brand-black px-4 pt-4 z-50">
            <div class="flex bg-brand-black w-full space-x-4">
              <div @click="addPhoto" class="flex my-auto text-white">
                <svg class="fill-current" width="44" height="45" viewBox="0 0 44 45"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M25.75 23.2512C25.75 24.2458 25.3549 25.1996 24.6516 25.9029C23.9484 26.6061 22.9946 27.0012 22 27.0012C21.0054 27.0012 20.0516 26.6061 19.3483 25.9029C18.6451 25.1996 18.25 24.2458 18.25 23.2512C18.25 22.2567 18.6451 21.3028 19.3483 20.5996C20.0516 19.8963 21.0054 19.5012 22 19.5012C22.9946 19.5012 23.9484 19.8963 24.6516 20.5996C25.3549 21.3028 25.75 22.2567 25.75 23.2512Z"
                  />
                  <path
                      d="M13 16.5C12.2044 16.5 11.4413 16.8161 10.8787 17.3787C10.3161 17.9413 10 18.7044 10 19.5V28.5C10 29.2956 10.3161 30.0587 10.8787 30.6213C11.4413 31.1839 12.2044 31.5 13 31.5H31C31.7956 31.5 32.5587 31.1839 33.1213 30.6213C33.6839 30.0587 34 29.2956 34 28.5V19.5C34 18.7044 33.6839 17.9413 33.1213 17.3787C32.5587 16.8161 31.7956 16.5 31 16.5H29.242C28.4464 16.4998 27.6835 16.1836 27.121 15.621L25.879 14.379C25.3165 13.8164 24.5536 13.5002 23.758 13.5H20.242C19.4464 13.5002 18.6835 13.8164 18.121 14.379L16.879 15.621C16.3165 16.1836 15.5536 16.4998 14.758 16.5H13ZM13.75 19.5C13.5511 19.5 13.3603 19.421 13.2197 19.2803C13.079 19.1397 13 18.9489 13 18.75C13 18.5511 13.079 18.3603 13.2197 18.2197C13.3603 18.079 13.5511 18 13.75 18C13.9489 18 14.1397 18.079 14.2803 18.2197C14.421 18.3603 14.5 18.5511 14.5 18.75C14.5 18.9489 14.421 19.1397 14.2803 19.2803C14.1397 19.421 13.9489 19.5 13.75 19.5ZM27.25 23.25C27.25 24.6424 26.6969 25.9777 25.7123 26.9623C24.7277 27.9469 23.3924 28.5 22 28.5C20.6076 28.5 19.2723 27.9469 18.2877 26.9623C17.3031 25.9777 16.75 24.6424 16.75 23.25C16.75 21.8576 17.3031 20.5223 18.2877 19.5377C19.2723 18.5531 20.6076 18 22 18C23.3924 18 24.7277 18.5531 25.7123 19.5377C26.6969 20.5223 27.25 21.8576 27.25 23.25Z"
                  />
                </svg>
              </div>
              <div
                  class="flex relative w-full rounded-xl max-h-32 overflow-hidden text-white space-x-4">
                <div class="flex overflow-y-scroll w-full">
                  <div
                      class="input bg-brand-dark-gray outline-none text-base max-h-24 relative w-full py-4 px-5 pr-20 rounded-3xl items-center overflow-y-scroll leading-5"
                      role="textbox"
                      data-placeholder="Write a message..."
                      contenteditable
                      @input="setMessage"
                      ref="messageField"
                  ></div>
                </div>
                <div
                    @click="postMessage"
                    class="absolute bottom-1.5 right-1.5 mt-auto h-10 mb-0 bg-brand-lime font-medium text-brand-black flex rounded-full px-0.5">
                  <div
                      :class="{
                      'px-2' : busy,
                      'px-4' : !busy
                    }"
                      class="m-auto  text-base">
                    <div v-if="!busy">Send</div>
                    <svg v-if="busy" class="animate-spin m-auto h-5 w-5 text-brand-black"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                              stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script lang="ts" setup>
import {
  actionSheetController,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
  isPlatform,
  modalController,
  useIonRouter,
} from '@ionic/vue';
import {computed, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, PropType, Ref, ref} from 'vue';
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {Keyboard} from "@capacitor/keyboard";
import ChatMessage from "@/components/ChatMessage.vue";
import ApiService from "@/services/api/apiService";
import {ChatMessageInterface} from "@/interfaces/ChatMessageInterface";
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";
import pluralize from "pluralize";
import FlirtViewChatParticipants from "@/views/flirt/FlirtViewChatParticipants.vue";
import {App} from "@capacitor/app";
import {useRoute} from "vue-router";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import {ChatParticipantInterface} from "@/interfaces/ChatParticipantInterface";
import {usePremium} from "@/composables/usePremium";

const loading = ref(true)
const ionRouter = useIonRouter()

const goBack = async () => {
  ionRouter.back()
  Observable.emit(ObservableEvent.ShowTabBar)
}

onBeforeMount(()=>{
  Observable.emit(ObservableEvent.HideTabBar)
})

onBeforeUnmount(()=>{
  Observable.emit(ObservableEvent.ShowTabBar)
})

const busy = ref(false)
const messageField: any = ref(null)

const keyboardVisible = ref(false)


const chatContainer: any = ref(null)
const message = ref('')

const contentOffset = ref()
const keyboardListeners: Ref<any[]> = ref([])

const showInfiniteScroll = ref(true)

const observable: any = ref()


const pagination = ref()
const messages: Ref<ChatMessageInterface[]> = ref([])

const seenLatest = async () => {
  await ApiService.Chat().conversation(chatId.value, 1)
}

const getMessages = async (page = 1) => {

  const response = (await ApiService.Chat().conversation(chatId.value, page))
  pagination.value = response.meta

  if (pagination.value.current_page >= pagination.value.last_page) showInfiniteScroll.value = false

  if (page === 1) {
    messages.value = response.data.reverse()
    showInfiniteScroll.value = true
  }

  if (page > 1) {
    messages.value.unshift(...response.data.reverse())
  }

  Observable.emit(ObservableEvent.ChatsChanged)

}

const loadMore = async (ev: any) => {
  const scrollHeight = (await chatContainer.value.$el.getScrollElement()).scrollHeight
  if (pagination.value) await getMessages(pagination.value.current_page + 1)
  const newScrollHeight = (await chatContainer.value.$el.getScrollElement()).scrollHeight - scrollHeight
  await chatContainer.value.$el.scrollToPoint(0, newScrollHeight)
  await ev.target.complete()
}


const authUserId = ref()
const appStateChangeListener = ref()

const isActiveInterval = ref()

const router = useRoute()
const chat = ref()
const chatId = ref()

onMounted(async () => {

  chatId.value = router.params.chatId
  chat.value = await ApiService.Chat().getById(chatId.value)


  appStateChangeListener.value = Observable.subscribe(async (response: any) => {
    if ([ObservableEvent.AppResumed].includes(response.event)) {
      await getMessages()
      await ApiService.Chat().isActive(chatId.value, true)
    }

    if ([ObservableEvent.AppPaused].includes(response.event)) {
      await ApiService.Chat().isActive(chatId.value, false)
    }
  })

  authUserId.value = (await ApiService.User().authUser()).id

  await getMessages(1)
  chatContainer.value.$el.scrollToBottom(0)

  loading.value = false

  observable.value = Observable.subscribe(async (response: any) => {
    if (response.event === ObservableEvent.ChatsChanged && response.data) {
      if (parseInt(`${chatId.value}`) === parseInt(`${response.data?.chat?.id}`)) {

        messages.value.push(response.data.message)
        scrollToBottom(0)

        await seenLatest()
        Observable.emit(ObservableEvent.ChatsChanged)

      }
    }
  })

  if (isPlatform('capacitor') && isPlatform('ios')) {

    const keyboardWillShow = await Keyboard.addListener('keyboardWillShow', (info) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const sab = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sab"))
      const ionApp = document.getElementById('keyboard')!
      const ionContent = document.getElementById('ionContent')!
      ionApp.style.transition = 'all 400ms ease'
      ionApp.style.setProperty(
          'transform',
          `translate3d(0, -${info.keyboardHeight - sab}px, 0)`
      )
      contentOffset.value = `${info.keyboardHeight}px`
      ionContent.style.setProperty('--keyboard-offset', contentOffset.value);

      keyboardVisible.value = true
      scrollToBottom(500)
    })

    keyboardListeners.value.push(keyboardWillShow)

    const keyboardWillHide = await Keyboard.addListener('keyboardWillHide', () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const ionApp = document.getElementById('keyboard')!
      const ionContent = document.getElementById('ionContent')!
      ionContent.style.setProperty('--keyboard-offset', '24px');

      ionApp.style.transition = 'all 200ms ease'
      ionApp.style.removeProperty('transform')

      keyboardVisible.value = false
    })

    keyboardListeners.value.push(keyboardWillHide)

    const keyboardDidHide = await Keyboard.addListener('keyboardDidHide', () => {
      const ionContent = document.getElementById('ionContent')!
      ionContent.style.setProperty('--keyboard-offset', '0px');
    })

    keyboardListeners.value.push(keyboardDidHide)

  }

  await ApiService.Chat().isActive(chatId.value, true)
  isActiveInterval.value = setInterval(async () => {
    await ApiService.Chat().isActive(chatId.value, true)
  }, 30000)

})

onUnmounted(async () => {
  await ApiService.Chat().isActive(chatId.value, false)

  appStateChangeListener.value?.remove()

  clearInterval(isActiveInterval.value)
  observable.value.unsubscribe()
  keyboardListeners.value.forEach(l => {
    l.remove()
  })
})

const scrollToBottom = (speed = 0) => {
  if (chatContainer.value) {
    chatContainer.value.$el.scrollToBottom(speed)
  }
}

const postMessage = async () => {
  if (busy.value) return
  if (!messageMedia.value && !message.value) return
  try {
    busy.value = true
    const newMessage: ChatMessageInterface = await ApiService.Chat().postMessage(chatId.value, message.value, messageMedia.value ?? null)
    Observable.emit(ObservableEvent.ChatsChanged)
    messages.value.push(newMessage)
    scrollToBottom(0)
    messageField.value.innerText = ''
    messageMedia.value = ''
    message.value = ''
    busy.value = false
  } catch (e:any) {

    busy.value = false

    if (e.status === 429) {
      return usePremium().presentPayWall({
        text: e.data.message,
        callback: () => {
          //
        }
      });
    }

  }
}

const setMessage = async (ev: any) => {
  message.value = ev.target.innerText
}

const isAndroid = () => {
  return isPlatform('android')
}


const removeMedia = async () => {

  const actionSheet = await actionSheetController.create({
    buttons: [
      {
        text: 'Remove Photo',
        handler: async () => {
          messageMedia.value = ''
        }
      },
      {
        text: 'Cancel',
        role: 'cancel'
      }
    ]
  })

  await actionSheet.present()

}

const messageMedia = ref('')
const uploading = ref(false)

const addPhoto = async () => {

  if (busy.value) return

  try {
    const photo = await useCamera(CameraSource.Photos, CameraResultType.Base64)
    if (Object.prototype.hasOwnProperty.call(photo, 'base64String')) messageMedia.value = `data:image/${photo.format};base64,${photo.base64String}`
    busy.value = false
  } catch (e) {
    //
  }

}

const pullToRefresh = async (ev: any) => {
  // await getFeed(1)
  setTimeout(() => {
    ev.target.complete()
  }, 1000)
}

const deleteMessage = async (message: ChatMessageInterface) => {

  const messageIndex = messages.value.findIndex(m => m.id === message.id)
  messages.value.splice(messageIndex, 1)
  await ApiService.Chat().deleteMessage(message.id)

}

const isAuthUser = (postedByUserId: any) => {
  return postedByUserId === authUserId.value
}

const showParticipants = async () => {
  const sheet = await modalController.create({
    breakpoints: [0, 1],
    initialBreakpoint: 1,
    component: FlirtViewChatParticipants,
    componentProps: {
      participants: chat.value.participants
    },
    mode: 'ios'
  })

  await sheet.present()
}

const isDirectMessage = computed(() => {
  return !(chat.value.participants.filter((participant: ChatParticipantInterface) => {
    return participant.role === 'ADMIN'
  })).length
})

const dmUser = computed(() => {
  return chat.value.participants.find((participant: ChatParticipantInterface) => !participant.is_auth_user)
})

</script>
<style scoped>
.input:empty:before {
  content: attr(data-placeholder);
  color: rgba(255, 255, 255, 0.5);
}

ion-footer ion-toolbar {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
}

ion-title {
  padding: 0;
}

</style>
