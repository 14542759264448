<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="mr-4">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title class="text-white font-medium text-2xl">Relationship</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="search" class=" rounded-md">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M21 21L16.514 16.506L21 21ZM19 10.5C19 12.7543 18.1045 14.9163 16.5104 16.5104C14.9163 18.1045 12.7543 19 10.5 19C8.24566 19 6.08365 18.1045 4.48959 16.5104C2.89553 14.9163 2 12.7543 2 10.5C2 8.24566 2.89553 6.08365 4.48959 4.48959C6.08365 2.89553 8.24566 2 10.5 2C12.7543 2 14.9163 2.89553 16.5104 4.48959C18.1045 6.08365 19 8.24566 19 10.5V10.5Z"
                  stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="h-full">
      <div class="flex h-full flex-col">

        <svg v-if="loading" class="animate-spin h-10 w-10 m-auto text-white" xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>

        <div v-if="!loading && !partnerRequests.length && !partners.length && !partnerRequestsSent.length"
             class="flex px-4 py-5 m-auto text-white">
          <div class="flex flex-col items-center space-y-6">
            <svg class="h-16 w-16 text-brand-lime fill-current" width="24" height="25" viewBox="0 0 24 25"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M17.5291 19.2339L17.4684 19.0516C15.8278 15.9225 12.5468 13.7959 8.77975 13.7959C5.01266 13.7959 1.73165 15.9225 0.091139 19.0212L0.0303799 19.2035C1.15437e-07 19.2643 0 19.3554 0 19.4465V20.206C0 20.6617 0.394936 21.0567 0.850633 21.0567H8.77975H16.7392C17.1949 21.0567 17.5899 20.6617 17.5899 20.206V19.4465C17.5899 19.3858 17.5595 19.2946 17.5291 19.2339Z"
              />
              <path
                  d="M18.957 18.3531L18.9266 18.2923C17.9848 16.5303 16.6481 15.1024 15.0684 14.0695C15.919 13.6746 16.8911 13.4619 17.8937 13.4619C20.5063 13.4619 22.7544 14.9201 23.9089 17.0771L23.9696 17.1986C24 17.2594 24 17.3201 24 17.3505V17.867C24 18.2012 23.7266 18.4442 23.4228 18.4442H19.0177L18.957 18.3531Z"
              />
              <path
                  d="M17.8937 11.5784C19.4541 11.5784 20.719 10.3134 20.719 8.75305C20.719 7.19267 19.4541 5.92773 17.8937 5.92773C16.3333 5.92773 15.0684 7.19267 15.0684 8.75305C15.0684 10.3134 16.3333 11.5784 17.8937 11.5784Z"
              />
              <path
                  d="M8.74968 12.4896C11.1658 12.4896 13.1244 10.531 13.1244 8.11492C13.1244 5.69885 11.1658 3.74023 8.74968 3.74023C6.33361 3.74023 4.375 5.69885 4.375 8.11492C4.375 10.531 6.33361 12.4896 8.74968 12.4896Z"
              />
            </svg>
            <div class="flex flex-col w-full items-center space-y-4 px-2">
              <div class="flex text-white text-2xl font-medium text-center">Invites & Requests
              </div>
              <div class="text-brand-light-gray text-lg text-center">Once you've liked your account with your partners
                your accounts will display as a couples account.
              </div>
            </div>
          </div>
        </div>

        <div v-if="!loading && (partners.length || partnerRequests.length || partnerRequestsSent.length)"
             class="flex w-full flex-col px-4 py-5 space-y-6">

          <div v-if="partners && partners.length" class="flex flex-col w-full space-y-4">
            <div class="font-medium text-lg text-brand-light-gray">{{ pluralize('Partner', partners.length) }}</div>
            <div class="flex justify-between w-full">
              <template v-for="(u,userIndex) in partners"
                        :key="userIndex">
                <div @click="partnerContext(u)" class="flex justify-between w-full">
                  <user-list-item
                      :user="u"/>
                  <div class="flex my-auto">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M5.52035 14.1598C6.71329 14.1598 7.68035 13.1928 7.68035 11.9998C7.68035 10.8069 6.71329 9.83984 5.52035 9.83984C4.32742 9.83984 3.36035 10.8069 3.36035 11.9998C3.36035 13.1928 4.32742 14.1598 5.52035 14.1598Z"
                          fill="#A5A7AF"/>
                      <path
                          d="M12.0008 14.1598C13.1938 14.1598 14.1608 13.1928 14.1608 11.9998C14.1608 10.8069 13.1938 9.83984 12.0008 9.83984C10.8079 9.83984 9.84082 10.8069 9.84082 11.9998C9.84082 13.1928 10.8079 14.1598 12.0008 14.1598Z"
                          fill="#A5A7AF"/>
                      <path
                          d="M18.4803 14.1598C19.6732 14.1598 20.6403 13.1928 20.6403 11.9998C20.6403 10.8069 19.6732 9.83984 18.4803 9.83984C17.2874 9.83984 16.3203 10.8069 16.3203 11.9998C16.3203 13.1928 17.2874 14.1598 18.4803 14.1598Z"
                          fill="#A5A7AF"/>
                    </svg>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div v-if="partnerRequestsSent && partnerRequestsSent.length" class="flex flex-col w-full space-y-4">
            <div class="font-medium text-lg text-brand-light-gray">Pending</div>
            <div class="flex justify-between w-full">
              <template v-for="(u,userIndex) in partnerRequestsSent"
                        :key="userIndex">
                <user-list-item
                    :user="u"/>
                <div @click="cancelRequest(u)"
                     class="text-white rounded-full border-2 font-medium py-1 pb-1.5 px-3 my-auto min-w-fit">
                  <span v-if="true" class="text-sm my-auto">Cancel Request</span>
                  <svg v-else class="animate-spin h-5 w-5 mt-0.5 text-white"
                       xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                            stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              </template>
            </div>
          </div>

          <div v-if="partnerRequests && partnerRequests.length" class="flex flex-col w-full space-y-4">
            <div class="font-medium text-lg text-brand-light-gray">Requests</div>
            <div class="flex justify-between w-full">
              <template v-for="(u,userIndex) in partnerRequests"
                        :key="userIndex">
                <user-list-item
                    :user="u"/>
                <div class="flex space-x-2">
                  <div @click="acceptRequest(u)"
                       class="bg-white rounded-full border-2  border-white font-medium py-1 pb-1.5 px-3 my-auto">
                    <span v-if="!acceptBusy" class="text-sm my-auto">Accept</span>
                    <svg v-else class="animate-spin h-5 w-5 mt-0.5 text-brand-black"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                              stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                  <div @click="rejectRequest(u)"
                       class="text-white rounded-full border-2 font-medium py-1 pb-1.5 px-3 my-auto">
                    <span v-if="true" class="text-sm my-auto">Reject</span>
                    <svg v-else class="animate-spin h-5 w-5 mt-0.5 text-white"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                              stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                </div>
              </template>
            </div>
          </div>

        </div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar, modalController,
  onIonViewWillEnter, toastController,
  useIonRouter,
} from '@ionic/vue';
import {inject, onMounted, onUnmounted, Ref, ref, watch} from 'vue';
import {UserInterface} from "@/interfaces/UserInterface";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import ApiService from "@/services/api/apiService";
import pluralize from "pluralize";
import UserListItem from "@/components/UserListItem.vue";
import ProfileSettingsPartnerContextMenu from "@/views/user/profile/ProfileSettingsPartnerContextMenu.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>

const loading = ref(true)
const ionRouter = useIonRouter()
const busy = ref(false)
const acceptBusy = ref(false)

const partners: Ref<UserBasicInformationInterface[]> = ref([])
const partnerRequests: Ref<UserBasicInformationInterface[]> = ref([])
const partnerRequestsSent: Ref<UserBasicInformationInterface[]> = ref([])
const observer = ref()

onIonViewWillEnter(async () => {
  await getData()
  loading.value = false
})

onMounted(async ()=>{
  observer.value = Observable.subscribe(async (response: any) => {
    if (response.event === ObservableEvent.PartnersChanged) {
      partners.value = await getPartners()
    }
  })
})

onUnmounted(() => {
  observer.value?.unsubscribe()
})

const getData = async () => {
  const [i, ii, iii]: any = await Promise.allSettled([await getPartnerRequests(), await getPartners(), await getPartnerRequestsSent()])
  partnerRequests.value = i.value
  partners.value = ii.value
  partnerRequestsSent.value = iii.value
  loading.value = false
}

const getPartners = async () => {
  return await ApiService.User().getPartners()
}

const getPartnerRequests = async () => {
  return await ApiService.User().getPartnerRequests()
}

const getPartnerRequestsSent = async () => {
  return await ApiService.User().getPartnerRequestsSent()
}

const goBack = async () => {
  await ionRouter.back()
}

const search = async () => {
  ionRouter.navigate({
    name: 'ProfileSettingsPartnerSearch'
  }, 'forward', 'push')
}

const cancelRequest = async (requestedUser: UserBasicInformationInterface) => {

  const alert = await alertController.create({
    header: 'Cancel Request',
    subHeader: `Are you sure you want to cancel the relationship request with ${requestedUser.name}?`,
    buttons: [
      {
        text: 'Yes',
        role: 'destructive',
        handler: async () => {

          if (busy.value) return
          busy.value = true

          const index = partnerRequestsSent.value.findIndex((u) => u.id === requestedUser.id)
          partnerRequestsSent.value.splice(index, 1)
          await alert.dismiss()
          const toast = await toastController.create({
            header: 'Request Cancelled',
            message: 'Your partner request has been cancelled 👍',
            duration: 3000,
            position: 'bottom',
            color: 'primary'
          });
          await toast.present();
          await ApiService.User().removeFromPartners(requestedUser.id)

        }
      },
      {
        text: 'Cancel',
      }
    ]
  })

  await alert.present()
  await alert.onDidDismiss()
  busy.value = false

}

const rejectRequest = async (requestedUser: UserBasicInformationInterface) => {

  const alert = await alertController.create({
    header: 'Reject Request',
    subHeader: `Are you sure you want to reject the relationship request from ${requestedUser.name}?`,
    buttons: [
      {
        text: 'Yes',
        role: 'destructive',
        handler: async () => {

          if (busy.value) return
          busy.value = true

          const index = partnerRequests.value.findIndex((u) => u.id === requestedUser.id)
          partnerRequests.value.splice(index, 1)
          await alert.dismiss()
          const toast = await toastController.create({
            header: 'Request Rejected',
            message: `The partner request from ${requestedUser.name} has been rejected 👍`,
            duration: 3000,
            position: 'bottom',
            color: 'primary'
          });
          await toast.present();
          await ApiService.User().rejectPartnerRequest(requestedUser.id)

        }
      },
      {
        text: 'Cancel',
      }
    ]
  })

  await alert.present()
  await alert.onDidDismiss()
  busy.value = false

}

const acceptRequest = async (requestedUser: UserBasicInformationInterface) => {

  const alert = await alertController.create({
    header: 'Accept Request',
    subHeader: `Are you sure you want to accept the relationship request from ${requestedUser.name}?`,
    buttons: [
      {
        text: 'Yes',
        handler: async () => {

          if (busy.value) return
          busy.value = true
          acceptBusy.value = true

          await alert.dismiss()
          await ApiService.User().makePartnerRequest(requestedUser.id)
          await getData()
          const toast = await toastController.create({
            header: 'Accepted!',
            message: `You and ${requestedUser.name} are now linked as a couple ❤️`,
            duration: 3000,
            position: 'bottom',
            color: 'primary'
          });
          await toast.present();
          busy.value = false
          acceptBusy.value = false

        }
      },
      {
        text: 'Cancel',
        handler: () => {
          busy.value = false
        }
      }
    ]
  })

  await alert.present()

}

const partnerContext = async (partner: UserBasicInformationInterface) => {

  const sheet = await modalController.create({
    breakpoints: [0, 1],
    initialBreakpoint: 1,
    component: ProfileSettingsPartnerContextMenu,
    componentProps: {
      partner
    },
    mode: 'ios'
  })

  await sheet.present()
}

</script>
