<template>

  <div class="flex flex-col w-full h-full">
    <div v-if="loading" class="flex w-full h-full">
      <svg class="animate-spin h-10 w-10 m-auto text-white" xmlns="http://www.w3.org/2000/svg"
           fill="none"
           viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>

    <div v-if="!loading && !feed.length && type === SocialFeedType.ForYou" class="flex h-full w-full">
    <div class="flex px-4 py-5 m-auto text-white">
      <div class="flex flex-col items-center space-y-6">

        <div class="h-20 w-20 bg-brand-lime text-brand-black rounded-full flex">
          <div class="m-auto">
            <svg class="w-7 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z"/></svg>
          </div>
        </div>

        <div class="flex flex-col w-full items-center space-y-0 px-2">
          <div class="flex text-white text-2xl font-medium text-center">Your feed is empty</div>
          <div class="text-brand-light-gray text-lg text-center">
           Your preferences might be too specific!
            <br/>Try reducing how many you have chosen.
          </div>
        </div>
      </div>
    </div>
    </div>

    <div v-if="!loading && !feed.length && type === SocialFeedType.Following" class="flex h-full w-full">
      <div class="flex px-4 py-5 m-auto text-white">
        <div class="flex flex-col items-center space-y-6">

          <div class="h-20 w-20 bg-brand-lime text-brand-black rounded-full flex">
            <div class="m-auto">
              <svg class="w-7 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M96 224c-11.1 0-21.6 2.8-30.7 7.8c4-36.7 16.8-68.8 34.5-92.4C121.3 110.8 148.6 96 176 96s54.7 14.8 76.2 43.4C273.7 168.1 288 209.2 288 256s-14.3 87.9-35.8 116.6S203.4 416 176 416s-54.7-14.8-76.2-43.4c-4.9-6.5-9.4-13.6-13.4-21.3c3.1 .5 6.4 .7 9.6 .7c35.3 0 64-28.7 64-64s-28.7-64-64-64zm224-97.3c-5-9.1-10.5-17.6-16.6-25.7C272.8 60.2 228.1 32 176 32s-96.8 28.2-127.4 69C18 141.8 0 196.7 0 256s18 114.2 48.6 155c30.6 40.8 75.3 69 127.4 69s96.8-28.2 127.4-69c6-8 11.6-16.6 16.6-25.7c5 9.1 10.5 17.6 16.6 25.7c30.6 40.8 75.3 69 127.4 69s96.8-28.2 127.4-69C622 370.2 640 315.3 640 256s-18-114.2-48.6-155C560.8 60.2 516.1 32 464 32s-96.8 28.2-127.4 69c-6 8-11.6 16.6-16.6 25.7zm54.4 224.6c3.1 .5 6.4 .7 9.6 .7c35.3 0 64-28.7 64-64s-28.7-64-64-64c-11.1 0-21.6 2.8-30.7 7.8c4-36.7 16.8-68.8 34.5-92.4C409.3 110.8 436.6 96 464 96s54.7 14.8 76.2 43.4C561.7 168.1 576 209.2 576 256s-14.3 87.9-35.8 116.6S491.4 416 464 416s-54.7-14.8-76.2-43.4c-4.9-6.5-9.4-13.6-13.4-21.3z"/></svg>
            </div>
          </div>

          <div class="flex flex-col w-full items-center space-y-0 px-2">
            <div class="flex text-white text-2xl font-medium text-center">Your feed is empty</div>
            <div class="text-brand-light-gray text-lg text-center">
              Follow your favourite pineapple accounts so you don't miss a single post!
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loading && !feed.length && type === SocialFeedType.Friends" class="flex h-full w-full">
      <div class="flex px-4 py-5 m-auto text-white">
        <div class="flex flex-col items-center space-y-6">

          <div class="h-20 w-20 bg-brand-lime text-brand-black rounded-full flex">
            <div class="m-auto">
              <svg class="w-7 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z"/></svg>
            </div>
          </div>

          <div class="flex flex-col w-full items-center space-y-0 px-2">
            <div class="flex text-white text-2xl font-medium text-center">Your feed is empty</div>
            <div class="text-brand-light-gray text-lg text-center">
              Your friends posts will appear hear so you don't miss a single post from them!
            </div>
          </div>
        </div>
      </div>
    </div>

    <DynamicScroller
        v-if="isPlatform('android') && feed.length"
        :buffer="200"
        ref="scrollContainer"
        :items="feed"
        :min-item-size="600"
        class="ion-content-scroll-host scroller flex w-full"
    >

      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
            :item="item"
            :active="active"
            :data-index="index"
            class="w-full"
        >
          <feed-post
              :auth-user="user"
              :post="item"/>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>

    <virtual-list v-if="(isPlatform('ios') || isPlatform('desktop')) && feed.length" class="list-dynamic ion-content-scroll-host"
                 :keeps="8"
                 data-key="id"
                 :data-sources="feed"
                 :estimate-size="600"
                 ref="scrollContainer"
    >
      <template #={source}>
        <feed-post
            :auth-user="user"
            :post="source"/>
      </template>
    </virtual-list>

  </div>

</template>
<script setup lang="ts">

import {isPlatform} from "@ionic/vue";
import FeedPost from "@/components/FeedPost.vue";
import {nextTick, onMounted, onUnmounted, onUpdated, PropType, Ref, ref} from "vue";
import ApiService from "@/services/api/apiService";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import {SocialFeedType} from "@/definitions/SocialFeedType";
import {EventFilterIs} from "@/definitions/EventFilterIs";
import {EventTypeModel} from "@/definitions/EventTypeModel";

const user: Ref<UserInterface | undefined> = ref()
const loading = ref(true)
const busy = ref(false)

const feed: Ref<(FeedPostInterface | any)[]> = ref([])

const showInfiniteScrollBottom = ref(true)

const props = defineProps({
  type: {
    type: String as PropType<SocialFeedType>,
    required: false,
    default: SocialFeedType.ForYou
  }
})
const pullToRefresh = async (ev: any) => {
  await getStart()
  showInfiniteScrollBottom.value = true
  ev.target.complete()
}

const getStart = async () => {
  if (busy.value) return
  busy.value = true

  let response: any[];
  if (props.type !== SocialFeedType.Friends) {
    response = await ApiService.Feed().getBefore(props.type)
  } else {
    response = (await ApiService.FriendsFeed().getBefore())
  }

  feed.value = response
  busy.value = false
}


const getAfter = async () => {

  if (busy.value) return
  busy.value = true

  let response: any[];
  if (props.type !== SocialFeedType.Friends) {
    response = (await ApiService.Feed().getAfter(feed.value.at(-1)?.id, props.type))
  } else {
    response = (await ApiService.FriendsFeed().getAfter(feed.value.at(-1)?.id))
  }

  if (response && response.length) {
    feed.value = [...feed.value, ...response]
  }

  if (response && !response.length) showInfiniteScrollBottom.value = false
  busy.value = false
}

const observer = ref()

const emit = defineEmits(['isScrollTop'])

const getAuthUser = async () => {
  user.value = await ApiService.User().get()
}

onMounted(async () => {

  await getAuthUser()

  observer.value = Observable.subscribe(async (response: any) => {

    if (
        (response.event === ObservableEvent.PullToRefreshForYou && props.type === SocialFeedType.ForYou) ||
        (response.event === ObservableEvent.PullToRefreshFollowing && props.type === SocialFeedType.Following) ||
        (response.event === ObservableEvent.PullToRefreshFriends && props.type === SocialFeedType.Friends)
    ) {
      await pullToRefresh(response.data)
    }

    if ([ObservableEvent.FeedPostDeleted, ObservableEvent.FeedPostChanged].includes(response.event)) {

      const index = feed.value.findIndex((post: any) => {
        return post.id === response.data.id
      })

      if (response.event === ObservableEvent.FeedPostDeleted) {
        feed.value.splice(index, 1)
      }

      // Update post that was just interacted with
      if (response.event === ObservableEvent.FeedPostChanged) {
        Object.keys(response.data).forEach((key: any) => {
          feed.value[index][key] = response.data[key]
        })
      }

    }
  })

  observer.value = Observable.subscribe(async (response: any) => {


    if (response.event === ObservableEvent.UserChanged) {
      await getAuthUser()
    }


    if (response.event === ObservableEvent.RefreshFeed && !feed.value.length && !response.data) {
      await getStart()
    }

    if (response.event === ObservableEvent.RefreshFeed && response.data === props.type) {
      if (isPlatform('ios') || isPlatform('desktop')) {
        scrollContainer.value.$el.scrollTo({top: 0, behavior: 'smooth'})
      } else {
        scrollContainer.value.$el.scrollTop = 0
      }
      await getStart()
    }
  })

  await getStart()
  loading.value = false

  setContainerHeight()
  addContainerEventListeners()

})

const addContainerEventListeners = () => {
  if (scrollContainer.value)
  scrollContainer.value.$el.addEventListener("scroll", () => {

    if (scrollContainer.value.$el.scrollTop <= 100) {
      emit('isScrollTop', true)
    } else {
      emit('isScrollTop', false)
    }

    handleScroll()
  });
}

onUnmounted(() => {
  observer.value?.unsubscribe()
})

const setContainerHeight = () => {
  const containerHeight = scrollContainer.value?.$el.closest('ion-content')?.clientHeight
  if (containerHeight === 0) return
  if (scrollContainer.value) scrollContainer.value.$el.style.height = `${containerHeight}px`
}

onUpdated(async () => {
  await nextTick()
})

const handleScroll = async () => {
  const threshold = 1500; // Adjust this threshold as needed
  const containerHeight = scrollContainer.value.$el.closest('ion-content')?.clientHeight
  const isScrolledToBottom = scrollContainer.value.$el.scrollTop + containerHeight >= scrollContainer.value.$el.scrollHeight - threshold;
  if (isScrolledToBottom) await getAfter()
}


const scrollContainer: Ref<any> = ref(null)


</script>
