<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="!p-0 !m-0">
        <ion-buttons class="justify-between flex w-full">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="fill-current text-white w-6 h-6 p-0.5" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 320 512">
              <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                  d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
            </svg>
          </ion-button>
          <div class="flex relative">
            <ion-button
                v-if="!post"
                @click="postToFeed"
                class="!text-xl">
              <div :class="{
                  'text-brand-light-gray' : !canPost,
                  'text-brand-lime' : canPost
                }">Post
              </div>
            </ion-button>
            <ion-button
                v-if="post"
                @click="updatePost"
                class="!text-xl">
              <div :class="{
                  'text-brand-light-gray' : !canPost,
                  'text-brand-lime' : canPost
                }">Update
              </div>
            </ion-button>

            <div v-if="posting" class="absolute flex top-0 left-0 w-full h-full bg-brand-black z-50">
              <svg class="animate-spin m-auto h-5 w-5 text-brand-lime" xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </div>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>

      <div
          class="bg-brand-black flex w-full p-4 py-5 font-medium text-xl text-white border-b-4 border-brand-dark-gray max-h-80 overflow-scroll">

        <Mentionable
            class="w-full"
            :keys="['@']"
            :items="mentionableUsers"
            offset="6"
            @open="loadUsers()"
            @search="loadUsers($event)"
            filtering-disabled
        >
          <ion-textarea
              auto-grow
              autocapitalize="on"
              v-model="postContent"
              placeholder="What's on your mind?"/>

          <template #no-result>
            <div class="text-sm text-white p-3">
              {{ loadingUsers ? 'Loading...' : 'No users found.' }}
            </div>
          </template>

          <template #item-@="{ item }">
            <div class="text-sm">
                @{{ item.value }}
            </div>
          </template>

        </Mentionable>
      </div>

      <div class="flex flex-col p-4 space-y-2">
        <div class="font-medium text-xl text-white">Images</div>
        <div class="flex grid gap-2 grid-cols-3">
          <div
              v-for="(photo,pIndex) in postPhotos"
              :key="pIndex"
              :style="{
                'background-image' : `url('${photo}')`
              }"
              class="w-full h-28 bg-brand-dark-gray bg-cover bg-center rounded-2xl flex p-2">
            <svg @click="removePhoto(pIndex)" class="mt-auto ml-auto" width="25" height="25"
                 viewBox="0 0 25 25"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="25" height="25" rx="12.5" fill="white"/>
              <path
                  d="M16.8921 7L12.5 11.3921L8.10786 7L7 8.10786L11.3921 12.5L7 16.8921L8.10786 18L12.5 13.6079L16.8921 18L18 16.8921L13.6079 12.5L18 8.10786L16.8921 7Z"
                  fill="black"/>
            </svg>
          </div>

          <div @click="addPhoto" class="w-full h-28 bg-brand-dark-gray rounded-2xl flex">
            <svg v-if="busy" class="animate-spin h-7 w-7 text-white m-auto"
                 xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                      stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <svg v-if="!busy" class="m-auto" width="25" height="25" viewBox="0 0 25 25" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M12.5301 5.5L12.5117 19.5" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M5.5 12.5H19.5" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>

      <div v-if="!loading" class="flex flex-col p-4 space-y-2">
        <div class="font-medium text-xl text-white">Who can see this post?</div>
        <div class="flex flex-col space-y-2">
          <input-checkbox
              v-for="(p,pIndex) in privacyOptions"
              :key="pIndex"
              name="privacy"
              :return-single-value="true"
              v-model="postPrivacy"
              :option="p"
          />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTextarea,
  IonToolbar,
  modalController,
  toastController,
  useIonRouter,
} from '@ionic/vue';
import {computed, onMounted, PropType, Ref, ref} from 'vue';
import InputCheckbox from "@/components/InputCheckbox.vue";
import {PostPrivacy} from "@/definitions/PostPrivacy";
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import {UserInterface} from "@/interfaces/UserInterface";
import {usePremium} from "@/composables/usePremium";
import {Mentionable} from 'vue-mention'
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";

const props = defineProps({
  post: {
    type: Object as PropType<FeedPostInterface>,
    required: false
  },
  authUser: {
    type: Object as PropType<UserInterface>,
    required: true
  }
})


const loading = ref(true)
const busy = ref(false)
const posting = ref(false)


const privacyOptions: Ref<any[]> = ref([])

const postContent = ref('')
const postPrivacy: Ref<PostPrivacy[]> = ref([])
const postPhotos: Ref<string[]> = ref([])

onMounted(async () => {

  await reset()

  if (!props.authUser?.app_reviewer) {
    privacyOptions.value.push({
      text: 'NSFW',
      description: 'Not Safe For Work 😈🍆💦',
      value: PostPrivacy.XRated,
    })
  }

  privacyOptions.value.push(
      {
        text: 'Inner Circle',
        description: 'Anything goes! Only visible to your friends.',
        value: PostPrivacy.InnerCircle
      },
      {
        text: 'Public',
        description: 'All posts will be manually verified before appearing on the public feed. Nudity of any kind is not accepted here.',
        value: PostPrivacy.Public
      }
  )

  if (props.post) {
    postContent.value = props.post.data.original_content
    postPrivacy.value = [props.post.visibility as PostPrivacy]
    postPhotos.value = props.post.media.map((m) => {
      return m.url
    })
  }

  loading.value = false
})

const reset = async () => {
  postContent.value = '',
      postPrivacy.value = []
  postPhotos.value = []
  posting.value = false
  busy.value = false
}

const goBack = async () => {

  if (busy.value || posting.value) return

  const modal = await alertController.create({
    header: 'Discard Post',
    message: 'Are you sure you want to discard this post?',
    buttons: [
      {
        text: 'Yes',
        role: 'destructive',
        handler: () => {
          modalController.dismiss()
          reset()
        }
      },
      {
        text: 'No',
      },

    ]
  })

  await modal.present()

}

const addPhoto = async () => {

  if (busy.value || posting.value) return

  try {
    const photo = await useCamera(CameraSource.Photos, CameraResultType.Base64)
    busy.value = true
    if (Object.prototype.hasOwnProperty.call(photo, 'base64String')) postPhotos.value.push(`data:image/${photo.format};base64,${photo.base64String}`)
    busy.value = false
  } catch (e) {
    busy.value = false
  }

}

const removePhoto = async (index: number) => {

  if (busy.value || posting.value) return

  const modal = await alertController.create({
    header: 'Remove Photo',
    message: 'Are you sure you want to remove this photo from the post?',
    buttons: [
      {
        text: 'Yes',
        role: 'destructive',
        handler: async () => {
          postPhotos.value.splice(index, 1)
        }
      },
      {
        text: 'No',
      },

    ]
  })

  await modal.present()

}

const canPost = computed(() => {
  return (postContent.value.length > 3 || postPhotos.value.length > 0) && postPrivacy.value.length > 0
})

const postToFeed = async () => {

  if (!canPost.value) return

  try {
    posting.value = true
    const newPost = await ApiService.Feed().post(postContent.value, postPrivacy.value[0])
    if (postPhotos.value.length) await ApiService.Feed().attachMedia(newPost.id, postPhotos.value)
    await reset()
    Observable.emit(ObservableEvent.FeedScrollTop)
    await modalController.dismiss()
  } catch (e: any) {

    posting.value = false
    if (e.status === 429) {
      return usePremium().presentPayWall({
        text: e.data.message,
        callback: () => {
          //
        }
      });
    }

    const toast = await toastController.create({
      message: e.data.message,
      duration: 3000,
      position: 'bottom',
      color: 'danger'
    });
    await toast.present();
  }
}

const updatePost = async () => {

  if (!canPost.value) return
  if (!props.post) return
  try {
    posting.value = true
    const updatedPost = await ApiService.Feed().updatePost(props.post.id, postContent.value, postPrivacy.value[0])
    await ApiService.Feed().attachMedia(props.post.id, postPhotos.value)
    await reset()
    await modalController.dismiss()
    Observable.emit(ObservableEvent.FeedPostChanged, updatedPost)
    posting.value = false
  } catch (e: any) {
    const toast = await toastController.create({
      message: e.data.message,
      duration: 3000,
      position: 'bottom',
      color: 'danger'
    });
    await toast.present();
    posting.value = false
  }
}

const loadingUsers = ref(false)
const mentionableUsers: Ref<any[]> = ref([])

const mentionableUserId = (name: string) => {
  return (mentionableUsers.value.find((user: UserBasicInformationInterface) => {
    return user.name === name
  })).id
}

const loadUsers = async (searchText = '') => {
  loadingUsers.value = true
  mentionableUsers.value = await fetchUsers(searchText)
  loadingUsers.value = false
}

const fetchUsers = async (search: string) => {
  const results = await ApiService.Search().users(search)
  return results.map((result: UserBasicInformationInterface) => {
    return {
      value: result.name,
      label: result.name
    }
  })
}
</script>