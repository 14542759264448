<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>

        <div class="flex flex-col w-full">
          <div class="flex w-full justify-between pb-1.5 relative px-4">

            <div class="flex w-1/3 space-x-4">

            </div>

            <svg class="mb-auto" width="118" viewBox="0 0 109 26" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_284_92176)">
                <path
                    d="M6.30522 16.6075C4.35057 16.6075 2.76232 15.0165 2.76232 13.0611V13.037C2.76232 11.0671 4.35216 9.46646 6.30522 9.46646C8.25828 9.46646 9.84812 11.0687 9.84812 13.037C9.84812 15.0052 8.25828 16.6075 6.30522 16.6075ZM6.30522 6.68262C4.62776 6.68262 3.04747 7.34567 1.8527 8.54975C0.657922 9.75223 0 11.3464 0 13.037V24.0953C0 24.8563 0.624468 25.4744 1.39231 25.4744C2.16015 25.4744 2.76072 24.8563 2.76072 24.0953V18.2996C3.7946 19.006 5.04035 19.3913 6.30363 19.3913C7.99224 19.3913 9.57731 18.7315 10.7657 17.5338C11.9541 16.3362 12.6088 14.7387 12.6088 13.037C12.6088 9.53228 9.77962 6.68262 6.30363 6.68262"
                    fill="#EBFF00"/>
                <path
                    d="M15.9831 1.70703C15.6327 1.70703 15.2679 1.86437 15.0066 2.12766C14.7453 2.39095 14.5908 2.7586 14.5908 3.1118C14.5908 3.46499 14.7469 3.83264 15.0066 4.09593C15.2567 4.34799 15.612 4.49087 15.9831 4.49087C16.3543 4.49087 16.7096 4.34638 16.9597 4.09593C17.2082 3.84388 17.3515 3.48587 17.3515 3.1118C17.3515 2.73773 17.2082 2.37971 16.9597 2.12766C16.7111 1.8756 16.3336 1.70703 15.9831 1.70703Z"
                    fill="#EBFF00"/>
                <path
                    d="M15.9271 6.58301C15.1593 6.58301 14.5332 7.2011 14.5332 7.96209V17.887C14.5332 18.6736 15.1449 19.2901 15.9271 19.2901C16.7093 19.2901 17.2955 18.6608 17.2955 17.887V7.96209C17.2955 7.2011 16.6822 6.58301 15.9271 6.58301Z"
                    fill="#EBFF00"/>
                <path
                    d="M25.606 6.58301C22.13 6.58301 19.3008 9.43428 19.3008 12.9374V17.8886C19.3008 18.6624 19.9141 19.2917 20.6692 19.2917C21.4243 19.2917 22.0615 18.6752 22.0615 17.8886V12.9374C22.0615 10.9675 23.6513 9.36685 25.6044 9.36685C27.5575 9.36685 29.1473 10.9691 29.1473 12.9374V17.8886C29.1473 18.6624 29.7606 19.2917 30.5157 19.2917C31.2708 19.2917 31.908 18.6752 31.908 17.8886V12.9374C31.908 9.43428 29.0788 6.58301 25.6028 6.58301"
                    fill="#EBFF00"/>
                <path
                    d="M36.9051 11.5342C37.469 10.2274 38.753 9.36685 40.1724 9.36685C41.3497 9.36685 42.8885 9.93999 43.4779 11.5342H36.9051ZM44.4624 8.21254C43.3075 7.17702 41.7447 6.58301 40.1724 6.58301C38.4838 6.58301 36.8987 7.24285 35.7103 8.44051C34.5219 9.63817 33.8672 11.2356 33.8672 12.9374C33.8672 16.442 36.6964 19.2917 40.1724 19.2917H40.2935C41.2063 19.2917 42.4457 19.2692 44.0291 18.2048C44.7237 17.7473 44.985 17.0072 44.6743 16.357C44.4879 15.9829 44.1422 15.6955 43.7472 15.588C43.4158 15.498 43.0845 15.5462 42.8152 15.7228C41.7463 16.4196 40.8399 16.5079 40.1708 16.5079C38.7466 16.5079 37.4611 15.6361 36.9019 14.3164H45.0837C45.8516 14.3164 46.476 13.6983 46.476 12.9374C46.476 10.526 45.38 9.03613 44.4608 8.21254"
                    fill="#EBFF00"/>
                <path
                    d="M54.4464 16.5099C53.4858 16.5099 52.581 16.1551 51.8991 15.5097C51.2094 14.8579 50.8286 13.9797 50.8286 13.0373C50.8286 11.061 52.4854 9.39292 54.4464 9.39292C55.3815 9.39292 56.2529 9.77662 56.8997 10.4718C57.5401 11.1589 57.8921 12.0708 57.8921 13.0389C57.8921 15.0184 56.4106 16.5115 54.4464 16.5115V16.5099ZM54.4464 6.63477C52.7339 6.63477 51.1345 7.29621 49.9413 8.49869C48.7481 9.70117 48.0918 11.313 48.0918 13.0389C48.0918 14.7647 48.7497 16.3381 49.9429 17.4956C51.1233 18.6403 52.7227 19.2712 54.4448 19.2712C55.8435 19.2712 56.9602 18.9261 57.9335 18.1908C58.0992 18.8121 58.7141 19.2712 59.2351 19.2712C60.0427 19.2712 60.6274 18.6804 60.6274 17.8681V13.0405C60.6274 11.305 60.0013 9.69314 58.8655 8.5035C57.7169 7.29942 56.1462 6.63798 54.4448 6.63798"
                    fill="#EBFF00"/>
                <path
                    d="M68.8736 16.6075C66.9189 16.6075 65.3307 15.0165 65.3307 13.0611V13.037C65.3307 11.0671 66.9205 9.46646 68.8736 9.46646C70.8266 9.46646 72.4165 11.0687 72.4165 13.037C72.4165 15.0052 70.8266 16.6075 68.8736 16.6075ZM68.8736 6.68262C67.1961 6.68262 65.6158 7.34567 64.4211 8.54975C63.2263 9.75384 62.5684 11.3464 62.5684 13.037V24.0953C62.5684 24.8563 63.1928 25.4744 63.9607 25.4744C64.7285 25.4744 65.3291 24.8563 65.3291 24.0953V18.2996C66.363 19.006 67.6087 19.3913 68.872 19.3913C70.5606 19.3913 72.1457 18.7315 73.3341 17.5338C74.5225 16.3362 75.1772 14.7387 75.1772 13.037C75.1772 9.53228 72.348 6.68262 68.872 6.68262"
                    fill="#EBFF00"/>
                <path
                    d="M83.441 16.6348C81.4863 16.6348 79.8981 15.0438 79.8981 13.0884V13.0643C79.8981 11.0944 81.4879 9.4938 83.441 9.4938C85.394 9.4938 86.9839 11.096 86.9839 13.0643C86.9839 15.0326 85.394 16.6348 83.441 16.6348ZM83.441 6.70996C81.7635 6.70996 80.1832 7.37301 78.9884 8.57709C77.7937 9.78118 77.1357 11.3738 77.1357 13.0643V24.1226C77.1357 24.8836 77.7602 25.5017 78.5281 25.5017C79.2959 25.5017 79.8965 24.8836 79.8965 24.1226V18.327C80.9303 19.0334 82.1761 19.4187 83.4394 19.4187C85.128 19.4187 86.713 18.7588 87.9014 17.5612C89.0898 16.3635 89.7446 14.7661 89.7446 13.0643C89.7446 9.55963 86.9154 6.70996 83.4394 6.70996"
                    fill="#EBFF00"/>
                <path
                    d="M93.0984 0.5C92.3305 0.5 91.7061 1.1181 91.7061 1.87908V17.9127C91.7061 18.6993 92.3178 19.3158 93.0984 19.3158C93.879 19.3158 94.4668 18.6865 94.4668 17.9127V1.87908C94.4668 1.1181 93.8535 0.5 93.0984 0.5Z"
                    fill="#EBFF00"/>
                <path
                    d="M99.4276 11.5342C99.9915 10.2274 101.275 9.36685 102.695 9.36685C103.872 9.36685 105.409 9.93999 106 11.5342H99.4276ZM106.985 8.21254C105.83 7.17702 104.267 6.58301 102.695 6.58301C101.006 6.58301 99.4212 7.24285 98.2328 8.44051C97.0444 9.63817 96.3896 11.2356 96.3896 12.9374C96.3896 16.442 99.2189 19.2917 102.695 19.2917H102.816C103.73 19.2917 104.968 19.2692 106.552 18.2048C107.246 17.7473 107.507 17.0072 107.198 16.357C107.012 15.9829 106.666 15.6955 106.271 15.588C105.94 15.498 105.61 15.5462 105.339 15.7228C104.27 16.4196 103.364 16.5079 102.695 16.5079C101.271 16.5079 99.9851 15.6361 99.426 14.3164H107.608C108.376 14.3164 109 13.6983 109 12.9374C109 10.526 107.904 9.03613 106.985 8.21254Z"
                    fill="#EBFF00"/>
              </g>
              <defs>
                <clipPath id="clip0_284_92176">
                  <rect width="109" height="25" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
              </defs>
            </svg>

            <div @click="newEvent" class="flex space-x-4 w-1/3 justify-end">
              <div class="rounded-md bg-white w-7 h-7 flex text-brand-black">
                <svg class="w-4 h-4 m-auto fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <path
                      d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
                </svg>
              </div>
            </div>

          </div>

          <div class="flex relative w-full">
            <div v-if="false" class="w-10 h-20 absolute top-0 right-0 z-50 bg-gradient-to-l from-brand-black to-transparent"></div>
            <div class="flex overflow-x-scroll hide-scrollbar w-full">
              <div class="flex min-w-max w-full">
                <ion-segment
                    :value="tab"
                    @ionChange="setTab($event)"
                    class="relative z-40 backdrop-blur-2xl bg-opacity-40 ml-0 w-full"
                    :swipe-gesture="false"
                    mode="ios">
                  <ion-segment-button value="explore">
                    <ion-label
                        class="text-lg font-medium">
                      Explore
                    </ion-label>
                  </ion-segment-button>
                  <ion-segment-button value="my-tickets">
                    <ion-label
                        class="text-lg font-medium">
                      Attending
                    </ion-label>
                  </ion-segment-button>
                  <ion-segment-button value="my-events">
                    <ion-label
                        class="text-lg flex space-x-2 font-medium">
                      <div>Hosting</div>
                      <div v-if="pendingResponses" class="flex my-auto">
                        <div
                            class="w-4 h-4 bg-brand-lime flex rounded-full my-auto">
                          <div class="m-auto text-xxs text-brand-black font-medium leading-normal">{{ pendingResponses }}</div>
                        </div>
                      </div>
                    </ion-label>
                  </ion-segment-button>
                </ion-segment>
              </div>
            </div>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <ion-content>

      <ion-refresher slot="fixed" @ionRefresh="pullToRefresh($event)">
        <ion-refresher-content pulling-icon="crescent" refreshing-spinner="crescent"></ion-refresher-content>
      </ion-refresher>

      <events-feed
          v-show="tab === 'explore'"
          class="flex"/>

      <div class="overflow-scroll h-full" v-show="tab === 'my-events'">
        <events-my-events @onEventTapped="viewEvent($event)"  />
      </div>

      <div class="overflow-scroll h-full" v-show="tab === 'my-tickets'">
        <events-my-tickets @onEventTapped="viewEvent($event)"  />
      </div>

    </ion-content>

  </ion-page>
</template>

<script setup lang="ts">
import {
  IonHeader,
  IonLabel,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  IonToolbar, modalController, useIonRouter
} from '@ionic/vue';
import {inject, onMounted, onUnmounted, Ref, ref} from 'vue';
import EventsFeed from "@/components/EventsFeed.vue";
import {UserInterface} from "@/interfaces/UserInterface";
import {EventFilterIs} from "@/definitions/EventFilterIs";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import NotificationsBell from "@/components/NotificationsBell.vue";
import EventsMyTickets from "@/components/EventsMyTickets.vue";
import {EventInterface} from "@/interfaces/EventInterface";
import EventsMyEvents from "@/components/EventsMyEvents.vue";
import ApiService from "@/services/api/apiService";
import FeedNewPostPage from "@/views/feed/FeedNewPostPage.vue";
import {usePremium} from "@/composables/usePremium";
import EventCRUDPage from "@/views/events/EventCRUDPage.vue";


const loading = ref(true)

const user: Ref<UserInterface | any> = inject('user') as Ref<UserInterface | undefined>
const observer = ref()

const tab = ref('explore')
const setTab = async (ev: any) => {
  tab.value = ev.detail.value
}

const tapTimeout = ref()

observer.value = Observable.subscribe(async (response: any) => {
  if (response.event === ObservableEvent.PendingEventResponsesChanged) {
    await getPendingEventResponses()
  }

  if (response.event === ObservableEvent.EventsScrollTop) {
    if (tapTimeout.value) {
      tab.value = 'explore'
    }
    tapTimeout.value = setTimeout(()=>{
      tapTimeout.value = undefined
    }, 500)
  }
})

onMounted(async () => {
  await getPendingEventResponses()
  loading.value = false
})

const pendingResponses = ref(0)
const getPendingEventResponses = async () => {
  pendingResponses.value = (await ApiService.Events().pendingResponse()).pending
}
onUnmounted(() => {
  observer.value?.unsubscribe()
})

const pullToRefresh = async (ev: any) => {

  switch (tab.value) {
    case 'explore': {
      Observable.emit(ObservableEvent.PullToRefreshExplore, ev)
    }
      break
    case 'attending': {
      Observable.emit(ObservableEvent.PullToRefreshAttending, ev)
    }
      break
    case 'interested': {
      Observable.emit(ObservableEvent.PullToRefreshInterested, ev)
    }
      break
    case 'my-events': {
      Observable.emit(ObservableEvent.PullToRefreshMyEvents, ev)
    }
      break
    case 'my-tickets': {
      Observable.emit(ObservableEvent.PullToRefreshMyTickets, ev)
    }
      break
  }

}

const ionRouter = useIonRouter()

const viewEvent = async (ev:EventInterface) => {
  ionRouter.navigate({
    name: 'Event',
    params: {
      eventId: ev.id
    }
  }, 'forward', 'push')
}

const newEvent = async () => {

  if (!user.value.subscribed) {
    return await usePremium().presentPayWall({
      text: `You must upgrade to host events on Pineapple.`,
    })
  }

  const modal = await modalController.create({
    component: EventCRUDPage,
    componentProps: {
    },
    mode: 'ios',
    presentingElement: document.getElementById('tabs') as HTMLElement,
  })
  await modal.present()

}


</script>

<style scoped>
ion-toolbar {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --padding-bottom: 1px !important;
}
</style>
