<template>
  <div class="flex space-x-5">

    <div
        @click="choosePhoto"
        class="flex flex-col items-center space-y-4">
      <div
          :style="[
                  !isImageUrl ? `background-image: url('data:image/jpeg;base64,${photo}')` : `background-image: url('${photo}')`
              ]"
          :class="{
              'border-2' : !photo
            }"
          class="flex w-32 h-32 rounded-xl bg-cover bg-center border-brand-light-gray border-dashed">
        <div
            v-if="!busy"
            :class="{ 'opacity-0' : photo}"
            class="text-brand-light-gray m-auto text-center text-sm">Choose photo
        </div>
        <svg v-if="busy" class="animate-spin m-auto h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    </div>

    <div class="flex flex-col w-full space-y-2 my-auto">
      <div class="flex text-white text-xl font-medium">Verification Photo</div>
      <div class="text-brand-light-gray text-base">Please take a clear picture of you holding the same I.D as previously uploaded, making sure your face and I.D are clearly visible.</div>
    </div>

  </div>
</template>
<script setup lang="ts">


import {useIonRouter} from "@ionic/vue";
import {computed, onMounted, PropType, ref} from "vue";
import ApiService from "@/services/api/apiService";
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import {AccountType} from "@/definitions/AccountType";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

const ionRouter = useIonRouter()
const busy = ref(true)
const status = ref()

const photo = ref()

const props = defineProps({
  user: {
    type: Object as PropType<UserBasicInformationInterface>,
    required: true
  }
})

onMounted(async () => {
  status.value = await ApiService.User().getVerificationStatus()
  if (status.value.id_photo) photo.value = status.value.id_photo
  busy.value = false
})

const isImageUrl = computed(()=>{
  if (!photo.value) return
  return photo.value.includes('http')
})

const awaitingVerification = computed(()=>{
  if (!status.value) return
  return status.value.submitted_at
})

const choosePhoto = async () => {
  try {
    const image = await useCamera(CameraSource.Camera, CameraResultType.Base64)
    busy.value = true
    photo.value = undefined
    if (image && image.base64String) await ApiService.User().setIdPhoto(image.base64String)
    photo.value = image.base64String
    Observable.emit(ObservableEvent.VerificationStatusChanged)
  } catch (e) {
    busy.value = false
  } finally {
    busy.value = false
  }
}

const goBack = async () => {
  await ionRouter.back()
}


</script>