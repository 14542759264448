<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="mr-4">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title class="text-white font-medium text-2xl">Notifications</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="flex w-full h-full">


      <ion-segment
          :value="tab"
          @ionChange="setTab($event)"
          class="relative z-40 backdrop-blur-2xl bg-opacity-40 ml-0 w-full sticky top-0"
          :swipe-gesture="false"
          mode="ios">
        <ion-segment-button value="feed">
          <ion-label
              class="text-lg font-medium">
            Feed
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="events">
          <ion-label
              class="text-lg font-medium">
            Events
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="profile">
          <ion-label
              class="text-lg font-medium">
            Profile
          </ion-label>
        </ion-segment-button>
      </ion-segment>

      <div v-if="loading" class="w-full h-full flex">
        <div class="m-auto">
          <svg class="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>


      <div v-if="!loading" class="flex flex-col py-5 px-4 justify-center space-y-4">

        <template v-if="tab === 'events'">

          <div class="flex flex-col space-y-1">
            <div class="text-white font-medium text-lg">When...</div>
            <div class="flex flex-col space-y-2">

              <input-checkbox
                  name="display"
                  v-model="preferences.notifyWhenSomebodyAttends"
                  input-type="toggle"
                  :option="{
              text: 'Somebody attends',
              value: true
            }"
              />
              <input-checkbox
                  name="display"
                  v-model="preferences.notifyWhenSomebodyShowsInterest"
                  input-type="toggle"
                  :option="{
              text: 'Somebody shows interest',
              value: true
            }"
              />
              <input-checkbox
                  name="display"
                  v-model="preferences.notifyWhenSomebodyRequestsAnInvite"
                  input-type="toggle"
                  :option="{
              text: 'Somebody requests an invite',
              value: true
            }"
              />
              <input-checkbox
                  name="display"
                  v-model="preferences.notifyWhenSomebodyCommentsOnMyEvent"
                  input-type="toggle"
                  :option="{
              text: 'Somebody comments on your event',
              value: true
            }"
              />
              <input-checkbox
                  name="display"
                  v-model="preferences.notifyWhenSomebodyRepliesToCommentOnEvent"
                  input-type="toggle"
                  :option="{
              text: 'Somebody replies to a comment',
              value: true
            }"
              />
              <input-checkbox
                  name="display"
                  v-model="preferences.notifyWhenSomebodyAcceptsAnInvite"
                  input-type="toggle"
                  :option="{
              text: 'Your invite request is accepted or rejected',
              value: true
            }"
              />
            </div>
          </div>

        </template>

        <template v-if="tab === 'profile'">

          <div class="flex flex-col space-y-1">
            <div class="text-white font-medium text-lg">When...</div>
            <div class="flex flex-col space-y-2">

              <input-checkbox
                  name="display"
                  v-model="preferences.notifyWhenSomebodyWinksAtMe"
                  input-type="toggle"
                  :option="{
              text: 'Somebody winks at me',
              value: true
            }"
              />
              <input-checkbox
                  name="display"
                  v-model="preferences.notifyWhenSomebodySendsAFriendRequest"
                  input-type="toggle"
                  :option="{
              text: 'Somebody sends a friend request',
              value: true
            }"
              />
              <input-checkbox
                  name="display"
                  v-model="preferences.notifyWhenSomebodyStartsFollowingMe"
                  input-type="toggle"
                  :option="{
              text: 'Somebody starts following me',
              value: true
            }"
              />
              <input-checkbox
                  name="display"
                  v-model="preferences.notifyWhenSomebodyAcceptsYourFriendRequest"
                  input-type="toggle"
                  :option="{
              text: 'Somebody accepts your friend request',
              value: true
            }"
              />
              <input-checkbox
                  name="display"
                  v-model="preferences.notifyWhenSomebodyReviewsMyProfile"
                  input-type="toggle"
                  :option="{
              text: 'Somebody reviews your profile',
              value: true
            }"
              />
              <input-checkbox
                  name="display"
                  v-model="preferences.notifyWhenSomebodyMentionsMe"
                  input-type="toggle"
                  :option="{
              text: 'Somebody mentions me',
              value: true
            }"
              />
            </div>
          </div>
        </template>

        <template v-if="tab === 'feed'">

          <div class="flex flex-col space-y-1">
            <div class="text-white font-medium text-lg">When...</div>
            <div class="flex flex-col space-y-2">
              <input-checkbox
                  name="display"
                  v-model="preferences.notifyWhenSomebodyLikesMyPost"
                  input-type="toggle"
                  :option="{
              text: 'Somebody likes my post',
              value: true
            }"
              />
              <input-checkbox
                  name="display"
                  v-model="preferences.notifyWhenSomebodyLikesMyComment"
                  input-type="toggle"
                  :option="{
              text: 'Somebody likes my comment',
              value: true
            }"
              />
              <input-checkbox
                  name="display"
                  v-model="preferences.notifyWhenSomebodyRepliesToMyPost"
                  input-type="toggle"
                  :option="{
              text: 'Somebody replies to my post',
              value: true
            }"
              />
              <input-checkbox
                  name="display"
                  v-model="preferences.notifyWhenSomebodyRepliesToMyComment"
                  input-type="toggle"
                  :option="{
              text: 'Somebody replies to my comment',
              value: true
            }"
              />
            </div>
          </div>


        </template>

      </div>
    </ion-content>

  </ion-page>
</template>

<script setup lang="ts">
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader, IonLabel,
  IonPage, IonSegment, IonSegmentButton,
  IonTitle,
  IonToolbar, onIonViewDidEnter,
  onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {defineComponent, inject, onMounted, reactive, Ref, ref, watch} from 'vue';
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import InputCheckbox from "@/components/InputCheckbox.vue";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {AccountType} from "@/definitions/AccountType";


const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>
const loading = ref(true)
const ionRouter = useIonRouter()

const busy = ref(false)
const errors = ref({})

const preferences: any = reactive({
  notifyWhenSomebodyLikesMyPost: [true],
  notifyWhenSomebodyLikesMyComment: [true],
  notifyWhenSomebodyRepliesToMyPost: [true],
  notifyWhenSomebodyRepliesToMyComment: [true],
  notifyWhenSomebodyMentionsMe: [true],
  notifyWhenSomebodySendsAFriendRequest: [true],
  notifyWhenSomebodyStartsFollowingMe: [true],
  notifyWhenSomebodyAcceptsYourFriendRequest: [true],
  notifyWhenSomebodyWinksAtMe: [true],
  notifyWhenSomebodyAttends: [true],
  notifyWhenSomebodyShowsInterest: [true],
  notifyWhenSomebodyRequestsAnInvite: [true],
  notifyWhenSomebodyReviewsMyProfile: [true],
  notifyWhenSomebodyCommentsOnMyEvent: [true],
  notifyWhenSomebodyRepliesToCommentOnEvent: [true]
})

onMounted(async () => {
  const userPreferences = (await ApiService.User().get()).preferences
  preferences.notifyWhenSomebodyLikesMyPost = [Boolean(Number(userPreferences[PreferenceKey.NotifyWhenSomebodyLikesMyPost]))]
  preferences.notifyWhenSomebodyLikesMyComment = [Boolean(Number(userPreferences[PreferenceKey.NotifyWhenSomebodyLikesMyComment]))]
  preferences.notifyWhenSomebodyRepliesToMyPost = [Boolean(Number(userPreferences[PreferenceKey.NotifyWhenSomebodyRepliesToMyPost]))]
  preferences.notifyWhenSomebodyRepliesToMyComment = [Boolean(Number(userPreferences[PreferenceKey.NotifyWhenSomebodyRepliesToMyComment]))]
  preferences.notifyWhenSomebodyMentionsMe = [Boolean(Number(userPreferences[PreferenceKey.NotifyWhenSomebodyMentionsMe]))]
  preferences.notifyWhenSomebodySendsAFriendRequest = [Boolean(Number(userPreferences[PreferenceKey.NotifyWhenSomebodySendsAFriendRequest]))]
  preferences.notifyWhenSomebodyStartsFollowingMe = [Boolean(Number(userPreferences[PreferenceKey.NotifyWhenSomebodyStartsFollowingMe]))]
  preferences.notifyWhenSomebodyAcceptsYourFriendRequest = [Boolean(Number(userPreferences[PreferenceKey.NotifyWhenSomebodyAcceptsYourFriendRequest]))]
  preferences.notifyWhenSomebodyWinksAtMe = [Boolean(Number(userPreferences[PreferenceKey.NotifyWhenSomebodyWinksAtMe]))]
  preferences.notifyWhenSomebodyAttends = [Boolean(Number(userPreferences[PreferenceKey.NotifyWhenSomebodyAttends]))]
  preferences.notifyWhenSomebodyShowsInterest = [Boolean(Number(userPreferences[PreferenceKey.NotifyWhenSomebodyShowsInterest]))]
  preferences.notifyWhenSomebodyRequestsAnInvite = [Boolean(Number(userPreferences[PreferenceKey.NotifyWhenSomebodyRequestsAnInvite]))]
  preferences.notifyWhenSomebodyAcceptsAnInvite = [Boolean(Number(userPreferences[PreferenceKey.NotifyWhenSomebodyAcceptsAnInvite]))]
  preferences.notifyWhenSomebodyReviewsMyProfile = [Boolean(Number(userPreferences[PreferenceKey.NotifyWhenSomebodyReviewsMyProfile]))]
  preferences.notifyWhenSomebodyCommentsOnMyEvent = [Boolean(Number(userPreferences[PreferenceKey.NotifyWhenSomebodyCommentsOnMyEvent]))]
  preferences.notifyWhenSomebodyRepliesToCommentOnEvent = [Boolean(Number(userPreferences[PreferenceKey.NotifyWhenSomebodyRepliesToCommentOnEvent]))]

  loading.value = false
})

watch(() => ({...preferences}), async (preference, previousPreference) => {

  if (previousPreference.notifyWhenSomebodyLikesMyPost[0] !== preference.notifyWhenSomebodyLikesMyPost[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyLikesMyPost, preference.notifyWhenSomebodyLikesMyPost[0])
  }

  if (previousPreference.notifyWhenSomebodyLikesMyComment[0] !== preference.notifyWhenSomebodyLikesMyComment[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyLikesMyComment, preference.notifyWhenSomebodyLikesMyComment[0])
  }

  if (previousPreference.notifyWhenSomebodyRepliesToMyComment[0] !== preference.notifyWhenSomebodyRepliesToMyComment[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyRepliesToMyComment, preference.notifyWhenSomebodyRepliesToMyComment[0])
  }

  if (previousPreference.notifyWhenSomebodyRepliesToMyPost[0] !== preference.notifyWhenSomebodyRepliesToMyPost[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyRepliesToMyPost, preference.notifyWhenSomebodyRepliesToMyPost[0])
  }

  if (previousPreference.notifyWhenSomebodyMentionsMe[0] !== preference.notifyWhenSomebodyMentionsMe[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyMentionsMe, preference.notifyWhenSomebodyMentionsMe[0])
  }

  if (previousPreference.notifyWhenSomebodySendsAFriendRequest[0] !== preference.notifyWhenSomebodySendsAFriendRequest[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodySendsAFriendRequest, preference.notifyWhenSomebodySendsAFriendRequest[0])
  }

  if (previousPreference.notifyWhenSomebodyAcceptsYourFriendRequest[0] !== preference.notifyWhenSomebodyAcceptsYourFriendRequest[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyAcceptsYourFriendRequest, preference.notifyWhenSomebodyAcceptsYourFriendRequest[0])
  }

  if (previousPreference.notifyWhenSomebodyStartsFollowingMe[0] !== preference.notifyWhenSomebodyStartsFollowingMe[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyStartsFollowingMe, preference.notifyWhenSomebodyStartsFollowingMe[0])
  }

  if (previousPreference.notifyWhenSomebodyWinksAtMe[0] !== preference.notifyWhenSomebodyWinksAtMe[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyWinksAtMe, preference.notifyWhenSomebodyWinksAtMe[0])
  }

  if (previousPreference.notifyWhenSomebodyAttends[0] !== preference.notifyWhenSomebodyAttends[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyAttends, preference.notifyWhenSomebodyAttends[0])
  }

  if (previousPreference.notifyWhenSomebodyShowsInterest[0] !== preference.notifyWhenSomebodyShowsInterest[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyShowsInterest, preference.notifyWhenSomebodyShowsInterest[0])
  }

  if (previousPreference.notifyWhenSomebodyRequestsAnInvite[0] !== preference.notifyWhenSomebodyRequestsAnInvite[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyRequestsAnInvite, preference.notifyWhenSomebodyRequestsAnInvite[0])
  }

  if (previousPreference.notifyWhenSomebodyAcceptsAnInvite[0] !== preference.notifyWhenSomebodyAcceptsAnInvite[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyAcceptsAnInvite, preference.notifyWhenSomebodyAcceptsAnInvite[0])
  }

  if (previousPreference.notifyWhenSomebodyReviewsMyProfile[0] !== preference.notifyWhenSomebodyReviewsMyProfile[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyReviewsMyProfile, preference.notifyWhenSomebodyReviewsMyProfile[0])
  }

  if (previousPreference.notifyWhenSomebodyReviewsMyProfile[0] !== preference.notifyWhenSomebodyReviewsMyProfile[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyReviewsMyProfile, preference.notifyWhenSomebodyReviewsMyProfile[0])
  }

  if (previousPreference.notifyWhenSomebodyReviewsMyProfile[0] !== preference.notifyWhenSomebodyReviewsMyProfile[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyReviewsMyProfile, preference.notifyWhenSomebodyReviewsMyProfile[0])
  }

  if (previousPreference.notifyWhenSomebodyCommentsOnMyEvent[0] !== preference.notifyWhenSomebodyCommentsOnMyEvent[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyCommentsOnMyEvent, preference.notifyWhenSomebodyCommentsOnMyEvent[0])
  }

  if (previousPreference.notifyWhenSomebodyRepliesToCommentOnEvent[0] !== preference.notifyWhenSomebodyRepliesToCommentOnEvent[0]) {
    await ApiService.User().setPreference(PreferenceKey.NotifyWhenSomebodyRepliesToCommentOnEvent, preference.notifyWhenSomebodyRepliesToCommentOnEvent[0])
  }

}, {deep: true})


const goBack = async () => {
  if (busy.value) return
  await ionRouter.back()
}

const tab = ref('feed')
const setTab = async (ev: any) => {
  tab.value = ev.detail.value
}

</script>
