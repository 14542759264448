<template>
  <ion-page>
    <ion-content>

      <div class="flex flex-col w-full bg-brand-black my-auto h-full px-6 z-50">

        <div class="flex flex-col m-auto space-y-14 items-center">


          <div class="flex flex-col space-y-4 items-center">
            <svg width="111" height="110" viewBox="0 0 111 110" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="2.5" y="2" width="106" height="106" rx="53" fill="#EBFF00"/>
              <path d="M56 72C57.925 72 59.5 70.4308 59.5 68.5128H52.5C52.5 70.4308 54.0575 72 56 72ZM66.5 61.5385V52.8205C66.5 47.4677 63.63 42.9867 58.625 41.801V40.6154C58.625 39.1682 57.4525 38 56 38C54.5475 38 53.375 39.1682 53.375 40.6154V41.801C48.3525 42.9867 45.5 47.4503 45.5 52.8205V61.5385L42 65.0256V66.7692H70V65.0256L66.5 61.5385Z" fill="#1A1B22"/>
              <rect x="2.5" y="2" width="106" height="106" rx="53" stroke="#EBFF00" stroke-width="4"/>
            </svg>

            <div class="flex flex-col w-full items-center space-y-2">
              <div class="flex m-auto text-white text-2xl font-medium">Allow notifications</div>
              <div class="m-auto text-brand-light-gray text-lg text-center">Don’t miss a thing. Get notified when you get a new message.
              </div>
            </div>
          </div>

          <div class="flex flex-col space-y-2 w-full">
            <button
                @click="registerForPush"
                class="flex bg-brand-lime rounded-full w-full h-14 font-medium text-xl text-brand-black">
              <div class="flex m-auto space-x-2.5">
                <div class="my-auto pt-0.5">
                  I want to be notified
                </div>
              </div>
            </button>
            <button
                @click="forward"
                class="flex bg-brand-dark-gray rounded-full w-full h-14 font-medium text-xl text-white">
              <div class="flex m-auto space-x-2.5">
                <div class="my-auto pt-0.5">
                  Not now
                </div>
              </div>
            </button>
          </div>
        </div>

      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {IonContent, IonPage, onIonViewWillEnter, useIonRouter,} from '@ionic/vue';
import {defineComponent, onMounted, ref} from 'vue';
import {FcmService} from "@/services/fcm/fcmService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import ApiService from "@/services/api/apiService";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {DateTime} from "luxon";
import {useOneSignal} from "@onesignal/onesignal-vue3";

export default defineComponent({
  name: 'AccountOnboardingNotificationsPage',
  components: {
    IonContent,
    IonPage,
  },
  setup() {

    const busy = ref(false)
    const errors = ref({})
    const ionRouter = useIonRouter()

    onIonViewWillEnter(()=>{
      Observable.emit(ObservableEvent.IonRouterOutletChanged, ionRouter.canGoBack())
    })

    const forward = async () => {
        await ApiService.User().setPreference(PreferenceKey.OnboardingPushNotifications, DateTime.now().toISO())
        await ionRouter.navigate('/pineapple/rules', 'forward', 'push')
    }

    const registerForPush = async () => {

      const oneSignal = useOneSignal()

      if (!oneSignal.Notifications.permission) {
        await oneSignal.Notifications.requestPermission()
      }

      // await FcmService.initPush()
      await forward()
    }

    return {busy, registerForPush, forward, errors}

  }
});
</script>
