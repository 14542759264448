<template>
  <ion-app>
    <ion-router-outlet/>
  </ion-app>
</template>

<script lang="ts">
import {IonApp, IonRouterOutlet, isPlatform, useIonRouter} from '@ionic/vue';
import {defineComponent, onMounted} from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  setup() {

    const ionRouter = useIonRouter()


      if (isPlatform('pwa') && isPlatform('android')) {
      // Prevents the PWA from closing the app when there isn't any history,
      // i.e. on the feed and looking and a photo, we want the physical back button
      // to be able to close down the image
      window.history.pushState({}, '');

      window.addEventListener('popstate', () => {

        const w = document.getElementsByClassName('PhotoSlider__PhotoBox')
        if (w.length) {
          const modal = w.item(0) as any
          modal?.click()
        } else {
          ionRouter.back()
        }

        window.history.pushState({}, '');

      });
    }

  }
});

</script>