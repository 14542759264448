<template>
  <ion-page>
    <ion-content>

      <div class="flex flex-col w-full bg-brand-black my-auto h-full px-6 pt-14 z-50">

        <div class="flex flex-col space-y-6">
          <div class="text-white font-medium text-2xl">Forgot password</div>

          <div class="flex flex-col space-y-2">
            <div class="flex w-full flex-col bg-brand-dark-gray rounded-lg h-14 relative focus:">
              <div class="flex w-auto px-4 h-full">
                <svg class="my-auto" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M19.2 4.80078H4.8C3.81 4.80078 3.009 5.61078 3.009 6.60078L3 17.4008C3 18.3908 3.81 19.2008 4.8 19.2008H19.2C20.19 19.2008 21 18.3908 21 17.4008V6.60078C21 5.61078 20.19 4.80078 19.2 4.80078ZM18.84 8.62578L12.477 12.6038C12.189 12.7838 11.811 12.7838 11.523 12.6038L5.16 8.62578C5.06975 8.57512 4.99073 8.50668 4.9277 8.42459C4.86467 8.3425 4.81896 8.24848 4.79332 8.14821C4.76768 8.04795 4.76265 7.94352 4.77854 7.84126C4.79443 7.73899 4.8309 7.64101 4.88575 7.55325C4.9406 7.46549 5.01269 7.38977 5.09765 7.33068C5.18262 7.27158 5.27868 7.23034 5.38005 7.20945C5.48141 7.18856 5.58595 7.18845 5.68736 7.20913C5.78876 7.22982 5.88492 7.27086 5.97 7.32978L12 11.1008L18.03 7.32978C18.1151 7.27086 18.2112 7.22982 18.3126 7.20913C18.414 7.18845 18.5186 7.18856 18.62 7.20945C18.7213 7.23034 18.8174 7.27158 18.9023 7.33068C18.9873 7.38977 19.0594 7.46549 19.1142 7.55325C19.1691 7.64101 19.2056 7.73899 19.2215 7.84126C19.2373 7.94352 19.2323 8.04795 19.2067 8.14821C19.181 8.24848 19.1353 8.3425 19.0723 8.42459C19.0093 8.50668 18.9302 8.57512 18.84 8.62578Z"
                      fill="#A5A7AF"/>
                </svg>
              </div>
              <ion-input
                  class="text-white text-lg font-medium absolute top-0 left-11 h-full"
                  placeholder="Email address"
                  type="email"></ion-input>
            </div>
          </div>


        </div>

        <button
            @click="resetPassword"
            class="mt-20 flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black">
          <div class="flex m-auto space-x-2.5">
            <div class="my-auto pt-0.5">
              Reset password
            </div>
          </div>
        </button>


      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonInput, modalController
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import ForgotPasswordInstructionsPage from "@/views/auth/ForgotPasswordInstructionsPage.vue";
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'ForgotPasswordPage',
  components: {
    IonContent,
    IonPage,
    IonInput
  },
  setup() {

    const router = useRouter()
    const resetPassword = async () => {
      await router.push({name: 'ForgotPasswordInstructions'})
    }

    return {resetPassword}

  }
});
</script>
