<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title class="text-white"></ion-title>
        <ion-buttons slot="start" :class="{
          'mr-4' : canGoBack
        }">
          <ion-button v-if="canGoBack" @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-router-outlet  id="onboardingOutlet" class="z-10"></ion-router-outlet>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter,
  IonButton,
  IonInput,
  IonRouterOutlet, useIonRouter
} from '@ionic/vue';
import {computed, defineComponent, onMounted, onUnmounted, PropType, ref, Ref, watch} from 'vue';
import {StatusBar, Style} from "@capacitor/status-bar";
import {useRouter} from "vue-router";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

export default defineComponent({
  name: 'AccountVerificationRouterOutlet',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonRouterOutlet,
    IonContent
  },
  setup(props) {

    const canGoBack = ref(false)
    const ionRouter = useIonRouter()

    const goBack = async () => {
      await ionRouter.back()
    }

    return {goBack, canGoBack}

  }
});
</script>
