<template>
  <div class="flex flex-col items-center p-5 py-10 space-y-10">


    <div class="flex flex-col space-y-6 items-center">
      <div class="flex text-brand-lime">
        <svg class="fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
              d="M327.5 85.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 128l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 128l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 64 426.8 7.5C425.1 3 420.8 0 416 0s-9.1 3-10.8 7.5L384 64 327.5 85.2zM205.1 73.3c-2.6-5.7-8.3-9.3-14.5-9.3s-11.9 3.6-14.5 9.3L123.3 187.3 9.3 240C3.6 242.6 0 248.3 0 254.6s3.6 11.9 9.3 14.5l114.1 52.7L176 435.8c2.6 5.7 8.3 9.3 14.5 9.3s11.9-3.6 14.5-9.3l52.7-114.1 114.1-52.7c5.7-2.6 9.3-8.3 9.3-14.5s-3.6-11.9-9.3-14.5L257.8 187.4 205.1 73.3zM384 384l-56.5 21.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 448l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 448l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 384l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L384 384z"/>
        </svg>
      </div>

      <div class="flex flex-col w-full items-center space-y-1 px-2">
        <div class="flex text-white text-2xl font-medium text-center">Linked accounts are here!</div>
        <div class="text-brand-light-gray text-lg text-center">Choose the account type that best describes how you'll be
          using Pineapple.
        </div>
      </div>

      <div class="flex w-full flex-col space-y-2">
        <input-checkbox
            v-for="(a,aIndex) in accountTypes"
            :key="aIndex"
            name="accountType"
            v-model="accountType"
            :return-single-value="true"
            :option="a"
        />
      </div>

      <div
          :class="{
            'opacity-0' : !accountType.length
          }"
          class="flex w-full px-5 text-brand-light-gray text-center items-center">
        <div class="w-full text-center" v-if="accountType[0] === AccountType.Individual">For singles or couples who
          would like their own profile. Profiles can be linked together so you are presented as a couple to the
          community.
        </div>
        <div class="w-full text-center" v-if="accountType[0] === AccountType.Joint">Perfect for couples who'd like to
          share a single account and profile, your profile is based on your collective desires and interests.
        </div>
        <div class="w-full text-center" v-if="accountType[0] === AccountType.Club">For clubs and businesses.</div>
      </div>


      <div
          class="flex flex-col space-y-2 w-full items-center">
        <button
            @click="saveAccountType"
            :class="{
                  'bg-opacity-40' : !accountType.length
                }"
            class="flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
          <div class="flex m-auto space-x-2.5">
            <div class="my-auto pt-0.5">
              <div>Done</div>
            </div>
          </div>
        </button>
      </div>
      <span class="text-brand-light-gray text-xs text-center px-10">You can change this at any time from your profile buy may be required to re verify your profile.</span>


    </div>

  </div>
</template>

<script setup lang="ts">

import {onMounted, ref} from 'vue';
import {AccountType} from "@/definitions/AccountType";
import InputCheckbox from "@/components/InputCheckbox.vue";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {Preferences} from "@capacitor/preferences";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {modalController} from "@ionic/vue";

const loading = ref(true)
const accountType = ref([])

const accountTypes = [
  {
    text: 'Individual',
    value: AccountType.Individual,
  },
  {
    text: 'Joint',
    value: AccountType.Joint,
  },
  {
    text: 'Club',
    value: AccountType.Club,
  },
  {
    text: 'Host',
    value: AccountType.Host,
  }
]

onMounted(async () => {
  loading.value = false
})

const saveAccountType = async () => {
  const key = PreferenceKey.OnboardingAccountType
  const value = accountType.value[0]
  await Preferences.set({
    key,
    value
  })
  await modalController.dismiss()
  await ApiService.User().setPreference(key, value)
  await Observable.emit(ObservableEvent.UserChanged)
}


</script>