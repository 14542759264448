<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="mr-4">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title class="text-white font-medium text-2xl">Verify your Identity</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="flex h-full">

      <template v-if="loading">
        <div class="flex w-full h-full">
          <svg class="animate-spin h-10 w-10 text-white m-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </template>

      <div v-if="!loading && !awaitingVerification" class="flex h-full flex-col py-10 px-4 space-y-10">

        <div class="mt-0 flex flex-col w-full space-y-5">
          <div v-if="user.account_type === AccountType.Individual" class="flex flex-col space-y-3">
            <div class="text-white text-xl">All pineapple users must verify their identity, we do this to keep our
              community safe and to combat against fake accounts.
            </div>
            <div class="text-white text-xl">We will accept any form of photo I.D that has a clear image of your face and
              your DOB on it.
            </div>
            <div class="text-white text-xl">You are welcome to cover any other personal data.</div>
          </div>

          <div v-if="user.account_type === AccountType.Joint" class="flex flex-col space-y-3">
            <div class="text-white text-xl">All pineapple users must verify their identity, we do this to keep our community safe and to combat against fake accounts, a single person image will not be approved for verification on a joint account.
            </div>
          </div>

          <div class="flex rounded-md bg-brand-dark-gray p-3 px-4 space-x-2.5"
               v-if="user.account_type === AccountType.Joint">
            <div class="text-brand-light-gray text-sm">
              <div class=" text-base text-white">You are verifying as a <span class="underline">Joint</span> account.
              </div>
              We require that all individuals who will share the account are included in the verification image, along with their photo I.D.
              We will accept any form of photo I.D that has a clear image of your face and your DOB on it.

            </div>
          </div>
        </div>


        <div class="flex flex-col space-y-10">
          <div class="flex flex-col space-y-5">
            <verify-id-card :user="user"/>
            <verify-id-image :user="user"/>
          </div>
          <div class="px-10 text-center text-sm text-brand-light-gray">You may cover sensitive ID Card information, we
            only require visibility of name and DOB.
          </div>
        </div>

        <div class="flex flex-col pb-10">
          <button
              @click="submitId"
              :class="{
              'bg-opacity-40' : !canSubmitForVerification
            }"
              class="flex bg-brand-lime rounded-full w-full h-14 font-medium text-xl text-brand-black transition-all">
            <div class="flex m-auto space-x-2.5">
              <div v-if="!busy" class="my-auto pt-0.5">
                Submit
              </div>
              <div v-if="busy" class="my-auto">
                <svg class="animate-spin h-5 w-5 text-brand-black" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
          </button>

          <div class="text-white text-sm px-8 text-center mt-4">Your Photo I.D Card and Verification Photo <span class="font-bold underline">will be removed from our servers</span> once your identity has been verified.</div>


        </div>

      </div>

      <div v-if="!loading && awaitingVerification" class="flex h-full flex-col py-10 px-4 justify-center space-y-6">
        <div class="flex flex-col m-auto space-y-14 items-center">

          <div class="flex flex-col space-y-4 items-center">
            <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="2" y="2" width="106" height="106" rx="53" fill="#EBFF00"/>
              <path
                  d="M54.9996 43.29C53.9747 43.29 53.1496 44.1151 53.1496 45.14V47.73H56.8496V45.14C56.8496 44.1151 56.0245 43.29 54.9996 43.29ZM40.5696 48.47C39.3397 48.47 38.3496 49.4602 38.3496 50.69V65.49C38.3496 66.7199 39.3397 67.71 40.5696 67.71H69.4296C70.6595 67.71 71.6496 66.7199 71.6496 65.49V50.69C71.6496 49.4602 70.6595 48.47 69.4296 48.47H40.5696ZM47.9696 53.28C49.1957 53.28 50.1896 54.274 50.1896 55.5C50.1896 56.7261 49.1957 57.72 47.9696 57.72C46.7435 57.72 45.7496 56.7261 45.7496 55.5C45.7496 54.274 46.7435 53.28 47.9696 53.28ZM56.4796 53.28H67.9496C68.3583 53.28 68.6896 53.6113 68.6896 54.02C68.6896 54.4287 68.3583 54.76 67.9496 54.76H56.4796C56.0709 54.76 55.7396 54.4287 55.7396 54.02C55.7396 53.6113 56.0709 53.28 56.4796 53.28ZM56.4796 57.35H67.9496C68.3583 57.35 68.6896 57.6813 68.6896 58.09C68.6896 58.4987 68.3583 58.83 67.9496 58.83H56.4796C56.0709 58.83 55.7396 58.4987 55.7396 58.09C55.7396 57.6813 56.0709 57.35 56.4796 57.35ZM47.9696 59.2C50.6343 59.2 52.7796 60.617 52.7796 62.2872V62.9H43.1596V62.2872C43.1596 60.617 45.3049 59.2 47.9696 59.2ZM56.4796 61.42H67.9496C68.3583 61.42 68.6896 61.7513 68.6896 62.16C68.6896 62.5687 68.3583 62.9 67.9496 62.9H56.4796C56.0709 62.9 55.7396 62.5687 55.7396 62.16C55.7396 61.7513 56.0709 61.42 56.4796 61.42Z"
                  fill="#1A1B22"/>
              <rect x="2" y="2" width="106" height="106" rx="53" stroke="#EBFF00" stroke-width="4"/>
            </svg>

            <div class="flex flex-col w-full items-center space-y-2">
              <div class="flex m-auto text-white text-2xl font-medium text-center">Thank you for submitting your
                ID.<br/>
                We’re reviewing it now.
              </div>
              <div class="m-auto text-brand-light-gray text-lg text-center">This may take a few days to verify.
              </div>
            </div>
          </div>

          <div class="flex">
            <button
                @click="cancelVerification"
                class="flex w-full bg-brand-dark-gray rounded-full px-8 h-14 font-medium text-xl text-white transition-all">
              <div class="flex m-auto space-x-2.5">
                <div class="my-auto pt-0.5">
                  <div v-if="!busy" class="my-auto pt-0.5">
                    Cancel verification
                  </div>
                  <div v-if="busy" class="my-auto">
                    <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </button>
          </div>

        </div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';
import {computed, onUnmounted, ref} from 'vue';
import ApiService from "@/services/api/apiService";
import VerifyIdCard from "@/components/VerifyIdCard.vue";
import VerifyIdImage from "@/components/VerifyIdImage.vue";
import {AccountType} from "@/definitions/AccountType";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

const loading = ref(true)
const ionRouter = useIonRouter()
const busy = ref(false)
const status = ref()
const user = ref()

const observer = ref()

onIonViewWillEnter(async () => {
  await getStatus()
  user.value = await ApiService.User().authUser()
  loading.value = false

  observer.value = Observable.subscribe(async (response: any) => {
    if (response.event === ObservableEvent.VerificationStatusChanged) {
      await getStatus()
    }
  })

})

onUnmounted(() => {
  observer.value?.unsubscribe()
})

const getStatus = async () => {
  status.value = await ApiService.User().getVerificationStatus()
}

const goBack = async () => {
  await ionRouter.back()
}

const submitId = async () => {
  if (!canSubmitForVerification.value) return
  busy.value = true
  await ApiService.User().submitIdForVerification()
  await getStatus()
  busy.value = false
}

const cancelVerification = async () => {

  const alert = await alertController.create({
    header: 'Cancel verification',
    message: 'Are you sure you want to cancel your verification request? Once cancelled you will be able to change your id photos and resubmit.',
    buttons: [
      {text: 'Cancel', role: "cancel"},
      {
        text: 'Yes', role: "destructive", handler: async () => {
          busy.value = true
          await alert.dismiss()
          await ApiService.User().cancelIdVerification()
          await getStatus()
          busy.value = false
        }
      }
    ]
  })

  await alert.present()

}

const hasIdCardPhoto = computed(() => {
  if (!status.value) return false
  return status.value.id_card_photo
})
const hasIdPhoto = computed(() => {
  if (!status.value) return false
  return status.value.id_photo
})

const canVerifyImage = computed(() => {
  if (!status.value) return false
  return status.value.id_card_photo
})

const canSubmitForVerification = computed(() => {
  if (!status.value) return false
  return status.value.id_card_photo && status.value.id_photo
})

const awaitingVerification = computed(() => {
  if (!status.value) return false
  return status.value.submitted_at
})


</script>
