<template>
  <ion-page>
    <ion-content>

      <ion-toolbar></ion-toolbar>

      <div class="flex flex-col w-full h-full bg-brand-black my-auto px-4 z-50">
        <div class="flex flex-col space-y-8 py-10">

          <svg class="mx-auto" width="109" height="26" viewBox="0 0 109 26" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_314_6366)">
              <path
                  d="M6.30522 16.6065C4.35057 16.6065 2.76232 15.0155 2.76232 13.0601V13.036C2.76232 11.0661 4.35216 9.46548 6.30522 9.46548C8.25828 9.46548 9.84812 11.0677 9.84812 13.036C9.84812 15.0043 8.25828 16.6065 6.30522 16.6065ZM6.30522 6.68164C4.62776 6.68164 3.04747 7.34469 1.8527 8.54877C0.657922 9.75125 0 11.3455 0 13.036V24.0943C0 24.8553 0.624468 25.4734 1.39231 25.4734C2.16015 25.4734 2.76072 24.8553 2.76072 24.0943V18.2986C3.7946 19.005 5.04035 19.3904 6.30363 19.3904C7.99224 19.3904 9.57731 18.7305 10.7657 17.5328C11.9541 16.3352 12.6088 14.7378 12.6088 13.036C12.6088 9.53131 9.77962 6.68164 6.30363 6.68164"
                  fill="#EBFF00"/>
              <path
                  d="M15.9831 1.70703C15.6327 1.70703 15.2679 1.86437 15.0066 2.12766C14.7453 2.39095 14.5908 2.7586 14.5908 3.1118C14.5908 3.46499 14.7469 3.83264 15.0066 4.09593C15.2567 4.34799 15.612 4.49087 15.9831 4.49087C16.3543 4.49087 16.7096 4.34638 16.9597 4.09593C17.2082 3.84388 17.3515 3.48587 17.3515 3.1118C17.3515 2.73773 17.2082 2.37971 16.9597 2.12766C16.7111 1.8756 16.3336 1.70703 15.9831 1.70703Z"
                  fill="#EBFF00"/>
              <path
                  d="M15.9271 6.58398C15.1593 6.58398 14.5332 7.20208 14.5332 7.96306V17.8879C14.5332 18.6746 15.1449 19.2911 15.9271 19.2911C16.7093 19.2911 17.2955 18.6618 17.2955 17.8879V7.96306C17.2955 7.20208 16.6822 6.58398 15.9271 6.58398Z"
                  fill="#EBFF00"/>
              <path
                  d="M25.606 6.58398C22.13 6.58398 19.3008 9.43526 19.3008 12.9383V17.8895C19.3008 18.6634 19.9141 19.2927 20.6692 19.2927C21.4243 19.2927 22.0615 18.6762 22.0615 17.8895V12.9383C22.0615 10.9685 23.6513 9.36783 25.6044 9.36783C27.5575 9.36783 29.1473 10.9701 29.1473 12.9383V17.8895C29.1473 18.6634 29.7606 19.2927 30.5157 19.2927C31.2708 19.2927 31.908 18.6762 31.908 17.8895V12.9383C31.908 9.43526 29.0788 6.58398 25.6028 6.58398"
                  fill="#EBFF00"/>
              <path
                  d="M36.9051 11.5332C37.469 10.2264 38.753 9.36587 40.1724 9.36587C41.3497 9.36587 42.8885 9.93902 43.4779 11.5332H36.9051ZM44.4624 8.21156C43.3075 7.17605 41.7447 6.58203 40.1724 6.58203C38.4838 6.58203 36.8987 7.24187 35.7103 8.43953C34.5219 9.63719 33.8672 11.2346 33.8672 12.9364C33.8672 16.4411 36.6964 19.2907 40.1724 19.2907H40.2935C41.2063 19.2907 42.4457 19.2683 44.0291 18.2039C44.7237 17.7463 44.985 17.0062 44.6743 16.356C44.4879 15.9819 44.1422 15.6945 43.7472 15.587C43.4158 15.4971 43.0845 15.5452 42.8152 15.7218C41.7463 16.4186 40.8399 16.5069 40.1708 16.5069C38.7466 16.5069 37.4611 15.6351 36.9019 14.3155H45.0837C45.8516 14.3155 46.476 13.6974 46.476 12.9364C46.476 10.525 45.38 9.03515 44.4608 8.21156"
                  fill="#EBFF00"/>
              <path
                  d="M54.4464 16.5099C53.4858 16.5099 52.581 16.1551 51.8991 15.5097C51.2094 14.8579 50.8286 13.9797 50.8286 13.0373C50.8286 11.061 52.4854 9.39292 54.4464 9.39292C55.3815 9.39292 56.2529 9.77662 56.8997 10.4718C57.5401 11.1589 57.8921 12.0708 57.8921 13.0389C57.8921 15.0184 56.4106 16.5115 54.4464 16.5115V16.5099ZM54.4464 6.63477C52.7339 6.63477 51.1345 7.29621 49.9413 8.49869C48.7481 9.70117 48.0918 11.313 48.0918 13.0389C48.0918 14.7647 48.7497 16.3381 49.9429 17.4956C51.1233 18.6403 52.7227 19.2712 54.4448 19.2712C55.8435 19.2712 56.9602 18.9261 57.9335 18.1908C58.0992 18.8121 58.7141 19.2712 59.2351 19.2712C60.0427 19.2712 60.6274 18.6804 60.6274 17.8681V13.0405C60.6274 11.305 60.0013 9.69314 58.8655 8.5035C57.7169 7.29942 56.1462 6.63798 54.4448 6.63798"
                  fill="#EBFF00"/>
              <path
                  d="M68.8736 16.6065C66.9189 16.6065 65.3307 15.0155 65.3307 13.0601V13.036C65.3307 11.0661 66.9205 9.46548 68.8736 9.46548C70.8266 9.46548 72.4165 11.0677 72.4165 13.036C72.4165 15.0043 70.8266 16.6065 68.8736 16.6065ZM68.8736 6.68164C67.1961 6.68164 65.6158 7.34469 64.4211 8.54877C63.2263 9.75286 62.5684 11.3455 62.5684 13.036V24.0943C62.5684 24.8553 63.1928 25.4734 63.9607 25.4734C64.7285 25.4734 65.3291 24.8553 65.3291 24.0943V18.2986C66.363 19.005 67.6087 19.3904 68.872 19.3904C70.5606 19.3904 72.1457 18.7305 73.3341 17.5328C74.5225 16.3352 75.1772 14.7378 75.1772 13.036C75.1772 9.53131 72.348 6.68164 68.872 6.68164"
                  fill="#EBFF00"/>
              <path
                  d="M83.441 16.6338C81.4863 16.6338 79.8981 15.0429 79.8981 13.0874V13.0633C79.8981 11.0935 81.4879 9.49283 83.441 9.49283C85.394 9.49283 86.9839 11.0951 86.9839 13.0633C86.9839 15.0316 85.394 16.6338 83.441 16.6338ZM83.441 6.70898C81.7635 6.70898 80.1832 7.37203 78.9884 8.57612C77.7937 9.7802 77.1357 11.3728 77.1357 13.0633V24.1216C77.1357 24.8826 77.7602 25.5007 78.5281 25.5007C79.2959 25.5007 79.8965 24.8826 79.8965 24.1216V18.326C80.9303 19.0324 82.1761 19.4177 83.4394 19.4177C85.128 19.4177 86.713 18.7579 87.9014 17.5602C89.0898 16.3625 89.7446 14.7651 89.7446 13.0633C89.7446 9.55865 86.9154 6.70898 83.4394 6.70898"
                  fill="#EBFF00"/>
              <path
                  d="M93.0984 0.5C92.3305 0.5 91.7061 1.1181 91.7061 1.87908V17.9127C91.7061 18.6993 92.3178 19.3158 93.0984 19.3158C93.879 19.3158 94.4668 18.6865 94.4668 17.9127V1.87908C94.4668 1.1181 93.8535 0.5 93.0984 0.5Z"
                  fill="#EBFF00"/>
              <path
                  d="M99.4276 11.5352C99.9915 10.2283 101.275 9.36783 102.695 9.36783C103.872 9.36783 105.409 9.94097 106 11.5352H99.4276ZM106.985 8.21351C105.83 7.178 104.267 6.58398 102.695 6.58398C101.006 6.58398 99.4212 7.24382 98.2328 8.44148C97.0444 9.63915 96.3896 11.2366 96.3896 12.9383C96.3896 16.443 99.2189 19.2927 102.695 19.2927H102.816C103.73 19.2927 104.968 19.2702 106.552 18.2058C107.246 17.7483 107.507 17.0081 107.198 16.3579C107.012 15.9839 106.666 15.6965 106.271 15.5889C105.94 15.499 105.61 15.5472 105.339 15.7238C104.27 16.4205 103.364 16.5088 102.695 16.5088C101.271 16.5088 99.9851 15.6371 99.426 14.3174H107.608C108.376 14.3174 109 13.6993 109 12.9383C109 10.527 107.904 9.03711 106.985 8.21351Z"
                  fill="#EBFF00"/>
            </g>
            <defs>
              <clipPath id="clip0_314_6366">
                <rect width="109" height="25" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
            </defs>
          </svg>


          <div class="flex flex-col w-full items-center space-y-1 px-2">
            <div class="flex text-white text-2xl font-medium">Welcome to Pineapple</div>
            <div class="text-white text-base text-center">Our unique verification enables us to keep our community safe.
            </div>
          </div>


          <div class="flex flex-col space-y-2">
            <verified-status-card status="unverified" />
            <verified-status-card status="verified" />
            <verified-status-card status="community" />
          </div>

          <div
              @click="forward"
              class="flex flex-col mt-auto mb-0 space-y-2 w-full items-center">
            <button
                class="flex bg-brand-lime rounded-full w-full h-14 font-medium text-xl text-brand-black">
              <div class="flex m-auto space-x-2.5">
                <div class="my-auto pt-0.5">
                  Let's go
                </div>
              </div>
            </button>
          </div>

        </div>
      </div>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage, IonTitle, IonToolbar, useIonRouter,
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {DateTime} from "luxon";
import VerifiedStatusCard from "@/components/VerifiedStatusCard.vue";

export default defineComponent({
  name: 'PineappleRulesPage',
  components: {
    VerifiedStatusCard,
    IonContent,
    IonPage,
    IonToolbar
  },
  setup() {

    const busy = ref(false)
    const ionRouter = useIonRouter()

    const forward = async () => {
      await ionRouter.navigate('/pineapple/feed', 'forward', 'replace')
    }

    return {busy, forward}

  }
});
</script>
