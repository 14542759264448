<template>
  <ion-page>
    <ion-content>

      <div class="flex h-full flex-col w-full bg-brand-black my-auto px-4 pt-14  z-50">

        <div @click="choosePhoto" class="flex flex-col w-full space-y-10 justify-center items-center relative">

          <div class="flex text-white text-2xl font-medium text-center">
            <div v-if="!avatar">Add your profile picture</div>
            <div v-if="avatar">Looking good, {{user.name}}!</div>
          </div>

          <div class="flex relative">
            <div v-if="busy" class="flex w-full h-full absolute top-0 left-0 bg-brand-lime rounded-full z-20">
              <svg class="animate-spin h-10 w-10 m-auto text-brand-black" xmlns="http://www.w3.org/2000/svg" fill="none"
                   viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
            <div
                :style="{
                  'background-image' : `url('data:image/jpeg;base64,${avatar}')`
                }"
                class="w-full h-full bg-cover bg-center absolute top-0 left-0 rounded-full z-50"></div>

            <svg width="201" height="200" viewBox="0 0 201 200" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" width="200" height="200" rx="100" fill="#EBFF00"/>
              <path
                  d="M107.596 101.419C107.596 103.301 106.849 105.106 105.518 106.436C104.187 107.767 102.382 108.515 100.5 108.515C98.6178 108.515 96.8129 107.767 95.482 106.436C94.1512 105.106 93.4036 103.301 93.4036 101.419C93.4036 99.5365 94.1512 97.7316 95.482 96.4007C96.8129 95.0699 98.6178 94.3223 100.5 94.3223C102.382 94.3223 104.187 95.0699 105.518 96.4007C106.849 97.7316 107.596 99.5365 107.596 101.419Z"
                  fill="#1A1B22"/>
              <path
                  d="M83.4687 88.6458C81.9631 88.6458 80.5191 89.244 79.4544 90.3086C78.3897 91.3733 77.7916 92.8173 77.7916 94.3229V111.354C77.7916 112.86 78.3897 114.304 79.4544 115.368C80.5191 116.433 81.9631 117.031 83.4687 117.031H117.531C119.037 117.031 120.481 116.433 121.546 115.368C122.61 114.304 123.208 112.86 123.208 111.354V94.3229C123.208 92.8173 122.61 91.3733 121.546 90.3086C120.481 89.244 119.037 88.6458 117.531 88.6458H114.204C112.699 88.6455 111.255 88.0472 110.191 86.9824L107.84 84.6321C106.776 83.5674 105.332 82.9691 103.827 82.9688H97.1732C95.6677 82.9691 94.2239 83.5674 93.1595 84.6321L90.8092 86.9824C89.7448 88.0472 88.301 88.6455 86.7955 88.6458H83.4687ZM84.888 94.3229C84.5116 94.3229 84.1506 94.1734 83.8844 93.9072C83.6182 93.6411 83.4687 93.2801 83.4687 92.9036C83.4687 92.5272 83.6182 92.1662 83.8844 91.9001C84.1506 91.6339 84.5116 91.4844 84.888 91.4844C85.2644 91.4844 85.6254 91.6339 85.8916 91.9001C86.1577 92.1662 86.3073 92.5272 86.3073 92.9036C86.3073 93.2801 86.1577 93.6411 85.8916 93.9072C85.6254 94.1734 85.2644 94.3229 84.888 94.3229ZM110.435 101.419C110.435 104.054 109.388 106.581 107.525 108.444C105.662 110.307 103.135 111.354 100.5 111.354C97.8651 111.354 95.3381 110.307 93.4749 108.444C91.6118 106.581 90.5651 104.054 90.5651 101.419C90.5651 98.7844 91.6118 96.2574 93.4749 94.3942C95.3381 92.5311 97.8651 91.4844 100.5 91.4844C103.135 91.4844 105.662 92.5311 107.525 94.3942C109.388 96.2574 110.435 98.7844 110.435 101.419Z"
                  fill="#1A1B22"/>
            </svg>
          </div>
        </div>

        <div v-if="avatar" class="mt-auto flex pb-20 ">
          <button
              @click="forward"
              class="mt-auto mx-auto w-full flex bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
            <div class="flex m-auto space-x-2.5">
              <div class="my-auto pt-0.5">
                <div v-if="!busy">Continue</div>
                <div v-if="busy">
                  <svg class="animate-spin h-5 w-5 text-brand-black" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              </div>
            </div>
          </button>
        </div>

        <div v-if="!avatar" class="mt-auto flex pb-20 ">
          <button
              @click="forward"
              class="mt-auto mx-auto  flex bg-brand-dark-gray rounded-full px-8 h-14 font-medium text-xl text-white transition-all">
            <div class="flex m-auto space-x-2.5">
              <div class="my-auto pt-0.5">
                <div v-if="!busy">Skip</div>
                <div v-if="busy">
                  <svg class="animate-spin h-5 w-5 text-brand-black" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              </div>
            </div>
          </button>
        </div>

      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {IonContent, IonPage, onIonViewWillEnter, useIonRouter,} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";

export default defineComponent({
  name: 'AccountOnboardingProfilePicturePage',
  components: {
    IonContent,
    IonPage,
  },
  setup() {

    const busy = ref(false)
    const errors = ref({})
    const ionRouter = useIonRouter()
    const user = ref()

    const avatar = ref()

    const getUser = async () => {
      user.value = await ApiService.User().get()
    }

    onIonViewWillEnter(async () => {
      if (user.value) return
      await getUser()
      Observable.emit(ObservableEvent.IonRouterOutletChanged, ionRouter.canGoBack())
    })

    const choosePhoto = async () => {
      try {
        const image = await useCamera(CameraSource.Photos, CameraResultType.Base64)
        busy.value = true
        avatar.value = undefined
        if (image && image.base64String) await ApiService.User().setProfilePhoto(image.base64String)
        avatar.value = image.base64String
        console.log(avatar.value)
      } catch (e) {
        console.log(e)
        busy.value = false
      } finally {
        busy.value = false
      }
    }

    const forward = async () => {
      await ionRouter.navigate('/pineapple/rules', 'forward', 'push')
    }

    return {busy, errors, choosePhoto, avatar, user, forward}

  }
});
</script>
