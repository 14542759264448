<template>
  <div class="font-sans" v-if="!loading">
      <div  class="flex flex-col p-4 pt-6 divide-y divide-brand-dark-gray">
        <div v-if="authUserId && authUserId !== review.reviewer.id" class="flex text-white p-4 py-5">
          <div class="flex justify-between">
            <div @click="reportReview" class="flex space-x-2">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21.8998 20.25L12.1498 3.375C12.0045 3.12422 11.7537 3 11.5006 3C11.2475 3 10.9943 3.12422 10.8514 3.375L1.10138 20.25C0.813094 20.7516 1.17403 21.375 1.75059 21.375H21.2506C21.8272 21.375 22.1881 20.7516 21.8998 20.25ZM10.7506 9.9375C10.7506 9.83437 10.835 9.75 10.9381 9.75H12.0631C12.1662 9.75 12.2506 9.83437 12.2506 9.9375V14.25C12.2506 14.3531 12.1662 14.4375 12.0631 14.4375H10.9381C10.835 14.4375 10.7506 14.3531 10.7506 14.25V9.9375ZM11.5006 18.1875C11.2062 18.1815 10.9259 18.0603 10.7198 17.85C10.5137 17.6397 10.3983 17.357 10.3983 17.0625C10.3983 16.768 10.5137 16.4853 10.7198 16.275C10.9259 16.0647 11.2062 15.9435 11.5006 15.9375C11.795 15.9435 12.0753 16.0647 12.2814 16.275C12.4874 16.4853 12.6029 16.768 12.6029 17.0625C12.6029 17.357 12.4874 17.6397 12.2814 17.85C12.0753 18.0603 11.795 18.1815 11.5006 18.1875Z"
                    fill="white"/>
              </svg>
              <div v-if="!reportBusy" class="text-lg">Report</div>
              <svg v-if="reportBusy" class="animate-spin m-auto h-5 w-5 text-white"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>

          </div>
        </div>

        <div v-if="authUserId && authUserId === review.reviewer.id" @click="deleteReview" class="flex text-white p-4 py-5">
          <div class="flex justify-between">
            <div class="flex space-x-2">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21.1667 6.1H17.8333V4.1C17.8333 3.2175 17.0859 2.5 16.1667 2.5H7.83333C6.91406 2.5 6.16667 3.2175 6.16667 4.1V6.1H2.83333C2.3724 6.1 2 6.4575 2 6.9V7.7C2 7.81 2.09375 7.9 2.20833 7.9H3.78125L4.42448 20.975C4.46615 21.8275 5.20052 22.5 6.08854 22.5H17.9115C18.8021 22.5 19.5339 21.83 19.5755 20.975L20.2187 7.9H21.7917C21.9062 7.9 22 7.81 22 7.7V6.9C22 6.4575 21.6276 6.1 21.1667 6.1ZM15.9583 6.1H8.04167V4.3H15.9583V6.1Z"
                    fill="white"/>
              </svg>
              <div v-if="!deleteBusy" class="text-lg">Delete</div>
              <svg v-if="deleteBusy" class="animate-spin m-auto h-5 w-5 text-white"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>

          </div>
        </div>

      </div>
    </div>
    <div class="safe-area-bottom"></div>
</template>

<script setup lang="ts">

import {computed, defineProps, inject, onDeactivated, onMounted, onUnmounted, PropType, Ref, ref, watch} from 'vue';
import {useRoute} from "vue-router";
import {alertController, IonContent, IonToggle, modalController, toastController} from "@ionic/vue";
import ApiService from "@/services/api/apiService";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import FeedNewPostPage from "@/views/feed/FeedNewPostPage.vue";
import {useUser} from "@/composables/useUser";
import {EventTypeModel} from "@/definitions/EventTypeModel";
import FeedNewVideoPage from "@/views/feed/FeedNewVideoPage.vue";
import {ReviewInterface} from "@/interfaces/ReviewInterface";


const loading = ref(true)
const reportBusy = ref(false)
const deleteBusy = ref(false)

const authUserId = ref()

const props = defineProps({
  review: {
    type: Object as PropType<ReviewInterface>,
    required: true
  },
})

onMounted(async () => {
  authUserId.value = parseInt((await Preferences.get({key: PreferenceKey.UserId})).value as string)
  loading.value = false
})

const deleteReview = async () => {

  if (isBusy.value) return

  const alertCtrl = await alertController.create({
    header: 'Delete',
    message: 'Are you sure you want to delete this?',
    buttons: [
      {
        text: 'Yes',
        role: 'destructive',
        handler: async () => {
          try {
            deleteBusy.value = true
            await alertCtrl.dismiss()
            await ApiService.User().deleteReview(props.review)
            Observable.emit(ObservableEvent.UserReviewsChanged)
            await modalController.dismiss()
            deleteBusy.value = false
          } catch (e: any) {
            const toast = await toastController.create({
              message: e.data.message,
              duration: 3000,
              position: 'bottom',
              color: 'danger'
            });
            await toast.present();
            deleteBusy.value = false
          }
        }
      },
      {
        text: 'No',
      }
    ]
  })

  await alertCtrl.present()

}

const reviewRef: Ref<ReviewInterface> = ref(props.review)

const isBusy = computed(() => {
  return reportBusy.value || deleteBusy.value
})

const reportReview = async () => {
  if (isBusy.value) return
  try {

    const alert = await alertController.create({
      header: 'Report?',
      message: 'Are you sure you want to report this?',
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Yes',
          role: 'destructive',
          handler: async () => {

            alertController.dismiss()
            reportBusy.value = true
            await ApiService.User().reportReview(props.review.id)
            Observable.emit(ObservableEvent.UserReviewsChanged)
            reportBusy.value = false

            await modalController.dismiss()
            const toast = await toastController.create({
              header: 'Reported',
              message: 'Thank you for reporting this, it has been flagged for review by our team.',
              duration: 3000,
              position: 'bottom',
              color: 'primary'
            });
            await toast.present();

          }
        },

      ]
    })
    await alert.present()

  } catch (e: any) {
    const toast = await toastController.create({
      message: e.data.message,
      duration: 3000,
      position: 'bottom',
      color: 'danger'
    });
    await toast.present();
    reportBusy.value = false
  }
}

</script>

