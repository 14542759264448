<template>
  <div class="flex flex-col items-center p-5 py-10 pt-10 space-y-10">

    <div class="flex flex-col space-y-6 items-center">
      <div class="flex text-brand-lime">
        <svg  class="w-16 h-16 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M160 32a104 104 0 1 1 0 208 104 104 0 1 1 0-208zm320 0a104 104 0 1 1 0 208 104 104 0 1 1 0-208zM0 416c0-70.7 57.3-128 128-128h64c70.7 0 128 57.3 128 128v16c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V416zm448 64c-38.3 0-72.7-16.8-96.1-43.5c.1-1.5 .1-3 .1-4.5V416c0-34.9-11.2-67.1-30.1-93.4c5.8-20 24.2-34.6 46.1-34.6H592c26.5 0 48 21.5 48 48v16c0 70.7-57.3 128-128 128H448z"/></svg>
      </div>

      <div class="flex flex-col w-full items-center space-y-1 px-2">
        <div class="flex text-white text-2xl font-medium text-center">Additional Profiles</div>
        <div class="text-brand-light-gray text-lg text-center leading-tight">You have a joint account so it's only right that we get to know each of you! </div>
        <div class="text-gray-500 text-sm text-center leading-tight py-3">Manage your profiles from your account settings.</div>
      </div>

      <div
          class="flex flex-col space-y-2 w-full items-center">
        <button
            @click="setup"
            class="flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
          <div class="flex w-full  m-auto space-x-2.5">
            <div class="m-auto pt-0.5">
              <div>Create Profile</div>
            </div>
          </div>
        </button>
      </div>


    </div>

  </div>
</template>

<script setup lang="ts">

import {onMounted, ref} from 'vue';
import {modalController} from "@ionic/vue";
import ApiService from "@/services/api/apiService";
import {UserProfileInterface} from "@/interfaces/UserProfileInterface";
import ProfileSettingsProfile from "@/views/user/profile/ProfileSettingsProfile.vue";
const loading = ref(true)

onMounted(async () => {
  loading.value = false
})

const dismiss = async () => {
  await modalController.dismiss()
}

const busy = ref(false)

const setup = async () => {
  if (busy.value) return
  busy.value = true
  await modalController.dismiss()
  const profile = await ApiService.User().createProfile()
  await viewProfile(profile)

}

const viewProfile = async (profile: UserProfileInterface) => {
  const modal = await modalController.create({
    component: ProfileSettingsProfile,
    mode: 'ios',
    componentProps: {
      profile
    },
    presentingElement: document.getElementById('tabs') as HTMLElement,
  })
  await modal.present()
}

</script>