<template>
    <div class="font-sans" v-if="!loading">

        <div class="flex flex-col p-4 pt-6 divide-y divide-brand-dark-gray">
            <div class="flex text-white p-4 py-5">
                <div class="flex justify-between">
                    <div @click="unblock" class="flex space-x-2 text-white">
                        <svg class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M352 144c0-44.2 35.8-80 80-80s80 35.8 80 80v48c0 17.7 14.3 32 32 32s32-14.3 32-32V144C576 64.5 511.5 0 432 0S288 64.5 288 144v48H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64H352V144z"/></svg>
                        <div v-if="!unblockBusy" class="text-lg">Unblock</div>
                        <svg v-if="unblockBusy" class="animate-spin m-auto h-5 w-5 text-white"
                             xmlns="http://www.w3.org/2000/svg"
                             fill="none"
                             viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>

                </div>
            </div>
        </div>
        <div class="safe-area-bottom"></div>
    </div>
</template>

<script setup lang="ts">

import {computed, onMounted, PropType, ref} from 'vue';
import {alertController, modalController, toastController} from "@ionic/vue";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import ApiService from "@/services/api/apiService";

const loading = ref(true)
const unblockBusy = ref(false)

const props = defineProps({
    user: {
        type: Object as PropType<UserBasicInformationInterface>,
        required: true
    }
})

onMounted(() => {
    loading.value = false
})

const unblock = async () => {

    if (isBusy.value) return

    const alertCtrl = await alertController.create({
        header: 'Unblock',
        message: 'Are you sure you want to unblock this user?',
        buttons: [
            {
                text: 'Yes',
                role: 'destructive',
                handler: async () => {
                    try {
                        unblockBusy.value = true
                        await alertCtrl.dismiss()
                        await ApiService.User().unblockUser(props.user?.id)
                        Observable.emit(ObservableEvent.BlockedUsersChanged)

                        const toast = await toastController.create({
                            header: 'Unblocked',
                            message: `You've unblocked ${props.user.name}, you are now discoverable to them.`,
                            duration: 3000,
                            position: 'bottom',
                            color: 'primary'
                        });
                        await toast.present();
                        await modalController.dismiss()

                        unblockBusy.value = false
                    } catch (e: any) {
                        const toast = await toastController.create({
                            message: e.data.message,
                            duration: 3000,
                            position: 'bottom',
                            color: 'danger'
                        });
                        await toast.present();
                        unblockBusy.value = false
                    }
                }
            },
            {
                text: 'No',
            }
        ]
    })

    await alertCtrl.present()

}

const isBusy = computed(() => {
    return (unblockBusy.value)
})


</script>

