<template>
  <div class="flex p-5 py-10 pt-10">
    <div class="flex flex-col w-full items-center space-y-10">

      <div class="flex flex-col space-y-6 items-center">
        <div class="flex text-brand-lime">
          <svg
              class="fill-current w-10 h-10"
              viewBox="0 0 29 30"
              xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.17261 2.76396C10.017 3.04821 11.8873 2.44094 13.2116 1.12627C13.7382 0.606218 14.3002 0.325195 14.8881 0.325195C15.3629 0.325195 15.8216 0.509314 16.2577 0.85171C16.3675 0.938924 16.4741 1.03583 16.5807 1.14242C17.905 2.43771 19.7624 3.03206 21.5906 2.74781C22.3271 2.65413 22.9538 2.75427 23.4318 3.10312C23.9067 3.44875 24.1974 4.00756 24.3169 4.73758C24.6173 6.57876 25.7737 8.17123 27.4308 9.02399C28.0865 9.36315 28.529 9.81214 28.7099 10.371C28.8908 10.9298 28.7971 11.5532 28.4644 12.2121C27.6246 13.8789 27.6246 15.8461 28.4644 17.5128C28.7971 18.1718 28.8908 18.7952 28.7099 19.354C28.529 19.9128 28.0865 20.3618 27.4308 20.701C25.7705 21.557 24.6173 23.1462 24.3169 24.9874C24.1974 25.7174 23.9067 26.2762 23.4318 26.6218C22.957 26.9675 22.3336 27.0708 21.6036 26.9578C19.7592 26.6735 17.8889 27.2808 16.5645 28.5955C16.038 29.1155 15.476 29.3965 14.8881 29.3965C14.3002 29.3965 13.7382 29.1155 13.2116 28.5955C11.8873 27.2808 10.017 26.6735 8.17261 26.9578C7.44259 27.0708 6.81917 26.9675 6.34434 26.6218C5.86951 26.2762 5.5788 25.7174 5.45928 24.9874C5.15888 23.1462 4.00248 21.5537 2.34542 20.701C1.6897 20.3618 1.24717 19.9128 1.06628 19.354C0.88539 18.7952 0.979064 18.1718 1.31177 17.5128C2.15161 15.8461 2.15161 13.8789 1.31177 12.2121C0.979064 11.5532 0.88539 10.9298 1.06628 10.371C1.24717 9.81214 1.6897 9.36315 2.34542 9.02399C4.00571 8.168 5.15888 6.57876 5.45928 4.73758C5.5788 4.00756 5.86951 3.44875 6.34434 3.10312C6.81917 2.7575 7.44259 2.65413 8.17261 2.76719V2.76396Z"
            />
            <path d="M8.9248 14.9L12.8998 18.875L19.5248 10.925" stroke="#1A1B22"
                  stroke-width="1.9875"
                  stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>

        <div class="flex flex-col w-full items-center space-y-1 px-2">
          <div class="flex text-white text-2xl font-medium text-center">{{title}}</div>
          <div class="text-brand-light-gray text-lg text-center">{{text}}</div>
        </div>
      </div>

      <in-app-subscription class="w-full" label="Upgrade to Pineapple Premium">
        <template v-slot:body>
          <button
              @click="dismiss"
              class=" mx-auto w-full flex bg-brand-lime rounded-full px-8 h-12 font-medium text-xl text-brand-black transition-all">
            <div class="flex m-auto space-x-2.5">
              <div class="my-auto pt-0.5">
                <div>Upgrade</div>
              </div>
            </div>
          </button>
        </template>
      </in-app-subscription>
    </div>
  </div>
</template>

<script setup lang="ts">

import {onMounted, ref} from 'vue';
import InAppSubscription from "@/components/InAppSubscription.vue";
import {modalController} from "@ionic/vue";

const props = defineProps({
  title: {
    type: String,
    default: 'Join Pineapple Premium'
  },
  text: {
    type: String,
    default: 'Upgrade to unlock all premium features.'
  }
})

const loading = ref(true)

const dismiss = async () => {
  await modalController.dismiss()
}

onMounted(async () => {
  loading.value = false
})
</script>