import {createMemoryHistory, createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import OnboardingPage from "@/views/auth/OnboardingPage.vue";
import WelcomePage from "@/views/auth/WelcomePage.vue";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import LoginPage from "@/views/auth/LoginPage.vue";
import ForgotPasswordPage from "@/views/auth/ForgotPasswordPage.vue";
import LoginRouterOutlet from "@/views/auth/LoginRouterOutlet.vue";
import ForgotPasswordInstructionsPage from "@/views/auth/ForgotPasswordInstructionsPage.vue";
import AccountRouterOutlet from "@/views/account/AccountRouterOutlet.vue";
import AccountPrivacyPage from "@/views/account/AccountPrivacyPage.vue";
import AccountEmailPage from "@/views/account/AccountEmailPage.vue";
import AccountPasswordPage from "@/views/account/AccountPasswordPage.vue";
import AccountBiometricsPage from "@/views/account/AccountBiometricsPage.vue";
import AccountOnboardingPage from "@/views/account/AccountOnboardingPage.vue";
import {StatusBar, Style} from "@capacitor/status-bar";
import AccountNotVerifiedPage from "@/views/account/AccountNotVerifiedPage.vue";
import AccountOnboardingRouterOutlet from "@/views/account/AccountOnboardingRouterOutlet.vue";
import AccountOnboardingNamePage from "@/views/account/AccountOnboardingNamePage.vue";

import AccountOnboardingBioPage from "@/views/account/AccountOnboardingBioPage.vue";
import AccountOnboardingInterestedInPage from "@/views/account/AccountOnboardingInterestedInPage.vue";
import AccountOnboardingLookingForPage from "@/views/account/AccountOnboardingLookingForPage.vue";
import AccountOnboardingProfilePicturePage from "@/views/account/AccountOnboardingProfilePicturePage.vue";
import {ref} from "vue";
import SubscriptionPage from "@/views/common/SubscriptionPage.vue";
import AccountOnboardingNotificationsPage from "@/views/account/AccountOnboardingNotificationsPage.vue";
import PineappleApp from "@/views/PineappleApp.vue";
import FeedPage from "@/views/feed/FeedTab.vue";
import FlirstTab from "@/views/flirt/FlirtTab.vue";
import ProfileTab from "@/views/user/profile/ProfileTab.vue";
import ProfilePage from "@/views/user/profile/ProfilePage.vue";
import ApiService from "@/services/api/apiService";
import PineappleRulesPage from "@/views/common/PineappleRulesPage.vue";
import PineappleBadgesPage from "@/views/common/PineappleBadgesPage.vue";
import {FcmService} from "@/services/fcm/fcmService";
import AccountVerificationRouterOutlet from "@/views/account/AccountVerificationRouterOutlet.vue";
import ProfileSettingsPage from "@/views/user/profile/ProfileSettingsPage.vue";
import {isPlatform} from "@ionic/vue";
import ProfileSettingsVerifyPage from "@/views/user/profile/ProfileSettingsVerifyPage.vue";
import ProfileSettingsVerifyIdCardPage from "@/views/user/profile/ProfileSettingsVerifyIdCardPage.vue";
import ProfileSettingsVerifyImagePage from "@/views/user/profile/ProfileSettingsVerifyImagePage.vue";
import FeedViewPostPage from "@/views/feed/FeedViewPostPage.vue";
import FeedNewPostPage from "@/views/feed/FeedNewPostPage.vue";
import FriendPage from "@/views/friends/FriendPage.vue";
import ReportUserPage from "@/views/common/ReportUserPage.vue";
import ReportUserConfirmationPage from "@/views/common/ReportUserConfirmationPage.vue";
import ReportUserConfirmationSuccessPage from "@/views/common/ReportUserConfirmationSuccessPage.vue";
import BlockUserPage from "@/views/common/BlockUserPage.vue";
import ProfileSettingsPersonal from "@/views/user/profile/ProfileSettingsPersonal.vue";
import ProfileSettingsUsername from "@/views/user/profile/ProfileSettingsUsername.vue";
import ProfileSettingsBio from "@/views/user/profile/ProfileSettingsBio.vue";
import ProfileSettingsLookingFor from "@/views/user/profile/ProfileSettingsLookingFor.vue";
import ProfileSettingsKinks from "@/views/user/profile/ProfileSettingsKinks.vue";
import ProfileSettingsAccount from "@/views/user/profile/ProfileSettingsAccount.vue";
import ProfileSettingsLocation from "@/views/user/profile/ProfileSettingsLocation.vue";
import FlirtViewChat from "@/views/flirt/FlirtViewChat.vue";
import SearchTab from "@/views/search/SearchTab.vue";
import FlirtArchive from "@/views/flirt/FlirtArchive.vue";

import PineappleNotificationsPage from "@/views/common/PineappleNotificationsPage.vue";
import ProfileSettingsAccountType from "@/views/user/profile/ProfileSettingsAccountType.vue";
import ProfileSettingsAccountBlocked from "@/views/user/profile/ProfileSettingsAccountBlocked.vue";
import ProfileSettingsPartner from "@/views/user/profile/ProfileSettingsPartner.vue";
import ProfileSettingsPartnerSearch from "@/views/user/profile/ProfileSettingsPartnerSearch.vue";
import {UserInterface} from "@/interfaces/UserInterface";
import ReportAProblem from "@/views/common/ReportAProblem.vue";
import FeedPreferences from "@/views/feed/FeedPreferences.vue";
import FeedPreferencesEdit from "@/views/feed/FeedPreferencesEdit.vue";
import FeedHashtag from "@/views/feed/FeedHashtag.vue";
import ProfileSettingsAccounNotifications from "@/views/user/profile/ProfileSettingsAccounNotifications.vue";
import EventsTab from "@/views/events/EventsTab.vue";
import FriendsTab from "@/views/friends/FriendsTab.vue";
import ProfileSettingsAccountPrivacy from "@/views/user/profile/ProfileSettingsAccountPrivacy.vue";
import EventPage from "@/views/events/EventPage.vue";
import EventViewChatPage from "@/views/events/EventViewChatPage.vue";

const onboardingStep = ref(1)
const canGoBack = ref(true)

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/onboarding'
    },
    {
        path: '/welcome',
        name: 'Welcome',
        component: WelcomePage,
    },
    {
        path: '/pineapple/feed/new',
        name: 'FeedNewPost',
        component: FeedNewPostPage,
    },
    {
        path: '/pineapple/feed/tag/:tag',
        name: 'FeedHashtag',
        component: FeedHashtag,
    },
    {
        path: '/pineapple/feed/post/:postId',
        name: 'FeedViewPost',
        component: FeedViewPostPage,
    },
    {
        path: '/pineapple/search/post/:postId',
        name: 'SearchViewPost',
        component: FeedViewPostPage,
    },
    {
        path: '/pineapple/feed/post/:postId/user/:userId',
        name: 'FeedPostMentionedUser',
        component: ProfilePage,
    },
    {
        path: '/pineapple',
        component: PineappleApp,
        children: [
            {
                path: '',
                redirect: 'feed'
            },
            {
                path: 'feed',
                name: 'Feed',
                component: FeedPage
            },
            {
                path: 'feed/preferences',
                name: 'PineappleFeedPreferences',
                component: FeedPreferences,
            },
            {
                path: 'feed/preferences/edit/:key',
                name: 'PineappleFeedPreferencesEdit',
                component: FeedPreferencesEdit,
            },
            {
                path: 'feed/user/:userId',
                name: 'PineappleFeedUserProfile',
                component: ProfilePage,
            },
            {
                path: 'feed/events/:eventId',
                name: 'FeedEvent',
                component: EventPage
            },
            {
                path: 'feed/events/:eventId/chat',
                name: 'FeedEventChat',
                component: EventViewChatPage
            },
            {
                path: 'feed/notifications',
                name: 'PineappleNotifications',
                component: PineappleNotificationsPage,
            },
            {
                path: 'feed/notifications/user/:userId/profile',
                name: 'PineappleNotificationsUserProfile',
                component: ProfilePage
            },
            {
                path: 'user/:userId/settings',
                name: 'PineappleUserSettings',
                component: FriendPage
            },
            {
                path: 'user/:userId/profile',
                name: 'PineappleUserProfile',
                component: ProfilePage
            },
            {
                path: 'events',
                name: 'Events',
                component: EventsTab
            },
            {
                path: 'events/:eventId',
                name: 'Event',
                component: EventPage
            },
            {
                path: 'events/user/:userId',
                name: 'PineappleEventsUserProfile',
                component: ProfilePage,
            },
            {
                path: 'profile/friends/user/:userId/profile',
                name: 'PineappleFriendsUserProfile',
                component: ProfilePage
            },
            {
                path: 'friends/user/:userId/settings',
                name: 'PineappleFriendsUserSettings',
                component: FriendPage
            },
            {
                path: 'search',
                name: 'Search',
                component: SearchTab
            },
            {
                path: 'search/user/:userId/profile',
                name: 'PineappleSearchUserProfile',
                component: ProfilePage
            },
            {
                path: 'flirt',
                name: 'Flirt',
                component: FlirstTab
            },
            {
                path: 'flirt/archive',
                name: 'FlirtArchive',
                component: FlirtArchive
            },
            {
                path: 'flirt/:chatId/chat',
                name: 'FlirtViewChat',
                component: FlirtViewChat
            },
            {
                path: 'flirt/chat/user/:userId',
                name: 'FlirtUserProfile',
                component: ProfilePage,
            },
            {
                path: 'profile',
                name: 'Profile',
                component: ProfileTab
            },
            {
                path: 'profile/friends',
                name: 'Friends',
                component: FriendsTab
            },
            {
                path: 'profile/settings',
                name: 'ProfileSettings',
                component: ProfileSettingsPage
            },
            {
                path: 'profile/settings/account',
                name: 'ProfileSettingsAccount',
                component: ProfileSettingsAccount
            },
            {
                path: 'profile/settings/partner',
                name: 'ProfileSettingsPartner',
                component: ProfileSettingsPartner
            },
            {
                path: 'profile/settings/partner/search',
                name: 'ProfileSettingsPartnerSearch',
                component: ProfileSettingsPartnerSearch
            },
            {
                path: 'profile/settings/blocked',
                name: 'ProfileSettingsAccountBlocked',
                component: ProfileSettingsAccountBlocked
            },
            {
                path: 'profile/settings/notifications',
                name: 'ProfileSettingsAccountNotifications',
                component: ProfileSettingsAccounNotifications
            },
            {
                path: 'profile/settings/privacy',
                name: 'ProfileSettingsAccountPrivacy',
                component: ProfileSettingsAccountPrivacy
            },
            {
                path: 'profile/settings/personal',
                name: 'ProfileSettingsPersonal',
                component: ProfileSettingsPersonal
            },
            {
                path: 'profile/settings/report-a-problem',
                name: 'ProfileSettingsReportAProblem',
                component: ReportAProblem
            },
            {
                path: 'profile/settings/personal/username',
                name: 'ProfileSettingsUsername',
                component: ProfileSettingsUsername
            },
            {
                path: 'profile/settings/personal/bio',
                name: 'ProfileSettingsBio',
                component: ProfileSettingsBio
            },
            {
                path: 'profile/settings/personal/looking-for',
                name: 'ProfileSettingsLookingFor',
                component: ProfileSettingsLookingFor
            },
            {
                path: 'profile/settings/personal/location',
                name: 'ProfileSettingsLocation',
                component: ProfileSettingsLocation
            },
            {
                path: 'profile/settings/personal/kinks',
                name: 'ProfileSettingsKinks',
                component: ProfileSettingsKinks
            },
            {
                path: 'profile/settings/personal/account-type',
                name: 'ProfileSettingsAccountType',
                component: ProfileSettingsAccountType
            },
            {
                path: 'profile/settings/verify',
                name: 'ProfileSettingsVerify',
                component: ProfileSettingsVerifyPage,
            },
            {
                path: 'profile/settings/verify/id-card',
                name: 'ProfileSettingsVerifyIdCard',
                component: ProfileSettingsVerifyIdCardPage
            },
            {
                path: 'profile/settings/verify/image',
                name: 'ProfileSettingsVerifyImage',
                component: ProfileSettingsVerifyImagePage
            },
            {
                path: 'profile/user/:userId/profile',
                name: 'PineappleProfileUserProfile',
                component: ProfilePage
            },
        ]
    },
    {
        path: '/login',
        component: LoginRouterOutlet,
        children: [
            {
                path: '',
                name: 'Login',
                component: LoginPage,
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: ForgotPasswordPage
            },
            {
                path: 'forgot-password-instructions',
                name: 'ForgotPasswordInstructions',
                component: ForgotPasswordInstructionsPage
            },
        ]
    },
    {
        path: '/account',
        component: AccountRouterOutlet,
        children: [
            {
                path: '',
                name: 'AccountPrivacy',
                component: AccountPrivacyPage,
            },
            {
                path: 'email',
                name: 'AccountEmail',
                component: AccountEmailPage,
            },
            {
                path: 'password',
                name: 'AccountPassword',
                component: AccountPasswordPage,
            },
        ]
    },
    {
        path: '/account/biometrics',
        name: 'AccountBiometrics',
        component: AccountBiometricsPage,
    },
    {
        path: '/account/onboarding',
        name: 'AccountOnboarding',
        component: AccountOnboardingPage,
    },
    {
        path: '/account/onboarding',
        component: AccountOnboardingRouterOutlet,
        props: {
            onboardingStep
        },
        children: [
            {
                path: 'name',
                name: 'AccountOnboardingName',
                component: AccountOnboardingNamePage,
                beforeEnter: () => {
                    onboardingStep.value = 1
                }
            },
            {
                path: 'bio',
                name: 'AccountOnboardingBio',
                component: AccountOnboardingBioPage,
                beforeEnter: () => {
                    onboardingStep.value = 3
                }
            },
            {
                path: 'interested-in',
                name: 'AccountOnboardingInterestedIn',
                component: AccountOnboardingInterestedInPage,
                beforeEnter: () => {
                    onboardingStep.value = 6
                }
            },
            {
                path: 'looking-for',
                name: 'AccountOnboardingLookingFor',
                component: AccountOnboardingLookingForPage,
                beforeEnter: () => {
                    onboardingStep.value = 7
                }
            },
            {
                path: 'profile-picture',
                name: 'AccountOnboardingProfilePicture',
                component: AccountOnboardingProfilePicturePage,
                beforeEnter: () => {
                    onboardingStep.value = 8
                }
            },
            {
                path: 'notifications',
                name: 'AccountOnboardingNotifications',
                component: AccountOnboardingNotificationsPage,
                beforeEnter: () => {
                    onboardingStep.value = 9
                },
            },
        ]
    },
    {
        path: '/account/verification',
        component: AccountVerificationRouterOutlet,
        props: {
            onboardingStep
        },
        children: []
    },
    {
        path: '/account/email/not-verified',
        name: 'AccountNotVerified',
        component: AccountNotVerifiedPage,
    },
    {
        path: '/account/subscription',
        name: 'AccountSubscription',
        component: SubscriptionPage,
    },
    {
        path: '/pineapple/rules',
        name: 'PineappleRules',
        component: PineappleRulesPage,
    },
    {
        path: '/pineapple/badges',
        name: 'PineappleBadges',
        component: PineappleBadgesPage,
    },
    {
        path: '/pineapple/user/:userId',
        name: 'PineappleUser',
        component: ProfilePage,
    },
    {
        path: '/block/:userId',
        name: 'BlockUserPage',
        component: BlockUserPage
    },
    {
        path: '/report/:userId',
        name: 'ReportUserPage',
        component: ReportUserPage
    },
    {
        path: '/report/:userId/confirmation',
        name: 'ReportUserConfirmationPage',
        component: ReportUserConfirmationPage
    },
    {
        path: '/report/:userId/confirmation/success',
        name: 'ReportUserConfirmationSuccessPage',
        component: ReportUserConfirmationSuccessPage
    },
    {
        path: '/onboarding',
        name: 'Onboarding',
        component: OnboardingPage,
        beforeEnter: async () => {


            // If onboarding has been completed we won't force the user to see this again
            const completedOnboarding = (await Preferences.get({
                key: PreferenceKey.CompletedOnboarding
            })).value

            const loggedIn = (await Preferences.get({
                key: PreferenceKey.ApiToken
            })).value


            if (loggedIn) {

                await FcmService.initPush()

                try {

                    const user: UserInterface = await ApiService.User().get()

                    const onboardingComplete = await user.preferences[PreferenceKey.OnboardingCompleted]

                    if (onboardingComplete) return {name: 'Feed'}

                    const onboardingAccountType = user.preferences[PreferenceKey.OnboardingAccountType]
                    const onboardingPushNotifications = user.preferences[PreferenceKey.OnboardingPushNotifications]

                    if (!user.onboarding.email_verified) return {name: 'AccountNotVerified'}
                    if (!user.onboarding.password_set) return {name: 'AccountPassword'}
                    if (!onboardingAccountType) return {name: 'AccountOnboarding'}
                    if (!user.onboarding.name_set) return {name: 'AccountOnboardingName'}
                    return {name: 'Feed'}

                } catch (e: any) {
                    if (e.status === 401) {
                        console.log('User not authenticated')
                        Preferences.clear()
                        return {name: 'Welcome'}
                    }
                }
            }

            return {name: 'Welcome'}

        }
    }
]


let history = createWebHistory(process.env.BASE_URL)
if (!isPlatform('capacitor')){
    history = createMemoryHistory()
}

const router = createRouter({
    history,
    routes,
})

router.beforeEach((to, from) => {
    if (isPlatform('capacitor')) {
        setTimeout(async () => {
            await StatusBar.setStyle({style: Style.Dark});
        }, 2500)
    }
    return true
})

router.afterEach(() => {
    //
})

export default router
