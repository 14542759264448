<template>
  <div class="flex flex-col items-center p-5 py-10 pt-7 space-y-10">


    <div class="flex flex-col space-y-6 items-center">
      <div class="flex text-brand-lime space-x-5 pt-4">
        <svg class="w-8 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM127 384.5c-5.5 9.6-17.8 12.8-27.3 7.3-9.6-5.5-12.8-17.8-7.3-27.3l14.3-24.7c16.1-4.9 29.3-1.1 39.6 11.4L127 384.5zm138.9-53.9H84c-11 0-20-9-20-20s9-20 20-20h51l65.4-113.2-20.5-35.4c-5.5-9.6-2.2-21.8 7.3-27.3 9.6-5.5 21.8-2.2 27.3 7.3l8.9 15.4 8.9-15.4c5.5-9.6 17.8-12.8 27.3-7.3 9.6 5.5 12.8 17.8 7.3 27.3l-85.8 148.6h62.1c20.2 0 31.5 23.7 22.7 40zm98.1 0h-29l19.6 33.9c5.5 9.6 2.2 21.8-7.3 27.3-9.6 5.5-21.8 2.2-27.3-7.3-32.9-56.9-57.5-99.7-74-128.1-16.7-29-4.8-58 7.1-67.8 13.1 22.7 32.7 56.7 58.9 102h52c11 0 20 9 20 20 0 11.1-9 20-20 20z"/></svg>
        <svg class="w-8 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"/></svg>
      </div>

      <div class="flex flex-col w-full items-center space-y-1 px-2">
        <div class="flex text-white text-2xl font-medium text-center">Feature Unavailable</div>
        <div class="text-brand-light-gray text-lg text-center leading-tight">This feature is not available on the Progressive Web App (PWA).<br/><br/>To access all app features ensure you're using the latest version of the app that's installable via the Apple and Google Play stores.   </div>
      </div>

      <div v-if="whatsNew.length" class="flex flex-col justify-center items-center px-2 pt-5">
        <div class="text-brand-light-gray text-xs font-medium mb-2">What's new in this release</div>
        <ul class="text-sm text-white space-y-3 text-center">
          <li
              v-for="(change, changeKey) in whatsNew"
              :key="changeKey"
              class="leading-tight">{{change}}</li>

        </ul>
      </div>

    </div>

  </div>
</template>

<script setup lang="ts">

import {onMounted, Ref, ref} from 'vue';
import InAppSubscription from "@/components/InAppSubscription.vue";
import {modalController} from "@ionic/vue";
import {useAppUpdate} from "@/composables/useAppUpdate";
import ApiService from "@/services/api/apiService";

const loading = ref(true)

const props = defineProps({
  appVersion: {
    type: String,
    default: ''
  }
})

const whatsNew: Ref<string[]> = ref([])


onMounted(async () => {
  whatsNew.value = await ApiService.ChangeLog().get(props.appVersion)
  loading.value = false
})
</script>