<template>
  <div class="flex w-full h-full">
    <div v-if="loading" class="flex m-auto">
      <svg class="animate-spin h-10 w-10 m-auto text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
           viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>


    <div class="flex flex-col px-4 py-5 w-full" v-if="!loading">

      <div  class="flex flex-col space-y-3 w-full pb-8 w-full">
        <div class="flex text-white text-lg font-medium">Upcoming</div>
        <template v-if="upcoming.length">
        <event-card
            @click="emit('onEventTapped', event)"
            v-for="event in upcoming"
            layout="list"
            :event="event"
            :with-context-menu="event.organiser.id === authUser.id"
            :key="event.id" />
        </template>
        <div v-if="!upcoming.length" class="p-3 bg-brand-dark-gray text-brand-light-gray rounded-lg w-full text-center">
          You aren't attending any upcoming events.
        </div>
      </div>

      <div v-if="requested.length" class="flex flex-col space-y-3 w-full  pb-8">
        <div class="flex text-white text-lg font-medium">Waiting for host to accept invite</div>
        <event-card
            @click="emit('onEventTapped', event)"
            v-for="event in requested"
            layout="list"
            :event="event"
            :with-context-menu="event.organiser.id === authUser.id"
            :key="event.id" />
      </div>

      <div v-if="interested.length" class="flex flex-col space-y-3 w-full  pb-8">
        <div class="flex text-white text-lg font-medium">Interested</div>
        <event-card
            @click="emit('onEventTapped', event)"
            v-for="event in interested"
            layout="list"
            :event="event"
            :with-context-menu="event.organiser.id === authUser.id"
            :key="event.id" />
      </div>

      <div v-if="past.length" class="flex flex-col  space-y-3 w-full  pb-8">
        <div class="flex text-white text-lg font-medium">Past</div>
        <event-card
            @click="emit('onEventTapped', event)"
            v-for="event in past"
            layout="list"
            :event="event"
            :with-context-menu="event.organiser.id === authUser.id"
            :key="event.id" />
      </div>
    </div>


  </div>
</template>
<script setup lang="ts">

import {onMounted, ref, Ref} from "vue";
import {EventInterface} from "@/interfaces/EventInterface";
import ApiService from "@/services/api/apiService";
import {EventFilterIs} from "@/definitions/EventFilterIs";
import EventCard from "@/components/EventCard.vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {useIonRouter} from "@ionic/vue";

const emit = defineEmits(['onEventTapped'])

const upcoming: Ref<EventInterface[]> = ref([])
const interested: Ref<EventInterface[]> = ref([])
const requested: Ref<EventInterface[]> = ref([])
const past: Ref<EventInterface[]> = ref([])

const loading = ref(true)
const observer = ref()
const authUser = ref()

onMounted(async () => {
  await getData()
  authUser.value = await ApiService.User().authUser()
  loading.value = false

  observer.value = Observable.subscribe(async (response: any) => {
    if (response.event === (
        ObservableEvent.PullToRefreshMyTickets ||
        ObservableEvent.EventsScrollTop
    )) {
      await pullToRefresh(response.data)
    }
  })

})

const pullToRefresh = async (ev?: any) => {
  await getData()
  Observable.emit(ObservableEvent.PendingEventResponsesChanged)
  if (ev) ev.target.complete()
}

const getData = async () => {

  const data = await Promise.all([
    await ApiService.Events().all(EventFilterIs.Attending),
    await ApiService.Events().all(EventFilterIs.Interested),
    await ApiService.Events().all(EventFilterIs.InviteRequested),
    await ApiService.Events().all(EventFilterIs.Attended)
  ])

  upcoming.value = data[0]
  interested.value = data[1]
  requested.value = data[2]
  past.value = data[3]
}


</script>