<template>
  <ion-page>
    <ion-content :fullscreen="true" class="h-full flex flex-col">
      <div class="flex w-full relative">

        <div class="absolute justify-between flex flex-col w-full top-0 left-0 z-50">
          <ion-toolbar class="opacity-0"/>
          <div class="flex p-4 justify-between">
            <div class="flex">
              <div @click="goBack" class="bg-brand-black/70  p-2.5 shadow-xl rounded-full flex">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"/>
                  <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
            <div class="flex">
              <div @click="viewUserSettings" class="bg-brand-black/70 p-2.5 shadow-xl text-white rounded-full flex">
                <svg class="fill-current" width="24" height="24" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M5.52035 14.1598C6.71329 14.1598 7.68035 13.1928 7.68035 11.9998C7.68035 10.8069 6.71329 9.83984 5.52035 9.83984C4.32742 9.83984 3.36035 10.8069 3.36035 11.9998C3.36035 13.1928 4.32742 14.1598 5.52035 14.1598Z"
                  />
                  <path
                      d="M12.0008 14.1598C13.1938 14.1598 14.1608 13.1928 14.1608 11.9998C14.1608 10.8069 13.1938 9.83984 12.0008 9.83984C10.8079 9.83984 9.84082 10.8069 9.84082 11.9998C9.84082 13.1928 10.8079 14.1598 12.0008 14.1598Z"
                  />
                  <path
                      d="M18.4803 14.1598C19.6732 14.1598 20.6403 13.1928 20.6403 11.9998C20.6403 10.8069 19.6732 9.83984 18.4803 9.83984C17.2874 9.83984 16.3203 10.8069 16.3203 11.9998C16.3203 13.1928 17.2874 14.1598 18.4803 14.1598Z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <user-profile class="w-full" @on-set-user="setUser" :userId="userId"/>
      </div>

    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonSkeletonText,
  IonToolbar, isPlatform,
  modalController,
  useIonRouter,
} from '@ionic/vue';
import {onMounted, Ref, ref} from 'vue';
import {UserInterface} from "@/interfaces/UserInterface";
import {useRoute} from "vue-router";
import UserProfile from "@/components/UserProfile.vue";
import {AccountType} from "@/definitions/AccountType";
import PartnerUsersList from "@/views/common/PartnerUsersList.vue";
import UserAccountTypeBadge from "@/components/UserAccountTypeBadge.vue";
import {Haptics, ImpactStyle} from "@capacitor/haptics";
import {useVerification} from "@/composables/useVerification";
import ApiService from "@/services/api/apiService";


const loading = ref(true)
const ionRouter = useIonRouter()
const user: Ref<UserInterface | undefined> = ref()
const route = useRoute()
const userId = ref(Number(route.params.userId))

const setUser = async (u: UserInterface) => {
  user.value = u
  loading.value = false
}

const goBack = async () => {
  await ionRouter.back()
}

const authUser = ref()
onMounted(async ()=>{
  authUser.value = await ApiService.User().authUser()
})

const viewUserSettings = async () => {

  if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});
  if (authUser.value && !authUser.value.id_verified) return await useVerification().presentVerificationRequired('You must be verified to report or block users.')

  ionRouter.navigate({
    name: 'PineappleUserSettings',
    params: {userId: user.value?.id},
  }, 'forward', 'push')
}

</script>
