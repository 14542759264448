import ApiServiceBase from "@/services/api/apiServiceBase";
import {PostPrivacy} from "@/definitions/PostPrivacy";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import {SocialFeedType} from "@/definitions/SocialFeedType";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";

export interface FeedServiceOptions {
  visibility?: PostPrivacy[]
}

export class FeedService extends ApiServiceBase {

  public async getBefore(type: SocialFeedType = SocialFeedType.ForYou): Promise<any> {
    const paramsObj: any = {
      before: '',
      without: 'comments',
    };
    const params = new URLSearchParams(paramsObj);

    return this.http?.get(`/feed/${type}?${params.toString()}`);
  }

  public async getAfter(postId?: number, type: SocialFeedType = SocialFeedType.ForYou): Promise<any> {
    const paramsObj: any = {
      after: postId ? `${postId}` : '',
      without: 'comments'
    };
    const params = new URLSearchParams(paramsObj);

    return this.http?.get(`/feed/${type}?${params.toString()}`);
  }

  public async getPost(postId: number): Promise<FeedPostInterface> {
    return this.http?.get(`/feed/${postId}`);
  }

  public async post(content: string, visibility: PostPrivacy): Promise<FeedPostInterface> {
    return this.http?.post(`/feed`, {
      content,
      visibility
    });
  }

  public async attachMedia(postId: number, media: string[]): Promise<FeedPostInterface> {
    return this.http?.post(`/feed/${postId}/media`, {
      images: media,
      type: 'IMAGE'
    });
  }

  public async attachVideo(postId: number, onProgress: any,  thumbnail?: string, video?: any): Promise<any> {

    const form = new FormData()
    form.append('type', 'VIDEO')

    if (thumbnail) form.append('thumbnail', thumbnail)
    if (video) form.append('video', video)

    return this.http?.upload(`/feed/${postId}/media`, form, (progress:any)=> {
      onProgress(progress)
    });
  }

  public async allowComments(postId: number, bool: boolean): Promise<FeedPostInterface> {
    return this.http?.put(`/feed/${postId}`, {
      allow_comments: bool
    });
  }

  public async updatePost(postId: number, content: string, visibility: PostPrivacy): Promise<FeedPostInterface> {
    return this.http?.put(`/feed/${postId}`, {
      content,
      visibility
    });
  }

  public async reportPost(postId: number): Promise<any> {
    return this.http?.put(`/feed/${postId}/report`, {});
  }

  public async reportPostComment(commentId: number): Promise<any> {
    return this.http?.put(`/feed/comments/${commentId}/report`, {});
  }

  public async deletePost(postId: number): Promise<any> {
    return this.http?.delete(`/feed/${postId}`, {});
  }

  public async postReaction(postId: number): Promise<any> {
    return this.http?.post(`/feed/${postId}/reaction?reaction=👍`, {});
  }

  public async deletePostReaction(postId: number): Promise<any> {
    return this.http?.delete(`/feed/${postId}/reaction?reaction=👍`, {});
  }
  public async deletePostComment(commentId: number): Promise<any> {
    return this.http?.delete(`/feed/comments/${commentId}`, {});
  }

  public async postCommentReaction(commentId: number): Promise<any> {
    return this.http?.post(`/feed/comments/${commentId}/reaction?reaction=👍`, {});
  }

  public async deletePostCommentReaction(commentId: number): Promise<any> {
    return this.http?.delete(`/feed/comments/${commentId}/reaction?reaction=👍`, {});
  }

  public async postCommentReply(commentId: number, comment: string): Promise<FeedPostInterface> {
    return this.http?.post(`/feed/comments/${commentId}/reply`, {
      comment
    });
  }

  public async updateComment(commentId: number, comment: string): Promise<FeedPostInterface> {
    return this.http?.put(`/feed/comments/${commentId}`, {
      comment
    });
  }

  public async commentReactions(commentId: number): Promise<any> {
    return this.http?.get(`/feed/comments/${commentId}/reaction`);
  }

  public async nearby(radius = 20, type?: string, page = 1): Promise<UserBasicInformationInterface[]> {
    return this.http?.get(`/feed/nearby?radius=${radius}&page=${page}${type ? `&type=${type}` : ''}`);
  }

}
