<template>
  <div class="flex flex-col w-full space-y-2">
    <div
        :class="{
          'border-brand-pink' : error,
          'border-brand-lime' : hasFocus
        }"
        class="flex w-full flex-col bg-brand-dark-gray rounded-lg relative border border-brand-dark-gray overflow-hidden">
      <ion-textarea
          class="input-textarea text-white text-lg font-medium"
          v-model="model"
          :autocomplete="autocomplete"
          :autocorrect="autocorrect"
          :autocapitalize="autocapitalize"
          :auto-grow="true"
          rows="5"
          :placeholder="placeholder"
          @keyup="keyPressed"
          :enterkeyhint="enterkeyhint"
          :maxlength="maxLength"
          :type="type"></ion-textarea>
    </div>
    <div class="flex w-full">
      <div v-if="error" class="text-brand-pink font-medium text-xs">{{ error }}</div>
      <div v-if="counter" class="flex text-brand-light-gray font-medium text-xs ml-auto mr-0">{{ characterCount }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">

import {IonTextarea} from "@ionic/vue";
import {computed, ref, watch} from "vue";

const props = defineProps({
  modelValue: {
    type: String,
  },
  placeholder: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  enterkeyhint: {
    type: String,
    default: 'return'
  },
  autofocus: {
    type: Boolean,
    default: false
  },
  inputmode: {
    type: String,
    default: 'text'
  },
  error: {
    type: String,
    required: false
  },
  counter: {
    type: Boolean,
    default: false
  },
  maxLength: {
    type: Number,
  },
  autocapitalize: {
    type: String,
    default: 'on'
  },
  autocomplete: {
    type: String,
    default: 'off'
  },
  autocorrect: {
    type: String,
    default: 'off'
  }
})

const emits = defineEmits(['update:modelValue', 'onKeyEnter'])
const model = ref(props.modelValue ?? '')

watch(() => model.value, newValue => {
  emits('update:modelValue', newValue)
})

const keyPressed = (event:any) => {
  if (event.keyCode === 13) emits('onKeyEnter', model)
}

const characterCount = computed(() => {
  if (props.maxLength) return `${model.value.length}/${props.maxLength}`
  return model.value.length
})

</script>
