<template>
  <div class="flex flex-col">

    <div
        v-if="!feed.length && !loading"
        class="px-4 py-6 flex w-full">
      <div
          class="w-full mx-auto flex justify-between bg-brand-dark-gray text-white items-center text-center p-4 rounded-2xl shadow-xl space-x-4">
        <div class="flex flex-col my-auto w-full">
          <div class="flex space-x-2 mx-auto">
            <div class="my-auto text-lg leading-normal text-center font-medium text-white">
              Check back soon!
            </div>
          </div>
          <div class="flex space-x-1 mx-auto">
            <div class="text-xs">{{ isAuthUser ? 'You do not have' : `${user.name} doesn't have` }} any {{ user.account_type === AccountType.Club ? 'reviews' : 'validations' }} yet.</div>
          </div>

          <div
              v-if="!isAuthUser"
              @click="leaveReview"
              :class="{
                'text-brand-black bg-brand-lime mb-2' : !user.has_reviewed,
                'text-brand-light-gray bg-brand-dark-gray' : user.has_reviewed
            }"
              class="flex rounded-full mx-auto space-x-4 z-50 my-auto px-3 pl-5 py-2.5 mt-4">
            <div class="flex my-auto text-sm font-medium">Leave a {{ user.account_type === AccountType.Club ? 'Review' : 'Validation' }}</div>
            <div class="flex my-auto">
              <svg class="fill-current w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div v-if="loading" class="py-16 m-auto">
      <svg class="animate-spin h-5 w-5 text-brand-light-gray" xmlns="http://www.w3.org/2000/svg" fill="none"
           viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>

    </div>

    <div v-if="!loading && feed.length" class="flex flex-col relative">

      <div v-if="!isAuthUser" class="sticky top-0 w-full flex items-center justify-center pt-6 pb-0 z-50">
        <div
            @click="leaveReview"
            :class="{
                'text-brand-black bg-brand-lime' : !user.has_reviewed,
                'text-white bg-brand-dark-gray' : user.has_reviewed
            }"
            class="flex rounded-full mx-auto space-x-4 z-50 my-auto px-3 pl-5 py-2.5">
          <div v-if="!user.has_reviewed" class="flex my-auto text-sm font-medium">Leave a {{ user.account_type === AccountType.Club ? 'Review' : 'Validation' }} for {{user.name}}</div>
          <div v-if="user.has_reviewed" class="flex my-auto text-sm font-medium">Your {{ user.account_type === AccountType.Club ? 'Review' : 'Validation' }} for {{user.name}}</div>
          <div class="flex my-auto">
            <svg class="fill-current w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>

          </div>
        </div>
      </div>

      <div class="flex flex-col">
       <user-review
           v-for="review in feed"
           :review="review"
           :key="review.id" />
      </div>

      <ion-infinite-scroll v-if="showInfiniteScroll" @ionInfinite="loadMore">
        <ion-infinite-scroll-content class="text-white" loading-spinner="crescent"></ion-infinite-scroll-content>
      </ion-infinite-scroll>

    </div>

  </div>
</template>
<script setup lang="ts">

import {onMounted, onUnmounted, PropType, Ref, ref,} from "vue";
import {UserInterface} from "@/interfaces/UserInterface";
import {IonInfiniteScroll, IonInfiniteScrollContent, isPlatform, modalController,} from "@ionic/vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import ApiService from "@/services/api/apiService";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";
import UserReview from "@/components/UserReview.vue";
import {Haptics, ImpactStyle} from "@capacitor/haptics";
import ReviewUserPage from "@/views/common/ReviewUserPage.vue";
import {UserReviewInterface} from "@/interfaces/UserReviewInterface";
import {AccountType} from "@/definitions/AccountType";
import {useVerification} from "@/composables/useVerification";
import {usePremium} from "@/composables/usePremium";

const loading = ref(true)
const observer = ref()

const feed: Ref<(FeedPostInterface | any)[]> = ref([])
const pagination = ref()
const showInfiniteScroll = ref(true)

const props = defineProps({
  user: {
    type: Object as PropType<UserInterface|UserBasicInformationInterface>,
    required: true
  },
  isAuthUser: {
    type: Boolean,
    default: false
  }
})

onMounted(async () => {

  await getFeed(1)


  observer.value = Observable.subscribe(async (response: any) => {
    if (response.event === ObservableEvent.UserReviewsChanged) {
      await getFeed()
    }
  })

  loading.value = false

})

onUnmounted(() => {
  observer.value?.unsubscribe()
})

const getFeed = async (page = 1) => {
  const response = (await ApiService.User().reviews(props.user.id, page))
  pagination.value = response.meta

  if (pagination.value.current_page >= pagination.value.last_page) showInfiniteScroll.value = false

  if (page === 1) {
    feed.value = response.data
    showInfiniteScroll.value = true
  }

  if (page > 1) {
    feed.value = [...feed.value, ...response.data]
  }

}

const createPost = async () => {
  // const user = await ApiService.User().get()
  //
  // if (!user.id_verified) return
  //
  // const feed = useFeed()
  // await feed.post(user)
}

const loadMore = async (ev: any) => {
  await getFeed(pagination.value.current_page + 1)
  ev.target.complete()
}

const leaveReview = async () => {


  const authUser = await ApiService.User().authUser()

  if (isPlatform('capacitor')) await Haptics.impact({style: ImpactStyle.Light});
  if (authUser && !authUser.subscribed) return  await usePremium().presentPayWall({
    text: `Upgrade to review and validate profiles.`,
    callback: () => {
      //
    }
  });
  if (authUser && !authUser.id_verified) return await useVerification().presentVerificationRequired('You must be verified to review or validate profiles.')

  let review: null | UserReviewInterface = null
  if (props.user.has_reviewed) review = await ApiService.User().reviewForUserId(props.user.id)

  const modal = await modalController.create({
    component: ReviewUserPage,
    mode: 'ios',
    componentProps: {
      user: props.user,
      review,
    },
    presentingElement: document.getElementById('tabs') as HTMLElement,
  })
  await modal.present()

}

</script>
