<template>
  <ion-page>
    <ion-content>

      <div class="bg-brand-black z-50">
        <div class="flex p-4 border-b border-brand-dark-gray border-opacity-80">
          <ion-buttons class="justify-between flex w-full">
            <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
              <svg class="fill-current text-white w-6 h-6 p-0.5" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 320 512">
                <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                    d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
              </svg>
            </ion-button>
            <div class="flex relative">
              <ion-button
                  @click="saveChanges"
                  class="!text-xl text-brand-lime">Save
              </ion-button>
              <div v-if="saving" class="absolute flex top-0 left-0 w-full h-full bg-brand-black z-50">
                <svg class="animate-spin m-auto h-5 w-5 text-brand-lime" xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
          </ion-buttons>
        </div>
      </div>

      <div class="flex flex-col p-4 space-y-2">
        <div class="font-medium text-xl text-white">Gallery</div>
        <div class="flex grid gap-2 grid-cols-3">
          <div @click="addPhoto" class="w-full h-28 bg-brand-dark-gray rounded-md flex">
            <svg v-if="busy" class="animate-spin h-7 w-7 text-white m-auto" xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <svg v-if="!busy" class="m-auto" width="25" height="25" viewBox="0 0 25 25" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M12.5301 5.5L12.5117 19.5" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M5.5 12.5H19.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div
              v-for="(photo,pIndex) in galleryPhotos"
              :key="pIndex"
              :style="{
                'background-image' : `url('${photo}')`
              }"
              class="w-full h-28 bg-brand-dark-gray bg-cover bg-center rounded-md flex p-2">
            <svg @click="removePhoto(pIndex)" class="mt-auto ml-auto" width="25" height="25" viewBox="0 0 25 25"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="25" height="25" rx="12.5" fill="white"/>
              <path
                  d="M16.8921 7L12.5 11.3921L8.10786 7L7 8.10786L11.3921 12.5L7 16.8921L8.10786 18L12.5 13.6079L16.8921 18L18 16.8921L13.6079 12.5L18 8.10786L16.8921 7Z"
                  fill="black"/>
            </svg>
          </div>
        </div>
      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  actionSheetController,
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonPage,
  IonTextarea,
  modalController, toastController,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, onMounted, PropType, Ref, ref} from 'vue';
import {useRoute} from "vue-router";
import InputCheckbox from "@/components/InputCheckbox.vue";
import {PostPrivacy} from "@/definitions/PostPrivacy";
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import {UserGalleryInterface} from "@/interfaces/UserGalleryInterface";

export default defineComponent({
  name: 'GalleryManagement',
  methods: {useRoute},
  components: {
    IonPage,
    IonContent,
    IonButtons,
    IonButton,
  },
  setup(props) {

    const loading = ref(true)
    const busy = ref(false)
    const saving = ref(false)

    const galleryPhotos: Ref<string[]> = ref([])

    onMounted(async () => {
      const userGalleryPhotos = await ApiService.User().gallery(-1)
      galleryPhotos.value = userGalleryPhotos.map((m:UserGalleryInterface)=>{
        return m.url
      })
      loading.value = false
    })

    const goBack = async () => {

      if (busy.value || saving.value) return

      const modal = await alertController.create({
        header: 'Discard Changes',
        message: 'Are you sure you want to discard your changes?',
        buttons: [
          {
            text: 'Yes',
            role: 'destructive',
            handler: () => {
              modalController.dismiss()
            }
          },
          {
            text: 'No',
          },

        ]
      })

      await modal.present()
    }

    const addPhoto = async () => {

      if (busy.value || saving.value) return

      try {
        const photo = await useCamera(CameraSource.Photos, CameraResultType.Base64)
        if (photo.format) {
          busy.value = true
          setTimeout(() => {
            galleryPhotos.value = [...[`data:image/${photo.format};base64,${photo.base64String}`], ...galleryPhotos.value]
            busy.value = false
          }, 2000)
        }
      } catch (e) {
        busy.value = false
      }

    }

    const removePhoto = async (index: number) => {

      if (busy.value || saving.value) return

      const modal = await alertController.create({
        header: 'Remove Photo',
        message: 'Are you sure you want to remove this photo from your gallery?',
        buttons: [
          {
            text: 'Yes',
            role: 'destructive',
            handler: async () => {
              galleryPhotos.value.splice(index, 1)
            }
          },
          {
            text: 'No',
          },

        ]
      })

      await modal.present()

    }

    const saveChanges = async () => {
      try {
        saving.value = true
        await ApiService.User().updateGalleryMedia(galleryPhotos.value)
        await Observable.emit(ObservableEvent.UserGalleryChanged)
        await modalController.dismiss()
      } catch (e:any) {
        const toast = await toastController.create({
          message: e.data.message,
          duration: 3000,
          position: 'bottom',
          color: 'danger'
        });
        await toast.present();
        saving.value = false
      }
    }

    return {
      goBack,
      galleryPhotos,
      addPhoto,
      removePhoto,
      saveChanges,
      busy,
      loading,
      saving,
    }

  }
});
</script>