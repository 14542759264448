<template>
  <div @click="showNotifications" class="flex relative">
    <div v-if="hasNotifications" class="absolute top-0 right-0">
      <span class="relative flex h-2 w-2 mt-0.5 mr-0">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-brand-lime opacity-75"></span>
        <span class="relative inline-flex rounded-full h-2 w-2 bg-brand-lime"></span>
      </span>
    </div>
    <svg
        :class="{
          'text-brand-darkish-gray' : !hasNotifications,
          'text-brand-light-gray' : hasNotifications
        }"
        class="fill-current my-auto" height="24" viewBox="0 0 24 29"  xmlns="http://www.w3.org/2000/svg">
      <path
          d="M12 29C13.65 29 15 27.6615 15 26.0256H9C9 27.6615 10.335 29 12 29ZM21 20.0769V12.641C21 8.07539 18.54 4.25333 14.25 3.24205V2.23077C14.25 0.99641 13.245 0 12 0C10.755 0 9.75 0.99641 9.75 2.23077V3.24205C5.445 4.25333 3 8.06051 3 12.641V20.0769L0 23.0513V24.5385H24V23.0513L21 20.0769Z"
          />
    </svg>
  </div>
</template>
<script setup lang="ts">


import {computed, onMounted, onUnmounted, ref} from "vue";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {useIonRouter} from "@ionic/vue";
import ApiService from "@/services/api/apiService";

const unseen = ref(0)

const observer = ref()

const ionRouter = useIonRouter()

onMounted(async () => {
  observer.value = Observable.subscribe(async (response: any) => {
    if (response.event === ObservableEvent.NotificationsChanged) {
      await getNotifications()
    }
  })
  await getNotifications()
})

const getNotifications = async () => {
  unseen.value = (await ApiService.User().getNotifications()).unseen
}

const showNotifications = async () => {
  ionRouter.navigate({name: 'PineappleNotifications'}, 'forward', 'push')
}

const hasNotifications = computed(() => {
  return unseen.value
})



</script>
