<template>
  <ion-page>
    <ion-content>

      <div class="safe-area-top bg-brand-black sticky top-0 z-40">
        <div class="flex p-4 border-b border-brand-dark-gray border-opacity-80 justify-between">
          <ion-buttons class="justify-between flex">
            <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
              <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </ion-button>
          </ion-buttons>
          <ion-buttons class="justify-between flex opacity-0">
            <ion-button class="bg-brand-dark-gray rounded-md">
              <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </ion-button>
          </ion-buttons>
        </div>
      </div>

      <div class="flex flex-col px-4 py-8">
        <div class="flex flex-col space-y-6">
          <div class="flex flex-col w-full space-y-2 items-center px-10">
            <div class="flex text-white text-2xl font-medium">Tell us more</div>
            <div class="text-brand-light-gray text-lg text-center">Please provide more details to help us understand the problem.</div>
          </div>
          <div class="flex space-x-2">
            <input-textarea
                v-model="reason"
                placeholder="Add details here"
                enterkeyhint="next"
                :counter="true"
                :error="errors && errors.hasOwnProperty('reason') ? errors['reason'][0] : null"
                :maxLength="1000"></input-textarea>
          </div>
        </div>
      </div>

      <div class="px-4 sticky backdrop-blur-md bottom-0 py-5 flex flex-col border-t border-brand-dark-gray border-opacity-80">
        <div class="px-10 text-center text-white text-sm">If we need more information, we’ll contact you at your registered email address.</div>
        <button
            @click="forward"
            :class="{
              'bg-opacity-40' : !reason
            }"
            class="mt-4 flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
          <div class="flex m-auto space-x-2.5">
            <div class="my-auto pt-0.5">
              <div v-if="!busy">Continue</div>
              <div v-if="busy">
                <svg class="animate-spin h-5 w-5 text-brand-black" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
          </div>
        </button>
      <div class="safe-area-bottom"></div>
      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonButtons,
  IonContent,
  IonPage, onIonViewDidEnter,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, inject, onMounted, onUnmounted, Ref, ref} from 'vue';
import {useRoute} from "vue-router";
import {FriendUserInterface} from "@/interfaces/FriendUserInterface";
import ApiService from "@/services/api/apiService";
import MenuButton from "@/components/MenuButton.vue";
import {DateTime} from "luxon";
import {useUser} from "@/composables/useUser";
import InputTextarea from "@/components/InputTextarea.vue";
import {Preferences} from "@capacitor/preferences";

export default defineComponent({
  name: 'ReportUserPage',
  methods: {useRoute},
  components: {
    IonPage,
    IonContent,
    IonButtons,
    IonButton,
    InputTextarea
  },
  setup() {

    const loading = ref(true)
    const busy = ref(false)
    const ionRouter = useIonRouter()
    const route = useRoute()

    const reason = ref('')
    const errors = ref({})


    const goBack = async () => {
      await ionRouter.back()
    }

    onIonViewDidEnter(()=>{
      loading.value = false
    })

    const forward = async () => {
      await Preferences.set({key: 'REPORT_USER_REASON', value: reason.value})
      ionRouter.navigate({
        name: 'ReportUserConfirmationPage',
        params: {
          userId: route.params.userId
        }
      }, 'forward', 'push')
    }

    return {
      goBack,
      busy,
      loading,
      reason,
      errors,
      forward
    }

  }
});
</script>