<template>
  <ion-page>
    <ion-content>

      <div class="flex flex-col w-full bg-brand-black my-auto px-4 pt-14 z-50">

        <div class="flex flex-col space-y-6">
          <div class="flex flex-col w-full space-y-2">
            <div class="flex text-white text-2xl font-medium">Please verify your email address</div>
            <div class="text-brand-light-gray text-lg">We protect our community by making sure everyone on Pineapple is real.</div>
          </div>

          <input-field
              :error="errors && errors['email'] ? errors['email'][0] : undefined"
              v-model="email"
              type="email"
              :autofocus="true"
              @onKeyEnter="registerAccount"
              placeholder="Email address">
            <template v-slot:icon-start>
              <svg class="fill-current" width="24" height="24" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.2 4.80078H4.8C3.81 4.80078 3.009 5.61078 3.009 6.60078L3 17.4008C3 18.3908 3.81 19.2008 4.8 19.2008H19.2C20.19 19.2008 21 18.3908 21 17.4008V6.60078C21 5.61078 20.19 4.80078 19.2 4.80078ZM18.84 8.62578L12.477 12.6038C12.189 12.7838 11.811 12.7838 11.523 12.6038L5.16 8.62578C5.06975 8.57512 4.99073 8.50668 4.9277 8.42459C4.86467 8.3425 4.81896 8.24848 4.79332 8.14821C4.76768 8.04795 4.76265 7.94352 4.77854 7.84126C4.79443 7.73899 4.8309 7.64101 4.88575 7.55325C4.9406 7.46549 5.01269 7.38977 5.09765 7.33068C5.18262 7.27158 5.27868 7.23034 5.38005 7.20945C5.48141 7.18856 5.58595 7.18845 5.68736 7.20913C5.78876 7.22982 5.88492 7.27086 5.97 7.32978L12 11.1008L18.03 7.32978C18.1151 7.27086 18.2112 7.22982 18.3126 7.20913C18.414 7.18845 18.5186 7.18856 18.62 7.20945C18.7213 7.23034 18.8174 7.27158 18.9023 7.33068C18.9873 7.38977 19.0594 7.46549 19.1142 7.55325C19.1691 7.64101 19.2056 7.73899 19.2215 7.84126C19.2373 7.94352 19.2323 8.04795 19.2067 8.14821C19.181 8.24848 19.1353 8.3425 19.0723 8.42459C19.0093 8.50668 18.9302 8.57512 18.84 8.62578Z"
                />
              </svg>
            </template>
          </input-field>


        </div>

        <button
            @click="registerAccount"
            :class="{
              'bg-opacity-40' : !email
            }"
            class="mt-10 flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
          <div class="flex m-auto space-x-2.5">
            <div class="my-auto pt-0.5">
              <div v-if="!busy">Continue</div>
              <div v-if="busy">
                <svg class="animate-spin h-5 w-5 text-brand-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
          </div>
        </button>



      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage, useIonRouter,
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import InputField from "@/components/InputField.vue";
import AuthService from "@/services/api/auth/authService";
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
  name: 'AccountEmailPage',
  components: {
    IonContent,
    IonPage,
    InputField
  },
  setup() {

    const busy = ref(false)
    const authService = new AuthService()
    const email = ref('')
    const errors = ref({})
    const ionRouter = useIonRouter()

    const registerAccount = async () => {
      if (!email.value || busy.value) return
      busy.value = true
      try {
        const password = uuidv4()
        await authService.register(email.value, password)
        await authService.login(email.value, password)

        await ionRouter.navigate('/account/email/not-verified', 'forward', 'push')
        email.value = ''
      } catch (e:any) {
        if (!e.data) return
        if (e.data.errors) errors.value = e.data.errors
      } finally {
        busy.value = false
      }
    }

    return {busy,registerAccount, email, errors}

  }
});
</script>
