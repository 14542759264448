<template>
  <ion-page>
    <ion-content>

      <div class="safe-area-top bg-brand-black sticky top-0 z-40">
        <div class="flex p-4 border-b border-brand-dark-gray border-opacity-80 justify-between">
          <ion-buttons class="justify-between flex">
            <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
              <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </ion-button>
          </ion-buttons>
          <ion-buttons class="justify-between flex opacity-0">
            <ion-button class="bg-brand-dark-gray rounded-md">
              <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </ion-button>
          </ion-buttons>
        </div>
      </div>

      <template v-if="loading">
        <div class="flex w-full h-96 py-80">
          <svg class="animate-spin h-10 w-10 m-auto text-white" xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </template>
      <template v-if="!loading">
        <div class="flex px-4 flex-col space-y-8 my-auto py-8">

          <div class="flex flex-col w-full space-y-1 px-2">
            <div class="flex text-white text-2xl font-medium">Block {{ user.name }}</div>
            <div class="text-brand-light-gray text-base">Blocking {{ user.name }} will prevent them from interacting
              with your account in the following ways:
            </div>
          </div>

          <div class="flex flex-col w-full space-y-2 px-2">
            <div class="text-brand-light-gray text-base">• Won’t be able to see your profile.</div>
            <div class="text-brand-light-gray text-base">• Won't be able to see your posts.</div>
            <div class="text-brand-light-gray text-base">• Won't be able to see your messages in group chats.</div>
            <div class="text-brand-light-gray text-base">• They will be removed from any group chats where you are the group admin.</div>
            <div class="text-brand-light-gray text-base">• Direct messages between you and {{ user.name }} will be hidden for both parties.</div>
            <div class="text-brand-light-gray text-base">• You and {{ user.name }} will no longer be friends.</div>
            <div class="text-brand-light-gray text-base">• You won't receive messages from {{ user.name }}.</div>
            <div class="text-brand-light-gray text-base">• You won't be able to see posts by {{ user.name }}.</div>
            <div class="text-brand-light-gray text-base">• You can unblock {{ user.name }} from your profile settings.</div>
          </div>

        </div>

        <div
            class="px-4 sticky backdrop-blur-md bottom-0 py-5 flex flex-col border-t border-brand-dark-gray border-opacity-80">
          <button
              @click="blockUser"
              class="mt-4 flex w-full bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
            <div class="flex m-auto space-x-2.5">
              <div class="my-auto pt-0.5">
                <div v-if="!busy">Block {{ user.name }}</div>
                <div v-if="busy">
                  <svg class="animate-spin h-5 w-5 text-brand-black" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              </div>
            </div>
          </button>
          <div class="safe-area-bottom"></div>
        </div>
      </template>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonButtons,
  IonContent,
  IonPage,
  onIonViewWillEnter,
  toastController,
  useIonRouter,
} from '@ionic/vue';
import {defineComponent, Ref, ref} from 'vue';
import {useRoute} from "vue-router";
import {FriendUserInterface} from "@/interfaces/FriendUserInterface";
import ApiService from "@/services/api/apiService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";

export default defineComponent({
  name: 'BlockUserPage',
  methods: {useRoute},
  components: {
    IonPage,
    IonContent,
    IonButtons,
    IonButton,
  },
  setup() {

    const loading = ref(true)
    const busy = ref(false)
    const ionRouter = useIonRouter()
    const route = useRoute()

    const reason = ref('')
    const errors = ref({})

    const user: Ref<FriendUserInterface | UserBasicInformationInterface | undefined> = ref()


    const goBack = async () => {
      await ionRouter.back()
    }

    onIonViewWillEnter(async () => {
      user.value = await ApiService.User().getById(Number(route.params.userId))
      loading.value = false
    })

    const blockUser = async () => {
      if (!user.value || busy.value) return
      try {
        busy.value = true
        await ApiService.User().blockUser(user.value.id)
        await Observable.emit(ObservableEvent.FriendsChanged)
        ionRouter.back()
        const toast = await toastController.create({
          header: 'Blocked',
          message: `You've blocked ${user.value?.name} 🚫`,
          duration: 3000,
          position: 'bottom',
          color: 'primary'
        });
        await toast.present();

      } catch (e) {
        //
      } finally {
        busy.value = false
      }
    }

    return {
      goBack,
      busy,
      loading,
      reason,
      errors,
      user,
      blockUser
    }

  }
});
</script>