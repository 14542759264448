<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="mr-4">
          <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
            <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title class="text-white font-medium text-2xl">Settings</ion-title>
        <ion-buttons slot="end" class="">
          <div @click="shareUrl" class="flex px-2 py-2">
            <svg class="h-5 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M241 7c-9.4-9.4-24.6-9.4-33.9 0L79 135c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l87-87V328c0 13.3 10.7 24 24 24s24-10.7 24-24V81.9l87 87c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L241 7zM48 344c0-13.3-10.7-24-24-24s-24 10.7-24 24v80c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V344c0-13.3-10.7-24-24-24s-24 10.7-24 24v80c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V344z"/></svg>
          </div>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="h-full">

      <div
          @click="changePhoto"
          :style="{
          backgroundImage: `url('${user && user.cover_photo}')`
        }"
          class="flex relative w-full aspect-auto bg-cover bg-center h-64 bg-brand-dark-gray p-4">
        <div class="absolute bottom-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-brand-black"></div>
      </div>

      <div class="flex flex-col pb-10 px-4 justify-center -mt-10 space-y-10">

        <div @click="changePhoto" class="flex text-center">
          <div class="flex border-[4px] rounded-full border-brand-black z-50">
            <div
                :style="{
                'background-image' : `url('${ user && user.avatar}')`
              }"
                class="w-24 h-24 bg-center bg-cover bg-brand-dark-gray rounded-full relative">
            </div>
          </div>

          <div class="flex rounded-xl mx-auto space-x-2 text-brand-black bg-brand-lime z-50 my-auto px-3 py-2.5">
            <div class="flex my-auto">
              <svg v-if="avatarBusy" class="animate-spin m-auto h-4 w-4 tex1t-brand-black"
                   xmlns="http://www.w3.org/2000/svg" fill="none"
                   viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <svg v-if="!avatarBusy" class="m-auto" width="16" viewBox="0 0 15 12" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.15339 2.43561C1.68069 2.43561 1.22736 2.62339 0.893115 2.95763C0.55887 3.29188 0.371094 3.74521 0.371094 4.2179L0.371094 9.56478C0.371094 10.0375 0.55887 10.4908 0.893115 10.825C1.22736 11.1593 1.68069 11.3471 2.15339 11.3471H12.8471C13.3198 11.3471 13.7732 11.1593 14.1074 10.825C14.4417 10.4908 14.6294 10.0375 14.6294 9.56478V4.2179C14.6294 3.74521 14.4417 3.29188 14.1074 2.95763C13.7732 2.62339 13.3198 2.43561 12.8471 2.43561H11.8027C11.3301 2.43551 10.8768 2.24767 10.5426 1.9134L9.80476 1.17553C9.4706 0.841264 9.01734 0.653421 8.54468 0.65332H6.45584C5.98318 0.653421 5.52992 0.841264 5.19576 1.17553L4.45789 1.9134C4.12372 2.24767 3.67046 2.43551 3.19781 2.43561H2.15339ZM2.59896 4.2179C2.48079 4.2179 2.36745 4.17096 2.28389 4.0874C2.20033 4.00384 2.15339 3.8905 2.15339 3.77233C2.15339 3.65416 2.20033 3.54082 2.28389 3.45726C2.36745 3.3737 2.48079 3.32676 2.59896 3.32676C2.71713 3.32676 2.83046 3.3737 2.91403 3.45726C2.99759 3.54082 3.04453 3.65416 3.04453 3.77233C3.04453 3.8905 2.99759 4.00384 2.91403 4.0874C2.83046 4.17096 2.71713 4.2179 2.59896 4.2179ZM10.6193 6.44577C10.6193 7.27298 10.2907 8.06631 9.70573 8.65124C9.12081 9.23617 8.32747 9.56478 7.50026 9.56478C6.67305 9.56478 5.87971 9.23617 5.29479 8.65124C4.70986 8.06631 4.38125 7.27298 4.38125 6.44577C4.38125 5.61856 4.70986 4.82522 5.29479 4.2403C5.87971 3.65537 6.67305 3.32676 7.50026 3.32676C8.32747 3.32676 9.12081 3.65537 9.70573 4.2403C10.2907 4.82522 10.6193 5.61856 10.6193 6.44577Z"
                    fill="#1A1B22"/>
              </svg>
            </div>
            <div class="flex my-auto text-sm font-medium">Edit Profile Photos</div>
          </div>

        </div>


        <join-premium-card v-if="!isSubscribed && !loading"/>

        <menu-button @click="privacyPin" name="Privacy PIN"/>

        <menu-button @click="manageGallery" name="Edit Gallery"/>

        <div class="flex flex-col space-y-2">
          <menu-button @click="personalDetails"
                       :name="user.account_type === AccountType.Club ? 'Business Details' : 'Profile'"/>
          <menu-button v-if="![AccountType.Joint,AccountType.Club].includes(user.account_type)" @click="addPartner"
                       name="Relationship"/>
          <menu-button @click="accountSettings" name="Account"/>
          <menu-button @click="accountNotifications" name="Notifications"/>
          <menu-button @click="accountPrivacy" name="Privacy"/>
          <menu-button @click="accountBlocked" name="Blocked Users"/>
        </div>


        <div class="flex flex-col space-y-2">
          <a href="https://pineapplelifestyle.app/join-the-naughty-party/community-guidelines.html"
             target="_system">
            <menu-button name="Community Guidelines"/>
          </a>
          <a href="https://pineapple-public.s3.eu-west-2.amazonaws.com/terms-of-use-eula.pdf"
             target="_system">
            <menu-button name="Terms of Use (EULA)"/>
          </a>
          <a href="https://pineapple-public.s3.eu-west-2.amazonaws.com/app-privacy-policy.pdf"
             target="_system">
            <menu-button name="Privacy Policy"/>
          </a>
        </div>

        <menu-button @click="reportAProblem" name="Report a Problem"/>


        <button
            @click="logout"
            class="mx-auto w-full flex bg-brand-lime rounded-full px-8 h-14 font-medium text-xl text-brand-black transition-all">
          <div class="flex m-auto space-x-2.5">
            <div class="my-auto pt-0.5">
              <div>Log out</div>
            </div>
          </div>
        </button>

        <div class="text-center text-brand-light-gray text-xs mx-auto">Version {{ appVersion }}</div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  actionSheetController, alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar, modalController,
  onIonViewWillEnter, toastController,
  useIonRouter,
} from '@ionic/vue';
import {computed, inject, onMounted, Ref, ref} from 'vue';
import ApiService from "@/services/api/apiService";
import {DateTime} from "luxon";
import MenuButton from "@/components/MenuButton.vue";
import AuthService from "@/services/api/auth/authService";
import {Preferences} from "@capacitor/preferences";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import JoinPremiumCard from "@/components/JoinPremiumCard.vue";
import useCamera from "@/composables/useCamera";
import {CameraResultType, CameraSource} from "@capacitor/camera";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";
import {useUser} from "@/composables/useUser";
import ProfileSettingsPersonal from "@/views/user/profile/ProfileSettingsPersonal.vue";
import OneSignal from "onesignal-cordova-plugin";
import {AccountType} from "@/definitions/AccountType";
import PinPad from "@/views/common/PinPad.vue";
import {Browser} from "@capacitor/browser";
import StripeSubscriptionPage from "@/views/common/StripeSubscriptionPage.vue";
import MeetSheet from "@/views/common/MeetSheet.vue";
import ProfilePictureSubmittedSheet from "@/views/common/ProfilePictureSubmittedSheet.vue";
import CoverPhotoSubmittedSheet from "@/views/common/CoverPhotoSubmittedSheet.vue";
import useClipboard from 'vue-clipboard3'

const user: Ref<UserInterface | undefined> = inject('user') as Ref<UserInterface | undefined>

const loading = ref(true)
const ionRouter = useIonRouter()


const appVersion = inject('appVersion')

const stripeBilling = ref()


onMounted(async () => {
  loading.value = false
})


const isSubscribed = computed(() => {
  return user.value?.subscribed;
})

const goBack = async () => {
  await ionRouter.back()
}

const logout = async () => {
  const authService = new AuthService()
  await authService.logout()
  ionRouter.navigate({name: 'Welcome'}, 'back', 'pop')
}

const gotoVerifyID = async () => {
  await ionRouter.navigate({name: 'ProfileSettingsVerify'}, 'forward', 'push')
}

const manageGallery = async () => {
  const user = useUser()
  await user.manageGallery()
}

const avatarBusy = ref(false)


const changePhoto = async () => {

  const sheet = await actionSheetController.create({
    buttons: [
      {
        text: 'Profile Picture',
        handler: async () => {
          await actionSheetController.dismiss()
          await changeProflePhoto()
        }
      },
      {
        text: 'Cover Photo',
        handler: async () => {
          await actionSheetController.dismiss()
          await changeCoverPhoto()
        }
      }
    ]
  })

  await sheet.present()

}


const changeProflePhoto = async () => {

  if (avatarBusy.value) return

  try {
    const image = await useCamera(CameraSource.Photos, CameraResultType.Base64)
    avatarBusy.value = true
    if (image && image.base64String) user.value = await ApiService.User().setProfilePhoto(image.base64String)
    Observable.emit(ObservableEvent.UserChanged)
    avatarBusy.value = false


    const sheet = await modalController.create({
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      backdropDismiss: false,
      cssClass: 'auto',
      component: ProfilePictureSubmittedSheet,
      mode: 'ios'
    })
    await sheet.present()

  } catch (e) {
    avatarBusy.value = false
  }
}

const changeCoverPhoto = async () => {

  if (avatarBusy.value) return

  try {
    const image = await useCamera(CameraSource.Photos, CameraResultType.Base64)
    avatarBusy.value = true
    if (image && image.base64String) user.value = await ApiService.User().setCoverPhoto(image.base64String)
    Observable.emit(ObservableEvent.UserChanged)
    avatarBusy.value = false


    const sheet = await modalController.create({
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      backdropDismiss: false,
      cssClass: 'auto',
      component: CoverPhotoSubmittedSheet,
      mode: 'ios'
    })
    await sheet.present()

  } catch (e) {
    avatarBusy.value = false
  }
}

const personalDetails = async () => {
  await ionRouter.navigate({name: 'ProfileSettingsPersonal'}, 'forward', 'push')
}

const accountSettings = async () => {
  await ionRouter.navigate({name: 'ProfileSettingsAccount'}, 'forward', 'push')
}

const accountNotifications = async () => {
  ionRouter.navigate({
    name: 'ProfileSettingsAccountNotifications'
  }, 'forward', 'push')
}

const accountBlocked = async () => {
  ionRouter.navigate({
    name: 'ProfileSettingsAccountBlocked'
  }, 'forward', 'push')
}

const accountPrivacy = async () => {
  ionRouter.navigate({
    name: 'ProfileSettingsAccountPrivacy'
  }, 'forward', 'push')
}

const addPartner = async () => {
  await ionRouter.navigate({name: 'ProfileSettingsPartner'}, 'forward', 'push')
}

const reportAProblem = async () => {
  await ionRouter.navigate({name: 'ProfileSettingsReportAProblem'}, 'forward', 'push')
}

const privacyPin = async () => {

  const storagePin = (await Preferences.get({key: PreferenceKey.PrivacyPin})).value
  if (typeof storagePin === 'string' && storagePin.length === 4) {

    const sheet = await actionSheetController.create({
      buttons: [
        {
          text: 'Change PIN',
          handler: async () => {

            const modal = await modalController.create({
              backdropDismiss: true,
              breakpoints: [0, 1],
              initialBreakpoint: 1,
              cssClass: 'auto',
              component: PinPad
            })

            await modal.present()
            const {data} = await modal.onDidDismiss()
            if (data && data.validated) {
              await Preferences.remove({key: PreferenceKey.PrivacyPin})
              Observable.emit(ObservableEvent.ShowPrivacyScreen, true)
            }

          }
        },
        {
          text: 'Remove PIN',
          role: 'destructive',
          handler: async () => {

            const modal = await modalController.create({
              backdropDismiss: true,
              breakpoints: [0, 1],
              initialBreakpoint: 1,
              cssClass: 'auto',
              component: PinPad
            })

            await modal.present()
            const {data} = await modal.onWillDismiss()
            if (data && data.validated) {
              await Preferences.set({key: PreferenceKey.PrivacyPin, value: ''})
            }

          }
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    })

    await sheet.present()

    return
  }

  Observable.emit(ObservableEvent.ShowPrivacyScreen, true)

}

const manageSubscription = async () => {

  if (!isSubscribed.value) {
    const modal = await modalController.create({
      component: StripeSubscriptionPage
    })
    return await modal.present()
  }

  if (!stripeBilling.value) {
    const alert = await alertController.create({
      header: 'Subscription',
      message: 'Billing portal is currently unavailable, please try again in a few seconds.',
      buttons: [
        {
          text: 'Ok'
        }
      ]
    })
    return await alert.present()
  }

  window.open(stripeBilling.value.url)

}

const shareUrl = async () => {



  if (!parseInt(user.value?.preferences['PUBLIC_PROFILE_ENABLED'])) {

    const alert = await alertController.create({
      header: 'Public Web Profile',
      message: 'To share your profile url you must enable "Public Web Profile" under Settings -> Privacy.',
      buttons: [
        { text: 'Ok'}
      ]

    })

    return await alert.present()

  }


  await useClipboard().toClipboard(`https://pineapplelifestyle.app/@${user.value?.slug}`)

  const toast = await toastController.create({
    message: 'Your profile url has been copied to your clipboard.',
    duration: 2000,
    color: 'primary'
  })

  await toast.present()

}


</script>
