<template>
  <ion-page>
    <ion-content>

      <div class="flex flex-col w-full bg-brand-black my-auto h-full px-6 z-50">

        <div class="flex flex-col m-auto space-y-14 items-center">


          <div class="flex flex-col space-y-4 items-center">
            <svg width="111" height="110" viewBox="0 0 111 110" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" width="110" height="110" rx="55" fill="#EBFF00"/>
              <path d="M56.9592 40.1687C56.3884 39.0896 54.6131 39.0896 54.0422 40.1687L39.1929 68.2173C39.0593 68.4686 38.993 68.7504 39.0006 69.035C39.0082 69.3196 39.0894 69.5974 39.2362 69.8414C39.3831 70.0853 39.5907 70.287 39.8387 70.4268C40.0867 70.5667 40.3667 70.6399 40.6515 70.6393H70.35C70.6345 70.6399 70.9143 70.5668 71.1622 70.427C71.41 70.2873 71.6174 70.0857 71.7642 69.8419C71.9109 69.5981 71.9919 69.3204 71.9994 69.036C72.0069 68.7516 71.9406 68.4701 71.8068 68.2189L56.9592 40.1687ZM57.1506 65.6896H53.8508V62.3897H57.1506V65.6896ZM53.8508 59.0899V50.8403H57.1506L57.1523 59.0899H53.8508Z" fill="#1A1B22"/>
            </svg>
            <div class="flex flex-col w-full items-center space-y-2">
              <div class="flex m-auto text-white text-2xl font-medium text-center">Your privacy and protection is our main concern</div>
              <div class="m-auto text-brand-light-gray text-lg text-center">We will never share your personal details or post on social media.</div>
            </div>
          </div>

          <div class="flex flex-col space-y-2 w-full">
            <button
                @click="forward"
                class="flex bg-brand-lime rounded-full w-full h-14 font-medium text-xl text-brand-black">
              <div class="flex m-auto space-x-2.5">
                <div class="my-auto pt-0.5">
                  Let's get stared
                </div>
              </div>
            </button>
          </div>
        </div>

      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonInput
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'AccountPrivacyPage',
  components: {
    IonContent,
    IonPage,
  },
  setup() {

    const router = useRouter()

    const forward = async () => {
      await router.push({name: 'AccountEmail'})
    }

    return {forward}

  }
});
</script>
