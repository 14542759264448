<template>
  <ion-page>
    <ion-content>

      <div class="safe-area-top bg-brand-black sticky top-0 z-40">
        <div class="flex p-4 border-b border-brand-dark-gray border-opacity-80 justify-between">
          <ion-buttons class="justify-between flex">
            <ion-button @click="goBack" class="bg-brand-dark-gray rounded-md">
              <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </ion-button>
          </ion-buttons>
          <ion-buttons class="justify-between flex opacity-0">
            <ion-button class="bg-brand-dark-gray rounded-md">
              <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 11.9961H18" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12 18L6 12L12 6" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </ion-button>
          </ion-buttons>
        </div>
      </div>

      <template v-if="loading">
        <div class="flex w-full h-96 py-80">
          <svg class="animate-spin h-10 w-10 m-auto text-white" xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </template>

      <div class=" text-white px-4 space-y-6 " v-if="!loading">

        <div class="flex flex-col items-center py-10 pb-4">
          <div class="flex">
            <div
                :style="{
                'background-image' : `url('${user.avatar_thumbnail ?? user.avatar}')`
              }"
                class="w-32 h-32 bg-center bg-cover bg-brand-dark-gray rounded-full relative">
            </div>
          </div>

          <div class="flex flex-col items-center mt-5 space-y-1">
            <div class="font-medium text-white text-2xl">{{ user.name }}</div>
            <div v-if="false" class="font-medium text-brand-light-gray text-base">{{ friendsSince }}</div>
          </div>
        </div>

        <div class="flex flex-col space-y-2">
          <menu-button v-if="false" name="View profile">
            <template v-slot:start>
              <svg  width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_461_179360)">
                  <g clip-path="url(#clip1_461_179360)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 7.40479C16.5 9.89007 14.4853 11.9048 12 11.9048C9.51472 11.9048 7.5 9.89007 7.5 7.40479C7.5 4.91951 9.51472 2.90479 12 2.90479C14.4853 2.90479 16.5 4.91951 16.5 7.40479ZM4 22.9048C3.44772 22.9048 3 22.4571 3 21.9048V20.9048C2.99999 17.0388 6.134 13.9048 10 13.9048H14C17.866 13.9048 21 17.0388 21 20.9048V21.9048C21 22.4571 20.5523 22.9048 20 22.9048H4Z" fill="white"/>
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_461_179360">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.904785)"/>
                  </clipPath>
                  <clipPath id="clip1_461_179360">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.904785)"/>
                  </clipPath>
                </defs>
              </svg>
            </template>
          </menu-button>
          <menu-button v-if="user.befriended_at && false" name="Message">
            <template v-slot:start>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_461_179399)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.37546 6.57435C2.66565 4.88966 4.00472 3.65126 5.69477 3.39411C7.31411 3.14772 9.53559 2.90479 12 2.90479C14.4644 2.90479 16.6859 3.14772 18.3052 3.39411C19.9953 3.65126 21.3344 4.88966 21.6245 6.57435C21.8268 7.7485 22 9.24004 22 10.9048C22 12.5695 21.8268 14.0611 21.6245 15.2352C21.3344 16.9199 19.9953 18.1583 18.3052 18.4155C16.8238 18.6409 14.8384 18.8634 12.6241 18.8996L6.50873 22.5133C5.84211 22.9072 5 22.4266 5 21.6523V18.2487C3.64656 17.7987 2.62456 16.6814 2.37546 15.2352C2.17321 14.0611 2 12.5695 2 10.9048C2 9.24004 2.17321 7.7485 2.37546 6.57435ZM6 8.90479C6 8.3525 6.44772 7.90479 7 7.90479H17C17.5523 7.90479 18 8.3525 18 8.90479C18 9.45707 17.5523 9.90479 17 9.90479H7C6.44771 9.90479 6 9.45707 6 8.90479ZM6 12.9048C6 12.3525 6.44772 11.9048 7 11.9048H11C11.5523 11.9048 12 12.3525 12 12.9048C12 13.4571 11.5523 13.9048 11 13.9048H7C6.44772 13.9048 6 13.4571 6 12.9048Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_461_179399">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.904785)"/>
                  </clipPath>
                </defs>
              </svg>
            </template>
          </menu-button>
          <menu-button @click="removeFriend" v-if="user.befriended_at" name="Remove from Friends">
            <template v-slot:start>
              <svg class="" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_461_179411)">
                  <path d="M12.8 8.50479C12.8 9.45956 12.4207 10.3752 11.7456 11.0504C11.0705 11.7255 10.1548 12.1048 9.2 12.1048C8.24522 12.1048 7.32955 11.7255 6.65442 11.0504C5.97929 10.3752 5.6 9.45956 5.6 8.50479C5.6 7.55001 5.97929 6.63433 6.65442 5.9592C7.32955 5.28407 8.24522 4.90479 9.2 4.90479C10.1548 4.90479 11.0705 5.28407 11.7456 5.9592C12.4207 6.63433 12.8 7.55001 12.8 8.50479ZM16.4 21.7048C16.4 19.7952 15.6414 17.9639 14.2912 16.6136C12.9409 15.2634 11.1096 14.5048 9.2 14.5048C7.29044 14.5048 5.45909 15.2634 4.10883 16.6136C2.75857 17.9639 2 19.7952 2 21.7048H16.4ZM15.2 10.9048C14.8817 10.9048 14.5765 11.0312 14.3515 11.2563C14.1264 11.4813 14 11.7865 14 12.1048C14 12.423 14.1264 12.7283 14.3515 12.9533C14.5765 13.1784 14.8817 13.3048 15.2 13.3048H20C20.3183 13.3048 20.6235 13.1784 20.8485 12.9533C21.0736 12.7283 21.2 12.423 21.2 12.1048C21.2 11.7865 21.0736 11.4813 20.8485 11.2563C20.6235 11.0312 20.3183 10.9048 20 10.9048H15.2Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_461_179411">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.904785)"/>
                  </clipPath>
                </defs>
              </svg>
            </template>
            <template v-slot:end><div></div></template>
          </menu-button>
          <menu-button @click="friendRequest" v-if="false" :name="`Wink at ${user.name}`">
            <template v-slot:start>
              <svg class="w-6 h-6 text-white fill-current" width="75" height="74" viewBox="0 0 75 74" xmlns="http://www.w3.org/2000/svg">
                <path d="M37.5006 0C17.2891 0 0.845703 16.4434 0.845703 36.6549C0.845703 56.8664 17.2891 73.3098 37.5006 73.3098C57.7121 73.3098 74.1555 56.8664 74.1555 36.6549C74.1555 16.4434 57.7121 0 37.5006 0Z" />
                <path d="M57.344 45.3167C58.4435 46.0038 58.7864 47.4604 57.9969 48.4889C55.9488 51.1572 53.3663 53.3809 50.4055 55.0124C46.7574 57.0225 42.6561 58.0668 38.4909 58.0461C34.3257 58.0254 30.235 56.9404 26.6071 54.8941C23.6626 53.2332 21.1024 50.984 19.0809 48.2954C18.3017 47.2591 18.659 45.806 19.7653 45.1298V45.1298C20.8716 44.4537 22.3061 44.8129 23.1088 45.8312C24.6966 47.8454 26.6667 49.5371 28.9137 50.8045C31.8446 52.4577 35.1494 53.3342 38.5142 53.3509C41.8791 53.3677 45.1924 52.524 48.1396 50.9001C50.3991 49.6551 52.3859 47.9831 53.9937 45.9847C54.8064 44.9745 56.2445 44.6295 57.344 45.3167V45.3167Z" fill="#1A1B22"/>
                <path d="M24.5105 40.1582C25.6758 40.2425 26.5724 41.2661 26.2606 42.392C26.0533 43.1407 25.7645 43.8666 25.3979 44.5563C24.6993 45.8709 23.7338 47.0251 22.5633 47.9449C21.3928 48.8648 20.043 49.53 18.6006 49.8981C17.8437 50.0912 17.0701 50.2003 16.2937 50.2247C15.1259 50.2615 14.3434 49.1483 14.5369 47.9961V47.9961C14.7304 46.8439 15.8401 46.1029 16.9933 45.9156C17.1817 45.8849 17.3689 45.846 17.5545 45.7986C18.4262 45.5762 19.2417 45.1742 19.949 44.6184C20.6563 44.0625 21.2397 43.3651 21.6619 42.5708C21.7518 42.4016 21.834 42.2289 21.9082 42.0531C22.3629 40.9768 23.3453 40.0738 24.5105 40.1582V40.1582Z" fill="#1A1B22"/>
                <path d="M32.9536 30.0211C33.9533 29.3009 34.1629 27.8739 33.2204 27.0804C32.4583 26.4388 31.6057 25.8977 30.6849 25.4747C29.0674 24.7316 27.287 24.3744 25.4949 24.4336C23.7028 24.4927 21.9521 24.9664 20.3917 25.8144C19.5056 26.2959 18.6958 26.89 17.9827 27.5781C17.0949 28.4348 17.4025 29.8458 18.45 30.4975V30.4975C19.4438 31.1157 20.7341 30.7992 21.6606 30.0839C21.9433 29.8657 22.2449 29.669 22.5627 29.4963C23.5056 28.9839 24.5635 28.6977 25.6463 28.6619C26.7292 28.6262 27.8051 28.842 28.7824 29.291C29.1166 29.4445 29.4358 29.6237 29.7373 29.8263C30.7047 30.4766 32.0077 30.7024 32.9536 30.0211V30.0211Z" fill="#1A1B22"/>
                <path d="M51.5546 32.8109C54.0696 32.8109 56.1406 30.849 56.1406 28.39C56.1406 25.9309 54.0696 23.9691 51.5546 23.9691C49.0396 23.9691 46.9686 25.9309 46.9686 28.39C46.9686 30.849 49.0396 32.8109 51.5546 32.8109Z" fill="#1A1B22" stroke="#1A1B22" stroke-width="0.916372"/>
              </svg>
            </template>
            <template v-slot:end><div></div></template>
          </menu-button>
        </div>

        <div class="flex flex-col space-y-4">
          <div class="font-medium text-lg text-brand-light-gray">Privacy & support</div>
          <div class="flex flex-col space-y-2">
            <menu-button @click="reportUser" name="Report">
              <template v-slot:start>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_461_179420)">
                    <path d="M22.3998 21.1548L12.6498 4.27979C12.5045 4.029 12.2537 3.90479 12.0006 3.90479C11.7475 3.90479 11.4943 4.029 11.3514 4.27979L1.60138 21.1548C1.31309 21.6563 1.67403 22.2798 2.25059 22.2798H21.7506C22.3272 22.2798 22.6881 21.6563 22.3998 21.1548ZM11.2506 10.8423C11.2506 10.7392 11.335 10.6548 11.4381 10.6548H12.5631C12.6662 10.6548 12.7506 10.7392 12.7506 10.8423V15.1548C12.7506 15.2579 12.6662 15.3423 12.5631 15.3423H11.4381C11.335 15.3423 11.2506 15.2579 11.2506 15.1548V10.8423ZM12.0006 19.0923C11.7062 19.0863 11.4259 18.9651 11.2198 18.7548C11.0137 18.5445 10.8983 18.2617 10.8983 17.9673C10.8983 17.6728 11.0137 17.3901 11.2198 17.1798C11.4259 16.9695 11.7062 16.8483 12.0006 16.8423C12.295 16.8483 12.5753 16.9695 12.7814 17.1798C12.9874 17.3901 13.1029 17.6728 13.1029 17.9673C13.1029 18.2617 12.9874 18.5445 12.7814 18.7548C12.5753 18.9651 12.295 19.0863 12.0006 19.0923Z" fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_461_179420">
                      <rect width="24" height="24" fill="white" transform="translate(0.5 0.904785)"/>
                    </clipPath>
                  </defs>
                </svg>
              </template>
            </menu-button>
            <menu-button v-if="!blocked" @click="blockUser" name="Block">
              <template v-slot:start>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_461_33489)">
                    <path d="M12.4996 3.00488C10.5416 3.00488 8.62752 3.58551 6.99947 4.67333C5.37142 5.76116 4.10251 7.30733 3.35321 9.11632C2.6039 10.9253 2.40785 12.9159 2.78984 14.8363C3.17183 16.7567 4.11472 18.5207 5.49926 19.9052C6.8838 21.2898 8.64781 22.2327 10.5682 22.6147C12.4886 22.9967 14.4792 22.8006 16.2882 22.0513C18.0972 21.302 19.6433 20.0331 20.7312 18.405C21.819 16.777 22.3996 14.8629 22.3996 12.9049C22.3996 10.2792 21.3566 7.76114 19.5 5.90453C17.6434 4.04792 15.1253 3.00488 12.4996 3.00488ZM17.9996 14.0049H6.99961V11.8049H17.9996V14.0049Z" fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_461_33489">
                      <rect width="22" height="22" fill="white" transform="translate(1.5 1.90479)"/>
                    </clipPath>
                  </defs>
                </svg>
              </template>
            </menu-button>
          </div>
        </div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonPage, toastController,
  useIonRouter,
} from '@ionic/vue';
import {computed, defineComponent, inject, onMounted, onUnmounted, Ref, ref} from 'vue';
import {useRoute} from "vue-router";
import {FriendUserInterface} from "@/interfaces/FriendUserInterface";
import ApiService from "@/services/api/apiService";
import MenuButton from "@/components/MenuButton.vue";
import {DateTime} from "luxon";
import {useUser} from "@/composables/useUser";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {UserInterface} from "@/interfaces/UserInterface";

export default defineComponent({
  name: 'FriendPage',
  methods: {useRoute},
  components: {
    IonPage,
    IonContent,
    IonButtons,
    IonButton,
    MenuButton
  },
  setup() {

    const loading = ref(true)
    const busy = ref(false)
    const ionRouter = useIonRouter()
    const user: Ref<FriendUserInterface | UserInterface | undefined> = ref()
    const route = useRoute()
    const observer = ref()

    const blocked = ref(false)


    const goBack = async () => {
      ionRouter.back()
    }

    const getData = async () => {
      try {
        user.value = await ApiService.User().getById(Number(route.params.userId))
      } catch (e) {
        if (!user.value) return
        blocked.value = true
      }
      loading.value = false
    }

    onMounted(async () => {
      await getData()
      observer.value = Observable.subscribe(async (response:any) => {
        if (response.event === ObservableEvent.FriendsChanged) {
          await getData()
        }
      })
    })

    const removeFriend = async () => {
      try {
        await useUser().removeFriend(user.value as FriendUserInterface)
        ionRouter.back()
      } catch (e) {
        //
      }
    }

    const friendRequest = async () => {
      if (!user.value) return
      user.value = await useUser().makeFriendRequest(user.value?.id)
    }

    const reportUser = async () => {
      ionRouter.navigate({
        name: 'ReportUserPage',
        params: {
          userId: user.value?.id
        }
      }, 'forward', 'push')
    }

    const blockUser = async () => {
      ionRouter.navigate({
        name: 'BlockUserPage',
        params: {
          userId: user.value?.id
        }
      }, 'forward', 'push')
    }

    return {
      goBack,
      busy,
      loading,
      user,
      removeFriend,
      friendRequest,
      reportUser,
      blockUser,
      blocked
    }

  }
});
</script>