<template>
  <div class="flex flex-col items-center p-5 py-10 space-y-10">


    <div class="flex flex-col space-y-6 items-center">
      <div class="flex text-brand-light-gray">
        <div class="w-10 h-10 flex bg-brand-light-gray rounded-full">
          <svg class="m-auto h-5 fill-current text-brand-black my-auto" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 448 512">
            <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
          </svg>
        </div>
      </div>

      <div class="flex flex-col w-full items-center space-y-1 px-2">
        <div class="flex text-white text-2xl font-medium text-center">Verification Required</div>
        <div class="text-brand-light-gray text-lg text-center">{{text}}</div>
      </div>

    </div>

  </div>
</template>

<script setup lang="ts">

import {onMounted, ref} from 'vue';
import {Observable, ObservableEvent} from "@/services/observable/Observable";

const loading = ref(true)

const props = defineProps({
  text: {
    type: String,
    default: 'You must be verified to perform this action.'
  }
})

onMounted(async () => {
  loading.value = false
})
</script>